import { useNavigate } from "react-router-dom"
import { TaskCard } from "./TaskCard"

type taskProps = {
  taskArray?: any
}

export const TaskTagsRoutine: React.FC<taskProps> = ({ taskArray = [] }) => {
  const navigate = useNavigate()
  return (
    <>
      {taskArray?.length === 0 ? (
        <div className="flex mt-[100px] h-full items-center justify-center ">
          <h6 className="text-sm  text-neutral700">No Available Tasks</h6>
        </div>
      ) : (
        <div>
          {taskArray?.map((task: any, index: any) => (
            <TaskCard
              key={index}
              title={task?.description}
              date={task?.due_date}
              description={task?.alias}
              alias={task?.alias}
              color="border-l-primary500"
              img={task?.avatar_url}
              state={task?.predefined_task_state}
            />
          ))}

          <div className="flex justify-end">
            <button
              className="mt-1 text-xs  text-subText3"
              onClick={() => navigate("/dashboard/tasks")}
            >
              View All
            </button>
          </div>
        </div>
      )}
    </>
  )
}
