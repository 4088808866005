import React, { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { CustomSelect } from "pages/dashboard/documents/custom-form"

import { Button } from "components"
import { Input, Spin } from "antd"
import { allCoreValues, allResponseType } from "./AddTemplateQuestion"

export const TemplateCard: React.FC<{
  question_title: string
  question: string
  id: any
  type: string
  type_id: string
  handleOpenDelete: Function
  invalidateQueries: Function
}> = ({
  question,
  id,
  question_title,
  type,
  type_id,
  handleOpenDelete,
  invalidateQueries,
}) => {
  const [showEdit, setShowEdit] = useState(false)
  const [activeEditQuestion, setActiveEditQuestion] = useState({
    question_title: question_title,
    question: question,
    type_id: type_id,
    type: type,
    value_id: "",
    value: "",
  })
  const handleChangeEdit = (value: any) => {
    console.log(value)

    let owner = value.split("-")
    let title = owner[0]
    let id = owner[1]

    setActiveEditQuestion({
      ...activeEditQuestion,
      type_id: id,
      type: title,
    })
  }
  const handleChangeValuesEdit = (value: any) => {
    let owner = value.split("-")
    let title = owner[0]
    let id = owner[1]

    setActiveEditQuestion({
      ...activeEditQuestion,
      value_id: id,
      value: title,
    })
  }
  const { isLoading: isEditing, mutate: editTempQuestion } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/performance/edit_reviewtemplate_question?id=${id}&description=${activeEditQuestion.question_title}&alias=${activeEditQuestion.question}&response_type_id=${activeEditQuestion.type_id}&response_option=${activeEditQuestion.type}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        invalidateQueries()
        setShowEdit(false)
      }
    },
  })
  const handleEditQuestion = () => {
    editTempQuestion()
  }
  return (
    <div className="border border-neutral40 rounded-5px ">
      <div className="   w-full gap-5 h-[78px] flex  items-center justify-between px-6  ">
        <div className=" flex flex-col gap-2 h-full justify-center">
          <h6 className=" text-neutral700   font-extrabold text-sm">
            {activeEditQuestion.question_title}
          </h6>{" "}
          <p className="text-neutral500 text-sm">
            {activeEditQuestion.question}
          </p>
        </div>
        <div className="flex items-center gap-3">
          <p
            className="text-primary500 text-sm font-medium cursor-pointer"
            onClick={() => setShowEdit(true)}
          >
            Edit
          </p>
          <p
            className="text-primary500 text-sm font-medium cursor-pointer"
            onClick={() => handleOpenDelete(id)}
          >
            Delete
          </p>
        </div>
      </div>
      {showEdit && (
        <div className=" border border-neutral30 py-6 px-3 rounded-5px mt-4">
          <div className=" bg-[#f3f5fbcf] p-2 rounded-5px h-auto border border-neutral30 flex flex-col justify-between gap-2 ">
            <Input
              type={"text"}
              name={""}
              // id="mobile-number"
              required={true}
              placeholder="Title"
              value={activeEditQuestion.question_title}
              onChange={(e) => {
                setActiveEditQuestion({
                  ...activeEditQuestion,
                  question_title: e.target.value,
                })
              }}
              className="px-3 border-none focus:border border-[#C2C7D0] bg-transparent"
            />
            <Input
              type={"text"}
              name={""}
              // id="mobile-number"
              required={true}
              placeholder="Question"
              value={activeEditQuestion.question}
              onChange={(e) => {
                setActiveEditQuestion({
                  ...activeEditQuestion,
                  question: e.target.value,
                })
              }}
              className="px-3 border-none focus:border border-[#C2C7D0] bg-transparent"
            />

            <div className="flex items-center justify-between w-full  mt-1">
              <div className=" flex gap-2  items-center">
                {" "}
                <CustomSelect
                  label=""
                  name="edit-type"
                  value={{
                    label: activeEditQuestion?.type,
                    value: `${activeEditQuestion?.type}-${activeEditQuestion.type_id}`,
                  }}
                  onChange={handleChangeEdit}
                  className="bg-[#f3f5fbcf] w-[157px] mb-0"
                  options={allResponseType?.map((state) => ({
                    label: `${state.title}`,
                    value: `${state.title}-${state.id}`,
                  }))}
                  placeholder="Response type"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-2 mt-2">
            <Button
              title="Previous"
              color="secondary"
              className="font-extrabold"
              onClick={() => setShowEdit(false)}
            >
              Cancel
            </Button>
            {isEditing ? (
              <Button
                title="Next"
                color="secondary"
                className="font-extrabold"
              >
                <Spin />
              </Button>
            ) : (
              <Button
                title="Next"
                color="primary"
                className="font-extrabold"
                disabled={
                  activeEditQuestion.question_title == "" ||
                  activeEditQuestion.type_id == ""
                    ? true
                    : false
                }
                onClick={() => handleEditQuestion()}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
