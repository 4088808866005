import { Tooltip } from "antd"
import React from "react"
interface Props {
  icon: string
  tooltitle?: string
  title: string
  subtitle: string
  onClick: () => void
  disabled?: boolean
  showButton?: React.ReactNode
}

const SendMoneyCard = ({
  tooltitle,
  title,
  subtitle,
  icon,
  onClick,
  disabled = false,
  showButton = "",
}: Props) => {
  return (
    <div
      className={`border min-h-[104px] rounded-5px w-full p-4 flex gap-5 items-center cursor-pointer ${
        disabled === true
          ? " cursor-not-allowed bg-gray-200 "
          : " bg-white hover:bg-gray-100  "
      }`}
      onClick={disabled ? () => {} : onClick}
    >
      <img
        src={icon}
        alt=""
      />
      <Tooltip title={tooltitle}>
        <div className={` flex flex-col gap-[6px]`}>
          <h5
            className={`font-circular text-base font-medium ${
              disabled && "opacity-50"
            } `}
          >
            {title}
          </h5>
          <h6 className={`font-circular text-sm ${disabled && "opacity-50"} `}>
            {subtitle}
          </h6>
          <div className="z-[99]">{showButton && showButton}</div>
        </div>
      </Tooltip>
    </div>
  )
}

export default SendMoneyCard
