import React, { useState, useEffect } from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Doughnut } from "react-chartjs-2"
ChartJS.register(ArcElement, Tooltip, Legend)
// interface props {
//   values: any
//   labels: any
//   color: any
//   height: any
//   width: any
//   radius: any
//   className?: string
// }
const DoughnutChart = ({ values, labels, color, height, width, radius }) => {
  var data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 0.1,
        borderRadius: radius || 0,
        borderTopRadius: 40,
      },
    ],
  }

  return (
    <div>
      <Doughnut
        data={data}
        height={height}
        width={width}
        options={{
          // maintainAspectRatio: false,
          // responsive: true,
          cutout: "80%",
          // scales: {},
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

export default DoughnutChart
