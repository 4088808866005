import React from "react"
import { Route } from "react-router-dom"

const CompanyOnboardingSetup = React.lazy(
  () => import("./CompanyOnboardingSetup")
)

const Company = React.lazy(() => import("./payroll-enabled/company/Company"))
const PaygradesBands = React.lazy(
  () => import("./payroll-enabled/paygrades-paybands/PaygradesBands")
)
const EmployeeContractors = React.lazy(
  () => import("./payroll-enabled/employees-contractors/EmployeeContractors")
)
const Payschedule = React.lazy(
  () => import("./payroll-enabled/payschedule/Payschedule")
)

const payrollCompanyOnboardingRoutes = (
  <>
    <Route
      path="company-onboarding"
      element={<CompanyOnboardingSetup />}
    />
    <Route
      path="company-onboarding/company-details"
      element={<Company />}
    />
    <Route
      path="company-onboarding/paygrades"
      element={<PaygradesBands />}
    />
    <Route
      path="company-onboarding/employees"
      element={<EmployeeContractors />}
    />
    <Route
      path="company-onboarding/payroll"
      element={<Payschedule />}
    />
  </>
)

export default payrollCompanyOnboardingRoutes
