import { Button, DashboardContainer } from "components"
import React, { useState } from "react"
import { useGet } from "utils/useFetch"

import ImageComponent from "components/custom/image"

import { formatCurrencyNoSymbol } from "utils/currency"

import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { Dropdown, Input, Table, Tag } from "antd"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import getIdFromKey from "utils/getIdFromKey"

import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import UnreversePayment from "./modals/UnreversePayment"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"

const Reversals = () => {
  let x: string = "0"
  const [paymentId, setPaymentId] = useState(getIdFromKey(x || ""))
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const [search, setSearch] = useState("")

  const [countSelected, setCountSelected] = useState(0)
  const [selectedRowData, setSelectedRowData] = useState<any[] | null>([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openUnreverseConfirmation, setOpenUnreverseConfirmation] =
    useState(false)
  const { isLoading: isPaymentLoading, data: reversalData } = useGet<any>({
    url: `admin/payment_schedules/selectpaymentreversals?payment_schedule_id=0`,
  })

  const TableData =
    reversalData?.data
      ?.filter((rev: any) => {
        const name = rev.full_name || rev.beneficiary_name
        return name?.toLowerCase().includes(search?.toLowerCase())
      })
      ?.map((payment: any, index: any) => {
        return {
          key: index,
          active: payment.is_active,
          selected: payment.is_selected,
          id: payment.payment_schedule_item_id,
          payment_schedule_id: payment?.payment_schedule_id,
          beneficiary: {
            name:
              payment.payment_schedule_item_type_id > 1
                ? payment.beneficiary_name
                : payment.full_name || payment.beneficiary_name,
            avatar: payment.avatar_url,
            payment_type: payment.payment_schedule_item_type_id,
            processed:
              String(payment.payment_schedule_item_status_id) == "1"
                ? true
                : false,
          },
          amount: `₦ ${formatCurrencyNoSymbol(payment.amount)}`,
          description: payment.description,
          reversed_amount: `₦ ${formatCurrencyNoSymbol(
            payment.reversed_amount
          )}`,

          action: {
            statusId: Number(payment.payment_schedule_item_status_id),
            paymentid: payment?.payment_schedule_item_id,
            selected: payment.is_selected ? true : false,
            active: payment.is_active,
            payment_type: payment.payment_schedule_item_type_id,
            payment_method_id: String(payment.payment_method_id),
            payment_schedule_item_type_id:
              payment.payment_schedule_item_type_id,
            payment: payment,
          },
          enabled: payment.payment_schedule_item_status_id == 1 ? false : true,
        }
      }) || []
  const TableColumns =
    [
      {
        title: "Beneficiary",
        dataIndex: "beneficiary",
        render: (item: {
          name: string
          avatar: string
          selected: boolean
          processed: boolean
          payment_type: number
        }) => (
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <ImageComponent
                className="w-6 h-6 rounded-full"
                src={
                  item.avatar ||
                  "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
                }
                alt=""
              />
            </span>
            <div className="flex flex-col">
              <span
                className={`whitespace-nowrap font-avenir text-sm text-[#42526D] ${
                  item.processed ? "text-success800" : ""
                }`}
              >
                {item.name}
              </span>
              {item.payment_type > 1 && (
                <Tag
                  color="processing"
                  className="w-[115px] text-xs font-medium"
                >
                  {item.payment_type === 3
                    ? "Pension Schedule"
                    : item.payment_type === 4
                    ? "NHF Schedule"
                    : item.payment_type === 2
                    ? "Tax Schedule"
                    : item.payment_type === 8
                    ? "EWA Repayment"
                    : ""}
                </Tag>
              )}
            </div>
          </div>
        ),
      },

      {
        title: "Description",
        dataIndex: "description",
        render: (description: string) => (
          <span
            className={`whitespace-nowrap font-avenir text-sm text-[#42526D] `}
          >
            {description}
          </span>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: string) => (
          <span
            className={`whitespace-nowrap font-avenir text-sm text-[#42526D] `}
          >
            {amount}
          </span>
        ),
      },

      {
        title: "Reversed Amount",
        dataIndex: "reversed_amount",
        render: (reversed_amount: string) => (
          <span
            className={`whitespace-nowrap font-avenir text-sm text-[#42526D] `}
          >
            {reversed_amount}
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (action: {
          statusId: number
          paymentid: number
          selected: boolean
          active: boolean
          payment_type: number
          payment_method_id: string
          payment_schedule_item_type_id: string
          payment: any
        }) => (
          <Dropdown
            menu={{
              items: actionItems(
                action.statusId,
                action.paymentid,
                action.selected,
                action.active,
                action.payment_method_id,
                action.payment_schedule_item_type_id,
                action.payment
              ),
            }}
          >
            <button>
              <EllipsisHorizontalIcon className="w-6 h-6 text-primary500" />
            </button>
          </Dropdown>
        ),
      },
    ] || []

  const actionItems = (
    id: number,
    paymentid: number,
    selected: boolean,
    active: boolean,
    payment_method_id: string,
    payment_schedule_item_type_id: string,
    payment: any
  ) => {
    let arrayOptions: any = []

    {
      active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "3",
            label: (
              <div
                onClick={() => {
                  handleSingleRowUnreverse(String(paymentid))
                }}
              >
                {" "}
                Unreverse
              </div>
            ),
          },
        ])
    }

    return arrayOptions
  }

  const invalidateQueries = () => {
    setPaymentId(getIdFromKey(x || ""))
    // refetchPayout()
    queryClient.invalidateQueries({
      queryKey: [
        `admin/payment_schedules/selectpaymentreversals?payment_schedule_id=0`,
      ],
    })
  }

  const { isLoading: isSubmittingUnreverseAll, mutate: submitUnreverseAll } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/delete_payment_reversals`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          {
            data.msg && toast.success(data.msg)
          }
          setOpenDeleteModal(false)
          mixPanelEvent("pay-schedule-payout-unreverse-success")
          invalidateQueries()
        } else {
          {
            data.msg && toast.error(data.msg)
          }
        }
      },
      onError: (data: any) => {
        {
          data.msg && toast.error(data.msg)
        }
      },
    })

  const handleSingleRowUnreverse = (id: string) => {
    setPaymentId(id)
    setOpenUnreverseConfirmation(true)
  }
  const handSelectedRows = (
    selectedRowKeys: React.Key[],
    selectedRows: any
  ) => {
    console.log(selectedRows)
    console.log(selectedRowKeys)

    setCountSelected(selectedRowKeys.length)
    setSelectedRowData(selectedRows)
  }

  return (
    <DashboardContainer>
      <>
        {isPaymentLoading ? (
          <div className="h-screen items-center flex justify-center">
            <LoadingIndicatorWhite />
          </div>
        ) : (
          <div className="relative">
            <div className=" bg-white rounded-5px mt-4  p-4 lg:p-[24px]">
              <div className=" flex items-center justify-between">
                <div className="w-[268px] h-9">
                  <Input
                    placeholder="Search"
                    prefix={
                      <MagnifyingGlassIcon className="w-4 h-4 text-neutral-300" />
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="font-avenir "
                  />
                </div>
                <Button
                  color="primary"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  Delete
                </Button>
              </div>
              <div className="border mt-4 relative">
                <Table
                  // id="custom-payment"
                  // columns={TableColumns}
                  columns={TableColumns}
                  dataSource={TableData}
                  loading={isPaymentLoading}
                  rowSelection={{
                    type: "checkbox",
                    onChange: (selectedRowKeys, selectedRows) => {
                      handSelectedRows(selectedRowKeys, selectedRows)
                    },
                  }}
                  className="text-[#42526D]"
                  pagination={{
                    defaultPageSize: 20,
                  }}
                  scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
                />
                {/* <SelectedTable
                TableColumns={TableColumns}
                TableData={TableData}
                isPaymentLoading={isPaymentLoading}
                submitSelection={submitSelection}
                submitDeselection={submitDeselection}
                setAllSelectedRowSum={setAllSelectedRowSum}
              /> */}
              </div>
            </div>

            <UnreversePayment
              open={openUnreverseConfirmation}
              onClose={() => {
                setOpenUnreverseConfirmation(false)
                setPaymentId(getIdFromKey(x || ""))
              }}
              paymentId={paymentId}
              invalidateQueries={invalidateQueries}
              x={x}
            />
            <NewModalWrapper
              open={openDeleteModal}
              title="Delete Reversals"
              onCancel={() => setOpenDeleteModal(false)}
              onClose={() => setOpenDeleteModal(false)}
              footer={
                <div className="border py-5 px-6 flex gap-4  justify-end">
                  <Button
                    title="cancel"
                    color="secondary"
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    submitting={isSubmittingUnreverseAll}
                    onClick={() => submitUnreverseAll()}
                  >
                    Confirm
                  </Button>
                </div>
              }
            >
              <div className="px-5 py-3">
                <p className=" text-neutral300 mb-5 text-base">
                  <span className="font-bold"> Note:</span> Only reversals that
                  are not in submitted payrolls will be removed ....i.e payments
                  without reversed amount{" "}
                </p>
              </div>
            </NewModalWrapper>
          </div>
        )}
      </>
    </DashboardContainer>
  )
}
export default Reversals
interface ItransferDet {
  status: string
  msg: string
  pagi: string
  record: string
  data: string
}
