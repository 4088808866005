import { LoadingIndicator } from "components/custom/Loader"

interface IProps {
  title: string
  description?: string
  actions?: React.ReactNode
  children: React.ReactNode
  loading: boolean
  className?: string
  icon?: string
}
const InfoCard = ({
  title,
  description,
  actions,
  children,
  loading,
  className,
  icon,
}: IProps) => {
  return (
    <div
      className={`bg-[#FFFFFF] border border-[#FFFFFF] shadow-md rounded-xl ${
        className ? className : ""
      }`}
    >
      <div className="py-5 px-6 flex items-center justify-between">
        <div>
          <div className="flex items-center">
            {" "}
            {icon && (
              <img
                src={icon}
                alt="icon"
                className="mr-1"
              />
            )}
            <h2 className="text-[#42526D] font-circular">{title}</h2>
          </div>

          <p className="text-neutral500 font-bold text-sm">{description}</p>
        </div>
        <div>{actions}</div>
      </div>
      <hr className="border-b border-[#DFE2E6]" />
      <div>
        {loading ? (
          <div className="my-5 py-5 flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  )
}

export default InfoCard
