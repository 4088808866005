import React, { useState } from "react"

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, BarElement)

const Barchart = ({ currency, thick, radius, labels, datasets }) => {
  const [chart, setChart] = useState([])

  const newLabels = labels.map((label) => {
    let firstLetter = label.toUpperCase().split("")
    firstLetter = firstLetter[0]
    return firstLetter
  })

  var data = {
    labels: labels,

    datasets: datasets,
  }

  var options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
      x: {
        // beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <div className=" bg-white-white h-full  w-full">
      <Bar
        height={300}
        data={data}
        options={options}
        // className="bg-primary500"
      />
    </div>
  )
}

export default Barchart
