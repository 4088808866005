import { H5 } from "components/typography/H5"
import { IconButton } from "../buttons/IconButton"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { H6 } from "components/typography/H6"
import { Modal } from "antd"

type props = {
  className?: string
  title?: string
  subTitle?: string
  onClose: Function
  children: JSX.Element | JSX.Element[]
  useBgColor?: boolean
}

export const ModalWrapper: React.FC<props> = ({
  className,
  onClose,
  children,
  title,
  subTitle,
  useBgColor = false,
}) => {
  return (
    <div
      className="fixed z-50 h-screen w-screen flex justify-center items-center top-0 bottom-0 left-0 right-0 bg-[#1E1E1E]/60 overflow-y-auto font-avenir"
      style={{
        zIndex: 99,
        // zIndex: 99999999999,
      }}
    >
      <div
        className={`relative h-auto my-auto bg-white rounded-lg  w-[500px] ${className}`}
      >
        {title ? (
          <div className="px-4 lg:px-5 py-3  flex items-center justify-between">
            <h2 className="font-circular text-base font-bold text-neutral500">
              {title}
            </h2>

            <button
              onClick={(e) => {
                e.preventDefault()
                onClose?.()
              }}
            >
              <XMarkIcon className="w-5 h-5 text-neutral800" />
            </button>
          </div>
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault()
              onClose?.()
            }}
          >
            <XMarkIcon className="w-5 h-5 text-neutral800" />
          </button>
        )}
        <div>
          {subTitle && (
            <div className="px-5 py-3 text-neutral300 text-sm">{subTitle}</div>
          )}

          {children}
        </div>
      </div>
    </div>
  )
}
