import { Avatar, Form, Spin } from "antd"
import React, { useState } from "react"
import ReactQuill from "react-quill"
import { EmployeeViolation, ItemsEntity } from "./ViewViolationPage"
import moment from "moment"
import NewButton from "components/new-stuff/ui/button/Button"
import { useMutation } from "@tanstack/react-query"
import { useAlert } from "hooks/hook"
import { axiosInstance } from "utils/request"
import parser from "html-react-parser"
import "react-quill/dist/quill.snow.css"

type Props = {
  violation: EmployeeViolation
  refetch: Function
}

const modules = {
  toolbar: {
    // Add image upload button to the toolbar
    container: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        //   { indent: "-1" },
        //   { indent: "+1" },
      ],
      // ["link"], // Add 'image' here
      // ["clean"],
    ],
  },
}

export const ChatComponent = ({ violation, refetch }: Props) => {
  const [form] = Form.useForm()

  const [comment, setComment] = useState("")

  const [item, setItem] = useState<ItemsEntity>()

  const [mode, setMode] = useState<"create" | "edit" | "delete">("create")

  const { alert: showAlert } = useAlert(() => {
    setComment("")
    setMode("create")
    refetch()
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const res =
        mode === "delete"
          ? await axiosInstance.post(
              `disciplinary/delete_employeeviolation_comment`,
              {
                comment,
                id: item ? item?.employee_violation_item_id?.toString() : null,
              }
            )
          : mode === "edit"
          ? await axiosInstance.post(
              `disciplinary/edit_employeeviolation_comment`,
              {
                comment,
                id: item ? item?.employee_violation_item_id?.toString() : null,
              }
            )
          : await axiosInstance.post(
              `disciplinary/add_employeeviolation_comment`,
              {
                comment,
                id: violation?.employee_violation_id?.toString(),
              }
            )
      return res.data
    },
    onSuccess: (data) => {
      showAlert(
        data,
        mode === "delete"
          ? "Response deleted successfully"
          : mode === "edit"
          ? "Response edited successfully"
          : "Response added successfully"
      )
    },
  })

  const addingState = mode === "create" && isLoading
  const editing = mode === "edit" && isLoading

  return (
    <Spin spinning={isLoading}>
      <div className="flex flex-col gap-3">
        <div className="">
          {violation?.items?.map((item) => (
            <div className="flex gap-2 w-full my-2">
              <div className="w-full group">
                <div className="flex gap-2">
                  <span
                    className="hidden group-hover:flex transition-all cursor-pointer"
                    onClick={() => {
                      setMode("edit")
                      setComment(item?.description)
                      setItem(item)
                    }}
                  >
                    Edit
                  </span>
                  <span
                    className="hidden group-hover:flex transition-all cursor-pointer"
                    onClick={() => {
                      setMode("delete")
                      setItem(item)
                      mutate()
                    }}
                  >
                    Delete
                  </span>
                </div>

                <div className="flex gap-2 items-center justify-between w-full">
                  <div className="text-[16px] font-[600]">
                    {item?.created_by_name}
                  </div>
                  <div className="text-sm">
                    {item?.created_date &&
                      moment(new Date(item?.created_date)).format(
                        "DD MMM, YYYY HH:mm A"
                      )}
                  </div>
                </div>
                <div className="text-sm font-[400]">
                  {item?.description ? parser(item?.description) : ""}
                </div>
              </div>
            </div>
          ))}
        </div>

        <Form
          form={form}
          className="min-h-[100px]"
          onFinish={() => {
            mutate()
          }}
        >
          <ReactQuill
            className="!min-h-0"
            value={comment}
            onChange={(value) => setComment(value)}
            placeholder="Type your message here"
            theme="snow"
            modules={modules}
          />
          <div className="flex justify-end my-2">
            <NewButton
              color="primary"
              onClick={() => {
                form.submit()
              }}
              submitting={addingState || editing}
              disabled={addingState || editing || !comment}
            >
              {mode === "create" ? "Send" : "Update"}
            </NewButton>
          </div>
        </Form>
      </div>
    </Spin>
  )
}
