import { useCustomizationSettingsStore } from "stores/customization-settings"

export interface IKYCData {
  users: null
  user: null
  managers: null
  approvalWorkflows: null
  customRoles: null
  customFields: null
  customNotifications: null
  customNotification: null
  customNotificationEntities: null
  customNotificationEntitySelect: null
  paymentFileCustomFields: null
  paygrades: null
  paygrade: null
  allowances: null
  allowance: null
  statutoryBenefits: null
  statutoryBenefit: null
  payrollSchedules: null
  payrollSchedule: null
  customPayrollSchedules: null
  customPayrollSchedule: null
  employeeTitles: null
  employeeTitle: null
  taxConfigRows: null
  verification: Verification
  auditCommentsEnabled: null
  newFinanceActEnabled: null
  supportEmail: null
  avatarUrl: null
  currentTab: null
  direct_Deposit_Status_Id: number
  autoPayrollEnabled: null
  checkPaymentOptionEnabled: null
  prorateRegularAllowances: null
  prorateSpecialAllowances: null
  payrollsCount: number
  activeEmployeesCount: number
  departmentsEnabled: null
  coworkerDirectoryEnabled: null
  displayIdentificationNumber: null
  employeeRequestsEnabled: null
  employeeProfileUpdatesEnabled: null
  timeTrackingEnabled: null
  primaryAdminHasFullAccess: null
  twoFactorAuthenticationEnabled: null
  employeeNotificationsEnabled: null
  approvalNotificationsEnabled: null
  adminsCanSwitchOrganisations: null
  autoSendBirthdayCard: null
  birthdayCardBackgroundImage: null
  birthdayCardMessage: null
  multipleAdminsEnabled: null
  basicInfoStatus: null
  compensationInfoStatus: null
  approvalWorkflowsEnabled: null
}

export interface Verification {
  verification_id: number
  organisation_id: null
  country_id: number
  country: string
  country_code: string
  industry_id: number
  industry: string
  verification_status_id: number
  verification_status: null
  registered_name: null
  trading_name: null
  registration_number: null
  registration_date: string | null
  description: null
  website: null
  social_media: null
  full_address: null
  bank_country_id: number
  bank_country: string
  bank_country_code: string
  bank_id: number
  bank_name: string
  bank_code: string
  rep_bvn?: string
  rep_first_name?: string
  rep_last_name?: string
  bank_local_code: null
  account_number: null
  currency_id: number
  currency: string
  currency_code: string
  routing_number: null
  swift_code: null
  bank_branch: null
  bank_address: null
  email: null
  phone: null
  is_terms_accepted: null
  is_incorporation_document_uploaded: null
  incorporation_document_extension: null
  is_association_document_uploaded: null
  association_document_extension: null
  is_other_document_uploaded: null
  other_document_extension: null
  is_deleted: null
  is_active: null
  verificationEntities: IVerificationEntities[]
  verificationFiles: null
}

interface IVerificationEntities {
  verification_entity_id: number
  verification_id: number | null
  first_name: string
  last_name: string
  bvn: string
  date_of_birth: string
  phone: string | null
  address_street: string | null
  address_city: string | null
  address_country_id: number | null
  address_country: string | null
  address_country_code: string | null
  address_state_id: number | null
  address_state: string | null
  address_state_code: string | null
  identification_type_id: number | null
  identification_type: string | null
  is_identification_document_uploaded: boolean | null
  identification_document_extension: string | null
  is_deleted: boolean | null
  is_active: boolean | null
}

export interface IIndustries {
  industryId: number
  description: string
  recordGuid: null
  isDeleted: boolean
  isActive: boolean
}

export interface ICurrencies {
  currencyId: number
  description: string
  flagUrl: string
  code: string
  symbol: string
  unit: null | string
  isPaymentActivated: boolean
  isDeleted: boolean
  isActive: boolean
  qualifiedDescription: string
}

export interface KYCWalletBody {
  phone?: string
  bank_country_code?: string
  bank_code?: string
  account_number?: string
  currency_id?: string
  routing_number?: string
  swift_code?: string
  registered_name?: string
  registration_date?: string
  email?: string
  full_address?: string
  registration_number?: string
  website?: string
  social_media?: string
  industry_id?: string
  description?: string
  rep_bvn?: string
  rep_first_name?: string
  rep_last_name?: string
  rep_phone?: string
  rep_dob?: string
}
export const KYCInitialState = {
  phone: "",
  bank_country_code: "",
  bank_code: "",
  account_number: "",
  currency_id: "",
  routing_number: "",
  swift_code: "",
  registered_name: "",
  email: "",
  full_address: "",
  registration_number: "",
  website: "",
  social_media: "",
  industry_id: "",
  description: "",
  bvn_identity: "",
  bvn: "",
}
export const IntegrationStatus = [
  {
    id: 1,
    title: "Direct Deposit is enabled!",
    buttonText: "Enabled",
    subTitle: `You can pay employees right from your ${
      useCustomizationSettingsStore.getState().customizationSettings.brand
        .platformName
    } account. If you want to pay your employees manually, disable Direct Deposit in Integration.`,
  },
  {
    id: 2,
    title: "Direct Deposit is disabled!",
    buttonText: "Disabled",
    subTitle: `If you want to pay your employees from your ${
      useCustomizationSettingsStore.getState().customizationSettings.brand
        .platformName
    } account, enable Direct Deposit in Integration.`,
  },
  {
    id: 3,
    title: "Company verification is pending!",
    buttonText: "Pending",
    subTitle: `You will be able to pay your employees from your ${
      useCustomizationSettingsStore.getState().customizationSettings.brand
        .platformName
    } account using Direct Deposit after your company verification is complete. Contact us for more information.`,
  },
  {
    id: 4,
    title: "Direct Deposit is suspended!",
    buttonText: "Suspended",
    subTitle:
      "Your Wallet services have been suspended. Please contact us for more information.",
  },
  {
    id: 5,
    title: "Direct Deposit is not setup!",
    buttonText: "Not setup",
    subTitle:
      "An attempt to setup your PaidHR Wallet failed. Please Please contact us for more information.",
  },
  {
    id: 6,
    title: "Direct Deposit is not setup!",
    buttonText: "Not setup",
    subTitle: `You will be able to pay your employees from your ${
      useCustomizationSettingsStore.getState().customizationSettings.brand
        .platformName
    } account using Direct Deposit after you verify your company and enable Direct Deposit in Settings.`,
  },
]
