import { Button } from "components"

import { useNavigate } from "react-router-dom"
import { useAuthStore } from "stores"

const WalletNotEnabled = () => {
  const navigate = useNavigate()
  const { user } = useAuthStore()

  return (
    <div className="flex flex-col-reverse xl:flex-row  items-center justify-between py-6 xl:py-[59px] px-6 xl:px-[52px] bg-white max-w-[1047px]  ">
      <div className=" w-full xl:w-[511px]">
        <h2 className="text-[32px] text-padeBlack font-bold mb-[10px] font-circular">
          Welcome to your PaidHR Wallet
        </h2>
        <h6 className="text-base text-[#0c1b3dbf] mb-[33px] font-circular">
          Send, receive and make payments.
        </h6>
        <div className="py-[15px] px-[13px] bg-[#E8F0FE] rounded-5px">
          <p className="text-sm text-padeBlack font-circular">
            To get started, we need to verify your details to ensure compliance
            with Central Bank of Nigeria regulations and also enjoy benefits.
          </p>
        </div>
        <div className="w-full items-center grid grid-cols-2 mt-[69px] gap-8">
          <Button
            color="primary"
            onClick={() =>
              navigate(
                user?.userStatusId === "2" || user?.userStatusId === "3"
                  ? `/employee/susp-exit/wallet/kyc`
                  : "/employee/dashboard/wallet/kyc"
              )
            }
          >
            Proceed{" "}
          </Button>
          {/* <Button color="neutral">Skip to wallet</Button> */}
        </div>
      </div>
      <div className=" flex justify-end">
        <img
          src={
            "https://padefiles.blob.core.windows.net/images/bb666dbf-326b-4d08-89c9-734e1e692206.svg"
          }
          alt=""
        />
      </div>
    </div>
  )
}

export default WalletNotEnabled
