import { Button, Modal, ModalProps } from "antd"
import { XMarkIcon } from "@heroicons/react/24/outline"

interface Props extends ModalProps {
  subTitle?: string
  onClose?: VoidFunction
}

const NewModalWrapper: React.FC<Props> = ({
  className,
  onClose,
  children,
  title,
  width = 500,
  open = false,
  closable = true,
  ...rest
}) => {
  return (
    <Modal
      open={open}
      rootClassName={`${className} [&_.ant-modal-content]:!p-0 `}
      onCancel={() => onClose?.()}
      closeIcon={null}
      footer={null}
      width={width}
      title={null}
      maskClosable={closable}
      {...rest}
    >
      <div className="max-w-full overflow-hidden rounded-lg ">
        {title ? (
          <div className="px-4 lg:px-5 py-3  flex items-center justify-between">
            <h2 className="font-circular text-base font-bold text-neutral500">
              {title}
            </h2>
            {closable && (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  onClose?.()
                }}
              >
                <XMarkIcon className="w-5 h-5 text-neutral800" />
              </button>
            )}
          </div>
        ) : null}

        {children}
      </div>
    </Modal>
  )
}

export default NewModalWrapper
