import { Form, FormItemProps, Select } from "antd"
import React from "react"

interface Props {
  options: {
    label: string
    value: string
  }[]
  placeholder?: string
}

export const AppSingleSelect = ({
  options,
  placeholder,
  ...rest
}: Props & FormItemProps) => {
  return (
    <Form.Item {...rest}>
      <Select
        options={options?.map((option, index) => ({
          value: `${option.value}`,
          label: `${option.label}`,
        }))}
        optionLabelProp="label"
        optionFilterProp="children"
        allowClear
        showSearch
        filterOption={(input, option) => {
          if (option) {
            const value = (option?.label as string) || ""
            return value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          } else {
            return false
          }
        }}
      />
    </Form.Item>
  )
}

interface MultipleProps extends Props {
  maxLength?: number
}

export const AppMultipleSelect = ({
  options,
  maxLength = 4,
}: MultipleProps) => {
  return (
    <Select
      options={options?.map((option, index) => ({
        value: `${option.value}`,
        label: `${option.label}`,
      }))}
      optionLabelProp="label"
      optionFilterProp="children"
      allowClear
      mode="multiple"
      maxLength={maxLength}
      showSearch
      filterOption={(input, option) => {
        if (option) {
          const value = (option?.label as string) || ""
          return value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
        } else {
          return false
        }
      }}
    />
  )
}
