import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { RolesDataList } from "./RolesDataList"
import { P1 } from "components"

type Props = {}

const RolesAndPermissionManagementPage = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const tabs = [
    {
      title: "System Roles",
      value: "system",
      content: <RolesDataList type="system" />,
    },
    {
      title: "Custom Roles",
      value: "custom",
      content: <RolesDataList type="custom" />,
    },
  ]

  const [tab, componentToRender] = useMemo(() => {
    const current = searchParams.get("tab") || "custom"
    const componentToRender = tabs.find((tab) => tab?.value === current)
      ?.content || <RolesDataList type="custom" />
    return [current, componentToRender]
  }, [searchParams])

  return (
    <>
      <div className="h-full flex justify-between items-center gap-6 px-4 md:px-6 lg:px-10 border-y border-y-neutral40 bg-white overflow-x-auto">
        <div className="h-full flex items-center gap-4 sm:gap-6">
          {tabs.map((item, index) => (
            <>
              <div
                className={` py-5 h-full relative flex items-center cursor-pointer`}
                onClick={() => {
                  setSearchParams({
                    ...searchParams,
                    tab: item.value,
                  })
                }}
                key={index}
              >
                <P1
                  className={`leading-none ${
                    item.value === tab
                      ? "font-semibold text-primary500"
                      : "text-neutral100"
                  }`}
                >
                  {item.title}
                </P1>
                {item.value === tab && (
                  <div className="w-full max absolute bottom-0 mt-5 h-[3px] bg-primary500" />
                )}
              </div>
            </>
          ))}
        </div>
      </div>
      <DashboardWrapper>
        <div>{componentToRender}</div>
      </DashboardWrapper>
    </>
  )
}

export default RolesAndPermissionManagementPage
