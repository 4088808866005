import { useQuery } from "@tanstack/react-query"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import React from "react"
import { useLocation } from "react-router-dom"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"

const ViewTransaction = () => {
  const myParam = useLocation().search
  const reference = new URLSearchParams(myParam).get("id")
  const { isLoading: isLoadingWalletData, data: walletData } = useQuery<any>({
    queryKey: [`${walletBaseUrl}/v1/wallets/payment-transactions/${reference}`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/payment-transactions/${reference}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
  })
  // /v1/wallets/payment-transactions/:reference
  return (
    <DashboardWrapper>
      <div className="border-4">
        <div className="py-[14px] px-4 bg-white">
          <h5 className="text-lg font-circular font-bold">
            Transaction Details
          </h5>
        </div>
        <div className="border-4"></div>
      </div>
    </DashboardWrapper>
  )
}

export default ViewTransaction
