import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useState } from "react"

import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"

import { TopActionBar } from "components/new-stuff/ui/TopActionBar"
import { DatePicker } from "antd"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useAuthStore } from "stores"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import dayjs from "dayjs"
import { dateData } from "pages/dashboard/wallets/Statements"
import { IEmployeeWalletRes } from "pages/dashboard/wallets/crossBorderPayments/transactions/interface"
import CrossBorderTransTable from "pages/dashboard/wallets/crossBorderPayments/transactions/component/Table"
import { useLocation } from "react-router-dom"

const CrossBorderTransEmp = () => {
  const [search, setSearch] = useState("")
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(7, "day").format("YYYY/MM/DD") || ""
  )
  const location = useLocation()
  const { currencyCode } = location.state || {}
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )
  const [isCustom, setIsCustom] = useState(false)
  const [activePeriod, setActivePeriod] = useState<dateData>({
    id: "1",
    period: "One Week",
    startDate: "",
    endDate: "",
  })

  const { isLoading: isTransactionsLoading, data: transactionsData } =
    useQuery<IEmployeeWalletRes>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transactions?limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}${
          currencyCode !== undefined ? `&currency=${currencyCode}` : ""
        }`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transactions?limit=0&startDate=${
            startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
          }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}${
            currencyCode !== undefined ? `&currency=${currencyCode}` : ""
          }`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })

  const periods: dateData[] = [
    {
      id: "1",
      period: "One Week",
      startDate: "",
      endDate: "",
    },
    {
      id: "2",
      period: "One Month",
      startDate: "",
      endDate: "",
    },

    {
      id: "3",
      period: "Custom",
      startDate: "",
      endDate: "",
    },
  ]
  return (
    <>
      <DashboardWrapper>
        <div className="">
          <div className=" bg-white my-4 px-4 pt-5">
            <div className="flex gap-2 justify-end items-center mb-4">
              <div className="bg-[#F3F5FB] rounded-sm cursor-pointer flex ">
                {periods?.map((it: dateData) => (
                  <div
                    onClick={() => {
                      if (it?.id === "1") {
                        setStartDate(
                          dayjs(new Date())
                            .subtract(7, "day")
                            .format("YYYY/MM/DD") || ""
                        )
                        setEndDate(
                          dayjs(new Date())?.format("YYYY/MM/DD") || ""
                        )
                      } else if (it?.id === "2") {
                        setStartDate(
                          dayjs(new Date())
                            .subtract(30, "day")
                            .format("YYYY/MM/DD") || ""
                        )
                        setEndDate(
                          dayjs(new Date())?.format("YYYY/MM/DD") || ""
                        )
                      } else {
                        setIsCustom(true)
                        setActivePeriod({
                          id: "3",
                          period: "Custom",
                          startDate: "",
                          endDate: "",
                        })
                        return
                      }
                      setActivePeriod(it)
                      setIsCustom(false)
                    }}
                    key={it?.id}
                    className={`${
                      it.id === activePeriod?.id && "bg-primary500 text-white"
                    } whitespace-nowrap text-[#42526D] h-full w-full p-2 rounded-md`}
                  >
                    {it?.period}
                  </div>
                ))}
              </div>
              {isCustom && (
                <div className="flex gap-2">
                  <DatePicker
                    placeholder="Start date"
                    onChange={(value) => {
                      setStartDate(value?.format("YYYY/MM/DD") || "")
                      setEndDate("")
                    }}
                    disabledDate={(date) => {
                      return date.isAfter(dayjs())
                    }}
                  />
                  <DatePicker
                    placeholder="End date"
                    disabled={!startDate}
                    onChange={(value) => {
                      setEndDate(value?.format("YYYY/MM/DD") || "")
                    }}
                    disabledDate={(date) => {
                      const testDate = dayjs(startDate)
                      const before = date.isBefore(testDate)
                      const after = date.isAfter(testDate.add(1, "M"))
                      const afterToday = date.isAfter(dayjs())

                      return before || after || afterToday
                    }}
                  />
                </div>
              )}
            </div>
            <CrossBorderTransTable
              transactionsData={transactionsData?.data?.docs}
              loading={isTransactionsLoading}
            />
          </div>
        </div>
      </DashboardWrapper>
    </>
  )
}
export default CrossBorderTransEmp
