import { EventCard } from "./EventCard"
type eventProps = {
  eventArray?: any
}
export const EventTagsAnniversaries: React.FC<eventProps> = ({
  eventArray,
}) => {
  const today = new Date()
  const formattedToday = today.toLocaleString("en-us", {
    month: "short",
    day: "numeric",
  })
  return (
    <div>
      {eventArray?.map((event: any, index: any) => (
        <EventCard
          title={event?.full_name}
          color="border-l-primary500"
          date={
            formattedToday === event?.hire_date_short
              ? "Today"
              : event?.hire_date_short
          }
          description={event?.employee_title}
          subtitle={
            formattedToday === event?.hire_date_short
              ? `Congratulate ${event?.first_name} on their anniversary`
              : ""
          }
          img={event?.avatar_url}
        />
      ))}

      {/* <div className="flex justify-end">
        <p className="mt-1 text-xs  text-subText3">View All</p>
      </div> */}
    </div>
  )
}
