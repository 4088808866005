import LogoWhite from "assets/logos/paidhr-logo-white.svg"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { isPadeDomain } from "utils/utils"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
} from "@heroicons/react/24/outline"
import { P2, TextButton } from "components"
import { Progress } from "antd"
import { useNavigate } from "react-router-dom"

const NewAuthWrapper = ({
  showGetHelp = true,
  showCompanies = false,
  showBackBtn = false,
  progressData,
  children,
  childrenWrapperClassName = "",
  yelloBgClassName = "",
  heading,
  subHeading,
}: {
  showGetHelp?: boolean
  showBackBtn?: boolean
  showCompanies?: boolean
  children: React.ReactNode
  progressData?: {
    percent: number
    text: string
    completed?: boolean
  }
  heading: string
  subHeading: string
  childrenWrapperClassName?: string
  yelloBgClassName?: string
}) => {
  const { customizationSettings } = useCustomizationSettingsStore()
  const navigate = useNavigate()

  return (
    <div className="min-h-full relative max-w-full overflow-hidden bg-[#004AF5]">
      <div
        className="opacity-40 bg-center bg-contain absolute inset-0 z-[1]"
        style={{
          backgroundImage: `url(https://padefiles.blob.core.windows.net/images/8273822e-4938-498a-a110-8736d5b5ad21.png)`,
        }}
      />

      <div
        className={`w-[300px] min-h-[140vh] h-full absolute top-0 right-10 bg-padeYellow bg-opacity-90 rotate-45 z-[2] ${yelloBgClassName}`}
      />

      <div className="min-h-[100dvh] px-4 lg:px-6 xl:px-20 py-6 xl:py-10 relative z-[3] max-w-screen-2xl mx-auto">
        <div className="flex justify-between items-center">
          <img
            className=""
            src={
              isPadeDomain() === true
                ? LogoWhite
                : customizationSettings.brand.logoUrl
            }
            alt="logo"
            width={120}
          />

          {showGetHelp && isPadeDomain() === true ? (
            <>
              <p className="font-circular font-extrabold text-base text-white items-center gap-2 hidden sm:flex">
                Having trouble?{" "}
                <a
                  className={
                    "text-padeYellow flex items-center gap-2 hover:underline"
                  }
                  href="https://support.padehcm.com/"
                  target="_blank"
                >
                  Get help <ArrowRightIcon className="w-4 h-4" />
                </a>
              </p>
            </>
          ) : null}
        </div>

        <div className="mt-10 xl:mt-20">
          <div className="flex justify-center lg:justify-between items-center gap-6 w-full max-w-[1250px] mx-auto">
            <div className="shrink lg:shrink-0 hidden lg:block">
              <div className="max-w-[450px]">
                <h4 className="text-white font-circular font-bold text-5xl">
                  {heading}
                </h4>
                <p className="text-white/90 mt-4 font-circular text-lg">
                  {subHeading}
                </p>
              </div>

              <div className="flex items-center gap-6 xl:gap-8 mt-14">
                {/* {showCompanies && (
                  <>
                    <img
                      src={flutterImg}
                      alt="flutterwave"
                      width={130}
                    />
                    <img
                      src={dantataImg}
                      alt="dantata"
                      width={40}
                    />
                    <img
                      src={avisImg}
                      alt="avis"
                      width={50}
                    />
                    <img
                      src={riseImg}
                      alt="rise"
                      width={50}
                    />
                    <img
                      src={herosheImg}
                      alt="heroshe"
                      width={90}
                    />
                  </>
                )} */}
              </div>
              <div className="mt-16 min-h-[40px] flex justify-between items-center max-w-[400px]">
                {progressData && (
                  // circular progress bar

                  <Progress
                    percent={progressData.percent}
                    type="circle"
                    strokeColor={"#FCBF23"}
                    trailColor="#fff"
                    strokeWidth={12}
                    // showInfo={false}
                    format={() => (
                      <P2 className="text-white font-circular font-bold !text-2xl ">
                        {progressData.completed ? (
                          <span className="flex justify-center">
                            <CheckIcon
                              className="w-10 text-white "
                              strokeWidth={4}
                            />
                          </span>
                        ) : (
                          progressData.text
                        )}
                      </P2>
                    )}
                  />
                )}

                {showBackBtn && (
                  <TextButton
                    title="Back"
                    className="font-circular flex gap-2 text-white font-semibold items-center"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowLeftIcon className="w-4 text-padeYellow stroke-2" />
                    <span>Back</span>
                  </TextButton>
                )}
              </div>
            </div>

            <div className="flex justify-center lg:justify-end w-full ">
              <div
                className={`bg-white w-full sm:w-[550px] min-h-[560px] rounded-lg shadow-lg px-4 py-6 lg:px-10 lg:py-10 ${childrenWrapperClassName} `}
              >
                {children}
              </div>
            </div>
          </div>

          {/* {showGetHelp && isPadeDomain() === true ? (
            <>
              <p className="font-circular font-extrabold text-base text-white items-center gap-2 sm:hidden flex">
                Having troubles?{" "}
                <a
                  className={
                    "text-padeYellow flex items-center gap-2 hover:underline"
                  }
                  href="https://support.padehcm.com/"
                  target="_blank"
                >
                  Get help <ArrowRightIcon className="w-4 h-4" />
                </a>
              </p>
            </>
          ) : null} */}
        </div>
      </div>
    </div>
  )
}

export default NewAuthWrapper
