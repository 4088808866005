import React from "react"
import { H4 } from "../typography/H4"
import { P1 } from "../typography/P1"
import { LinkButton } from "../buttons/LinkButton"

export const UnknownPage: React.FC<{}> = () => {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="w-[466px] flex flex-col items-center gap-3">
        <div className="w-full bg-white py-[42px] px-[42px] flex flex-col justify-center items-center gap-9">
          <div className="text-center">
            <H4 className="font-semibold text-danger500">
              <code>404 error</code>
            </H4>
            <P1 className="text-center text-neutral600">
              You've reached a page that doesn't exist.
            </P1>
          </div>

          <div className="w-full flex justify-center">
            <LinkButton
              title="Return to dashboard"
              to="/dashboard"
              color="primary"
              className="font-bold"
            >
              Return to dashboard
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  )
}
