import { useMutation } from "@tanstack/react-query"
import saveAs from "file-saver"
import { useAppNotificationStore } from "stores"
import { baseUrl } from "utils/constants"
import { axiosInstance } from "utils/request"

export const useDownloadFileFromServer = () => {
  const { toast } = useAppNotificationStore()
  const { isLoading, mutateAsync, data } = useMutation({
    mutationFn: async ({
      url,
      method,
      data,
    }: {
      url: string
      data?: unknown
      method?: "POST" | "GET"
    }) => {
      if (method === "POST") {
        const res = await axiosInstance.post(url, data, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        })

        return res?.data
      } else {
        const res = await axiosInstance.get(url, {
          responseType: "blob",

          headers: {
            "Content-Type": "application/json",
          },
        })

        return res?.data
      }
    },
    onError(error) {
      toast.error("An error has occured. Try again")
      console.log(error)
    },
  })

  const downloadFile = (
    url: string,
    ext: string,
    fileName: string,
    data?: unknown,
    method?: "POST" | "GET"
  ): Promise<void> =>
    mutateAsync({ url, data, method }).then((data) =>
      saveAs(data, `${fileName}.${ext}`)
    )

  return { downloadFile, isLoading, data }
}
