import React from "react"
import MoveFundsComp from "./MoveFundsComp"
import { useAuthStore } from "stores"
import { useNavigate } from "react-router-dom"

const MoveFunds = () => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const walletId = user?.organisationWalletId || ""
  return <MoveFundsComp walletId={walletId} />
}

export default MoveFunds
