import { Form, InputNumber, Select } from "antd"
import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { useState } from "react"
import ArrowRight from "assets/svg/right-arrow.svg"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useAppNotificationStore, useAuthStore } from "stores"
import NigFlag from "assets/svg/nig_flag.svg"
import { useLocation, useNavigate } from "react-router-dom"
import { axiosInstance } from "utils/request"
import { useMutation, useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import SuccessModal from "../modals/SuccessModal"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import EnterPin from "../modals/EnterPin"
import { IWalletPade } from "../../interface/wallet"

const MoveFundsComp = ({ walletId }: { walletId: string }) => {
  const [form] = Form.useForm()
  const amount = Form.useWatch("amountEntered", form)
  const [amountInputed, setAmountInputed] = useState("")
  const sourceValue = Form.useWatch("from", form)
  const DestinationValue = Form.useWatch("to", form)
  const [balance, setBalance] = useState(0)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const { user } = useAuthStore()
  const navigate = useNavigate()

  const { toast } = useAppNotificationStore()
  console.log(balance)

  const { isLoading: isLoadingSwap, mutate: transferToSwap } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/swap-balance-funding`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.success) {
        data?.message && toast.success(data?.message)

        setShowSuccessModal(true)
        setAmountInputed(data?.data?.amount)
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      toast.error(data?.response?.data?.message)
    },
  })
  const { isLoading: isLoadingWithdraw, mutate: WithdrawToMain } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/swap-balance-withdrawal`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.success) {
        data?.message && toast.success(data?.message)
        form.setFieldValue("amountEntered", "")
        setShowSuccessModal(true)
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      toast.error(data?.response?.data?.message)
    },
  })
  const {
    isLoading: iswalletLoading,
    data: walletData,
    refetch: refetchWalletData,
  } = useQuery<IWalletPade>({
    queryKey: [`${walletBaseUrl}/v1/wallets`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${walletId}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })
  const handleSubmit = () => {
    const updatedType =
      sourceValue === "NGN Main Balance" ? "default" : "currency-swap"
    updatedType === "default"
      ? transferToSwap({
          amount: amountInputed,
          walletId,
          currency: "NGN",
        })
      : WithdrawToMain({
          amount: amountInputed,
          walletId,
          currency: "NGN",
        })
  }

  const options = [
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <img
            src={NigFlag}
            alt={`NGN flag`}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "8px",
            }}
          />
          <span>NGN Main Balance</span>
        </span>
      ),
      value: "NGN Main Balance",
    },
    {
      label: (
        <span style={{ display: "flex", alignItems: "center" }}>
          <img
            src={NigFlag}
            alt={`NGN flag`}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "8px",
            }}
          />
          <span>NGN Swap Balance</span>
        </span>
      ),
      value: "NGN Swap Balance",
    },
  ]

  const getFilteredOptions = (selectedValue: any) => {
    return options.filter((option) => option.value !== selectedValue)
  }
  const onFinish = () => {
    handleSubmit()
  }
  const getBalance = (e: string) => {
    const updatedType = e === "NGN Main Balance" ? "default" : "currency-swap"

    const balance = walletData?.data?.balances.find(
      (bal) => bal.currency === "NGN" && bal.type === updatedType
    )

    if (balance) {
      setBalance(balance.balance)
    } else {
    }
  }
  return (
    <DashboardWrapper>
      <>
        {showSuccessModal && (
          <SuccessModal
            title="Transfer successful "
            subtitle={`You have transfered ${formatCurrencyNoSymbol(
              Number(amountInputed)
            )} to your NGN Balance.`}
            onClose={() => {
              setShowSuccessModal(false)
              form.setFieldValue("amountEntered", "")
              setAmountInputed("")
              navigate(-1)
            }}
            open={showSuccessModal}
            // handleSubmit={handleSubmit}
          />
        )}
        <FormSectionWrapper
          isActive
          title="Move Funds"
          subtitle="Choose your source account to send money from."
          form={form}
          layout="vertical"
          onFinish={onFinish}
          footerBtns={
            <div className="  flex items-center gap-4 justify-end   ">
              <Button
                color="secondary"
                type="button"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Cancel{" "}
              </Button>
              <Button
                color="primary"
                submitting={isLoadingSwap || isLoadingWithdraw}
              >
                Confirm
              </Button>
            </div>
          }
        >
          <div className="px-4 md:px-6 lg:px-8 xl:px-[60px] flex flex-col gap-7 pb-[60px]">
            <div className="flex  items-center gap-[10px] ">
              <Form.Item
                className="flex-grow"
                label={
                  <span className="font-avenir  text-padeBlack text-sm  ">
                    Source{" "}
                  </span>
                }
                name="from"
                rules={[
                  {
                    required: true,
                    message: "Please enter this field!",
                  },
                ]}
              >
                <Select
                  showSearch
                  // loading={isLoadingBeneficiaries}
                  options={getFilteredOptions(DestinationValue)}
                  onChange={(e) => {
                    getBalance(e)
                  }}
                  placeholder="Select a currency"
                  filterOption={(input, option) =>
                    option?.label?.props.children[1]?.props.children
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <div className="">
                <img
                  src={ArrowRight}
                  alt=""
                />
              </div>
              <Form.Item
                className="flex-grow"
                label={
                  <span className="font-avenir  text-padeBlack text-sm  ">
                    {" "}
                    Destination
                  </span>
                }
                name="to"
                rules={[
                  {
                    required: true,
                    message: "Please enter this field!",
                  },
                ]}
              >
                <Select
                  showSearch
                  // loading={isLoadingBeneficiaries}
                  onChange={(value) => {}}
                  options={getFilteredOptions(sourceValue)}
                  placeholder="Select a currency"
                  filterOption={(input, option) =>
                    option?.label?.props.children[1]?.props.children
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </div>
            <Form.Item
              className="!mb-0"
              name="amountEntered"
              label={
                <div className="flex w-screen justify-between ">
                  <span className="font-avenir  text-padeBlack text-sm  ">
                    {" "}
                    Amount
                  </span>
                  {balance && (
                    <span>
                      Balance:{" "}
                      <span className="font-medium">
                        ₦{formatCurrencyNoSymbol(balance)}
                      </span>
                    </span>
                  )}
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the amount you want to withdraw",
                },
                {
                  validator: (_, value) => {
                    const minimumAmount = 200
                    if (!value || value >= minimumAmount) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(`Amount must be at least ₦${minimumAmount}`)
                    )
                  },
                },
                () => ({
                  validator: (_, value) => {
                    if (value && Number(value) > Number(balance)) {
                      return Promise.reject(
                        "Your amount is more than your source balance"
                      )
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <InputNumber<number>
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) =>
                  value?.replace(/,/g, "") as unknown as number
                }
                className="w-full"
                placeholder="e.g 200"
                onChange={(e: any) => setAmountInputed(e)}
              />
            </Form.Item>
          </div>
        </FormSectionWrapper>
      </>
    </DashboardWrapper>
  )
}

export default MoveFundsComp
