import { Form, Modal, Spin } from "antd"
import { useMemo, useState } from "react"
import {
  IAddUserRequest,
  ICustomRoleRequest,
  IRole,
} from "../interface/interface"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { Button } from "components"
import { useGet, usePost } from "utils/useFetch"
import usePermissions from "hooks/usePermissions"
import { AppSingleSelect } from "components/inputs/select/AppSelect"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { useServerResponse } from "hooks/useServerResponse"

type Props = {
  role: IRole
  onClose: Function
}

export const ChangePrimaryAdminModal = ({ role, onClose }: Props) => {
  const [form] = Form.useForm()

  const { data } = usePost<any>({
    url: `/admin/company/getadmins?includePrimary=false`,
    body: {
      q: "",
      page: "",
    },
  })

  const toast = useServerResponse()

  const { toast: showToast } = useAppNotificationStore()

  const { mutate, isLoading } = useMutation({
    mutationFn: async (new_admin_id: string) => {
      const res = await axiosInstance.post(`/admin/company/change_admin`, {
        new_admin_id,
      })
      return res.data
    },
    onSuccess: (data) => {
      toast(data)
      if (data.status == "success") {
        onClose(true)
      }
    },
  })

  const [canAddMember, canRemove] = usePermissions({
    allowedPermissions: [
      "can_change_team_member_roles",
      "can_remove_team_members",
    ],
  })

  return (
    <CustomModal
      onCancel={() => onClose()}
      title={`Switch Primary Admin`}
      open
      footer={null}
    >
      <Spin spinning={false}>
        <>
          <Form
            form={form}
            className=""
            layout="vertical"
            onFinish={(values) => {
              if (canRemove && canAddMember) {
                mutate(values?.primaryAdmin)
              } else {
                showToast.error(
                  "You don't have the required permission to perform this operation"
                )
              }
            }}
          >
            <div className="py-1 flex flex-col gap-2">
              <div className="pb-1">
                The Primary Admin is the only admin that is able to add and
                remove collaborators unless they decide to delegate. When
                setting a new admin, the old and new admins will be
                automatically notified via email about this change.
              </div>
              <div>
                <div className="text-padeSubtext font-[600]">
                  Current Primary Admin
                </div>
                <div className="text-padeBlack font-[600]">
                  {role?.roleMembers?.[0]?.nick_name}
                </div>
              </div>
              <AppSingleSelect
                name={"primaryAdmin"}
                placeholder="New Primary Admin"
                label="New Primary Admin"
                rules={[
                  {
                    required: true,
                    message: "Please the select new primary admin",
                  },
                ]}
                options={data?.map((it: any) => ({
                  label: it?.text,
                  value: it?.id?.toString(),
                }))}
              />
              <div className="p-2 bg-[#fbe9c9] text-[#916c2e] rounded-md">
                After making this change, you’ll no longer be able to add or
                remove admins. Only the new Primary Admin can undo this action.
              </div>
            </div>
          </Form>

          <div className="flex w-full justify-end pt-4 gap-3 mt-8">
            <Button
              color="secondary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              submitting={isLoading}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Done
            </Button>
          </div>
        </>
      </Spin>
    </CustomModal>
  )
}
