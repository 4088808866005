import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useRef } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { useAuthStore } from "stores"
import { useGet } from "utils/useFetch"
import { Spin } from "antd"
import { AppTableContainer } from "components/custom/AppTableContainer"
import { DetailsSummary } from "../components/DetailsSummary"
import { AppPdfDownloadButton } from "components/buttons/AppPdfDownloadButton"
import { AppCSVDownloadButton } from "components/buttons/AppCSVDownloadButton"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const PayRollReportDetails = () => {
  const { user } = useAuthStore()
  const { pathname } = useLocation()

  const { payrollId } = useParams()

  const [searchParams] = useSearchParams()

  const type = searchParams.get("type")

  const { isLoading: getting, data } = useGet<any>({
    url:
      type === "preview"
        ? `${
            pathname.includes("approval") ? "" : "/admin"
          }/payrolls/view/report_preview?id=${payrollId}&x=full`
        : `${
            pathname.includes("approval") ? "" : "/admin"
          }/payrolls/view/report?id=${payrollId}&x=full`,
    onsuccess: () => {
      mixPanelEvent(
        type === "preview"
          ? "payroll-report-preview-listing"
          : "payroll-report-listing"
      )
    },
  })

  //@: For pdf report printing
  const ref: any = useRef()

  return (
    <DashboardWrapper>
      <Spin spinning={getting}>
        <div className="font-avenir w-[100%]">
          <div className="w-full flex items-center justify-between gap-3 py-[30px]">
            <div>{/* <AppBackButton /> */}</div>
            <div className="flex gap-3 items-center">
              {ref && (
                <AppPdfDownloadButton
                  title={data?.report_download_title}
                  ref={ref}
                />
              )}

              <AppCSVDownloadButton title={data?.report_download_title} />
            </div>
          </div>
          <div
            className=" bg-white p-4 flex gap-2 flex-col w-[100%] box-border"
            ref={ref}
          >
            <div className="py-4 border-b border-gray-100">
              <div className="flex justify-between  gap-2 items-center">
                <div className=" text-lg font-[600] text-neutral500 ">
                  {data?.payrollStatusId < 5
                    ? "Payroll Journal Report (Draft)"
                    : "Payroll Journal Report"}
                </div>
                {data?.logoUploaded && (
                  <div className="h-[50px] w-[50px]">
                    <img
                      className="float-right object-contain clear-left"
                      src={`${user?.organisationAvatarFilename}`}
                      alt="logo"
                      height="100%"
                      width="100%"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between gap-3 items-center">
              <div className="text-neutral500">
                Report created on: {data?.report_date}
              </div>
              <div className="flex justify-end  flex-col items-end">
                <div className="text-neutral500">{user?.userOrganisation}</div>
                <div className="text-neutral500">
                  {data?.officeAddress?.address_line_1}
                </div>
                <div className="text-neutral500">
                  {data?.officeAddress?.address_city}&nbsp;
                  {data?.officeAddress?.address_state}&nbsp;
                  {data?.officeAddress?.zip_code}&nbsp;
                  {data?.officeAddress?.country}
                </div>
              </div>
            </div>

            <AppTableContainer>
              <DetailsSummary data={data} />
            </AppTableContainer>
          </div>
        </div>
      </Spin>
    </DashboardWrapper>
  )
}

export default PayRollReportDetails
