import { Link, LinkProps } from "react-router-dom"

/**
 * An optimized text link.
 * Pass the `title` props, else `⚠` is returned
 */
export const TextLink: React.FC<
  LinkProps & React.RefAttributes<HTMLAnchorElement>
> = (props) => {
  // if (!props["title"]) return <span children={"⚠"} />
  return (
    <Link
      aria-label={props.title}
      {...props}
      className={`text-center p-0 decoration-solid underline focus:outline-none focus:shadow-none disabled:opacity-50 ${props.className}`}
    />
  )
}
