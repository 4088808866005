import { useMutation } from "@tanstack/react-query"
import { Form, Input } from "antd"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import React, { useState } from "react"
import { useAppNotificationStore, useAuthStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"
import { axiosInstance } from "utils/request"
type Props = {
  onClose: () => void
  invalidateQueries: () => void
  open: boolean
  paymentId: string
  x: string
  currency?: string
}

export const RefreshPayment = ({
  onClose,
  open,
  paymentId,
  x,
  invalidateQueries,
  currency,
}: Props) => {
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const [comment, setComment] = useState("")
  const { isLoading: isSubmittingRefreshAll, mutate: submitRefreshAll } =
    useMutation({
      mutationFn: async (body: string) => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/refresh_payment_schedule_items_new?id=${paymentId}&currency_code=${currency}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          onClose()
          setComment("")
          {
            data.msg && toast.success(data.msg)
          }
          mixPanelEvent("pay-schedule-payout-refresh-success")
          invalidateQueries()
        } else {
          setComment("")
          onClose()
          {
            data.msg && toast.error(data.msg)
          }
        }
      },
      onError: (data: any) => {
        onClose()
        setComment("")
        {
          data.msg && toast.error(data.msg)
        }
      },
    })
  const { isLoading: isSubmittingRefreshSingle, mutate: submitRefreshSingle } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/refresh_payment_schedule_item_new?id=${paymentId}&currency_code=${currency}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          onClose()

          {
            data.msg && toast.success(data.msg)
          }

          invalidateQueries()
          mixPanelEvent("pay-schedule-payout-refresh-success")
        } else {
          onClose()
          {
            data.msg && toast.error(data.msg)
          }
        }
      },
      onError: (data: any) => {
        onClose()
        {
          data.msg && toast.error(data.msg)
        }
      },
    })

  return (
    <NewModalWrapper
      open={open}
      title="Refresh Payment"
      className="w-[750px]"
      onClose={onClose}
    >
      <div className="px-5 py-3">
        <p className=" text-neutral300 mb-5 text-sm">
          {paymentId == getIdFromKey(x || "")
            ? `All payments will be refreshed in this list`
            : `This payment will be refreshed`}
        </p>
      </div>
      <div className="border py-5 px-6 flex gap-4  justify-end">
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          submitting={isSubmittingRefreshSingle || isSubmittingRefreshAll}
          onClick={() => {
            mixPanelEvent("pay-schedule-payout-refresh-init")
            {
              paymentId == getIdFromKey(x || "")
                ? submitRefreshAll(comment)
                : submitRefreshSingle()
            }
          }}
        >
          Refresh
        </Button>
      </div>
    </NewModalWrapper>
  )
}

export default RefreshPayment
