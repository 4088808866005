import React from "react"
import { useNavigate } from "react-router-dom"
import {
  Button,
  H3,
  IconButton,
  Label,
  ModalPortal,
  P2,
  P3,
} from "../../../../components"
import parse from "html-react-parser"
import { XMarkIcon } from "@heroicons/react/24/outline"

const DocumentPreview: React.FC<{
  value?: string
  onClose: Function
  title?: string
}> = ({ value, onClose, title = "your" }) => {
  const navigate = useNavigate()

  return (
    <div className="fixed z-40 h-screen w-screen flex justify-center items-center top-0 bottom-0 left-0 right-0 bg-[#1E1E1E]/70 overflow-y-auto font-avenir pt-16">
      <div
        className={`relative h-auto my-auto bg-white rounded-5px   w-[900px]`}
      >
        <div className="px-6 pt-4 pb-10 ">
          <div className=" flex justify-between items-center w-full">
            <h5 className="text-neutral700 font-medium">
              {" "}
              Preview of {title} Template
            </h5>

            <IconButton
              title="Close"
              onClick={() => onClose()}
            >
              <XMarkIcon className="w-5" />
            </IconButton>
          </div>
          <div className="border"></div>
          <div className="flex justify-center mt-4">
            <div className=" bg-backgrounddarker rounded-5px text-center p-5">
              <P2>
                We’ve filled in your Smart Text fields with example info so you
                can see what this offer will look like.
              </P2>
            </div>
          </div>
          <div className=" mt-10 text-sm  ">
            {parse(`<div className="h-full">${value}</div>`)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentPreview
