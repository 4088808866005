import { DashboardContainer } from "components"
import { useState } from "react"
import { EditCompanyNameModal } from "./Popups"
import { useNavigate } from "react-router-dom"

const EditCompanyInfo = () => {
  const navigate = useNavigate()
  const [activeKey, setActiveKey] = useState<string[]>(["1"])

  return (
    <DashboardContainer>
      <div className="max-w-[1024px] mt-6  rounded-md space-y-[20px]">
        <EditCompanyNameModal
          active={activeKey.includes("1")}
          setActiveKey={setActiveKey}
          toggleEditCompanyNameModal={() =>
            navigate("/dashboard/company-details")
          }
        />
      </div>
    </DashboardContainer>
  )
}

export default EditCompanyInfo
