import { Spin } from "antd"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useParams } from "react-router-dom"
import { useGet } from "utils/useFetch"
import { ChatComponent } from "./ChatComponent"
import SegmentWrapper from "components/custom/SegmentWrapper"

export const ViewViolationPage = () => {
  const { violationId } = useParams()

  const { data, isLoading, refetch } = useGet<Data>({
    url: `/disciplinary/view/violation?id=${violationId}`,
    enabled: !!violationId,
  })

  return (
    <DashboardWrapper>
      <Spin spinning={isLoading}>
        <SegmentWrapper
          title={data?.employeeViolation?.employee_violation_type}
        >
          <div className="px-4 md:px-6 lg:px-[60px]">
            <div className="mb-2 border-b py-2">
              <LabelRender
                title="Violation Detail"
                content={data?.employeeViolation?.description}
              />
            </div>

            <div>
              {data && (
                <ChatComponent
                  violation={data?.employeeViolation}
                  refetch={refetch}
                />
              )}
            </div>
          </div>
        </SegmentWrapper>
      </Spin>
    </DashboardWrapper>
  )
}

const LabelRender = ({ title, content }: any) => {
  return (
    <div>
      {/* <div className="text-gray-600 text-[16px]">{title}</div> */}
      <p className="text-gray-700 text-[18px]">{content}</p>
    </div>
  )
}

export interface Data {
  violationPolicies?: null
  violationPolicy?: null
  employeeViolations?: null
  employeeViolation: EmployeeViolation
  persons?: null
}
export interface EmployeeViolation {
  employee_violation_key: string
  employee_violation_id: number
  description: string
  is_response_required: boolean
  has_document?: null
  person_key: string
  person_id: number
  first_name: string
  middle_initial: string
  last_name: string
  full_name: string
  nick_name: string
  email: string
  avatar_url: string
  person_status_id?: null
  person_status?: null
  target_person_key: string
  target_person_id: number
  target_first_name: string
  target_middle_initial?: null
  target_last_name: string
  target_full_name: string
  target_nick_name: string
  target_email: string
  target_avatar_url: string
  target_person_status_id?: null
  target_person_status?: null
  incident_date: string
  incident_date_short?: null
  reviews?: null
  review_date?: null
  review_status?: null
  friendly_date?: null
  created_date?: null
  employee_violation_type_id: number
  employee_violation_type: string
  employee_violation_status_id: number
  employee_violation_status: string
  employee_violation_source_id: number
  employee_violation_source: string
  is_deleted: boolean
  is_active: boolean
  items?: ItemsEntity[] | null
}
export interface ItemsEntity {
  employee_violation_item_key?: null
  employee_violation_item_id: number
  employee_violation_id: number
  description: string
  start_date?: null
  due_date?: null
  start_date_display?: null
  due_date_display?: null
  employee_violation_item_status_id?: null
  employee_violation_item_status?: null
  employee_violation_item_stateId?: null
  employee_violation_item_state?: null
  employee_violation_item_type_id: number
  employee_violation_item_type: string
  created_by: string
  created_by_id: number
  created_by_name: string
  created_date: string
  is_deleted: boolean
  is_active: boolean
}
