import { Alert, Form, InputNumber } from "antd"
import { useState } from "react"
import { Button, P2 } from "components"
import { useGet } from "utils/useFetch"
import { newBaseUrl, walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore } from "stores"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import FormSectionWrapper from "pages/employee-dashboard/FormSectionWrapper"
import { CustomInput, CustomSelect } from "pages/employee-dashboard/form"
import PrevNextButton from "pages/employee-dashboard/form/PrevNextButton"
import { IWithdrawalReasonsData } from "../interface/withdrawal"
import { IEarningsWalletData } from "../interface/wallet"
import { divideEarningsByPercent } from "./EarnedWageAccess"
import EnterEwaPin from "./modals/EnterPin"
import SuccessModal from "pages/dashboard/wallets/crossBorderPayments/modals/SuccessModal"
import { WalletWrapper } from "../wallet/crossBorder/viewLocal/ViewLocalCurrency"
import { IUserWalletID } from "../wallet/kyc/interface"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"

const Withdrawal = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId

  const [form] = Form.useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [fee, setFee] = useState(0)
  const [otherReasonTrue, setOtherReasonTrue] = useState(false)
  const [hideDetails, setHideDetails] = useState(false)
  const [otp, setOtp] = useState<string[]>(["", "", "", ""])

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [withdrawalData, setWithdrawalData] = useState<IBody>({
    amount: "",
    reasonId: "",
    transactionPin: "",
    reasonText: "",
    channel: "WEB",
  })

  const onFinish = (values: any) => {
    setShowPassword(true)
  }
  const { isLoading: isWithdrawalsLoading, data: withdrawalsReasons } =
    useGet<IWithdrawalReasonsData>({
      url: `${newBaseUrl}/v1/withdrawal-reasons`,
      external: true,
    })
  const { isLoading: isWithdrawalInfoLoading, data: withdrawalsInfo } =
    useGet<any>({
      url: `${newBaseUrl}/v1/misc/settings`,
      external: true,
    })

  const {
    isLoading: isEarningWalletLoading,
    data: earningWalletData,
    refetch,
  } = useGet<IEarningsWalletData>({
    url: `${newBaseUrl}/v1/earnings/wallet`,
    external: true,
  })
  const {
    isLoading: isLoadingWalletData,
    data: walletData,
    refetch: refetchWallet,
  } = useQuery<IUserWalletID>({
    queryKey: [`${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
    onSuccess: (data: IUserWalletID) => {},
  })
  const mainBalance = walletData?.data?.balances?.find(
    (balance: any) => balance.currency === "NGN"
  )

  const { isLoading: isLoadingCheck, mutate: checkAmount } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.get(
        `${newBaseUrl}/v1/fees/calculate?amount=${withdrawalData?.amount}`
      )

      return res
    },
    onSuccess: (data: any) => {
      console.log(data)

      if (data) {
        setFee(data?.data?.data.fee)
      } else {
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {},
  })
  const { isLoading: isProcessingWithdrawal, mutate: processWithdrawal } =
    useMutation({
      mutationFn: async (body: IBody) => {
        const res: any = await axiosInstance.post(
          `${newBaseUrl}/v1/earnings/withdraw`,
          body
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.success) {
          setOtp(["", "", "", ""])
          data.message && toast.success(data.message)
          mixPanelEvent("empl-ewa-withdrawal-success")
          setShowSuccessModal(true)
          setShowPassword(false)
          refetchWallet()
          // navigate("/employee/dashboard/wallet/earned-wage-access/overview")
        } else {
          setOtp(["", "", "", ""])

          data.message && toast.error(data.message)
          setShowPassword(false)
        }
      },
      onError: (data: any) => {
        setOtp(["", "", "", ""])

        data?.response?.data?.message &&
          toast.error(data?.response?.data?.message)
      },
    })

  const handleWithdraw = (pin: string) => {
    mixPanelEvent("empl-ewa-withdrawal-init")
    processWithdrawal({
      ...withdrawalData,
      amount: Number(withdrawalData.amount),
      transactionPin: pin,
    })
  }

  const handleCheckValue = (value: number): boolean => {
    const minimumWithdrawalAmount =
      withdrawalsInfo?.data?.minimumWithdrawalAmount
    const maximumWithdrawalAmount =
      withdrawalsInfo?.data?.maximumWithdrawalAmount
    return value >= minimumWithdrawalAmount && value <= maximumWithdrawalAmount
  }
  const EWAWalletWithdrawalDisabled =
    user?.userOrganisationId === "108" ? true : false

  return (
    <DashboardWrapper>
      <>
        {isEarningWalletLoading ? (
          <div className=" z-50 flex justify-center items-center right-0 top-0 left-0 bottom-0 py-10">
            <LoadingIndicatorWhite />
          </div>
        ) : (
          <div className="flex  w-full">
            <div className=" w-full sm:w-[952px]">
              <FormSectionWrapper
                name="Withdraw Earned Wages"
                title="Withdraw Earned Wages"
                subtitle="Access your earned wages instantly through your wallet"
                isActive={true}
                initialValues={{
                  bank: earningWalletData?.data?.employee?.bankName || "",
                  accountNumber:
                    earningWalletData?.data?.employee?.bankAccountNumber || "",
                }}
                layout="vertical"
                form={form}
                onFinish={onFinish}
                style={{ maxWidth: 1024 }}
                autoComplete="off"
                footerBtns={
                  <div className="flex gap-2 items-end justify-end">
                    <Button
                      type="button"
                      onClick={() => navigate(-1)}
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <PrevNextButton showPrevButton={false} />
                  </div>
                }
              >
                {EWAWalletWithdrawalDisabled ? (
                  <div className="flex flex-col flex-wrap gap-10 px-4 md:px-6 xl:px-[60px]">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-10 w-full withdrawal">
                      <CustomInput
                        label="Bank"
                        name="bank"
                        // required
                        disabled={true}
                      />
                      <CustomInput
                        label="Account number"
                        name="accountNumber"
                        // required
                        disabled={true}
                      />

                      <Form.Item
                        className="!mb-0"
                        name="amount"
                        label={
                          <div className="flex justify-between items-center !w-full  ">
                            <p className="font-circular text-padeBlack text-sm">
                              Amount
                            </p>
                            <p className="font-circular text-padeBlack text-sm font-light">
                              Balance: ₦
                              {formatCurrencyNoSymbol(
                                Number(
                                  earningWalletData?.data?.availableBalance || 0
                                )
                              )}
                            </p>
                          </div>
                        }
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input the amount you want to withdraw",
                          },
                          {
                            validator: (_, value) => {
                              const minimumAmount =
                                withdrawalsInfo?.data?.minimumWithdrawalAmount
                              if (value === 0) {
                                return Promise.reject(
                                  new Error(
                                    `Amount must be at least ₦${minimumAmount}`
                                  )
                                )
                              } else {
                                if (!value || value >= minimumAmount) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(
                                  new Error(
                                    `Amount must be at least ₦${minimumAmount}`
                                  )
                                )
                              }
                            },
                          },
                          () => ({
                            validator: (_, value) => {
                              if (
                                value &&
                                Number(value) >
                                  Number(
                                    earningWalletData?.data?.availableBalance ||
                                      0
                                  )
                              ) {
                                return Promise.reject(
                                  "Your withdrawal amount is more than your balance"
                                )
                              }
                              return Promise.resolve()
                            },
                          }),
                        ]}
                        extra={
                          <P2 className="text-padeSubtext mt-1 min-h-[14px]">
                            {withdrawalData?.amount && fee > 0 ? (
                              <p className="text-center text-subText3">
                                You will be charged NGN
                                {formatCurrencyNoSymbol(Number(fee))} for this
                                transaction
                              </p>
                            ) : (
                              ""
                            )}
                          </P2>
                        }
                      >
                        <InputNumber<number>
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) =>
                            value?.replace(/,/g, "") as unknown as number
                          }
                          className="w-full"
                          placeholder="e.g 500"
                          onChange={(e: any) => {
                            setWithdrawalData({
                              ...withdrawalData,
                              amount: e,
                            })
                          }}
                          onBlur={(e: any) => {
                            const inputValue = parseFloat(e)
                            if (isNaN(inputValue)) {
                            } else {
                              const isValid = handleCheckValue(inputValue)
                              if (isValid) {
                                checkAmount(e)
                              } else {
                                toast.error(
                                  `Withdrawal amount is not valid, minimum withdrawal is N${formatCurrencyNoSymbol(
                                    Number(
                                      withdrawalsInfo?.data
                                        ?.minimumWithdrawalAmount
                                    )
                                  )} and maximum withdrawal is N${formatCurrencyNoSymbol(
                                    Number(
                                      divideEarningsByPercent(
                                        earningWalletData?.data
                                          ?.totalEarnings || 0,
                                        50
                                      )
                                    )
                                  )} `
                                )
                              }
                            }
                          }}
                        />
                      </Form.Item>

                      <CustomSelect
                        label="Purpose"
                        name="purpose"
                        required
                        onChange={(selected) => {
                          let owner = selected.split("-")
                          let name = owner[0]
                          owner = owner[1]
                          setWithdrawalData({
                            ...withdrawalData,
                            reasonId: owner,
                          })
                          if (name == "others") {
                            setOtherReasonTrue(true)
                          } else {
                            setOtherReasonTrue(false)
                          }
                        }}
                        isLoading={isWithdrawalsLoading}
                        options={
                          isWithdrawalsLoading
                            ? []
                            : withdrawalsReasons?.data?.docs?.map((state) => ({
                                label: state.name,
                                value: `${state.name}-${state._id}`,
                              }))
                        }
                        placeholder="Please select"
                      />
                      {otherReasonTrue && (
                        <CustomInput
                          label="Reason"
                          name="reason"
                          required={otherReasonTrue ? true : false}
                          onChange={(e) => {
                            setWithdrawalData({
                              ...withdrawalData,
                              reasonText: e.target.value,
                            })
                          }}
                        />
                      )}
                    </div>
                    {withdrawalData?.amount && fee > 0 ? (
                      <p className="text-center text-subText3 font-medium">
                        You will be charged NGN
                        {formatCurrencyNoSymbol(Number(fee))} for this
                        transaction
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col flex-wrap gap-10 px-4 md:px-6 xl:px-[60px] pb-10">
                    <Alert
                      message={
                        "Your earned wages will now be sent directly to your PaidHR wallet, giving you quicker access to your funds. "
                      }
                      type="info"
                      showIcon
                      className="font-avenir text-padeBlack text-sm w-full flex justify-between items-center !bg-[#E8F0FE] !rounded border-none"
                    />

                    <div className=" grid grid-cols-1 sm:grid-cols-2 gap-x-10 w-full min-h-[237px]">
                      <div className="">
                        <div className=" mb-6">
                          <span className="text-[#6C7A9399] font-circular font-semibold mb-1">
                            E.W.A Withdrawable Balance
                          </span>
                          <h4 className="font-circular font-bold text-2xl ">
                            ₦
                            {formatCurrencyNoSymbol(
                              Number(
                                earningWalletData?.data?.availableBalance || 0
                              )
                            )}
                          </h4>
                        </div>
                        <Form.Item
                          name="amount"
                          label={
                            <p className="font-circular text-padeBlack text-sm">
                              Amount
                            </p>
                          }
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the amount you want to withdraw",
                            },
                            {
                              validator: (_, value) => {
                                const minimumAmount =
                                  withdrawalsInfo?.data?.minimumWithdrawalAmount
                                if (value === 0) {
                                  return Promise.reject(
                                    new Error(
                                      `Amount must be at least ₦${minimumAmount}`
                                    )
                                  )
                                } else {
                                  if (!value || value >= minimumAmount) {
                                    return Promise.resolve()
                                  }
                                  return Promise.reject(
                                    new Error(
                                      `Amount must be at least ₦${minimumAmount}`
                                    )
                                  )
                                }
                              },
                            },
                            () => ({
                              validator: (_, value) => {
                                if (
                                  value &&
                                  Number(value) >
                                    Number(
                                      earningWalletData?.data
                                        ?.availableBalance || 0
                                    )
                                ) {
                                  return Promise.reject(
                                    "Your withdrawal amount is more than your balance"
                                  )
                                }
                                return Promise.resolve()
                              },
                            }),
                          ]}
                        >
                          <InputNumber<number>
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) =>
                              value?.replace(/,/g, "") as unknown as number
                            }
                            className="w-full"
                            placeholder="e.g 500"
                            onChange={(e: any) => {
                              setWithdrawalData({
                                ...withdrawalData,
                                amount: e,
                              })
                              if (
                                Number(e) >=
                                Number(
                                  withdrawalsInfo?.data?.minimumWithdrawalAmount
                                )
                              ) {
                                const inputValue = parseFloat(e)
                                if (isNaN(inputValue)) {
                                  console.log("NAN")
                                } else {
                                  const isValid = handleCheckValue(inputValue)
                                  console.log(isValid)

                                  if (isValid) {
                                    checkAmount(e)
                                  } else {
                                    toast.error(
                                      `Withdrawal amount is not valid, minimum withdrawal is N${formatCurrencyNoSymbol(
                                        Number(
                                          withdrawalsInfo?.data
                                            ?.minimumWithdrawalAmount
                                        )
                                      )} and maximum withdrawal is N${formatCurrencyNoSymbol(
                                        Number(
                                          divideEarningsByPercent(
                                            earningWalletData?.data
                                              ?.totalEarnings || 0,
                                            50
                                          )
                                        )
                                      )} `
                                    )
                                  }
                                }
                              }
                            }}
                            onBlur={(e: any) => {
                              console.log(e)
                            }}
                          />
                        </Form.Item>

                        <CustomSelect
                          label="Purpose"
                          name="purpose"
                          required
                          onChange={(selected) => {
                            let owner = selected.split("-")
                            let name = owner[0]
                            owner = owner[1]
                            setWithdrawalData({
                              ...withdrawalData,
                              reasonId: owner,
                            })
                            if (name == "others") {
                              setOtherReasonTrue(true)
                            } else {
                              setOtherReasonTrue(false)
                            }
                          }}
                          isLoading={isWithdrawalsLoading}
                          options={
                            isWithdrawalsLoading
                              ? []
                              : withdrawalsReasons?.data?.docs?.map(
                                  (state) => ({
                                    label: state.name,
                                    value: `${state.name}-${state._id}`,
                                  })
                                )
                          }
                          placeholder="Please select"
                        />
                        {otherReasonTrue && (
                          <CustomInput
                            label="Reason"
                            name="reason"
                            required={otherReasonTrue ? true : false}
                            onChange={(e) => {
                              setWithdrawalData({
                                ...withdrawalData,
                                reasonText: e.target.value,
                              })
                            }}
                          />
                        )}
                      </div>

                      <WalletWrapper
                        className={`px-4 lg:px-6 py-8 w-full flex items-center bg-black relative h-[237px]`}
                        id="wallet-balance"
                      >
                        <div className="h-full w-full flex flex-col justify-between">
                          <div className="flex justify-between items-center w-full  ">
                            <div className=" gap-[2px] w-full">
                              <div className=" !w-full flex items-center justify-between">
                                <p className="text-[#98A2B3] text-base  font-semibold font-circular flex items-center">
                                  Wallet Balance
                                </p>
                                <button
                                  className=" flex"
                                  type="button"
                                  onClick={() => setHideDetails(!hideDetails)}
                                >
                                  {hideDetails ? (
                                    <EyeIcon className="text-a11y w-6 h-6 cursor-pointer" />
                                  ) : (
                                    <EyeSlashIcon className="text-a11y w-6 h-6 cursor-pointer" />
                                  )}
                                </button>
                              </div>
                              {hideDetails ? (
                                <h3 className="text-a11y font-bold font-circular text-4xl lg:text-[40px]">
                                  ****
                                </h3>
                              ) : (
                                <>
                                  <p className="text-a11y font-bold font-circular text-4xl lg:text-[40px]">
                                    {mainBalance?.symbol}
                                    {mainBalance?.balance &&
                                      formatCurrencyNoSymbol(
                                        Number(mainBalance?.balance) || 0
                                      )}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="mt-[21px] flex justify-between items-center">
                            <div className="mt-3 flex items-center gap-3">
                              <Button
                                type="button"
                                outline={true}
                                onClick={() => {
                                  navigate(
                                    "/employee/dashboard/wallet/cross-border/local"
                                  )
                                }}
                                className="text-white"
                                id="wallet-withdrawal-btn"
                              >
                                Go to Wallet
                              </Button>
                            </div>
                          </div>
                        </div>
                      </WalletWrapper>
                    </div>
                    <div className="">
                      {withdrawalData?.amount && fee > 0 ? (
                        <p className="text-center text-subText3 font-medium">
                          You will be charged NGN
                          {formatCurrencyNoSymbol(Number(fee))} for this
                          transaction
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </FormSectionWrapper>
            </div>
          </div>
        )}
      </>

      <EnterEwaPin
        title={`Enter Transaction PIN`}
        onClose={() => setShowPassword(false)}
        open={showPassword}
        loading={isProcessingWithdrawal}
        handleSubmit={handleWithdraw}
        btnText={`Withdraw ₦${formatCurrencyNoSymbol(
          Number(withdrawalData?.amount)
        )}`}
        setOtp={setOtp}
        otp={otp}
      />

      <SuccessModal
        title="Withdrawal Successful "
        subtitle={
          EWAWalletWithdrawalDisabled
            ? `₦${formatCurrencyNoSymbol(
                Number(withdrawalData?.amount)
              )} has been sent to your provided bank account `
            : `₦${formatCurrencyNoSymbol(
                Number(withdrawalData?.amount)
              )} has been sent to your PaidHR Wallet `
        }
        onClose={() => {
          if (EWAWalletWithdrawalDisabled) {
            setShowSuccessModal(false)
            form.resetFields()
            navigate(-1)
          } else {
            navigate("/employee/dashboard/wallet/cross-border/local")
          }
        }}
        open={showSuccessModal}
        ctaButton={
          <Button
            color="primary"
            className="mt-5"
            onClick={() => {
              if (EWAWalletWithdrawalDisabled) {
                setShowSuccessModal(false)
                form.resetFields()
                navigate(-1)
              } else {
                navigate("/employee/dashboard/wallet/cross-border/local")
              }
            }}
          >
            Ok
          </Button>
        }
      />
    </DashboardWrapper>
  )
}

export default Withdrawal
interface IBody {
  amount: string | number
  reasonId: string
  transactionPin: string
  channel: string
  reasonText: string
}
