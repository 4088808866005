import { Tag } from "antd"

export const ProfileTemp: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    title: string
    subtitle?: string
    value: string
  }
> = ({ title, subtitle, value, ...props }) => {
  return (
    <div className=" flex items-center  justify-between mb-8">
      <div className=" w-5/12">
        <h6 className=" text-sm font-bold text-neutral500 ">{title}</h6>
        {subtitle && (
          <p className=" text-neutral100 mt-1 font-normal text-sm">
            {subtitle}
          </p>
        )}
      </div>
      <div className=" w-5/12 flex justify-start">
        <p className=" text-neutral300 text-sm font-avenir">
          {value ? (
            value
          ) : (
            <Tag
              color="gold"
              className="px-2 py-1"
            >
              Missing details
            </Tag>
          )}
        </p>
      </div>
    </div>
  )
}
