import { useQuery } from "@tanstack/react-query"
import { Spin } from "antd"
import { DashboardContainer, P2 } from "components"
import { TableActionButton } from "components/buttons/TableActionButton"
import SegmentWrapper from "components/custom/SegmentWrapper"
import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import getIdFromKey from "utils/getIdFromKey"
import { axiosInstance } from "utils/request"
import parse from "html-react-parser"
import DocumentPreview from "../DocumentPreview"

type Props = {}

const ViewOfferDocument = (props: Props) => {
  const [searchParams] = useSearchParams()

  const [showPreview, setShowPreview] = useState(false)

  const navigate = useNavigate()

  const { data, isLoading: gettingOfferTemplate } = useQuery<any>({
    queryFn: async () => {
      const res = await axiosInstance.get(
        `/admin/documents/gettemplate?id=${getIdFromKey(
          searchParams.get("x") || ""
        )}`
      )
      return res.data
    },
    queryKey: ["offer-content", searchParams.get("x")],
    enabled: !!searchParams.get("x"),
  })

  const content = useMemo(() => data?.data?.contents, [data])

  if (gettingOfferTemplate)
    return (
      <DashboardContainer>
        <div>
          <Spin />
        </div>
      </DashboardContainer>
    )
  return (
    <DashboardContainer>
      <SegmentWrapper
        title={data?.data?.description?.toUpperCase()}
        actionBtn={
          <TableActionButton
            menuItems={actionItems(
              navigate,
              searchParams.get("x") || "",
              setShowPreview
            )}
          />
        }
      >
        <div>
          <div className="flex justify-center mt-4">
            {content && (
              <div className="px-[60px]">
                <P2>
                  Click “Preview” to see a preview of the template with sample
                  candidate info filled in, so you can see what it will look
                  like. It will be customized for the candidate you extend an
                  offer to.
                </P2>
              </div>
            )}
          </div>
          <div className="mt-10 text-sm px-[60px]">
            {content ? (
              parse(`<div className="h-full">${content}</div>`)
            ) : (
              <div>
                <div>
                  No content to preview. <br /> Click “Edit” to add content to
                  your template.
                </div>
              </div>
            )}
          </div>
        </div>
        {showPreview && (
          <DocumentPreview
            value={content}
            onClose={() => setShowPreview(false)}
            title={data?.data?.description}
          />
        )}
      </SegmentWrapper>
    </DashboardContainer>
  )
}

export default ViewOfferDocument

const actionItems = (
  navigate: NavigateFunction,
  key: string = "",
  setShowPreview: Dispatch<SetStateAction<boolean>>
) => [
  {
    key: "1",
    label: (
      <div
        onClick={() => {
          navigate(
            `/dashboard/documents/offer-template?id=${getIdFromKey(key)}`
          )
        }}
      >
        Edit
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <div
        onClick={() => {
          navigate(`/dashboard/offers/send-offer?x=${key}`)
        }}
      >
        Make offer
      </div>
    ),
  },
  {
    key: "3",
    label: (
      <div
        onClick={() => {
          setShowPreview(true)
        }}
      >
        Preview
      </div>
    ),
  },
]
