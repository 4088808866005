import { EventCard } from "./EventCard"

type eventProps = {
  eventArray?: any
}
export const EventTagsLeaves: React.FC<eventProps> = ({ eventArray = [] }) => {
  return (
    <div className="h-full">
      {eventArray?.length === 0 ? (
        <div className="flex mt-[100px] h-full items-center justify-center ">
          <h6 className="text-sm  text-neutral700">No Available Leaves</h6>
        </div>
      ) : (
        <div>
          {eventArray?.map((birthday: any, index: any) => (
            <EventCard
              key={index}
              title={birthday?.full_name}
              subtitle={`${birthday?.start_date_short} - ${birthday?.end_date_short}`}
              description={`${birthday?.start_date_short} - ${birthday?.end_date_short}`}
              color="border-l-primary500"
              img={birthday?.avatar_url}
            />
          ))}

          {/* <div className="flex justify-end">
            <p className="mt-1 text-xs  text-subText3">View All</p>
          </div> */}
        </div>
      )}
    </div>
  )
}
