/**
 * An optimized input.
 *
 * @param props
 * @returns input
 */
export const SelectOption: React.FC<
  React.OptionHTMLAttributes<HTMLOptionElement> & { filter?: boolean }
> = ({ filter, ...props }) => {
  return (
    <option
      {...props}
      className={` ${filter ? "font-extrabold" : "font-semibold"} ${props.selected ? "text-primary500 " : ""
        } ${props.className}`}
    />
  )
}
