import { Spin } from 'antd';

export const LoadingIndicator: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({className}) => {
  return (
    <Spin />

  )
}

type props = {
  children: JSX.Element | JSX.Element[]
  message: string
  loading: "true" | "false"
}

export const Loader: React.FC<props> = ({ loading, children, message }) => {
  return (
    <>
      {loading === "true" ? (
        <div className="h-full w-full p-3 flex justify-center items-center gap-2">
          <LoadingIndicator className="text-pryBase" />
          <span>{message}</span>
        </div>
      ) : (
        children
      )}
    </>
  )
}
