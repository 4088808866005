import ImageComponent from "components/custom/image"
import React from "react"

const SummaryTemplate = ({
  title,
  data,
  img,
  emptyText,
}: {
  title: string
  data: any[] | undefined
  img: string
  emptyText: string
}) => {
  return (
    <div className="border mt-8 font-avenir flex flex-col gap-4">
      <div className="bg-white rounded-5px shadow-card">
        <div className="  ">
          <h4 className="text-base font-extrabold text-neutral700 my-4 px-[22px]">
            {title}
          </h4>
          <div className="border border-neutral50 w-full mb-[14px]" />
        </div>
        <div className="flex flex-col gap-2 p-4 md:p-[22px] ">
          {data?.length ? (
            data.map((item) => (
              <div className=" min-h-[52px] max-h-[200px] overflow-y-scroll  border-2 rounded-5px py-[15px] px-[18px] gap-[10px] flex flex-col ">
                <div className=" flex  items-center">
                  <div className="flex items-center gap-1">
                    <ImageComponent
                      src={item?.review_entity_avatar_url}
                      alt="user"
                      className="rounded-full w-[25px] h-[25px]"
                    />
                    <h6 className="text-[#15294B]  text-sm font-black">
                      {item?.review_entity_full_name}
                    </h6>
                  </div>
                </div>
                <p className="text-[#243757] text-sm">{item?.description}</p>
              </div>
            ))
          ) : (
            <div className="text-neutral500 font-medium flex justify-center items-center w-full py-10 ">
              {emptyText}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SummaryTemplate
