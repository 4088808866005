import { useMutation } from "@tanstack/react-query"
import { Button } from "components"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"

const DeleteReviewee: React.FC<{
  onClose: Function
  invalidateQueries: Function
  deleteActive: any
}> = ({ onClose, invalidateQueries, deleteActive }) => {
  const { toast } = useAppNotificationStore()
  const { isLoading: isSubmittingRemove, mutate: submitRemove } = useMutation({
    mutationFn: async (empReviewId: string) => {
      const res = await axiosInstance.post(
        `/admin/performance/remove_reviewer?id=${empReviewId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        {
          data.msg && toast.success(data.msg)
        }

        invalidateQueries()
        onClose()
      } else {
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  return (
    <div className=" bg-black bg-opacity-60 h-screen fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-50">
      <div className="p-5  rounded-5px  shadow-card bg-white w-[407px] h-[171px] flex items-start gap-3">
        <div>
          <h6 className="text-[#15294B]  font-bold text-base mb-[10px]">
            Remove {deleteActive.name}
          </h6>
          <p className="text-[#15294B] text-sm mb-6">
            If you remove {deleteActive.name}, any responses saved for them will
            be deleted. This action can’t be undone. Click Confirm to continue.
          </p>
          <div className="flex justify-end items-center gap-4">
            <Button
              title="Cancel"
              color="secondary"
              onClick={() => {
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              title="Remove"
              color="danger"
              submitting={isSubmittingRemove}
              onClick={() => submitRemove(deleteActive.id)}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DeleteReviewee
