export const APP_ID = process.env.REACT_APP_ID!
export const APP_URL = process.env.REACT_APP_URL!
export const baseUrl = process.env.REACT_APP_BASE_URL
// export const baseUrl = process.env.REACT_APP_BASE_URL
export const hostName = process.env.REACT_APP_HOST_NAME
export const testHostName = process.env.REACT_APP_TEST_HOST_NAME
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const microsoftClientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID
export const microsoftClientRedirectUri =
  process.env.REACT_APP_MICROSOFT_REDIRECT_URL
export const customizationSettingsBaserUrl = process.env.REACT_APP_EWA_BASE_URL
export const appColor = process.env.REACT_APP_APP_COLOR
export const smartTextBaseUrl = `https://padefiles.blob.core.windows.net/images/smarttext`
export const csvBulkUploadEmployeeSheetLicenceKey = process.env
  .REACT_APP_CSVBOX_SHEET_LICENSE_KEY as string
export const csvBulkUploadChangeSheetLicenceKey = process.env
  .REACT_APP_CSVBOX_BULK_CHANGE_SHEET_LICENSE_KEY as string
