import Employee from "assets/images/employeesvg.svg"
import Employer from "assets/images/employersvg.svg"
import Subtract from "assets/images/Subtract.svg"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { NotificationBar, TextButton } from "components"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import NewAuthWrapper from "pages/new-auth/NewAuthWrapper"
import { isPadeDomain } from "utils/utils"

const Onboarding = () => {
  const navigate = useNavigate()
  const { customizationSettings } = useCustomizationSettingsStore()

  return (
    <>
      {isPadeDomain() === true ? (
        <NewAuthWrapper
          heading="A few clicks from creating your account"
          subHeading="Start in minutes, save time and money"
          showGetHelp
          showCompanies={false}
          showBackBtn
          progressData={{
            percent: 50,
            text: "2/4",
          }}
        >
          <div className="">
            <h2 className="font-circular font-bold text-3xl mb-6">
              How would you like to use PaidHR?
            </h2>
            <p className="font-avenir font-semibold text-base text-neutral400 mb-12">
              You can create your company account right away if you’re an
              employer or refer your employer if you’re an employee.
            </p>
            <div className="">
              <Content />
            </div>
          </div>
        </NewAuthWrapper>
      ) : (
        <div className="h-screen w-full">
          <NotificationBar />
          <div className="flex md:flex-row bg-primary50">
            <div className="hidden lg:block fixed top-0 bottom-0 left-0">
              <div
                className={`hidden md:block relative w-[417px] h-[100%] md:pt-[157px] md:pl-[35px] bg-primary500`}
              >
                <img
                  className="absolute h-[119px] bottom-[66px] right-0"
                  src={
                    "https://padefiles.blob.core.windows.net/images/74fdc7f1-f671-417b-ba63-e470f9eee392.png"
                  }
                  alt=""
                />
                <img
                  className="absolute w-[70px] bottom-[76px] right-0"
                  src={Subtract}
                  alt=""
                />
                <h1 className="text-a11y circularstdbold font-bold text-[32px] leading-[40.48px] mb-[38px] md:mr-[97px]">
                  A few clicks from creating your account
                </h1>
                <p className="text-a11y/90 avenirstdroman font-normal text-[18px]  leading-[25px]">
                  Start in minutes, save time and money
                </p>
              </div>
            </div>
            <div className="lg:ml-[417px] w-full lg:w-[calc(100%-417px)] grow min-h-screen h-full pt-[41px] px-4 md:pl-[83px] md:pr-[68.5px] pb-[30px]">
              <div className="flex justify-between gap-2">
                <TextButton
                  title="Back"
                  className="flex gap-2 text-neutral500 font-medium items-center"
                  onClick={() => navigate(-1)}
                >
                  <ArrowLeftIcon className="w-4 text-primary500" />
                  <span>Back</span>
                </TextButton>
                <TextButton
                  title="Get help"
                  className="flex gap-2 items-center"
                  onClick={() => navigate("/get-started")}
                >
                  <span>Having troubles?</span>
                  <span className="text-primary500 underline">Get help</span>
                  <ArrowRightIcon className="w-4 text-primary500" />
                </TextButton>
              </div>

              <h2 className=" mt-[50px] md:mt-[70px] circularstdbold font-bold text-[32px] leading-[40px] text-neutral700 mb-[16px]">
                How would you like to use{" "}
                {customizationSettings?.brand?.platformName}
              </h2>

              <h3 className="max-w-[533px] avenirstdbook text-[16px] leading-[21px] text-neutral700 mb-[48px]">
                You can create your company account right away if you're an
                employer or refer your employer if you're an employee.
              </h3>

              <Content />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Onboarding

const Content = () => {
  const { state } = useLocation()

  return (
    <>
      <Link
        className="md:w-[463px]"
        to="/onboarding/business-registration"
        state={{ email: state?.email }}
        onClick={() => {
          mixPanelEvent("auth-get-started-employer", String(state?.email) || "")
        }}
      >
        <div className="border hover:shadow-lg md:w-[463px] py-[17.5px] md:h-[87px] flex items-center bg-white px-[24px] gap-x-[18px] rounded-[8px]">
          <div className="shrink-0">
            <img
              className="shrink-0 w-[45px] h-[45px]"
              src={Employee}
              alt=""
            />
          </div>
          <div>
            <h4 className="circularstdbold font-bold md:text-[18px] md:leading-[22px] text-neutral700 mb-[16px]">
              I am an Employer
            </h4>
            <p className="avenirstdroman md:text-[15px] md:leading-[21px] text-[#42526D]">
              I want to create an account for my company
            </p>
          </div>
        </div>
      </Link>
      <Link
        className="md:w-[463px]"
        to="/onboarding/employee"
        state={{ email: state?.email }}
        onClick={() => {
          if (state?.email) {
            const response = mixPanelEvent(
              "auth-get-started-employee",
              String(state?.email) || ""
            )
          } else {
          }
        }}
      >
        <div className="mt-8 border hover:shadow-lg md:w-[463px] py-[17.5px] md:h-[87px] flex items-center bg-white px-[24px] gap-x-[18px] rounded-[8px]">
          <div className="shrink-0">
            <img
              className="w-[45px] h-[45px]"
              src={Employer}
              alt=""
            />
          </div>
          <div>
            <h4 className="circularstdbold font-bold md:text-[18px] md:leading-[22px] text-neutral700 mb-[16px]">
              I am an Employee
            </h4>
            <p className="avenirstdroman md:text-[15px] md:leading-[21px] text-[#42526D]">
              I want to refer my Employer
            </p>
          </div>
        </div>
      </Link>
    </>
  )
}
