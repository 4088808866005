import ImageComponent from "components/custom/image"

type props = {
  title?: string
  date?: string
  description?: string
  subtitle?: string
  name?: string
  color?: string
  img?: string
  alias?: string | null
  state?: string | null
  subtitleAction?: () => void | undefined
}
export const EventCard: React.FC<props> = ({
  title,
  date,
  description,
  subtitle,
  color,
  img,
  subtitleAction,
}) => {
  return (
    <div
      className={` h-auto mb-3  border-l-purple-500 border-l-4 p-2 lg:p-4 flex justify-between items-center border ${
        color ? color : "border-cardgrey"
      } rounded-5px `}
    >
      <div className="flex items-start gap-2">
        <ImageComponent
          src={
            img ||
            "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
          }
          alt="avatar"
          className="flex items-center justify-center w-6 h-6 rounded-full "
        />

        <div>
          <p className=" text-sm mb-[7px] text-neutral600 font-medium">
            {title}
          </p>
          {subtitle !== "" ? (
            <p
              className={`text-xs font-medium leading-4  text-primary500 ${
                subtitleAction && "cursor-pointer"
              }`}
              onClick={subtitleAction && subtitleAction}
            >
              {subtitle}
            </p>
          ) : (
            <p className="text-xs font-light leading-4 text-neutral400">
              {description}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-xs font-light  text-subText3">{date}</p>
      </div>
    </div>
  )
}
