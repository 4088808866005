import { useFormik } from "formik"

import Logo from "assets/logos/paidhr-logo.svg"
import { Link, useNavigate } from "react-router-dom"
import { Button, H1, Input, Label, P1, TextLink } from "components"
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { isPadeDomain } from "utils/utils"
import NewAuthWrapper from "pages/new-auth/NewAuthWrapper"

const GetStarted = () => {
  const { customizationSettings } = useCustomizationSettingsStore()

  return (
    <>
      {isPadeDomain() === true ? (
        <NewAuthWrapper
          heading="Simplify your HR with PaidHR"
          subHeading="Our HR software helps you save time and money by automating your HR processes."
          showGetHelp
          // showCompanies
          progressData={{
            percent: 25,
            text: "1/4",
          }}
        >
          <div className="">
            <h2 className="font-circular font-bold text-4xl mb-2">
              Get Started
            </h2>
            <p className="font-avenir font-semibold text-base text-neutral400 mb-10">
              Create your company account right away or refer your employer if
              you’re an employee.
            </p>
            <div className="">
              <GetStartedForm />
            </div>
          </div>
        </NewAuthWrapper>
      ) : (
        <div className="px-4 md:px-0 h-screen mx-auto min-h-screen bg-primary50 flex">
          <div
            className={`hidden lg:block relative w-[542px] flex-wrap h-[100%] bg-primary500`}
          >
            <div className="w-full h-[100%] flex flex-col pt-5 pb-[21.20%] px-[20px] ">
              <div className="flex flex-col items-start justify-center px-[30px] gap-[10px] py-5 text-a11y">
                <p className="font-avenir font-semibold leading-[150.02%] text-[32px] tracking-[0.015em]">
                  {customizationSettings?.pages?.getStarted?.title}
                </p>

                <p className="text-[16px] font-avenir font-[400px]  leading-[150%] tracking-[0.015em] text-a11y/90 bg-opacity-92">
                  {customizationSettings?.pages?.getStarted?.description}
                </p>
              </div>
              <div className="flex flex-col gap-[20px] px-[40px] w-full gap-[30px] mt-[30px]">
                <span className=" w-full ">
                  <img
                    src={customizationSettings?.pages?.getStarted?.imageUrl}
                    alt="landing-image"
                    className="  "
                  />
                </span>
                {isPadeDomain() ? (
                  <>
                    <p className="text-[16px] font-avenir font-[400px]  leading-[150%] tracking-[0.015em] text-a11y/90 bg-opacity-92">
                      Join hundreds of companies across Africa using PaidHR to
                      simplify and automate their diverse HR processes
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-auto flex flex-col md:gap-24 gap-16 md:py-10 py-5 md:pl-24 md:pr-16">
            <div>
              {customizationSettings?.brand?.platformName === "PaidHR" ? (
                <Link to="/">
                  <img
                    className="object-contain"
                    src={Logo}
                    alt="logo"
                    width={120}
                  />
                </Link>
              ) : null}{" "}
            </div>
            <div className="flex flex-col gap-12">
              <div className="space-y-[8px]">
                <H1 className="font-bold text-neutral700 text-4xl">
                  Get Started
                </H1>
                <P1 className="font-avenir font-semibold text-base text-neutral400 md:max-w-[532px]">
                  You can create your company account right away if you're an
                  employer or refer your employer if you're an employee.
                </P1>
              </div>
              <GetStartedForm />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default GetStarted

const GetStartedForm = () => {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    initialErrors: {},
    onSubmit: (values) => {
      mixPanelEvent("auth-get-started-init", String(values.email) || "")
      navigate("/onboarding", { state: { email: values.email } })
    },
  })
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col mt-10 md:flex-row gap-y-[24px] md:gap-x-[24px]"
    >
      <div className="flex flex-col gap-2">
        <Label
          htmlFor="email"
          className="font-avenir text-neutral400 font-semibold text-base"
        >
          Email Address
        </Label>
        <div className="flex flex-col gap-10">
          <Input
            name="email"
            required={true}
            type="email"
            className="md:w-[400px] lg:w-[450px] w-full bg-transparent border-neutral100"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <Button
            type="submit"
            title="Get started"
            className="h-full font-bold"
            color="primary"
          >
            <span>Get Started</span>
            {/* <ArrowRightIcon className="w-5" /> */}
          </Button>
        </div>
        <div className="mt-8 flex gap-2 items-center">
          <span className="text-neutral400 font-avenir font-semibold">
            Already have an account?
          </span>
          <TextLink
            className="text-primary500 underline font-avenir font-semibold"
            title="Login"
            to={"/auth/login"}
          >
            Log in
          </TextLink>
          <span>
            <ArrowRightIcon className="w-4 text-primary500" />
          </span>
        </div>
      </div>
    </form>
  )
}
