import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

interface IProps {
  title?: string | React.ReactNode
  description?: string
  actions?: React.ReactNode
  children: React.ReactNode
  loading?: boolean
  rootClassName?: string
  icon?: string
  id?: string
}
const CustomInfoCard = ({
  title = "",
  description = "",
  actions = null,
  children,
  loading = false,
  rootClassName = "",
  icon,
  id,
}: IProps) => {
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  )
  return (
    <div
      className={`bg-[#FFFFFF] border rounded-xl ${rootClassName}`}
      id={id}
    >
      <Spin
        spinning={loading}
        indicator={antIcon}
      >
        {title || description || actions ? (
          <>
            <div className="py-5 px-4 lg:px-6 flex gap-4 items-center justify-between flex-wrap border-b">
              <div>
                <div className="flex items-center">
                  {" "}
                  {icon && (
                    <img
                      src={icon}
                      alt="icon"
                      className="mr-1"
                    />
                  )}
                  <h2 className="text-padeBlack font-semibold font-circular text-base">
                    {title}
                  </h2>
                </div>

                <p className="text-neutral500 font-avenir text-sm max-w-[600px]">
                  {description}
                </p>
              </div>
              <div>{actions}</div>
            </div>
            {/* <hr className="border-b" /> */}
          </>
        ) : null}
        <div className="font-avenir">{children}</div>
      </Spin>
    </div>
  )
}

export default CustomInfoCard
