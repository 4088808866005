import { ArrowLeftIcon, UserPlusIcon } from "@heroicons/react/24/outline"

import ImageComponent from "components/custom/image"
import React, { useEffect, useState } from "react"

import { ProfessionalDvt } from "./component/ProfessionDevt"
import { EmployeeRating } from "./component/EmployeeRating"
import { NotableAchievement } from "./component/NotableAchievement"
import { Summary } from "./component/Summary"
import { AreaOfImprovement } from "./component/AreaOfImprovement"
import getIdFromKey from "utils/getIdFromKey"
import { useGet } from "utils/useFetch"
import {
  IEmployeeRevieweeData,
  IReviewEmployeeData,
} from "./interface/listings"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { Alert, Space, Spin, Tag, Tooltip } from "antd"
import { useQueryClient } from "@tanstack/react-query"
import { useLocation, useNavigate } from "react-router-dom"
import Feedback from "./component/Feedback"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import DeleteIcon from "../../../../../../assets/images/deleteIconRed.svg"
import AddReviewee from "./modals/AddReviewee"
import DeleteReviewee from "./modals/DeleteReviewee"
import InactiveReview from "./modals/InactiveReview"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const ViewEmployee: React.FC<{ isAdminToReview?: boolean }> = ({
  isAdminToReview = true,
}) => {
  const navigate = useNavigate()
  const myParam = useLocation().search
  const entity_key = new URLSearchParams(myParam).get("e")

  const person_key = new URLSearchParams(myParam).get("p")
  const person_id = getIdFromKey(person_key || "")
  const group_key = new URLSearchParams(myParam).get("g")

  const queryClient = useQueryClient()
  const [activeMenu, setActiveMenu] = useState("Employee Rating")
  const [updatedEntityKey, setUpdatedEntityKey] = useState(entity_key)
  const [updatedPersonId, setUpdatedPersonId] = useState(person_id)
  const [updatedQuestions, setUpdatedQuestions] = useState<any[]>([])
  const [reviewHasEnded, setReviewHasEnded] = useState(false)
  const [is_active, set_is_active] = useState(true)
  const [hasRelationship, setHasRelationship] = useState(false)
  const [showRevieweeModal, setShowRevieweeModal] = useState(false)
  const [deleteRevieweeModal, setDeleteRevieweeModal] = useState(false)
  const [personForKR, setPersonForKR] = useState(updatedPersonId)
  const [deleteActive, setDeleteActive] = useState({
    name: "",
    id: "",
  })
  const allMenu = [
    {
      title: "Employee Rating",
      id: "pf-review-empl-rating",
    },
    {
      title: "Professional development goals",
      id: "pf-review-development-goals",
    },
    {
      title: "Notable achievements",
      id: "pf-review-achievements",
    },
    {
      title: "Areas of Improvement",
      id: "pf-review-improvement",
    },
    {
      title: "Feedback",
      id: "pf-review-feedback",
    },
    {
      title: "Summary",
      id: "pf-review-summary",
    },
  ]

  const {
    isLoading: isEmployeeLoading,
    data: employeeData,
    isFetching: isFetchingEmpData,
    refetch,
  } = useGet<IReviewEmployeeData>({
    url: `/performance/view/review?id=employee&x=${updatedEntityKey}`,
    enabled: !!updatedEntityKey,
  })

  const {
    isLoading: isOtherReviewees,
    data: otherReviewees,
    refetch: refetchReviewees,
  } = useGet<IEmployeeRevieweeData>({
    url: `/admin/performance/selectemployeereviews?key=${group_key}&person_id=${updatedPersonId}&locationId=-1&department_id=-1`,
  })

  useEffect(() => {
    if (
      updatedEntityKey ==
      employeeData?.reviewGroup?.reviewEntity?.review_entity_key
    ) {
      setHasRelationship(true)
    } else {
      setHasRelationship(false)
    }
    if (
      (employeeData && employeeData?.reviewGroup?.is_closed == true) ||
      (employeeData?.reviewGroup &&
        employeeData?.reviewGroup?.review_group_state_id > 3) ||
      employeeData?.reviewGroup?.is_active == false
    ) {
      setReviewHasEnded(true)
    } else {
      setReviewHasEnded(false)
    }
    if (
      employeeData?.reviewGroup &&
      employeeData?.reviewGroup?.review_group_id > 0
    ) {
      set_is_active(true)
    } else {
      set_is_active(false)
    }
    mixPanelEvent("pf-review-launch-success")
    const questionsList =
      employeeData?.reviewGroup?.reviewEntity?.reviewQuestions
    const answerList = employeeData?.reviewGroup?.reviewEntity?.reviewResponses

    if (questionsList?.length) {
      if (answerList?.length) {
        const updatedList = questionsList.map((ques, index) => {
          const found = answerList.find(
            (ans) => ans.review_question_id === ques.review_question_id
          )
          if (found) {
            return {
              response: found,
              ...ques,
            }
          } else {
            return {
              ...ques,
            }
          }
        })

        setUpdatedQuestions(updatedList)
      } else {
        setUpdatedQuestions(questionsList)
      }
    }
  }, [employeeData])

  const invalidateQueries = () => {
    refetch()
    queryClient.invalidateQueries({
      queryKey: [`/performance/view/review?id=employee&x=${updatedEntityKey}`],
    })
  }
  const handleClick = () => {
    navigate(-1)
  }
  console.log(personForKR)
  console.log(otherReviewees)

  return (
    <>
      {/* <MobileWarner /> */}
      <DashboardWrapper>
        <div className="w-full relative">
          {isEmployeeLoading ? (
            <div className="h-[200px] flex justify-center items-center py-5">
              <LoadingIndicatorWhite />
            </div>
          ) : (
            <div className="relative w-full">
              {is_active ? (
                <>
                  <div className="  flex flex-col   gap-5 mb-4">
                    <div className="flex  items-center justify-between gap-6">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center    bg-cardgrey rounded-full justify-center h-[28px] sm:h-[44px] w-[28px] sm:w-[44px] ">
                          <ArrowLeftIcon
                            className=" h-4 w-4 "
                            onClick={() => handleClick()}
                          />
                        </div>
                        <div className="flex-grow">
                          <Alert
                            message={`${employeeData?.reviewGroup?.reviewEntity?.full_name}'s performance feedback`}
                            type="info"
                            className="  font-avenir text-neutral500 text-sm sm:text-base font-medium w-full flex justify-between items-center !py-1 "
                          />
                        </div>
                      </div>
                      {reviewHasEnded && (
                        <Space
                          size={[0, 2]}
                          wrap
                        >
                          <Tag
                            color="gold"
                            className="font-medium ml-1 py-2 text-sm"
                          >
                            This review has ended and you can no longer edit
                            your responses.
                          </Tag>
                        </Space>
                      )}
                    </div>
                    {isOtherReviewees ? (
                      <div className="flex justify-center items-center">
                        <Spin />
                      </div>
                    ) : (
                      <div className=" bg-white flex  w-full justify-between items-center  gap-8 p-2 rounded-5px h-[105px]">
                        <div
                          id="pf-review-reviewees"
                          className=" flex gap-2 w-full max-w-[1280px]   overflow-x-scroll scrollbar-hide h-full"
                        >
                          {otherReviewees?.data.map((item, index) => (
                            <button
                              key={index}
                              className={`border outline-none  hover:bg-white bg-[#FCFCFF]   border-cardgrey h-full min-w-[100px]  sm:min-w-[120px] w-auto p-2 rounded-5px flex flex-col justify-between items-center ${
                                item?.review_entity_key ==
                                  employeeData?.reviewGroup?.reviewEntity
                                    ?.review_entity_key &&
                                " border-2 border-primary500 shadow-card"
                              }`}
                            >
                              <div className="flex items-center w-full relative">
                                <div
                                  className="flex-grow "
                                  onClick={() => {
                                    setUpdatedEntityKey(item?.review_entity_key)
                                    setPersonForKR(
                                      getIdFromKey(
                                        item?.review_entity_person_key
                                      )
                                    )
                                  }}
                                >
                                  <ImageComponent
                                    src={
                                      item?.review_entity_avatar_url ??
                                      "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
                                    }
                                    alt="user"
                                    className="h-[25px] w-[25px] border rounded-full "
                                  />
                                </div>
                                {isAdminToReview && (
                                  <div className="absolute right-0">
                                    <Tooltip
                                      title={`Remove ${item?.review_entity_first_name}  ${item?.review_entity_last_name}`}
                                    >
                                      <img
                                        src={DeleteIcon}
                                        alt="delete"
                                        onClick={() => {
                                          setDeleteRevieweeModal(true)
                                          setDeleteActive({
                                            name: `${item?.review_entity_first_name}  ${item?.review_entity_last_name}`,
                                            id: item?.employee_review_id,
                                          })
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                )}
                              </div>

                              <h6
                                className="text-neutral500 text-[13px] break-words"
                                onClick={() => {
                                  setUpdatedEntityKey(item?.review_entity_key)
                                  setPersonForKR(
                                    getIdFromKey(item?.review_entity_person_key)
                                  )
                                }}
                              >
                                {item?.review_entity_first_name} <br />
                                {item?.review_entity_last_name}
                              </h6>

                              {/* <p className="text-bluesubtext text-[10px]">
                                {item?.review_entity_employee_title || "-"}
                              </p> */}
                            </button>
                          ))}
                        </div>
                        {isAdminToReview && (
                          <div
                            id="pf-add-reviewees"
                            className="border-2 outline-none bg-white  border-cardgrey h-full p-2  rounded-5px flex flex-col w-[82px] justify-between items-center cursor-pointer"
                            onClick={() => setShowRevieweeModal(true)}
                          >
                            <UserPlusIcon className=" text-primary500 w-5 h-5" />

                            <p className=" text-bluesubtext text-xs text-center font-bold leading-tight ">
                              Add new Reviewee
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <div className="">
                      <div className="flex w-full items-center  max-w-max sm:max-w-full  whitespace-nowrap overflow-scroll-touch  overflow-auto scrollbar-hide">
                        {hasRelationship &&
                          allMenu.map((menu, index) => (
                            <button
                              id={menu.id}
                              key={index}
                              className={`flex-grow outline-none text-neutral700 py-2 text-sm ${
                                activeMenu == menu.title
                                  ? "border-b-2 border-primary500 font-extrabold"
                                  : "border-neutral50 border-b"
                              }  px-4 `}
                              onClick={() => setActiveMenu(menu.title)}
                            >
                              {menu.title}
                            </button>
                          ))}
                      </div>

                      {activeMenu == "Employee Rating" && (
                        <EmployeeRating
                          hasRelationship={hasRelationship}
                          reviewHasEnded={reviewHasEnded}
                          questions={updatedQuestions || []}
                          ReviewEntityKey={
                            employeeData?.reviewGroup?.reviewEntity
                              .review_entity_key || ""
                          }
                          employeeReviewId={
                            employeeData?.reviewGroup?.employeeReviewId || ""
                          }
                          isLoadingEmpData={isEmployeeLoading}
                          ratings={
                            employeeData?.reviewGroup?.reviewEntity.ratings ||
                            []
                          }
                          invalidateQueries={invalidateQueries}
                          // personForKR={}
                          personForKR={personForKR}
                          periodID={String(
                            employeeData?.reviewGroup?.period_id
                          )}
                        />
                      )}
                      {activeMenu == "Professional development goals" && (
                        <ProfessionalDvt
                          invalidateQueries={invalidateQueries}
                          employeeReviewId={
                            employeeData?.reviewGroup?.reviewEntity
                              ?.review_entity_id || ""
                          }
                          feedback={
                            employeeData?.reviewGroup?.reviewEntity?.reviewEntityFeedbacks.filter(
                              (feedback) =>
                                feedback.review_entity_feedback_type_id == 1
                            ) || []
                          }
                        />
                      )}
                      {activeMenu == "Notable achievements" && (
                        <NotableAchievement
                          invalidateQueries={invalidateQueries}
                          employeeReviewId={
                            employeeData?.reviewGroup?.reviewEntity
                              ?.review_entity_id || ""
                          }
                          feedback={
                            employeeData?.reviewGroup?.reviewEntity?.reviewEntityFeedbacks.filter(
                              (feedback) =>
                                feedback.review_entity_feedback_type_id == 2
                            ) || []
                          }
                        />
                      )}
                      {activeMenu == "Areas of Improvement" && (
                        <AreaOfImprovement
                          invalidateQueries={invalidateQueries}
                          employeeReviewId={
                            employeeData?.reviewGroup?.reviewEntity
                              ?.review_entity_id || ""
                          }
                          feedback={
                            employeeData?.reviewGroup?.reviewEntity?.reviewEntityFeedbacks.filter(
                              (feedback) =>
                                feedback.review_entity_feedback_type_id == 3
                            ) || []
                          }
                        />
                      )}
                      {activeMenu == "Feedback" && (
                        <Feedback
                          updatedEntityKey={updatedEntityKey}
                          questions={updatedQuestions || []}
                          ratings={
                            employeeData?.reviewGroup?.reviewEntity.ratings ||
                            []
                          }
                          invalidateQueries={invalidateQueries}
                          employeeReviewId={
                            employeeData?.reviewGroup?.reviewEntity
                              ?.review_entity_id || ""
                          }
                          feedback={
                            employeeData?.reviewGroup?.reviewEntity
                              ?.reviewEntityFeedbacks || []
                          }
                        />
                      )}
                      {activeMenu == "Summary" && (
                        <Summary
                          showEditSummary={isAdminToReview}
                          updatedEntityKey={updatedEntityKey}
                          questions={updatedQuestions || []}
                          personId={updatedPersonId}
                          ratings={
                            employeeData?.reviewGroup?.reviewEntity.ratings ||
                            []
                          }
                          invalidateQueries={invalidateQueries}
                          employeeReviewId={
                            employeeData?.reviewGroup?.reviewEntity
                              ?.review_entity_id || ""
                          }
                          feedback={
                            employeeData?.reviewGroup?.reviewEntity
                              ?.reviewEntityFeedbacks || []
                          }
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <InactiveReview onClose={handleClick} />
              )}

              {deleteRevieweeModal && (
                <DeleteReviewee
                  onClose={() => {
                    setDeleteRevieweeModal(false)
                    setDeleteActive({
                      name: "",
                      id: "",
                    })
                  }}
                  invalidateQueries={refetchReviewees}
                  deleteActive={deleteActive}
                />
              )}
              {showRevieweeModal && (
                <AddReviewee
                  onClose={() => setShowRevieweeModal(false)}
                  invalidateQueries={refetchReviewees}
                  personId={person_id || ""}
                  groupId={getIdFromKey(group_key || "")}
                  existingReviewees={otherReviewees}
                />
              )}
            </div>
          )}
        </div>
      </DashboardWrapper>
    </>
  )
}
export default ViewEmployee
