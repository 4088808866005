import { Button } from "components"
import React, { useState } from "react"
import { Select, Form, Alert, InputNumber, Spin } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useMutation, useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import DeleteIcon from "assets/images/delete_icon.svg"
import { KycLevel } from "pages/employee-dashboard/wallet/wallet/kyc/interface"
import { IBeneficiaries } from "pages/employee-dashboard/wallet/wallet/beneficiaries/interface"
import { useGetCountries } from "components/new-stuff/library/common-utils"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { availableFlags, getCurrencyDataByCode } from "utils/flags"
import { DivideByRate } from "utils/rate"
import { LoadingOutlined } from "@ant-design/icons"
// import EnterPin from "../pin/EnterPin"

interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<any[]>>
  currentLevel: null | KycLevel
  amountOverBalance: boolean
  fee: string

  setFee: React.Dispatch<React.SetStateAction<string>>
  submitFee: (body: any) => void

  showPinModal: boolean
  setShowPinModal: React.Dispatch<React.SetStateAction<boolean>>
  handleProceed: () => void
  found: any | undefined
  currencyCode: string
  availableBalance: number | undefined
}

const MultipleSend = ({
  availableBalance,
  setPreviewData,
  currentLevel,
  amountOverBalance,
  fee,
  setFee,
  submitFee,

  showPinModal,
  setShowPinModal,
  handleProceed,
  found,
  currencyCode,
}: Props) => {
  const { countries, isLoading: isLoadingCountries } = useGetCountries()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const description = Form.useWatch("description", form)
  const USDElement = availableFlags?.find((item) => item?.code === "USD")
  const USDSymbol = USDElement?.symbol
  const [activeForm, setActiveForm] = useState(0)
  const [formBodyMultiple, setFormBodyMultiple] = useState<any[]>([
    {
      accountNumber: "",
      amount: "",
      currency: "",
      paymentMethodType: "",
      bankCode: "",
      beneficiaryCountryISO: "",
      beneficiaryType: "",
      beneficiaryFirstName: "",
      beneficiaryLastName: "",
      beneficiaryCompanyName: "",
      purposeId: "",
    },
  ])

  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [`beneficiaries`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })

  // Purpose TYPES
  const { isLoading: isLoadingBenTypes, data: purposeRes } = useQuery<any>({
    queryKey: [`transfer-purposes`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/transfer-purposes`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })

  // Handler to add a new form body
  const handleAddRecipient = () => {
    setFormBodyMultiple([
      ...formBodyMultiple,
      {
        accountNumber: "",
        amount: "",
        currency: "",
        paymentMethodType: "",
        bankCode: "",
        beneficiaryCountryISO: "",
        beneficiaryType: "",
        beneficiaryFirstName: "",
        beneficiaryLastName: "",
        beneficiaryCompanyName: "",
        purposeId: "",
      },
    ])
  }

  // Handler to delete a form body
  const handleDeleteRecipient = (index: number) => {
    const updatedFormBodies = formBodyMultiple.filter((_, i) => i !== index)
    setFormBodyMultiple(updatedFormBodies)
  }
  const {
    isLoading: isLoadingRate,
    data: rateData,
    mutate: getRate,
  } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/conversion-rate`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )

      return res.data
    },

    onSuccess: (data: any) => {
      setFormBodyMultiple((prev) => {
        return prev.map((item, i) => {
          if (i === activeForm) {
            return {
              ...item,
              rate: data?.data.rate,
            }
          }
          return item
        })
      })
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })
  const rateValue = rateData?.data?.rate

  const onFinish = (values: any) => {
    const formData = form.getFieldsValue()
    const body: any[] = formBodyMultiple.map((data) => ({
      accountNumber: data?.accountNumber,
      amount: data?.amount,
      amountconverted: data?.amountconverted,
      currency: data?.currency,
      // currency: currencyCode,
      description,
      paymentMethodType: data?.paymentMethodType,
      bankCode: data?.bankCode,
      beneficiaryCountryISO: data?.beneficiaryCountryISO,
      beneficiaryType: data?.beneficiaryType,
      beneficiaryFirstName: data?.beneficiaryFirstName,
      beneficiaryLastName: data?.beneficiaryLastName,
      beneficiaryCompanyName: data?.beneficiaryCompanyName,
      purposeId: String(purposeRes?.data[0]?.id) ?? "",
      addToBeneficiary: false,
      symbol: data?.symbol,
    }))

    setPreviewData(body)

    handleProceed()
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ scheduleConfirmation: false }}
      >
        <>
          <div className="pb-4">
            <div className=" mt-5 flex flex-col items-start w-full ">
              <div className="w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
                <CustomInput
                  label="Payment description"
                  name="description"
                />{" "}
              </div>
              {formBodyMultiple.map((item, index) => (
                <div
                  key={index}
                  className={` w-full ${
                    index !== formBodyMultiple?.length - 1
                      ? "mb-6 pb-6 border-b border-[#C2C7D0]"
                      : ""
                  } px-4 md:px-6 lg:px-8 xl:px-[40px]`}
                >
                  <div className="flex items-center mb-4">
                    <p className="font-bold text-[15px] font-circular  text-padeBlack  ">
                      Recipient {index + 1}
                    </p>
                    {formBodyMultiple.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleDeleteRecipient(index)}
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          className="ml-6 "
                        />
                      </button>
                    )}
                  </div>
                  <div className="w-full flex flex-col lg:grid grid-cols-2 gap-x-6">
                    <div className="">
                      <Form.Item
                        label={
                          <span className="text-padeBlack text-sm font-circular  ">
                            Select beneficiary to send
                          </span>
                        }
                        name={`beneficiary~${index}`}
                        rules={[
                          {
                            required: true,
                            message: "Please enter this field!",
                          },
                        ]}
                        extra={
                          index === activeForm && isLoadingRate ? (
                            <Spin
                              spinning={true}
                              indicator={
                                <LoadingOutlined
                                  style={{ color: "blue", fontSize: 16 }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            item?.rate && (
                              <span className="text-padeBlack  font-medium">
                                {USDSymbol}1 = {item?.symbol}
                                {formatCurrencyNoSymbol(
                                  Number(item?.rate || 0),
                                  {
                                    minimumFractionDigits: 7,
                                    maximumFractionDigits: 7,
                                  }
                                )}
                              </span>
                            )
                          )
                        }
                      >
                        <Select
                          showSearch
                          loading={isLoadingBeneficiaries}
                          onChange={(value) => {
                            const selected = beneficiariesData?.data?.find(
                              (item) => item.accountName === value
                            )
                            const body: any = {
                              sourceCurrency: currencyCode,
                              destinationCurrency: selected?.currency,
                            }
                            setActiveForm(index)
                            selected?.currency !== "USD" && getRate(body)
                            form.setFieldValue(`amount~${index}`, "")
                            setFormBodyMultiple((prev) => {
                              return prev.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    amount: "",
                                    amountconverted: "",
                                    symbol: getCurrencyDataByCode(
                                      selected?.currency || ""
                                    )?.symbol,
                                    accountNumber: String(
                                      selected?.accountNumber
                                    ),
                                    currency: selected?.currency,
                                    paymentMethodType:
                                      selected?.paymentMethod ?? "",
                                    bankCode: selected?.bankCode,
                                    beneficiaryCountryISO:
                                      selected?.beneficiaryCountryIso ?? "",
                                    beneficiaryType:
                                      selected?.beneficiaryType ?? "",
                                    beneficiaryFirstName:
                                      selected?.beneficiaryFirstName ?? "",
                                    beneficiaryLastName:
                                      selected?.beneficiaryLastName ?? "",
                                    beneficiaryCompanyName:
                                      selected?.beneficiaryCompanyName ?? "",
                                  }
                                }
                                return item
                              })
                            })
                          }}
                          options={beneficiariesData?.data
                            ?.filter((ben) => ben.currency !== "NGN")
                            ?.filter((item) => item.type === "bank")
                            ?.map((person: any) => ({
                              label: (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>{person?.accountName}</span>
                                  <div>
                                    <img
                                      src={
                                        availableFlags?.find(
                                          (item) =>
                                            item.code === person.currency
                                        )?.flag || ""
                                      }
                                      alt={`${person?.currency} flag`}
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                        marginRight: "8px",
                                        marginLeft: "8px",
                                      }}
                                    />
                                    <span>{person.currency}</span>
                                  </div>
                                </span>
                              ),
                              value: person?.accountName,
                            }))}
                          placeholder="Select beneficiary"
                        />
                      </Form.Item>
                    </div>

                    {/* AMOUNT */}

                    <div className="w-full">
                      <Form.Item
                        label={
                          <span className="text-padeBlack text-sm font-circular  ">
                            {" "}
                            Enter amount
                          </span>
                        }
                        name={`amount~${index}`}
                        required
                        extra={
                          availableBalance && (
                            <span>
                              Balance:{" "}
                              <span className="font-medium  text-padeBlack ">
                                {found?.symbol}
                                {formatCurrencyNoSymbol(availableBalance)}
                              </span>
                            </span>
                          )
                        }
                        rules={[
                          // {
                          //   validator: (_, value) => {
                          //     const limit =
                          //       currentLevel && currentLevel.level > 2
                          //         ? Number.MAX_SAFE_INTEGER
                          //         : 200000
                          //     if (!value || value <= limit) {
                          //       return Promise.resolve()
                          //     }
                          //     return Promise.reject(
                          //       new Error(
                          //         `You can only send a maximum of ${formatCurrencyNoSymbol(
                          //           limit
                          //         )} to other banks, kindly upgrade your account `
                          //       )
                          //     )
                          //   },
                          // },
                          {
                            validator: (_, value) => {
                              const minimumAmount = 5
                              if (!value || value >= minimumAmount) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                new Error(
                                  `Amount must be at least ${found?.symbol} ${minimumAmount}`
                                )
                              )
                            },
                          },
                        ]}
                      >
                        <InputNumber<number>
                          disabled={
                            item?.currency !== "USD" && !item?.rate
                              ? true
                              : false
                          }
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) =>
                            value?.replace(/,/g, "") as unknown as number
                          }
                          className="w-full font-circular text-neutral400"
                          addonBefore={item?.symbol}
                          placeholder="e.g 200"
                          onChange={(e) => {
                            setActiveForm(index)
                            setFormBodyMultiple((prev) => {
                              return prev.map((item, i) => {
                                if (i === index) {
                                  if (e) {
                                    return {
                                      ...item,
                                      amount: e,
                                      amountconverted:
                                        item?.currency !== "USD"
                                          ? DivideByRate(e, item?.rate)
                                          : e,
                                    }
                                  }
                                }
                                return item
                              })
                            })
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {item?.amountconverted && (
                    <div className=" col-span-2">
                      <Alert
                        type="warning"
                        showIcon
                        icon={
                          <InformationCircleIcon className="h-4 w-4 lg:h-6 lg:w-6 !text-primary500" />
                        }
                        message={`${USDSymbol}
${item?.amountconverted}

                     will be debited from your  USD Balance`}
                        className="bg-[#CCDBFD] font-circular text-padeBlack text-sm w-full items-center mb-6 border-[#CCDBFD] !rounded-none"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-4 w-full px-4 md:px-6 lg:px-8 xl:px-[40px]">
              {formBodyMultiple.length < 5 && (
                <button
                  type="button"
                  onClick={handleAddRecipient}
                  className="text-primary500  font-circular text-sm underline"
                >
                  +Add Another Recipient
                </button>
              )}
            </div>
          </div>
          <div className=" flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/overview">
              <Button
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              onClick={() => form.submit()}
            >
              Proceed
            </Button>
          </div>
        </>
      </Form>
    </>
  )
}

export default MultipleSend
// setArchiveRestoreData((prev) => ({
//   ...prev,
//   id: payroll_id,
//   type: "archive",
// }))
