import React, { useState, useEffect } from "react"
import {
  Button,
  DashboardContainer,
  H7,
  P2,
  TextAnchor,
} from "../../../../components"
import { useNavigate } from "react-router-dom"
import { useIntegrationStore } from "stores/setting/integrations"
import { IFilterOptions } from "stores/interface"
import { debounce } from "lodash"
import { IntegrationStatus } from "./interface/kyc"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { VerifyPasswordPrompt } from "pages/2fa/modals/VerifyPasswordPrompt"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { Spin, Tooltip } from "antd"
import AntToggle from "components/inputs/AntToggle"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

interface Password {
  password: string
}
const Integration: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [editSetting, toggleEditSetting] = useState(false)
  const [directDeposit, toggleDirectDeposit] = useState(false)

  const [integration, setIntegration] = useState<any>()
  const [activeStatus, setActiveStatus] = useState<any>()
  const [toggle, setToggle] = useState(false)

  const { getIntegrationStatus, isLoading } = useIntegrationStore()

  const getPermissionFunction = (params: IFilterOptions) => {
    getIntegrationStatus(params).then((res) => {
      setIntegration(res)
      if (res === 1) {
        setToggle(true)
      } else {
        setToggle(false)
      }
      console.log(res)

      const status = IntegrationStatus.find((stat) => stat.id == res)
      if (status) {
        setActiveStatus(status)
      }
    })
  }
  useEffect(() => {
    debounce(function () {
      getPermissionFunction({})
    }, 500)()
  }, [])
  const { isLoading: isLoadingConfirmPassword, mutate: submit } = useMutation({
    mutationFn: async (body: Password) => {
      const res = await axiosInstance.post(`/admin/settings/signin`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        toggleEditSetting(false)
        toggleDirectDeposit(true)
      }
    },
  })
  const { isLoading: isLoadingWallet, mutate: submitWallet } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/editdirectdepositstatus`,
        body
      )

      if (res.data.status === "success") {
      }
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        getPermissionFunction({})
      }
    },
  })
  return (
    <DashboardContainer>
      <div className="flex flex-col gap-6 w-[100%] lg:w-[60%]">
        <H7 className="text-neutral500 font-bold mb-2">Integrations</H7>
        <Spin spinning={isLoading}>
          <div className="w-full  border border-neutral30  rounded bg-white flex flex-col">
            <div className="h-full w-full  px-4 md:px-8 py-4 md:py-9 flex flex-col gap-11">
              <div className="w-full flex gap-2">
                <div className="w-full flex flex-col gap-2">
                  <P2 className="font-bold text-neutral500 my-2">
                    Payment Configurations
                  </P2>
                  <div className="w-full flex lg:flex-row sm:flex-col gap-3 items-center justify-between">
                    <P2 className="w-full text-neutral100">Direct Deposit</P2>
                    <div className="w-full   flex gap-4 items-center">
                      <P2 className=" text-neutral900">
                        {activeStatus?.buttonText}
                      </P2>
                      <div className="ml-2 flex justify-end items-center">
                        <AntToggle
                          name="integration"
                          label=""
                          loading={isLoadingWallet}
                          // loading={isCompanyDetailsLoading }
                          checked={toggle}
                          onChange={(e: any) => {
                            setToggle(!toggle)
                            submitWallet({
                              enableDirectDeposit: toggle ? "no" : "yes",
                            })
                          }}
                        />
                        <Tooltip
                          title={
                            toggle
                              ? "Toggle this to disable your wallet."
                              : "Toggle this to enable your wallet."
                          }
                        >
                          <InformationCircleIcon className="ml-0.5 w-4 h-4" />
                        </Tooltip>
                      </div>
                    </div>
                    {integration !== 4 &&
                      integration !== 5 &&
                      integration !== 2 && (
                        <Button
                          title="Edit"
                          color="neutral"
                          onClick={() => toggleEditSetting(true)}
                        >
                          {integration === 6 ? "Set Up" : "Edit"}
                        </Button>
                      )}
                  </div>
                </div>
              </div>
              {/* <div className="w-full flex flex-col gap-2">
              <P2 className="font-bold text-neutral500">API Configuration</P2>
              <div className="w-full flex gap-7 items-center">
                <P2 className="w-full text-neutral100">Secret key</P2>
                <P2 className="w-full text-neutral900">**************</P2>
              </div>
            </div> */}
            </div>
          </div>
        </Spin>

        {editSetting === true && (
          <VerifyPasswordPrompt
            onClose={(status: boolean) => {
              toggleEditSetting(false)
              if (status) {
                toggleDirectDeposit(true)
              }
            }}
          />
        )}

        <NewModalWrapper
          width={672}
          open={directDeposit}
          onClose={() => toggleDirectDeposit(false)}
          title="Direct deposit"
          closable
        >
          <div className="p-4 h-full w-full flex flex-col gap-6 justify-center items -center">
            <div className="p-2.5 bg-primary50 w-full flex items-start gap-2 rounded-5px">
              <P2 className="text-neutral200">
                Direct Deposit may attract a transfer charge per deposit
                depending on the transfer amount.{" "}
                <TextAnchor
                  title="Learn more"
                  className="cursor-pointer underline"
                >
                  Learn more about transfer charges in our Help Center.
                </TextAnchor>
                <br />
                Important Note: Only the Primary Admin and Secondary Admins like
                you can modify Direct Deposit settings.
              </P2>
            </div>

            {activeStatus?.id === 3 && (
              <h6 className="font-circular text-neutral400 font-medium text-sm">
                Your kyc documents are with us and we are in the process of
                creating your PaidHR Wallet. We'll contact you for more
                information or clarification if required. Please{" "}
                <TextAnchor
                  title="Learn more"
                  className="cursor-pointer underline"
                >
                  contact us
                </TextAnchor>{" "}
                for more information.
              </h6>
            )}

            <div className="justify-end items-center gap-4 flex">
              <Button
                color="secondary"
                onClick={() => {
                  toggleDirectDeposit(false)
                  navigate("company-verification")
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  toggleDirectDeposit(false)
                  navigate("company-verification")
                }}
              >
                Update Kyc
              </Button>
            </div>
          </div>
        </NewModalWrapper>
      </div>
    </DashboardContainer>
  )
}

export default Integration
