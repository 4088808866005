/**
 * An optimized H3 component.
 *
 * @param props
 * @returns h3
 */
export const H3: React.FC<JSX.IntrinsicElements["h3"]> = (props) => {
  return (
    <h3
      {...props}
      className={`text-[26px] leading-[30px] font-circular ${props.className}`}
    >
      {props.children}
    </h3>
  )
}
