import React from "react"
import { Button } from "./Button"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"

export const AppBackButton = () => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => navigate(-1)}
      className="cursor-pointer justify-self-start"
    >
      <ArrowLeftIcon
        width={20}
        height={20}
      />
    </div>
  )
}
