import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { Form, Upload, message } from "antd"
import { useState, useEffect } from "react"
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface"
import type { UploadChangeParam } from "antd/es/upload"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
import { useQueryClient } from "@tanstack/react-query"
import { useGet } from "utils/useFetch"
import { ICompany } from "./typings"
const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const beforeUpload = (file: RcFile) => {
  const isPng = file.type === "image/png"
  if (!isPng) {
    message.error("You can only upload PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isPng && isLt2M
}
const ImageUpload = () => {
  const [loading, setLoading] = useState(false)
  const [companyImg, setCompanyImg] = useState("")
  const [forceUpdate, setForceUpdate] = useState(false)

  const queryClient = useQueryClient()

  const {
    data: company,
    isLoading,
    refetch,
    isFetching,
  } = useGet<ICompany>({
    url: "admin/company/view/details",
    cacheTime: 24 * 60 * 60 * 1000,
    onSuccess: (data: any) => {
      setCompanyImg("")
      setCompanyImg(data?.avatarUrl)
    },
  })

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true)
      return
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setCompanyImg(url)
        setForceUpdate(!forceUpdate)
      })
    }
  }

  const customRequest = async (options: any) => {
    const { file, onSuccess } = options

    const formData = new FormData()
    formData.append("image_upload", file)

    try {
      await axiosInstance.post("/admin/company/upload_image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      message.success("Company logo uploaded successfully!")
      onSuccess("OK", new XMLHttpRequest())
      // setCompanyImg("")
      // queryClient.invalidateQueries({
      //   queryKey: ["admin/company/view/details"],
      // })
      // window.location.reload()
    } catch (error) {
      message.error("Failed to upload image.")
      console.error(error)
    }
  }
  const uploadButton = (
    <div className=" flex flex-col items-center">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  return (
    <>
      {isFetching ? (
        <></>
      ) : (
        <Upload
          key={forceUpdate ? "forceUpdate" : "normal"}
          name="avatar"
          listType="picture-card"
          customRequest={customRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          showUploadList={false}
        >
          {companyImg ? (
            <img
              src={companyImg}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      )}
    </>
  )
}

export default ImageUpload
