import Subtract from "assets/images/Subtract.svg"
import { useNavigate, useSearchParams } from "react-router-dom"
import { NotificationBar } from "components"
import { isPadeDomain } from "utils/utils"
import NewAuthWrapper from "pages/new-auth/NewAuthWrapper"
import { Spin } from "antd"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid"
import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAppNotificationStore } from "stores"

const OnboardingEmailVerification = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const [searchParams] = useSearchParams()

  const token = searchParams.get("token")
  const email = searchParams.get("email")

  if (!token || !email) {
    navigate("/auth/login")
  }

  const { data, isLoading, error } = useQuery({
    queryKey: ["user", { email }],
    queryFn: async () => {
      const baseurl =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_EWA_BASE_URL
          : process.env.REACT_APP_EWA_BASE_URL + "/"
      const res = await axios.post(`${baseurl}v1/accounts/verify-otp`, {
        token: encodeURIComponent(token as string),
        email,
      })
      return res.data
    },
    enabled: !!token,
    retry: 1,
    onSettled(data, error: any) {
      if (error) {
        if (error?.response?.data?.message === "Verification code expired") {
          toast.error("The email verification link has expired.")
        }
        if (error?.response?.data?.message === "Email already verified") {
          toast.error("Email already verified.")
        }
      }
    },
  })

  const {
    isLoading: isResendingEmail,
    mutate: resendEmail,
    data: resendEmailData,
  } = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_EWA_BASE_URL}/v1/accounts/resend-otp`,
        {
          email,
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.success === true) {
        toast.success("Email sent successfully. Please check your inbox.")
      } else {
        toast.error(data.message)
      }
    },
  })

  const isVerified = data?.success === true
  // @ts-ignore
  const errorMessage = error?.response?.data?.message
    ? // @ts-ignore
      error.response.data.message
    : "The email verification link has expired or is invalid."

  return (
    <>
      <NotificationBar />
      {isPadeDomain() === true ? (
        <NewAuthWrapper
          heading={"Email Verification"}
          subHeading={
            isVerified
              ? data?.data
              : isLoading
              ? "Please wait while we verify your email."
              : errorMessage
          }
          showGetHelp
          showCompanies={false}
          progressData={{
            percent: isVerified ? 100 : 75,
            text: isVerified ? "4/4" : "3/4",
          }}
          showBackBtn={false}
        >
          {isLoading ? (
            <div className="grid place-content-center grow min-h-full">
              <Spin size="large" />
            </div>
          ) : isVerified ? (
            <div className=" flex flex-col items-center justify-center min-h-full">
              <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />
              <h2 className="font-avenir font-bold text-neutral700 text-2xl mt-4 mb-4 text-center">
                Your email has been verified successfully.
              </h2>
              <p className="font-avenir font-medium text-base text-neutral400 mb-4 text-center">
                Please keep an eye on your inbox for important emails regarding
                your onboarding process.
              </p>
              <p className="font-avenir text-base text-neutral400 text-center font-semibold">
                Get ready to experience people automation like never before.
              </p>
            </div>
          ) : resendEmailData && resendEmailData.success ? (
            <div className="w-full  mt-3 flex justify-center">
              <div className=" flex flex-col justify-center items-center">
                <ExclamationCircleIcon className="text-[#38CB89] w-20 mb-4 " />

                <div className="w-full flex flex-col gap-4 items-center">
                  <h4 className=" text-neutral500 text-xl font-bold mb-4">
                    Verification Email Sent
                  </h4>
                  <p className="text-[#5D6B82] text-base text-center mb-2">
                    We have sent a verification email to {email}. Please check
                    your inbox and click on the link to verify your email.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full  mt-3 flex justify-center">
              <div className=" flex flex-col justify-center items-center">
                <ExclamationCircleIcon className="text-[#FF0000] w-20 mb-4 " />

                <div className="w-full flex flex-col gap-4 items-center">
                  <h4 className=" text-neutral500 text-xl font-bold mb-4">
                    Email Verification Failed
                  </h4>
                  <p className="text-[#5D6B82] text-base text-center mb-2">
                    {errorMessage}
                  </p>

                  {errorMessage === "Verification code expired" &&
                  !resendEmailData ? (
                    <button
                      className={`h-full w-full font-bold mt-10 text-primary500 ${
                        isResendingEmail ? "cursor-not-allowed opacity-50" : ""
                      }`}
                      onClick={() => {
                        if (email) resendEmail()
                      }}
                      disabled={isResendingEmail}
                    >
                      Resend Verification Email
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </NewAuthWrapper>
      ) : (
        <div className="h-screen w-full">
          <div className="flex md:flex-row bg-primary50">
            <div className="hidden lg:block fixed top-0 bottom-0 left-0">
              <div
                className={`relative w-[417px] h-full md:pt-[157px] md:px-12 bg-primary500`}
              >
                <img
                  className="absolute h-[119px] bottom-[66px] right-0"
                  src={
                    "https://padefiles.blob.core.windows.net/images/74fdc7f1-f671-417b-ba63-e470f9eee392.png"
                  }
                  alt=""
                />
                <img
                  className="absolute w-[70px] bottom-[76px] right-0"
                  src={Subtract}
                  alt=""
                />
                <h1 className="text-a11y circularstdbold font-bold text-[32px] leading-[40.48px] mb-[38px] md:mr-4">
                  Email Verification
                </h1>
                <p className="text-a11y/90 avenirstdroman font-normal text-[18px] leading-[25px]">
                  {isVerified
                    ? "Your email has been verified successfully."
                    : isLoading
                    ? "Please wait while we verify your email."
                    : errorMessage}
                </p>
              </div>
            </div>
            <div className="lg:ml-[417px] lg:w-[calc(100%-417px)] min-h-screen h-full grow pt-[41px] px-4 md:pl-[83px] md:pr-[68.5px] pb-[30px]">
              <div>
                {isLoading ? (
                  <div className="grid place-content-center grow min-h-full pt-10">
                    <Spin size="large" />
                  </div>
                ) : isVerified ? (
                  <div className="flex flex-col items-center justify-center min-h-full mt-10 max-w-lg mx-auto">
                    <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />
                    <h2 className="font-avenir font-bold text-neutral700 text-2xl mt-4 mb-4 text-center">
                      Your email has been verified successfully.
                    </h2>
                    <p className="font-avenir font-medium text-base text-neutral400 mb-4 text-center">
                      Please keep an eye on your inbox for important emails
                      regarding your onboarding process.
                    </p>
                    <p className="font-avenir text-base text-neutral400 text-center font-semibold">
                      Get ready to experience people automation like never
                      before.
                    </p>
                  </div>
                ) : (
                  <div className="w-full  mt-3 flex justify-center">
                    <div className=" flex flex-col justify-center items-center">
                      <ExclamationCircleIcon className="text-[#FF0000] w-20 mb-4 " />

                      <div className="w-full flex flex-col gap-4 items-center">
                        <h4 className=" text-neutral500 text-xl font-bold mb-4">
                          Email Verification Failed
                        </h4>
                        <p className="text-[#5D6B82] text-base text-center mb-2">
                          {errorMessage}
                        </p>

                        <button
                          className={`h-full w-full font-bold mt-10 text-primary500 ${
                            isResendingEmail
                              ? "cursor-not-allowed opacity-50"
                              : ""
                          }`}
                          onClick={() => {
                            if (email) resendEmail()
                          }}
                          disabled={isResendingEmail}
                        >
                          Resend Verification Email
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OnboardingEmailVerification
