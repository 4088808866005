import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { APP_ID } from "../utils/constants"
import { request } from "../utils/request"

interface AccountState {}

type createAccountType = {
  first_name: string
  last_name: string
  company_name: string
  email: string
  phone: string
  country_id: string
  registration_code?: string
  password?: string
  employees: string
  is_employee: string
}

type saveReferralType = {
  first_name: string
  last_name: string
  company_name: string
  email: string
  phone: string
  referrer_first_name: string
  referrer_last_name: string
  referrer_email: string
  referrer_phone: string
  country_id: string
}

interface AccountMethods {
  createAccount: (body: createAccountType) => Promise<any>
  getRegisterCode: (code: string) => Promise<any>
  saveReferral: (body: saveReferralType) => Promise<any>
}

export const useAccountStore = create<AccountState & AccountMethods>()(
  devtools(
    persist(
      (set, get) => ({
        createAccount: async (body) => {
          return request.post({ url: "/account/create", body: body })
        },
        getRegisterCode: async (code) => {
          return request.get({ url: `/account/view/register?code=${code}` })
        },
        saveReferral: async (body) => {
          return request.post({ url: "/account/save_referral", body: body })
        },
      }),
      {
        name: `${APP_ID}.accounts`,
      }
    )
  )
)
