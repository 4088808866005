import { Form, Input, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import {
  CustomInput,
  CustomSelect,
} from "pages/dashboard/documents/custom-form"
import FormSectionWrapper from "pages/dashboard/extras/settings/FormSectionWrapper"
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/24/outline"
import { Button, DashboardContainer, Label } from "components"
import PrevNextButton from "pages/dashboard/extras/settings/form/PrevNextButton"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"

import { useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useGet, usePost } from "utils/useFetch"

import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { AddTemplateToSurvey } from "./AddTemplateToSurvey"
import AntDesignSelect from "components/antd/AntDesignSelect"
import { ICustomSurveyData } from "../../Templates/interface/template"
import { IEmployeesListing } from "stores/payroll/Changepayroll/CreateEmployees/getEmployees"

const CreateSurvey: React.FC<{}> = ({}) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search

  const [selectedEmployeeType, setSelectedEmployeeType] = useState("")
  const [selectedTemplateId, setSelectedTemplateId] = useState("1")
  const [selectedTypeId, setSelectedTypeId] = useState("1")
  const [activeKey, setActiveKey] = useState<string[]>(["1"])
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([])
  const reqBody = {
    q: "",
    page: "",
  }

  const { isLoading: isTemplateListingLoading, data: templateListing } =
    useGet<ICustomSurveyData>({
      url: `admin/surveys/selectcustomtemplates?typeId=${selectedTypeId}`,
      enabled: !!selectedTypeId,
    })

  const onFinish = (values: Values) => {
    const { title, description } = values

    submit({
      description: title,
      survey_id: "-1",
      survey_type_id: selectedTypeId,
      survey_template_id: selectedTemplateId,
      default_template: "no",
    })
  }

  // const { isLoading: isLoadingCheck, mutate: check } = useMutation({
  //   mutationFn: async (body: string) => {
  //     const res = await axiosInstance.get(
  //       `/admin/requests/checkexitsurveytemplate?description=${body}&exit_survey_template_id=`
  //     )
  //     return res.data
  //   },
  //   onSuccess: (data) => {
  //     if (data) {
  //     } else {
  //       setErrorMessage("Template name is already taken")
  //     }
  //   },
  // })
  // ADD SURVEY

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: ITemplateBody) => {
      const res = await axiosInstance.post(`/admin/surveys/add`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey/edit?x=${data?.data}`)

        {
          data.msg && toast.success(data.msg)
        }
      }
    },
  })
  // FINISH SURVEY
  const { isLoading: isLoadingEdit, mutate: submitEdit } = useMutation({
    mutationFn: async (body: ITemplateBody) => {
      const res = await axiosInstance.post(`/admin/surveys/finish`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey`)

        {
          data.msg && toast.success(data.msg)
        }
      }
    },
  })
  // GET EMPLOYEES
  const { isLoading: employeeListLoading, data: employeeList } = useGet<
    IEmployeesListing[]
  >({
    url: `/admin/employees/get?active=true`,
  })

  const surveyTypes = [
    { survey_title: "General", survey_id: "1" },
    { survey_title: "Confirmation", survey_id: "2" },
    { survey_title: "Exit", survey_id: "3" },
  ]

  const employeedocOwners = [
    {
      title: "All employees",
      id: "1",
    },
    {
      title: "Specific employees",
      id: "2",
    },
  ]
  const handleChangeEmployee = (values: any) => {
    const personIds = values.map((per: string) => {
      let person = per.split("-")
      let personId = person[1]
      let personName = person[0]
      return personId
    })

    setSelectedEmployees(personIds)
  }
  return (
    <DashboardContainer>
      <div className="max-w-[1024px]   rounded-md space-y-[20px]">
        <FormSectionWrapper
          name="Create Survey"
          title="Create Survey"
          subtitle="Once it has a title, you can continue setting it up "
          isActive={activeKey.includes("1")}
          layout="vertical"
          initialValues={{
            type: "General",
          }}
          form={form}
          onFinish={onFinish}
          style={{ maxWidth: 1024 }}
          autoComplete="off"
          footerBtns={
            <div className="flex gap-3 justify-end">
              <Button
                color="primary"
                title="Submit"
                submitting={isLoading}
              >
                Submit
              </Button>
            </div>
          }
          className="font-avenir"
        >
          <div className="grid grid-cols-2 gap-x-6  px-4 md:px-6 xl:px-[60px] pb-4">
            <div>
              <CustomInput
                label="Survey title"
                name="title"
                required
                className="w-full"
                // onBlur={(e) => {
                //   check(e.target.value)
                // }}
              />
              {errorMessage !== "" && errorMessage}
            </div>
            <AntDesignSelect
              name="type"
              label="Survey type"
              placeholder="Please select"
              isLoading={isTemplateListingLoading}
              required={true}
              onChange={(selected) => {
                const selectedOption = surveyTypes?.find(
                  (option) => option.survey_title === selected
                )
                setSelectedTypeId(selectedOption?.survey_id || "")
              }}
              options={
                isTemplateListingLoading
                  ? []
                  : surveyTypes &&
                    surveyTypes?.map((temp) => ({
                      label: temp.survey_title,
                      value: temp.survey_title,
                    }))
              }
            />
            <AntDesignSelect
              label="Templates"
              placeholder="Please select"
              isLoading={isTemplateListingLoading}
              required={true}
              onChange={(selected) => {
                const selectedOption = templateListing?.data?.find(
                  (option) => option.description === selected
                )
                setSelectedTemplateId(selectedOption?.survey_template_id || "")
              }}
              options={
                isTemplateListingLoading
                  ? []
                  : templateListing &&
                    templateListing?.data?.map((temp) => ({
                      label: temp.description,
                      value: temp.description,
                    }))
              }
            />

            {selectedTypeId === "1" && (
              <AntDesignSelect
                label="Employees"
                // isLoading={employeeListLoading}
                onChange={(value) => {
                  let owner = value.split("*~^-")
                  owner = owner[1]
                  setSelectedEmployeeType(owner)
                }}
                options={employeedocOwners?.map((emp) => ({
                  label: emp.title,
                  value: `${emp.title}*~^-${emp.id}`,
                }))}
                placeholder="Select Employee"
              />
            )}

            {selectedEmployeeType === "2" && (
              <AntDesignSelect
                label="Select Employees"
                mode="multiple"
                loading={employeeListLoading}
                onChange={handleChangeEmployee}
                options={
                  employeeListLoading
                    ? []
                    : employeeList?.map((emp) => ({
                        label: emp.text,
                        value: `${emp.text}*~^-${emp.id}`,
                      }))
                }
                placeholder="Select Employee"
              />
            )}
          </div>
        </FormSectionWrapper>
      </div>
    </DashboardContainer>
  )
}
export default CreateSurvey
interface Values {
  title: string
  description: string
}
interface question {
  title: string
  alias: string
  questions: eachQuestion[]
}
interface eachQuestion {
  question_title: string
  question: string
  type_id: string
  type: string
  value_id: string
  value: string
}
interface ITemplateBody {
  survey_id: string
  survey_type_id: string
  description: string
  survey_template_id: string
  default_template: string
}
