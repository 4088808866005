import { Modal } from "antd"
import React from "react"
import { IRole } from "../interface/interface"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { Button } from "components"
import usePermissions from "hooks/usePermissions"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"

type Props = {
  role: IRole
  onClose: Function
}

export const DeleteCustomRoleModal = ({ role, onClose }: Props) => {
  const { toast } = useAppNotificationStore()

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.delete(
        `/admin/roles/delete?id=${role?.role_id}&comment=deleted`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status == "success") {
        data?.msg && setTimeout(() => toast.success(data?.msg), 100)
        onClose(true)
      } else {
        data?.msg && setTimeout(() => toast.error(data?.msg), 100)
      }
    },
  })

  const [canRemove] = usePermissions({
    allowedPermissions: ["can_remove_custom roles"],
  })

  return (
    <CustomModal
      onCancel={() => onClose()}
      title={`Delete ${role?.description}`.replace(/(role)/gi, " ") + " role"}
      open
      footer={null}
    >
      <div className="text-[14px] text-[#5D6B82]">
        Deleting this role will remove its permissions from any employees
        currently assigned to it, and the role won't be available for new
        assignments.
      </div>

      <div className="flex w-full justify-end pt-4 gap-3 mt-8">
        <Button
          color="secondary"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          color="destructive"
          type="submit"
          submitting={isLoading}
          onClick={(e) => {
            e.preventDefault()
            if (canRemove) {
              mutate()
            } else {
              toast.error(
                "You don't have the required permissions to perform this operation."
              )
            }
          }}
        >
          Confirm
        </Button>
      </div>
    </CustomModal>
  )
}
