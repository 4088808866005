import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React from "react"
import successImg from "assets/illustrations/Success.svg"
interface Props {
  title: string
  subtitle: string | React.ReactNode
  onClose: () => void
  open: boolean
  ctaButton?: React.ReactNode
}
const SuccessModal = ({ title, subtitle, onClose, open, ctaButton }: Props) => {
  return (
    <CustomModal
      title={<span className="invisible">Success</span>}
      onCancel={onClose}
      open={open}
    >
      <div className="flex  flex-col items-center ">
        <img
          src={successImg}
          alt=""
        />
        <p className="text-xl text-padeBlack font-bold my-6 font-circular">
          {title}{" "}
        </p>
        <p className=" text-sm text-[#42526D] font-avenir text-center">
          {subtitle}
        </p>
        {ctaButton}
      </div>
    </CustomModal>
  )
}

export default SuccessModal
