/**
 * An optimized P1 component.
 */
export const P3: React.FC<JSX.IntrinsicElements["p"]> = (props) => {
  return (
    <p
      {...props}
      className={`text-xs ${props.className}`}
    >
      {props.children}
    </p>
  )
}
