import { message } from "antd"
import Dragger from "antd/es/upload/Dragger"
import React, { Dispatch, FC } from "react"
import { H6 } from "./typography/H6"
import { P3 } from "./typography/P3"
import { RcFile } from "antd/es/upload"
import { InboxOutlined } from "@ant-design/icons"
import { Button } from "./buttons/Button"
import { DocumentCheckIcon } from "@heroicons/react/24/solid"

type Props = {
  onUpdate: Dispatch<File>
  accept?: "pdf" | "xlsx" | "image"
  label?: string
  description?: string
  multiple?: boolean
  maxCount?: number
  defaultFileList?: any[]
}

const AppFileUpload: FC<Props> = ({
  onUpdate,
  accept = "xlsx",
  label = " Only XLSX with max size of 2MB",
  description = "Drop your files here",
  multiple = false,
  maxCount = 1,
  defaultFileList,
}) => {
  const beforeUpload = (file: RcFile) => {
    if (accept === "xlsx") {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

      if (!isXlsx) {
        message.error("You can only upload xlsx file!")
      }

      const isLt2M = file.size / 1024 / 1024 < 2 // 2MB
      if (!isLt2M) {
        message.error("File must smaller than 2MB!")
      }
      return isXlsx && isLt2M
    }

    if (accept === "pdf") {
      const isPdf = /application\/pdf/.test(file.type)
      if (!isPdf) {
        message.error("You can only upload pdf file!")
      }

      const isLt2M = file.size / 1024 / 1024 < 2 // 2MB
      if (!isLt2M) {
        message.error("File must smaller than 2MB!")
      }
      return isPdf && isLt2M
    }
    if (accept === "image") {
      const image = /image/.test(file.type)
      if (!image) {
        message.error("You can only upload image files!")
      }

      const isLt2M = file.size / 1024 / 1024 < 2 // 2MB
      if (!isLt2M) {
        message.error("File must smaller than 2MB!")
      }
      return image && isLt2M
    }
  }

  const handleChange = (info: any) => {
    if (info.file.status === "done") {
      onUpdate(info.file.originFileObj)
    }
  }

  const customRequest = async (options: any) => {
    setTimeout(() => {
      options.onSuccess("ok")
    }, 0)
  }

  return (
    <div className="w-full">
      <Dragger
        name="upload-document"
        className=" font-avenir"
        beforeUpload={beforeUpload}
        onChange={handleChange}
        // iconRender={(file) => (
        //   <svg
        //     width="48"
        //     height="48"
        //     viewBox="0 0 48 48"
        //     fill="none"
        //     xmlns="http://www.w3.org/2000/svg"
        //     className="w-5 h-5"
        //   >
        //     <path
        //       fill-rule="evenodd"
        //       clip-rule="evenodd"
        //       d="M6 0C4.89543 0 4 0.895431 4 2V46C4 47.1046 4.89543 48 6 48H42C43.1046 48 44 47.1046 44 46V8L36 0H6Z"
        //       fill="#E4E7EC"
        //     />
        //     <path
        //       fill-rule="evenodd"
        //       clip-rule="evenodd"
        //       d="M36 0V8H44L36 0Z"
        //       fill="#98A2B3"
        //     />
        //     <rect
        //       x="2"
        //       y="20"
        //       width="44"
        //       height="20"
        //       rx="2"
        //       fill="#CC400C"
        //     />
        //     <path
        //       d="M9.32812 35.818V24.1816H13.6918C14.5857 24.1816 15.3357 24.3483 15.9418 24.6816C16.5516 25.015 17.0118 25.4733 17.3224 26.0566C17.6368 26.6362 17.794 27.2953 17.794 28.0339C17.794 28.7801 17.6368 29.443 17.3224 30.0226C17.008 30.6021 16.544 31.0585 15.9304 31.3919C15.3168 31.7214 14.5611 31.8862 13.6634 31.8862H10.7713V30.1532H13.3793C13.902 30.1532 14.33 30.0623 14.6634 29.8805C14.9967 29.6987 15.2429 29.4487 15.402 29.1305C15.5649 28.8123 15.6463 28.4468 15.6463 28.0339C15.6463 27.621 15.5649 27.2574 15.402 26.943C15.2429 26.6286 14.9948 26.3843 14.6577 26.21C14.3243 26.032 13.8944 25.943 13.3679 25.943H11.4361V35.818H9.32812Z"
        //       fill="white"
        //     />
        //     <path
        //       d="M23.5682 35.818H19.625V24.1816H23.6477C24.803 24.1816 25.7955 24.4146 26.625 24.8805C27.4583 25.3426 28.0985 26.0074 28.5455 26.8748C28.9924 27.7422 29.2159 28.7801 29.2159 29.9885C29.2159 31.2006 28.9905 32.2422 28.5398 33.1135C28.0928 33.9847 27.447 34.6532 26.6023 35.1191C25.7614 35.585 24.75 35.818 23.5682 35.818ZM21.733 33.9941H23.4659C24.2765 33.9941 24.9527 33.8464 25.4943 33.551C26.036 33.2517 26.4432 32.8066 26.7159 32.2157C26.9886 31.621 27.125 30.8786 27.125 29.9885C27.125 29.0983 26.9886 28.3597 26.7159 27.7725C26.4432 27.1816 26.0398 26.7404 25.5057 26.4487C24.9754 26.1532 24.3163 26.0055 23.5284 26.0055H21.733V33.9941Z"
        //       fill="white"
        //     />
        //     <path
        //       d="M31.2188 35.818V24.1816H38.6733V25.9487H33.3267V29.1078H38.1619V30.8748H33.3267V35.818H31.2188Z"
        //       fill="white"
        //     />
        //   </svg>
        // )}
        defaultFileList={defaultFileList?.map((x, index) => ({
          ...x,
          name: `Document ${index + 1}`,
        }))}
        accept={
          accept === "pdf"
            ? "application/pdf"
            : accept === "image"
            ? "image/*"
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
        customRequest={customRequest}
        multiple={multiple}
        maxCount={maxCount}
        onDrop={(e) => {
          onUpdate(e.dataTransfer.files[0])
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <H6 className="text-dark10 font-bold  text-sm leading-[21px]">
          {description}
        </H6>

        <P3 className="text-sm pb-5 text-dark30 text-[12px] leading-[18px]">
          {label}
        </P3>
        <Button
          color="primary"
          title="Choose file"
          className=" mt-4  "
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          Choose file
        </Button>
      </Dragger>
    </div>
  )
}

export default AppFileUpload
