import { Form, Input, Spin } from "antd"
import React, { useState } from "react"
import {
  CustomInput,
  CustomSelect,
} from "pages/dashboard/documents/custom-form"
import FormSectionWrapper from "pages/dashboard/extras/settings/FormSectionWrapper"
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/24/outline"
import { Button, DashboardContainer, Label } from "components"
import PrevNextButton from "pages/dashboard/extras/settings/form/PrevNextButton"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"
import { AddTemplateQuestion } from "./components/AddTemplateQuestion"
import { useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useGet } from "utils/useFetch"
import {
  ISingleQuestionTemplate,
  ISingleSurveyTemplateData,
} from "./interface/template"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"

const SurveyTemplate: React.FC<{}> = ({}) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")

  const [createdTemplateId, setCreatedTemplateId] = useState("")
  const [activeKey, setActiveKey] = useState<string[]>(["1"])
  const [allQuestions, setAllQuestions] = useState<question>({
    title: "",
    alias: "",
    questions: [],
  })

  const {
    isLoading: isEmployeeLoading,
    data: templateData,
    isFetching: isFetchingEmpData,
  } = useGet<ISingleSurveyTemplateData>({
    url: `/admin/surveys/view/index?id=template&x=${x}`,
    enabled: !!x,
  })
  const [showNew, setShowNew] = useState(
    templateData?.template.questions &&
      templateData?.template.questions.length > 0
      ? false
      : true
  )
  const [errorMessage, setErrorMessage] = useState("")

  const onFinish = (values: Values) => {
    const { title, description } = values
    if (x) {
      submitEdit({
        survey_template_id: x ? getIdFromKey(x || "") : "-1",
        survey_template_type_id: "3",
        description: title,
        alias: description,
      })
    } else {
      submit({
        survey_template_id: x ? getIdFromKey(x || "") : "-1",
        survey_template_type_id: "3",
        description: title,
        alias: description,
      })
    }
  }

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`/admin/surveys/view/index?id=template&x=${x}`],
    })
  }

  const { isLoading: isLoadingCheck, mutate: check } = useMutation({
    mutationFn: async (body: string) => {
      const res = await axiosInstance.get(
        `/admin/surveys/checktemplate?description=${body}&survey_template_id=-1&survey_template_type_id=3`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
      } else {
        setErrorMessage("Template name is already taken")
      }
    },
  })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: ITemplateBody) => {
      const res = await axiosInstance.post(`/admin/surveys/add_template`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey/template?x=${data?.data}`)
        setActiveKey(["2"])
        setCreatedTemplateId(getIdFromKey(data.data))
        {
          data.msg && toast.success(data.msg)
        }
      }
    },
  })
  const { isLoading: isLoadingEdit, mutate: submitEdit } = useMutation({
    mutationFn: async (body: ITemplateBody) => {
      const res = await axiosInstance.post(
        `/admin/surveys/finish_template`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        setActiveKey(["2"])
        setCreatedTemplateId(getIdFromKey(data.data))
        // toast.success("Template has been created successfully")
      }
    },
  })

  return (
    <DashboardContainer>
      {x && isEmployeeLoading ? (
        <div className="h-[200px] items-center flex justify-center">
          <LoadingIndicatorWhite />
        </div>
      ) : (
        <div className="max-w-[1024px]   rounded-md space-y-[20px]">
          <div className="flex  gap-1 items-center">
            <h6 className=" uppercase text-neutral700 text-base  font-semibold ml-1">
              {templateData?.template.description}
            </h6>
          </div>
          <FormSectionWrapper
            name=" Survey template"
            title=" Survey template"
            subtitle="Customize template of your review "
            isActive={activeKey.includes("1")}
            initialValues={{
              title: templateData?.template.description || "",
              description: templateData?.template.alias || "",
            }}
            layout="vertical"
            form={form}
            onFinish={onFinish}
            style={{ maxWidth: 1024 }}
            autoComplete="off"
            footerBtns={
              <div className="w-full items-center justify-end">
                {isLoading || isLoadingEdit ? (
                  <Button
                    color="secondary"
                    title="Loading"
                  >
                    <Spin />
                  </Button>
                ) : (
                  <PrevNextButton showPrevButton={false} />
                )}
              </div>
            }
            className="font-avenir"
          >
            <div className="flex flex-col  px-4 md:px-6 xl:px-[60px]">
              <CustomInput
                label="Template name"
                name="title"
                required
                className="w-full"
                onBlur={(e) => {
                  check(e.target.value)
                }}
              />
              {errorMessage !== "" && errorMessage}
              <CustomInput
                label="Description"
                name="description"
                className="w-full"
              />
            </div>
          </FormSectionWrapper>

          <AddTemplateQuestion
            isLoading={isFetchingEmpData}
            activeKey={activeKey.includes("2") ? true : false}
            setActiveKey={setActiveKey}
            showNew={showNew}
            setShowNew={setShowNew}
            questions={templateData?.template.questions || []}
            invalidateQueries={invalidateQueries}
            x={x}
          />
        </div>
      )}
    </DashboardContainer>
  )
}
export default SurveyTemplate
interface Values {
  title: string
  description: string
}
interface question {
  title: string
  alias: string
  questions: eachQuestion[]
}
interface eachQuestion {
  question_title: string
  question: string
  type_id: string
  type: string
  value_id: string
  value: string
}
interface ITemplateBody {
  survey_template_id: string
  survey_template_type_id: string
  description: string
  alias: string
}
