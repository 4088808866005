import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import AntDCustomTable from "components/antd/AntDCustomTable"
import { formatCurrencyNoSymbol } from "utils/currency"
import { Doc } from "../interface/wallet"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { Table } from "antd"

const TransactionsTemplate: React.FC<{
  data: Doc[] | any[]
  isLoading?: boolean
}> = ({ data, isLoading }) => {
  const [search, setSearch] = useState("")
  const TableData =
    data?.map((transaction: Doc) => {
      return {
        key: transaction?._id,
        date: {
          date: formatDateReadable(String(transaction?.createdAt)),
          time: formatTimeReadble(String(transaction?.createdAt)),
        },
        type: transaction.channel,
        amount: `₦ ${formatCurrencyNoSymbol(
          Number(transaction?.amountToProcess)
        )}`,
        charge: `₦ ${formatCurrencyNoSymbol(Number(transaction?.fee))}`,
        reference: transaction.reference,
        // balance: `₦ ${formatCurrencyNoSymbol(
        //   Number(transaction.currentBalance)
        // )}`,
        status: {
          id: transaction.status,
          title: transaction.status,
        },
      }
    }) || []
  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },

      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {amount}{" "}
          </span>
        ),
      },
      {
        title: "Trn.Charge",
        dataIndex: "charge",
        render: (charge: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {charge}{" "}
          </span>
        ),
      },

      // {
      //   title: "Reference",
      //   dataIndex: "reference",
      //   render: (reference: string) => (
      //     <span
      //       className={`font-avenir flex flex-wrap text-sm text-[#42526D] `}
      //     >
      //       {reference}
      //     </span>
      //   ),
      // },
      // {
      //   title: "Type",
      //   dataIndex: "type",
      //   render: (type: string) => (
      //     <span
      //       className={`font-avenir flex flex-wrap text-sm text-[#42526D]  lowercase `}
      //     >
      //       {type}
      //     </span>
      //   ),
      // },
      // {
      //   title: "Current Balance",
      //   dataIndex: "balance",
      //   render: (balance: string) => (
      //     <span
      //       className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
      //     >
      //       {balance}{" "}
      //     </span>
      //   ),
      // },
      {
        title: "Status",
        dataIndex: "status",
        render: (status: { id: string; title: any; method: string }) => (
          <span
            className={`text-sm font-medium py-1 block text-center w-[80px] lowercase rounded-[4px]
                    ${
                      status?.title == "APPROVED"
                        ? "bg-success100 text-success800"
                        : ""
                    }
                    ${
                      status?.title == "PAID"
                        ? "bg-success100 text-success800"
                        : ""
                    }

                    ${
                      status?.title == "PROCESSING"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${
                      status?.title == "PENDING"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${status?.title == "DECLINED" ? "bg-neutral40 " : ""}
                    ${status?.title == "FAILED" ? "bg-neutral40 " : ""}
                    ${status?.title == "CUTOFF" ? "bg-neutral40 " : ""}

                    `}
          >
            {status?.title}
          </span>
        ),
      },
    ] || []
  return (
    <div className="bg-white ">
      <Table
        columns={TableColumns}
        dataSource={TableData}
        loading={isLoading}
        className="text-[#42526D]"
        scroll={{ x: `100%` }}

        // pagination={{
        //   current,
        //   onChange: handlePaginationChange,
        //   total: data?.total,
        //   defaultPageSize: 20,
        // }}
      />
    </div>
  )
}
export default TransactionsTemplate
