import { DocumentArrowDownIcon } from "@heroicons/react/24/outline"
import { AppBackButton } from "components/buttons/AppBackButton"
import usePermissions from "hooks/usePermissions"
import React, { forwardRef } from "react"
import ReactToPrint from "react-to-print"
import { useAppNotificationStore } from "stores"
import { exportTableToCSV } from "../utils/report-utils"
import { downloadPDF } from "utils/downloadPdf"

type Props = {
  title: string
  showCsvDownload?: boolean
  showPdfDownload?: boolean
}

export const ReportDownloadHeading = forwardRef(
  (
    { title, showCsvDownload = true, showPdfDownload = true }: Props,
    ref: any
  ) => {
    const { toast } = useAppNotificationStore()

    const [canDownload] = usePermissions({
      allowedPermissions: ["can_download_reports"],
    })

    return (
      <div className="w-full flex items-center justify-between gap-3 py-[30px]">
        <AppBackButton />
        <div className="flex gap-3 items-center">
          {ref && showPdfDownload && (
            <ReactToPrint
              content={() => {
                if (!canDownload) {
                  toast.error(
                    "You don't have the required permissions to download this report."
                  )
                  return null
                }
                return ref.current
              }}
              trigger={() => (
                <button className="px-4 py-2 font-circular !text-sm text-white rounded-md bg-red-400 flex items-center">
                  <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
                  <span>PDF</span>
                </button>
              )}
              documentTitle={`${title}.pdf`}
            />
          )}
          {/* {ref && (
            <button
              className="px-4 py-2 font-circular !text-sm text-white rounded-md bg-red-400 flex items-center"
              onClick={() => {
                downloadPDF(title, ref)
              }}
            >
              <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
              <span>PDF</span>
            </button>
          )} */}

          {showCsvDownload && (
            <button
              className="px-4 py-2 font-circular !text-sm text-white rounded-md bg-primary400 flex items-center"
              onClick={() => {
                if (!canDownload) {
                  toast.error(
                    "You don't have the required permissions to download this report."
                  )
                } else exportTableToCSV(title + ".csv")
              }}
            >
              <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
              <span>CSV</span>
            </button>
          )}
        </div>
      </div>
    )
  }
)
