/**
 * An optimized radio input.
 */
export const RadioInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = (props) => {
  return (
    <div>
      <input
        {...props}
        type={"radio"}
        className={`rounded bg-white border-neutral50 h-6 w-6 checked:border-primary500 checked:text-white checked:bg-primary500 focus:outline-0 focus:outline-primary500 hover:opacity-95 ${
          props.className
        } ${props.disabled && "cursor-not-allowed"}`}
      />
    </div>
  )
}
