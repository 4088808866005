import {
  EllipsisHorizontalIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Dropdown, Input, Select, Table } from "antd"
import {
  Button,
  DashboardContainer,
  H6,
  ModalWrapper,
  TopActionBar,
} from "components"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
import { useGet } from "utils/useFetch"

const Surveys = () => {
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const [search, setSearch] = useState("")
  const [typeId, setTypeId] = useState("3")
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeleteId, setActiveDeleteId] = useState("")

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`admin/surveys/select?typeId=${typeId}`],
    })
  }

  const { isLoading: isLoadingSurvey, data: surveyData } = useGet<any>({
    url: `admin/surveys/select?typeId=${typeId}`,
  })
  const { isLoading: isSubmittingDelete, mutate: submitDelete } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/surveys/remove?id=${activeDeleteId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        setOpenDeleteConfirmation(false)
        invalidateQueries()
        data.msg && toast.success(data.msg)
      } else {
        setOpenDeleteConfirmation(false)
        data.msg && toast.success(data.msg)
      }
    },
    onError: (data: any) => {
      setOpenDeleteConfirmation(false)
      data.msg && toast.success(data.msg)
    },
  })
  const TableData = useMemo(
    () =>
      surveyData?.data?.map((survey: ISurvey) => ({
        key: survey.survey_id,
        description: survey.description,
        status: {
          id: survey.survey_status_id,
          text: survey.survey_status,
        },
        action: {
          id: survey.survey_id,
          key: survey.survey_key,
        },
      })) || [],
    [surveyData]
  )

  const TableColumns = useMemo(
    () =>
      [
        {
          title: "Description",
          dataIndex: "description",
          render: (description: string) => (
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]
               `}
            >
              {description}
            </span>
          ),
        },

        {
          title: "Status",
          dataIndex: "status",
          render: (status: { text: string; id: string }) => (
            <span
              className={`text-sm font-medium py-1 block text-center w-[80px] rounded-[4px]
                    ${status.id === "2" ? "bg-success100 text-success800" : ""}
                    ${status.id === "1" ? "bg-neutral40 text-neutral500" : ""}
                    ${status.id === "3" ? "bg-[#FEE3B1] text-[#E69702]" : ""}

                    `}
            >
              {status.text}
            </span>
          ),
        },

        {
          title: "",
          dataIndex: "action",
          key: "action",
          render: (data: { key: string; id: number }) => (
            <Dropdown
              menu={{
                items: actionItems(data),
              }}
            >
              <button>
                <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
              </button>
            </Dropdown>
          ),
        },
      ] || [],
    []
  )
  //  three dots on each row
  const actionItems = (data: { key: string; id: number }) => [
    {
      key: "2",
      label: <Link to={`/dashboard/survey/edit?x=${data.key}`}>Finish</Link>,
    },
    {
      key: "3",
      label: (
        <span
          onClick={() => {
            handleOpenDeleteModal(String(data.id))
          }}
        >
          Delete
        </span>
      ),
    },
  ]
  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteConfirmation(true)
    setActiveDeleteId(String(id))
  }
  const handleDelete = () => {
    submitDelete()
  }
  const surveyTypes = [
    { survey_title: "General", survey_id: "1" },
    { survey_title: "Confirmation", survey_id: "2" },
    { survey_title: "Exit", survey_id: "3" },
  ]
  return (
    <div className="relative !font-avenir text-[#42526D]">
      <DashboardContainer>
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center mb-4">
            <H6 className="">Surveys</H6>
            <Select
              defaultValue="3"
              style={{ width: 120 }}
              placeholder="Filter by"
              onChange={(value: string) => {
                setTypeId(value)
              }}
              // options={[
              //   { value: true, label: "Enrolled" },
              //   { value: false, label: "Unenrolled" },
              // ]}
              options={surveyTypes.map((surv) => ({
                value: surv.survey_id,
                label: surv.survey_title,
              }))}
              className="font-avenir "
            />
          </div>
          <Table
            columns={TableColumns}
            dataSource={TableData}
            loading={isLoadingSurvey}
            className="text-[#42526D] min-h-screen"
            pagination={{
              total: surveyData?.total,
              defaultPageSize: 20,
            }}
          />
        </div>
      </DashboardContainer>
      <NewModalWrapper
        title="
                        Delete Survey
"
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(false)
          setActiveDeleteId("")
        }}
      >
        <div className="px-5 pt-3 flex flex-col items-center">
          <p className="text-[#15294B] text-sm mb-6">
            If you delete this survey, it would be removed from your list. Are
            you sure you want to continue?
          </p>
        </div>
        <div className="border py-5 px-6 flex gap-4  justify-end items-center">
          <Button
            color="secondary"
            onClick={() => {
              setOpenDeleteConfirmation(false)
              setActiveDeleteId("")
            }}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            submitting={isSubmittingDelete}
            onClick={() => {
              handleDelete()
            }}
          >
            Confirm
          </Button>
        </div>
      </NewModalWrapper>
    </div>
  )
}
export default Surveys

export interface ISurvey {
  survey_id: string
  survey_key: string
  survey_status_id: string
  survey_status: string
  survey_type_id: string
  survey_type: null
  description: string
  is_active: string
}
