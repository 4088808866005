/**
 * An optimized icon button.
 * Pass the `title` props, else `⚠` is returned
 */
export const IconButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  if (!props["title"]) return <span children={"⚠"} />
  return (
    <button
      aria-label={props.title}
      {...props}
      className={`p-2 flex justify-center items-center rounded-full text-center hover:opacity-95 active:opacity-95 focus:opacity-95 focus:outline-none focus:shadow-none disabled:opacity-50 ${props.className}`}
    />
  )
}
