import React, { useRef } from "react"
import { useLocation } from "react-router-dom"
import { IPayslipDetailed } from "pages/employee-dashboard/payslip/interface/Payslip"
import { useGet } from "utils/useFetch"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { Button } from "components"
import ReactToPrint from "react-to-print"
import ComponentToPrintAll from "pages/employee-dashboard/payslip/component/ComponentToPrintAll"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"

const ViewAllPayslips: React.FC<{}> = () => {
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const id = new URLSearchParams(myParam).get("id")
  const componentRef: any = useRef()
  const { isLoading: isPayslipLoading, data: allPayslips } = useGet<
    IPayslipDetailed[]
  >({
    url: `payroll_items/view/payslips_admin?id=${id}&x=${x}`,
  })

  return (
    <DashboardWrapper>
      <div className="relative ">
        <div className="flex items-center justify-between mb-8">
          <h6 className=" text-bluesubtext text-lg font-medium">
            Employee Payslips
          </h6>

          {/* <Button
            color="primary"
            title="Download"
            onClick={() => {
              captureAndDownloadScreenshot()
            }}
          >
            Download
          </Button> */}
          {componentRef && (
            <ReactToPrint
              trigger={() => (
                <Button
                  color="primary"
                  title="Download"
                >
                  Download All
                </Button>
              )}
              content={() => componentRef.current}
              documentTitle={`${
                allPayslips && allPayslips[0]?.full_name
              }-payslips.pdf`}
            />
          )}
        </div>
        <>
          {isPayslipLoading ? (
            <div className="h-screen flex w-full justify-center items-center">
              <LoadingIndicatorWhite />
            </div>
          ) : (
            <ComponentToPrintAll
              ref={componentRef}
              allPayslips={allPayslips}
            />
            // <div
            //   id="payslip"
            //   className=" gap-10 flex flex-col"
            // >
            //   {allPayslips?.map((payslip, index) => (
            //     <PayslipTemplate data={payslip} />
            //   ))}
            // </div>
          )}
        </>
      </div>
    </DashboardWrapper>
  )
}
export default ViewAllPayslips
