import { useState } from "react"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { useNavigate } from "react-router-dom"
import { axiosInstance } from "utils/request"
import { useQuery } from "@tanstack/react-query"
import { useAppNotificationStore, useAuthStore } from "stores"
import { Form, Radio, Space } from "antd"
import { formatCurrencyNoSymbol } from "utils/currency"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import SingleEmployee from "./SingleEmployee"
import MultipleEmployee from "./MultipleEmployee"
import { IFormData, sampleBody } from "../contractor/ContractorPaymentSchedule"
import DynamicEmployee from "./DynamicEmployee"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { usePost } from "utils/useFetch"
import { ICurrencies } from "pages/dashboard/wallets/integration/interface/kyc"

const EmployeePaymentSchedule = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const [form] = Form.useForm()
  const walletId = user?.organisationWalletId
  const [transferType, setTransferType] = useState("Single")

  const [formBodySingle, setFormBodySingle] = useState<IFormData>({
    description: "",
    person_id: "",
    person_name: "",
    person_bank: "",
    person_account_number: "",
    amount: "",
    au_comment: "",
    state: "",
    currency_symbol: "",
    currency_code: "",
    source_currency_code: "",
  })
  const [formBodyMultipleEmp, setFormBodyMultipleEmp] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const [formBodyMultipleCont, setFormBodyMultipleCont] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const [formBodyDynamicEmp, setFormBodyDynamicEmp] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const [formBodyDynamicCont, setFormBodyDynamicCont] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })

  const { isLoading: isLoadingOverview, data: walletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const { isLoading: isCurrencyLoading, data: currencies } = usePost<
    ICurrencies[]
  >({
    url: "/commonutils/getcurrencies",
    body: {
      q: "",
      page: "",
    },
  })
  return (
    <DashboardWrapper>
      <div className=" flex flex-col md:flex-row items-start justify-start mt-5 gap-10">
        <div className="w-full lg:w-10/12">
          <SegmentWrapper
            title="Custom Schedule for Employees"
            // subtitle="You can send payment schedules to your employees."
          >
            <div className="w-full  bg-white  rounded-md border-neutral-300 px-4 md:px-6 lg:px-8 xl:px-[60px]">
              <Form layout="vertical">
                <AntDesignRadio
                  label="Choose type of transfer"
                  name="sameAmount"
                  value={transferType}
                  id="custom-payment-transfer-type"
                >
                  <Space direction="horizontal">
                    <Radio
                      value={"Single"}
                      checked={transferType == "Single" ? true : false}
                      onChange={() => {
                        setTransferType("Single")
                      }}
                    >
                      Single
                    </Radio>
                    <Radio
                      value={"Multiple"}
                      onChange={() => {
                        setTransferType("Multiple")
                        setFormBodyDynamicCont(sampleBody)
                        setFormBodyDynamicEmp(sampleBody)
                      }}
                      checked={transferType == "Multiple" ? true : false}
                    >
                      Multiple
                    </Radio>
                    <Radio
                      value={"Dynamic"}
                      onChange={() => {
                        setTransferType("Dynamic")
                        setFormBodyMultipleCont(sampleBody)
                        setFormBodyMultipleEmp(sampleBody)
                      }}
                      checked={transferType == "Dynamic" ? true : false}
                    >
                      Dynamic Payout{" "}
                    </Radio>
                  </Space>
                </AntDesignRadio>
              </Form>

              {transferType == "Single" ? (
                <SingleEmployee
                  formBodySingle={formBodySingle}
                  setFormBodySingle={setFormBodySingle}
                  currencies={currencies}
                />
              ) : transferType == "Multiple" ? (
                <MultipleEmployee
                  setFormBodyMultipleEmp={setFormBodyMultipleEmp}
                  formBodyMultipleEmp={formBodyMultipleEmp}
                  setFormBodyMultipleCont={setFormBodyMultipleCont}
                  formBodyMultipleCont={formBodyMultipleCont}
                  currencies={currencies}
                />
              ) : (
                <DynamicEmployee
                  setFormBodyDynamicEmp={setFormBodyDynamicEmp}
                  formBodyDynamicEmp={formBodyDynamicEmp}
                  setFormBodyDynamicCont={setFormBodyDynamicCont}
                  formBodyDynamicCont={formBodyDynamicCont}
                  currencies={currencies}
                />
              )}
            </div>
          </SegmentWrapper>
        </div>{" "}
        {(formBodySingle?.person_id !== "" ||
          formBodyMultipleEmp?.persons?.length > 0 ||
          formBodyMultipleCont?.persons?.length > 0 ||
          formBodyDynamicCont?.persons?.length > 0 ||
          formBodyDynamicEmp?.persons?.length > 0) && (
          <div className="w-full lg:w-5/12 lg:border-l-2 border-#C2C7D0 lg:pl-10">
            <div className="h-auto bg-primary700  rounded-[10px] px-4 py-5 justify-between flex flex-col mb-7 ">
              <h3 className="text-white font-extrabold text-lg mb-2">
                Wallet Balance
              </h3>
              <div className="border  border-white w-full" />
              {isLoadingOverview ? (
                <div className="w-full flex justify-center items-center py-3 mt-4">
                  <LoadingIndicatorWhite />
                </div>
              ) : (
                <>
                  {walletData?.data?.balances.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-2 my-2"
                    >
                      <p className="text-white text-sm">{item?.currency}</p>

                      <p className="text-white text-sm font-bold text-right">
                        {item?.symbol}
                        {formatCurrencyNoSymbol(Number(item?.balance) || 0)}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
            {transferType == "Single" &&
              formBodySingle.amount !== "" &&
              formBodySingle.person_id !== "" && (
                <div className="mt-6">
                  <p className="font-bold text-[#42526D]">Preview</p>
                  <hr className="my-4 border-b border-[#C2C7D0]" />
                  <div className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4">
                    <div className="grid grid-cols-2">
                      <span className="text-[#98A1B0] text-sm">
                        Beneficiary
                      </span>
                      <span className="text-[#243757] font-bold text-sm">
                        {formBodySingle.person_name}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="text-[#98A1B0] text-sm"> Account</span>
                      <span className="text-[#243757] font-bold text-sm">
                        {formBodySingle.person_bank}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="text-[#98A1B0] text-sm">
                        Account Number
                      </span>
                      <span className="text-[#243757] font-bold text-sm">
                        {formBodySingle.person_account_number}
                      </span>
                    </div>
                    <div className="grid grid-cols-2">
                      <span className="text-[#98A1B0] text-sm">Amount</span>
                      <span className="text-[#243757] font-bold text-sm">
                        {formBodySingle?.source_currency_code !== "USD"
                          ? formBodySingle?.currency_symbol
                          : "$"}
                        {formatCurrencyNoSymbol(Number(formBodySingle.amount))}
                      </span>
                    </div>
                  </div>
                </div>
              )}

            {transferType == "Multiple" &&
              (formBodyMultipleEmp?.persons?.length > 0 ||
                formBodyMultipleCont?.persons?.length) && (
                <div className="mt-6">
                  <p className="font-bold text-[#42526D]">Preview</p>
                  <hr className="my-4 border-b border-[#C2C7D0]" />
                  <div className=" h-[500px] overflow-y-scroll scrollbar-hide ">
                    {formBodyMultipleEmp.persons.map(
                      (item: any, index: any) => (
                        <div
                          key={index}
                          className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                        >
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Beneficiary
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_name}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Account
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_bank}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Account Number
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_account_number}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Amount
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item?.source_currency_code !== "USD"
                                ? item?.currency_symbol
                                : "$"}
                              {""}
                              {formatCurrencyNoSymbol(Number(item.amount || 0))}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                    {formBodyMultipleCont.persons.map(
                      (item: any, index: any) => (
                        <div
                          key={index}
                          className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                        >
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Beneficiary
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_name}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Account
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_bank}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Account Number
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_account_number}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Amount
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item?.source_currency_code !== "USD"
                                ? item?.currency_symbol
                                : "$"}
                              {formatCurrencyNoSymbol(Number(item.amount || 0))}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            {transferType == "Dynamic" &&
              (formBodyDynamicEmp?.persons?.length > 0 ||
                formBodyDynamicCont?.persons?.length > 0) && (
                <div className="mt-6">
                  <p className="font-bold text-[#42526D]">Preview</p>
                  <hr className="my-4 border-b border-[#C2C7D0]" />
                  <div className=" h-[500px] overflow-y-scroll scrollbar-hide ">
                    {formBodyDynamicEmp.persons.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                      >
                        <div className="grid grid-cols-2">
                          <span className="text-[#98A1B0] text-sm">
                            Beneficiary
                          </span>
                          <span className="text-[#243757] font-bold text-sm">
                            {item.person_name}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-[#98A1B0] text-sm">
                            Account
                          </span>
                          <span className="text-[#243757] font-bold text-sm">
                            {item.person_bank}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-[#98A1B0] text-sm">
                            Account Number
                          </span>
                          <span className="text-[#243757] font-bold text-sm">
                            {item.person_account_number}
                          </span>
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="text-[#98A1B0] text-sm">Amount</span>
                          <span className="text-[#243757] font-bold text-sm">
                            ${formatCurrencyNoSymbol(Number(item.amount || 0))}
                          </span>
                        </div>
                      </div>
                    ))}
                    {formBodyDynamicCont.persons.map(
                      (item: any, index: any) => (
                        <div
                          key={index}
                          className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                        >
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Beneficiary
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_name}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Account
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_bank}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Account Number
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.person_account_number}
                            </span>
                          </div>
                          <div className="grid grid-cols-2">
                            <span className="text-[#98A1B0] text-sm">
                              Amount
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              $
                              {formatCurrencyNoSymbol(Number(item.amount || 0))}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </DashboardWrapper>
  )
}

export default EmployeePaymentSchedule

interface IBalance {
  balance: string
  text: string
  currency: string
  icon: string
}

interface IBank {
  name: string
  icon: string
}
interface IEmployee {
  person_id: number
  full_name: string
  identification_number: string | null
  person_status_id: number
  bank?: string
  account_number?: string
}
interface ISelectedEmployee {
  name: string
  person_id: string
  amount: string
}
