import PrevNextButton from "pages/dashboard/people/people/form/PrevNextButton"

import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import { Form } from "antd"
import { useAppNotificationStore } from "stores"

import { usePost } from "utils/useFetch"
import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { useNavigate } from "react-router-dom"
import { Button, CheckboxInput, H7, Label, P2, TextAnchor } from "components"
import { AntDesignCheckbox } from "components/antd/AntDesignCheckbox"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

export const TermsConditions = ({
  setActiveKey,
  active,
}: {
  active: boolean
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const navigate = useNavigate()
  const [termsAgreed, setTermsAgreed] = useState(false)
  const { toast } = useAppNotificationStore()
  const [form] = Form.useForm()

  const { isLoading: isSubmittingKYC, mutate: submitForVerification } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(`/admin/company/submit_ewa`)
        return res.data
      },
      onSuccess: (data) => {
        if (data.status === "success") {
          {
            data.msg && toast.success(data.msg)
          }
          mixPanelEvent("settings-hr-payroll-ewa-update-success")
          navigate("/dashboard/settings/hr-and-payroll")
        }
      },
    })

  return (
    <FormSectionWrapper
      name="Terms and conditions"
      title="Terms and conditions"
      isActive={active}
      layout="vertical"
      form={form}
      // onFinish={onFinish}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      footerBtns={
        <div className="flex gap-3 justify-end">
          <Button
            color="secondary"
            title="Previous"
            type="button"
            onClick={() => setActiveKey(["2"])}
          >
            Previous
          </Button>

          <Button
            color="primary"
            title="Submit"
            onClick={() => {
              mixPanelEvent("settings-hr-payroll-ewa-update-init")
              submitForVerification()
            }}
            defaultChecked={termsAgreed}
            disabled={termsAgreed ? false : true}
            submitting={isSubmittingKYC}
          >
            Submit
          </Button>
        </div>
      }
    >
      <div className=" px-4 md:px-6 xl:px-[60px]">
        <AntDesignCheckbox
          id="agree"
          label={
            <p>
              {" "}
              By clicking Agree, I accept the{" "}
              <a
                href="https://www.paidhr.com/ewa-terms-and-conditions"
                target="_blank"
                className="underline  text-primary500"
              >
                terms and conditions
              </a>{" "}
              of earned wage access services, granting permission to access my
              earned wages in advance.
            </p>
          }
          onChange={() => setTermsAgreed(!termsAgreed)}
        />
      </div>
    </FormSectionWrapper>
  )
}
