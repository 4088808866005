import { TZDate } from "@toast-ui/calendar"
import { hostName, testHostName } from "./constants"

export function clone(date: TZDate): TZDate {
  return new TZDate(date)
}

export function addHours(d: TZDate, step: number) {
  const date = clone(d)
  date.setHours(d.getHours() + step)

  return date
}

export function addDate(d: TZDate, step: number) {
  const date = clone(d)
  date.setDate(d.getDate() + step)

  return date
}

export function subtractDate(d: TZDate, steps: number) {
  const date = clone(d)
  date.setDate(d.getDate() - steps)

  return date
}

export function isPadeDomain() {
  const host_name = window.location.hostname

  if (
    host_name == hostName ||
    host_name == testHostName ||
    host_name === "localhost"
  ) {
    return true
  } else {
    return false
  }
}
export const removeNullOrUndefined = (obj: any): any => {
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      delete obj[key]
    }
  })

  return obj
}

export const unescapedContent = (escapedContent: string): any =>
  new DOMParser().parseFromString(escapedContent, "text/html").documentElement
    .textContent
