import { DocumentArrowDownIcon } from "@heroicons/react/24/outline"
import { exportTableToCSV } from "pages/dashboard/documents/reports/utils/report-utils"
import React from "react"

type Props = {
  title: string
}

export const AppCSVDownloadButton = ({ title }: Props) => {
  return (
    <button
      className="px-4 py-2 font-circular !text-sm text-white rounded-md bg-primary400 flex items-center"
      onClick={() => {
        exportTableToCSV(title + ".csv")
      }}
    >
      <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
      <span>CSV</span>
    </button>
  )
}
