/**
 * An optimized checkbox input.
 */
export const CheckboxInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & { error?: boolean }
> = (props) => {
  return (
    <div>
      <input
        {...props}
        type={"checkbox"}
        className={`rounded-full bg-wh ite border-neutral80 h-6 w-6 checked:border-primary500 checked:text-white checked:bg-primary500 focus:outline-0 focus:outline-primary500 hover:opacity-95 ${props.className}`}
      />
    </div>
  )
}
