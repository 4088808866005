// import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

/**
 * An optimized input.
 */
export const Input: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    isError?: boolean
    errorText?: string
    maxLength?: any
  }
> = ({ isError, errorText, maxLength, ...props }) => {
  return (
    <>
      <div className="relative">
        <input
          type={props.type ?? "text"}
          {...props}
          className={` rounded-[5px] h-10 w-full border  border-neutral50 focus:border-primary500 active:border-primary500 bg-white px-5 py-3 text-base md:text-sm placeholder:text-neutral80 text-neutral600 focus:outline-0 ${props.className}`}
          maxLength={maxLength && Number(maxLength)}
        />
      </div>
      {isError && (
        <span>
          <div className="text-red-500 text-sm">{errorText}</div>
        </span>
      )}
    </>
  )
}
