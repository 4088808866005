import { Form, FormProps, Tag } from "antd"
import { ReactNode } from "react"
import { H5 } from "components"
import { ChevronDownIcon } from "@heroicons/react/24/outline"

interface FormSectionWrapperProps extends FormProps {
  title?: string
  subtitle?: string | ReactNode
  formHeading?: ReactNode
  isActive: boolean
  showDropdown?: boolean
  footerBtns?: ReactNode
  headerClassName?: string
  status?: "not setup" | "completed" | "pending" | string
  children?: ReactNode
  onDropDown?: () => void
}

const FormSectionWrapper = ({
  title,
  subtitle,
  formHeading,
  isActive,
  footerBtns,
  showDropdown = false,
  onDropDown,
  headerClassName,
  children,
  status,
  ...formProps
}: FormSectionWrapperProps) => {
  return (
    <div className="h-full gap-9 w-full">
      <div className=" w-full h-full">
        <div className="flex flex-col w-full gap-7 bg-white max-w-[952px] pt-6 lg:pt-[30px]">
          {/* Introduction */}
          <div
            className={`w-full flex justify-between gap-4 px-4 md:px-6 lg:px-8 xl:px-[60px] ${headerClassName}`}
          >
            <div className="grow flex justify-between items-start">
              <div>
                <H5 className="font-bold text-padeBlack">{title}</H5>
                {subtitle && (
                  <div className="mt-2 text-padeSubtext text-sm">
                    {subtitle}
                  </div>
                )}
              </div>
              {status && (
                <div>
                  <Tag
                    color={
                      status === "not setup"
                        ? "blue"
                        : status === "completed"
                        ? "green"
                        : status === "pending"
                        ? "orange"
                        : "red"
                    }
                    className=" capitalize"
                  >
                    {status}
                  </Tag>
                </div>
              )}
            </div>
            {showDropdown ? (
              <div
                className="shrink-0 border border-neutral50 rounded-full h-7 w-7 flex justify-center items-center"
                role="button"
                onClick={() => onDropDown?.()}
              >
                <ChevronDownIcon
                  className={`ease-in-out transition-all w-4 text-neutral700 ${
                    isActive && "rotate-180"
                  }`}
                />
              </div>
            ) : null}
          </div>
          <div
            className={`w-full max-w-[952px] ${
              isActive ? " h-full py-6 pb-2" : "h-0 overflow-y-hidden"
            } container rounded-lg border-t transition-all ease-in-out duration-100`}
          >
            {formHeading && (
              <div className="w-full px-4 md:px-6 xl:px-[60px]">
                <div className=" text-[16px] leading-[24px] font-circular text-neutral500 font-medium mb-4 ">
                  {formHeading}
                </div>
              </div>
            )}
            <div className=" w-full ">
              <Form {...formProps}>
                {children}
                <div className="w-full py-2 mt-2 border-t px-4 md:px-6 xl:px-[60px]">
                  {footerBtns}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormSectionWrapper
