import { Form } from "antd"
import { Button } from "components"
import { AppSegmentWrapper } from "components/custom/AppSegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useState } from "react"
import { PermissionsPicker } from "./PermissionsPicker"
import { RoleForm } from "./RoleForm"
import { useNavigate } from "react-router-dom"
import { ICustomRoleRequest } from "./interface/interface"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { LoadingButton } from "components/buttons/LoadingButton"
import { useGet, usePost } from "utils/useFetch"
import SegmentWrapper from "components/custom/SegmentWrapper"

const CreateRolePage = () => {
  const [form] = Form.useForm()

  const { toast } = useAppNotificationStore()

  const [employees, setEmployees] = useState<string[]>([])

  const [formData, setFormData] = useState<ICustomRoleRequest>()

  //@: Local state to manage the selected permissions
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])

  const { data: admins, isLoading: isGetting } = usePost<any>({
    url: `/admin/company/getadmins?includePrimary=${true}`,
    body: {
      q: "",
      page: "",
    },
  })

  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: ICustomRoleRequest) => {
      const res = await axiosInstance.post(`/admin/roles/create`, data)
      return res.data
    },
    onSuccess: (data) => {
      if (data.status == "success") {
        data.msg && toast.success(data.msg)

        navigate(-1)
      } else {
        data.msg && toast.error(data.msg)
      }
    },
  })

  return (
    <DashboardWrapper>
      <SegmentWrapper title="Create Custom Role">
        <div className="px-4 md:px-6 xl:px-[60px] flex flex-col relative h-screen overflow-y-hidden overflow-x-hidden -mb-10">
          <div className="grid lg:grid-cols-[40%,60%]  grid-cols-1 h-[100%]">
            <RoleForm
              form={form}
              employees={employees}
              setEmployees={setEmployees}
              isEditMode
              showButton={false}
              isCustomRole
              admins={admins}
              createMode={true}
              onUpdateData={(values) => {
                setFormData(values as any)
              }}
              onFinish={() => {
                mutate({
                  ...formData,
                  permissions: selectedPermissions.map((permission) =>
                    String(permission)
                  ),
                  assignments: employees?.map((x: string) =>
                    admins?.find((it: any) => it?.text === x)?.id?.toString()
                  ),
                } as ICustomRoleRequest)
              }}
              showRolePeopleList={false}
            />
            <PermissionsPicker
              isEditMode
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
            />
          </div>
          <div className="flex justify-end gap-3 mt-6 border-t pr-3 py-4 absolute bottom-0 right-0 w-full bg-white">
            <Button
              color="neutral"
              onClick={() => navigate(-1)}
              type="button"
            >
              Cancel
            </Button>

            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
              type="submit"
              submitting={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </SegmentWrapper>
    </DashboardWrapper>
  )
}

export default CreateRolePage
