import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Checkbox, Form, Input } from "antd"
import {
  P2,
  H5,
  AppDownloadTemplateButton,
  AppFileUpload,
  Button,
} from "components"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import usePermissions from "hooks/usePermissions"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import { isPadeDomain } from "utils/utils"

type Props = {}

const BulkUploadCustomPayment = (props: Props) => {
  const { toast } = useAppNotificationStore()
  const [file, setFile] = useState<any>(null)
  const [active, setActive] = useState(false)
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const { user } = useAuthStore()

  const { isLoading: isAddingFile, mutate: submitFile } = useMutation({
    mutationFn: async (formData: FormData) => {
      const res = await axiosInstance.post(
        `/admin/payment_schedules/import_custom_payment_schedule`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        data?.msg && toast.success(data.msg)
        navigate(-1)
      } else if (data.status === "error") {
        toast.error(data.msg)
      }
    },
  })

  const [form] = Form.useForm()

  const [canCreate] = usePermissions({
    allowedPermissions: ["can_create_pay_schedules"],
  })

  const { isLoading: isCheckingScheduleName, mutateAsync: checkScheduleName } =
    useMutation({
      mutationFn: async (body: string) => {
        const res = await axiosInstance.get(
          `/admin/payment_schedules/check_custom_payment_schedule?description=${body}&payment_schedule_id=${-1}`
        )
        if (res.data.status === "success") {
          return {
            status: false,
            msg: res.data.msg,
          }
        }
        if (res.data.status === "error" && res.data.msg) {
          return {
            status: true,
            msg: res.data.msg,
          }
        }

        return {
          status: false,
          msg: res.data.msg,
        }
      },
    })

  const handleFinish = () => {
    console.log(form.getFieldValue("description"))
    if (file) {
      const formData = new FormData()
      formData.append("file_upload", file)
      formData.append("description", form.getFieldValue("description"))
      submitFile(formData)
    }
  }

  return (
    <DashboardWrapper>
      <SegmentWrapper
        title="Bulk custom payment upload in 3 steps"
        subtitle={
          isPadeDomain() ? (
            <p className="font-normal text-[#42526D] ">
              Need Help?{" "}
              <a
                href="https://support.padehcm.com"
                className=" text-primary500 font-light"
                target="_blank"
              >
                Get Started: Bulk upload custom payment
              </a>
            </p>
          ) : (
            <></>
          )
        }
        contentHeading={
          <div className=" mb-8">
            <h3 className="font-medium text-lg text-[#42526D] mb-2  font-circular">
              What you must have ready
            </h3>
            <div className="flex flex-col w-full gap-3">
              {["Amount to pay"].map((item, index) => (
                <P2 className="flex w-full gap-4">
                  <img
                    src={
                      "https://padefiles.blob.core.windows.net/images/8a82057b-5be2-4992-9e08-0c0f66ca9bc5.svg"
                    }
                  />
                  <span className="font-medium text-sm text-[#5D6B82] ml-4">
                    {item}
                  </span>
                </P2>
              ))}
            </div>
          </div>
        }
      >
        <Form
          form={form}
          onFinish={() => {
            handleFinish()
          }}
          layout="vertical"
        >
          <div className="px-4 md:px-6 xl:px-[60px] mb-6 space-y-10">
            <div className="w-full flex flex-col gap-2.5">
              <H5 className="font-medium text-neutral600">
                1. Download spreadsheet
              </H5>
              <P2 className="text-neutral500">
                We recommend that you download a new spreadsheet before each
                bulk payment schedule upload. This ensures that your lookup data
                is up-to-date.
              </P2>
              <div className="flex gap-2 items-center">
                <Checkbox
                  onChange={(e) => {
                    setActive(e.target.checked)
                  }}
                />{" "}
                <P2 className="font-medium text-neutral600">
                  Include exited employees
                </P2>
              </div>
              <AppDownloadTemplateButton
                fileName={`${user?.userOrganisation}_bulk_custom_payment_schedule`}
                url={`/admin/payment_schedules/view/download_bulk_custom_schedule_upload_form?include_exited=${active}`}
                method="GET"
                type="xlsx"
              />
            </div>

            <div className="w-full flex flex-col gap-2.5">
              <H5 className="font-medium text-neutral600">
                2. Enter payment schedule title
              </H5>

              <Form.Item
                name="description"
                label="Enter payment schedule title"
                rootClassName="mb-0"
                rules={[
                  {
                    required: true,
                    message: "Please add a title",
                  },
                  {
                    validator(rule, value, callback) {
                      return new Promise(async (res, rej) => {
                        if (value) {
                          const status = await checkScheduleName(value)
                          if (status?.status) {
                            rej(status?.msg)
                          } else res("success")
                        }
                      })
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className="w-full flex flex-col gap-2.5">
              <H5 className="font-medium text-neutral600">
                3. Upload spreadsheet
              </H5>
              <P2 className="text-neutral500">
                We recommend that you double-check to be sure you have filled
                the spreadsheet correctly.
                <br /> Note: Maximum allowed file size is 2MB.
              </P2>
              <AppFileUpload
                onUpdate={(file) => {
                  setFile(file)
                }}
              />
            </div>
          </div>

          <div className="flex justify-end items-center w-full pt-6  mt-6 -mb-4 border-t px-4 md:px-6 lg:px-8 xl:px-[60px]">
            <div className={`mt-2 flex gap-3 justify-end `}>
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  navigate(-1)
                }}
                color="neutral"
                type="button"
                className="h-fit text-[#5D6B82]  font-circular"
                disabled={isAddingFile}
              >
                Cancel
              </Button>
              <Button
                title="Upload"
                color="primary"
                className="w-max"
                onClick={(e) => {
                  // e.preventDefault()
                  form.submit()
                }}
                // disabled={isAddingFile}
                type="submit"
                submitting={isAddingFile}
              >
                Upload information
              </Button>
            </div>
          </div>
        </Form>
      </SegmentWrapper>
    </DashboardWrapper>
  )
}

export default BulkUploadCustomPayment
