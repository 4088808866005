import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import { useState } from "react"

/**
 * An optimized password input.
 */
export const PasswordInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & { error?: boolean }
> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <div className="relative">
      <input
        {...props}
        type={showPassword === true ? "text" : "password"}
        className={`rounded-[5px] w-full h-10 border border-neutral80 bg-white px-5 py-3 placeholder:text-neutral80 focus:border-primary500 active:border-primary500 invalid:border-danger500 text-neutral600 focus:outline-0 ${props.className}`}
      />
      <div className="absolute top-0 right-0 h-full flex items-center p-4 text-neutral600 cursor-pointer">
        {showPassword === true ? (
          <EyeSlashIcon
            onClick={() => setShowPassword(false)}
            className="w-5 text-neutral80"
          />
        ) : (
          <EyeIcon
            onClick={() => setShowPassword(true)}
            className="w-5 text-neutral80"
          />
        )}
      </div>
    </div>
  )
}
