import mixpanel from "mixpanel-browser"
import React from "react"
import { useAuthStore } from "stores"
import { mixPanelKey } from "utils/mixpanel"

export const mixPanelEvent = (
  event: string,
  userId?: string,
  properties?: any
) => {
  const user = useAuthStore.getState().user
  const token = mixPanelKey

  mixpanel.init(token, {
    debug: true,
    persistence: "localStorage",
  })

  if (userId || user?.userName) {
    mixpanel.identify(userId ?? user?.userName)
  }

  const MixPanelProperties = {
    "Organization Id": user?.userOrganisationId,
    "Organization Name": user?.userOrganisation,
    "Is Admin": user?.isAdmin,
    channel: "Web",
    ...properties,
  }

  mixpanel.track(event, MixPanelProperties)
}
