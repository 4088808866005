import { Alert } from "antd"
import { Link } from "react-router-dom"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

type setupProps = {
  setupArray?: any
}

export const SetupMessageAction: React.FC<setupProps> = ({
  setupArray = [],
}) => {
  return (
    <>
      {setupArray?.length === 0 ? (
        <div className="flex mt-[100px] h-full items-center justify-center ">
          <h6 className="text-sm  text-neutral700">No Pending Action</h6>
        </div>
      ) : (
        <div>
          {setupArray?.map((msg: any, index: any) => (
            <Alert
              key={index}
              message=""
              type="info"
              showIcon
              icon={
                <InformationCircleIcon className="w-4 h-4 !text-primary500" />
              }
              action={
                msg?.url && (
                  <Link to={msg?.url}>
                    <button className="border border-primary500 ml-1 p-[2px] px-2 rounded-5px  text-a11y bg-primary500 text-xs">
                      Review now
                    </button>
                  </Link>
                )
              }
              description={msg.subtitle}
              className="font-avenir text-neutral500 bg-primary50 border-primary400 min-h-10 px-2  text-sm gap-0 w-full mt-[5px] flex justify-between items-center"
            />
          ))}
        </div>
      )}
    </>
  )
}
