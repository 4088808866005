import { Modal, Spin } from "antd"
import { H7 } from "components"
import React, { FC } from "react"
import { useGet, usePost } from "utils/useFetch"
import parse from "html-react-parser"
import { capitalize } from "lodash"

type Props = {
  onClose: () => void
  id: string
  itemIsRead: boolean
}

export const ViewNotificationDetail: FC<Props> = ({
  onClose,
  id,
  itemIsRead,
}) => {
  const { data, isLoading } = useGet<any>({
    url: `/notifications/view/detail?id=${id}`,
  })

  usePost({
    url: `/admin/announcements/read?id=${id}`,
    enabled: itemIsRead ? false : true,
  })

  return (
    <Modal
      onCancel={() => {
        onClose()
      }}
      footer={
        <div className="mt-5 flex justify-center gap-2">
          <div className="flex-1 flex gap-1 justify-center">
            <></>
            <></>
          </div>
        </div>
      }
      open={true}
      title={<H7 className="font-avenir ">Notification Detail</H7>}
    >
      <hr className="mb-6" />
      <div className="w-full bg-white">
        <Spin spinning={isLoading}>
          <div className="">
            <div className="font-bold text-lg font-avenir">
              {data?.subject &&
                data?.subject
                  ?.split(" ")
                  .map((it: string) => capitalize(it))
                  .join(" ")}
            </div>
            <div className="min-h-[100px] w-full">
              {data?.contents && (
                <div className="w-full font-avenir">
                  {parse(data?.contents)}
                </div>
              )}
            </div>
          </div>
        </Spin>
      </div>
    </Modal>
  )
}
