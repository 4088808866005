import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"

/**
 * @param {string} url The URL to connect to
 * @description checks if the specified name is already in use
 * @returns boolean
 */
export const useVerifyName = (url: string) => {
  const { data, mutateAsync, isLoading } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.get(url)

      if (res.data.status === "success") {
        return {
          status: false,
          message: res?.data?.msg,
        }
      }
      if (res.data.status === "error") {
        return {
          status: true,
          message: res?.data?.msg,
        }
      }

      return {
        status: false,
        message: res?.data?.msg,
      }
    },
  })

  return { status: data, verify: mutateAsync, isVerifying: isLoading }
}
