import { Form, FormProps } from "antd"
import { ReactNode } from "react"
import { H5, H7, P2 } from "components"

interface FormSectionWrapperProps extends FormProps {
  title?: string
  subtitle?: string | ReactNode
  formHeading?: ReactNode
  isActive: boolean
  footerBtns?: ReactNode
  children?: ReactNode
}

const FormSectionWrapper = ({
  title,
  subtitle,
  formHeading,
  isActive,
  footerBtns,
  children,
  ...formProps
}: FormSectionWrapperProps) => {
  return (
    <div className="h-full gap-9">
      <div className=" w-full h-full">
        <div className="flex flex-col w-full gap-7 bg-white max-w-[952px] pt-6 lg:pt-[30px]">
          {/* Introduction */}
          <div className="w-full px-4 md:px-6 xl:px-[60px]">
            <H5 className="font-bold text-[#42526D]">{title}</H5>
            {subtitle && (
              <div className="mt-2 text-neutral500 text-sm">{subtitle}</div>
            )}
          </div>
          <div
            className={`w-full max-w-[952px] ${
              isActive ? " h-full py-6 pb-2" : "h-0 overflow-y-hidden"
            } container rounded-lg border-t transition-all ease-in-out duration-100`}
          >
            {formHeading && (
              <div className="w-full px-4 md:px-6 xl:px-[60px]">
                <div className=" text-[16px] leading-[24px] font-circular text-neutral500 font-medium mb-4 ">
                  {formHeading}
                </div>
              </div>
            )}
            <div className=" w-full ">
              <Form {...formProps}>
                {children}
                <div className="w-full py-2 mt-2 border-t px-4 md:px-6 xl:px-[60px]">
                  {footerBtns}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormSectionWrapper
