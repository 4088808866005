import { Suspense, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { P1 } from "components"
import getIdFromKey from "utils/getIdFromKey"
import { Spin } from "antd"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"

const subPages = [
  {
    index: 0,
    to: "/dashboard/payroll/configuration/pay-schedules",
    title: "Pay schedules",
  },
  {
    index: 1,
    to: "/dashboard/payroll/configuration/paybands",
    title: "Paybands",
    id: "payroll-config-paybands",
    description: "Configurable employee payment bands",
  },
  {
    index: 2,
    to: "/dashboard/payroll/configuration/paygrades",
    title: "Paygrades",
    id: "payroll-config-paygrade",
    description: "Configurable employee payment grades",
  },
  {
    index: 3,
    to: "/dashboard/payroll/configuration/allowances",
    title: "Allowances",
    description: "Custom paygrade allowances",
    id: "payroll-config-allowances",
  },
  {
    index: 4,
    to: "/dashboard/payroll/configuration/tax-reliefs",
    title: "Tax Reliefs",
    description: "Employee tax relief",
    id: "payroll-config-tax-reliefs",
  },
  {
    index: 5,
    to: "/dashboard/payroll/configuration/statutory-benefits",
    title: "Statutory Benefits",
    description: "Statutory compliance benefits",
    id: "payroll-config-benefits",
  },
  {
    index: 6,
    to: "/dashboard/payroll/configuration/tax-tables",
    title: "Tax Table",
    description: "Employee tax computation table",
    id: "payroll-config-tax",
  },
]

const PayrollConfigurations: React.FC<{}> = () => {
  const [currentSubPage, setCurrentSubPage] = useState<number>(0)
  const { pathname } = useLocation()
  const { employeeKey } = useParams()
  const employeeId = getIdFromKey(employeeKey || "")
  const navigate = useNavigate()

  useEffect(() => {
    const index = subPages.findIndex((item) => item.to === pathname)
    setCurrentSubPage(index)
  }, [pathname])

  return (
    <>
      <div className="h-full flex items-center gap-6 px-4 md:px-6 lg:px-10 border-y border-y-neutral40 bg-white overflow-x-auto">
        {subPages.map((item, index) => (
          <div
            id={item.id}
            className={` h-full relative flex items-center cursor-pointer py-4`}
            onClick={() => {
              setCurrentSubPage(item.index)
              navigate(item.to)
            }}
            key={index}
          >
            <P1
              className={`whitespace-nowrap leading-none ${
                item.index === currentSubPage
                  ? "font-semibold text-primary500"
                  : "text-neutral100"
              }`}
            >
              {item.title}
            </P1>
            {item.index === currentSubPage && (
              <div className="w-full max absolute  bottom-0 h-[3px] bg-primary500" />
            )}
          </div>
        ))}
      </div>
      <div className="w-full mx-auto flex flex-col gap-4">
        <div className="w-full bg-[#ededed]min-h-screen ">
          <DashboardWrapper>
            <>
              <Suspense
                fallback={
                  <div
                    className="fixed right-0 top-32 bottom-0 left-0 md:left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-white"
                    style={{
                      zIndex: 9999999,
                    }}
                  >
                    <Spin />
                  </div>
                }
              >
                <Outlet />
              </Suspense>
            </>
          </DashboardWrapper>
        </div>
      </div>
    </>
  )
}

export default PayrollConfigurations
