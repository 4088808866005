import { Button } from "components"
import React from "react"

const InactiveReview: React.FC<{
  onClose: Function
}> = ({ onClose }) => {
  return (
    <div className=" bg-black bg-opacity-60 h-screen fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-50">
      <div className="p-5  rounded-5px  shadow-card bg-white w-[407px] h-[171px] flex items-start gap-3">
        <div>
          <h6 className="text-[#15294B]  font-bold text-base mb-[10px]">
            Remove Unassigned
          </h6>
          <p className="text-[#15294B] text-sm mb-6">
            Only assigned reviewers can provide responses in this review.
          </p>
          <div className="flex justify-end items-center gap-4">
            <Button
              title="Cancel"
              color="secondary"
              onClick={() => {
                onClose()
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InactiveReview
