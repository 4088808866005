import React, { FC } from "react"
import { Button } from "./Button"
import { useDownloadFileFromServer } from "hooks/useDownloadFile"
import { Spin } from "antd"
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

type Props = {
  disabled?: boolean
  fileName: string
  data?: unknown
  url: string
  method?: "POST" | "GET"
  type?: "xlsx" | "pdf" | "csv" | "xml"
  className?: string
  title?: string
}

const AppDownloadTemplateButton: FC<Props> = ({
  disabled,
  fileName,
  data,
  url,
  method = "GET",
  type = "xlsx",
  className,
  title = "Download",
}) => {
  const { downloadFile, isLoading: isDownloading } = useDownloadFileFromServer()

  function appendZero(val: number) {
    if (val === 0) return `01`
    if (val < 9) return `0${val}`
    return val
  }

  const timestamp =
    "_" +
    dayjs().year() +
    "_" +
    appendZero(dayjs().month() + 1) +
    "_" +
    appendZero(dayjs().day()) +
    "_" +
    appendZero(dayjs().hour()) +
    "_" +
    appendZero(dayjs().minute()) +
    "_" +
    appendZero(dayjs().second())
  fileName = fileName.toLowerCase().replace(/\s/gi, "_") + timestamp

  return (
    <Button
      color="primary"
      type="button"
      className={className ? className : "w-max"}
      disabled={disabled}
      onClick={(e) => {
        downloadFile(url, type, fileName, data, method)
        mixPanelEvent("file_download")
      }}
      submitting={isDownloading}
    >
      <div className="flex"> {title}</div>
    </Button>
  )
}

export default AppDownloadTemplateButton
