import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"
import { Alert } from "antd"
import { LinkButton } from "components"
import React from "react"
import { useNavigate } from "react-router-dom"

const EWANotEnabled = () => {
  const navigate = useNavigate()
  return (
    <div className="w-full mt-4 md:w-[90%]">
      <div className="p-4 md:p-5 md:pr-10 mb-6 bg-white  flex gap-2 items-center rounded ">
        <div>
          <InformationCircleIcon className="w-[48px] h-[48px] text-primary500 font-thin" />
        </div>
        <div className=" mb-4 ">
          <h5 className=" text-padeBlack font-bold text-base mb-[10px] font-avenir">
            Earned Wage Access is turned off
          </h5>
          <p className="text-padeBlack text-sm font-normal font-circular">
            Earned Wage Access gives your employees access to a portion of their
            earned wages before their regular payday. This provides greater
            financial flexibility for your employees.
          </p>
        </div>
        <LinkButton
          to="/dashboard/settings/hr-and-payroll/ewa"
          title="Next"
          color="primary"
          className="font-extrabold"
        >
          Turn on
        </LinkButton>
      </div>
    </div>
  )
}

export default EWANotEnabled
