import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import React, { useState } from "react"

import { Button } from "components"

import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"

import { TopActionBar } from "components/new-stuff/ui/TopActionBar"
import { Dropdown, Menu, Table } from "antd"
import { useAuthStore } from "stores"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import AddBeneficiary from "./modal/AddBeneficiary"
import { BenITem, IBeneficiaries } from "./interface"
import { useNavigate } from "react-router-dom"
import DeleteBeneficiary from "./modal/DeleteBeneficiary"
import { ICurrenciesData } from "pages/dashboard/wallets/crossBorderPayments/interface"
import { availableFlags } from "utils/flags"

const Beneficiaries = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const { user } = useAuthStore()
  const [addModal, setAddModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [beneficiaryId, setBenId] = useState("")
  const EmployeeWalletID = user?.userWalletId
  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })

  const TableData = beneficiariesData?.data?.filter((item) => {
    if (!search) {
      return true
    }

    const searchValue = search.toLowerCase()

    return (
      item.accountName.toLowerCase().includes(searchValue) ||
      item.bankName.toLowerCase().includes(searchValue)
    )
  })
  const availableCurrencies = [
    ...new Set(beneficiariesData?.data.map((item) => item.currency)),
  ]
  const columns =
    [
      {
        title: "Beneficiary",
        dataIndex: "accountName",
      },
      {
        title: "Currency",
        dataIndex: "currency",
        filters:
          availableCurrencies?.map((item) => ({
            text: item,
            value: item,
          })) || [],
        onFilter: (value: any, record: any) => {
          return record?.currency === value
        },
        render: (item: any, record: any) => {
          return (
            <div className="flex items-center gap-2">
              <div>
                <img
                  src={
                    availableFlags.find((flag) => flag.code === record.currency)
                      ?.flag
                  }
                  alt=""
                  className="h-6 w-6"
                />
              </div>
              <span
                className={`font-circular flex flex-wrap text-sm text-padeBlack`}
              >
                {record?.currency}
              </span>
            </div>
          )
        },
      },
      {
        title: "Bank",
        dataIndex: "bankName",
      },
      {
        title: "Account number",
        dataIndex: "accountNumber",
      },

      {
        title: "Type",
        dataIndex: "type",
        className: "capitalize",
      },
      {
        dataIndex: "actions",
        title: "",
        render: (_: any, record: BenITem) => {
          // render: (_: any, record: IBeneficiaries) => {

          const menu = (
            <Menu>
              {record?.currency === "NGN" && (
                <Menu.Item key="send">
                  <button
                    className="font-medium text-sm text-neutral500 hover:cursor-pointer mr-2"
                    onClick={() => {
                      if (record?.type === "bank") {
                        navigate(
                          `/employee/dashboard/wallet/send-money-bank?x=${record?._id}`
                        )
                      } else {
                        navigate(
                          `/employee/dashboard/wallet/send-money-wallet?x=${record?._id}`
                        )
                      }
                    }}
                  >
                    Send Money
                  </button>
                </Menu.Item>
              )}
              <Menu.Item key="delete">
                <button
                  className="font-medium text-sm text-neutral500 hover:cursor-pointer mr-2"
                  onClick={() => {
                    setDeleteModal(true)
                    setBenId(record?._id)
                  }}
                >
                  Delete
                </button>
              </Menu.Item>
            </Menu>
          )

          return (
            <>
              {record ? (
                <Dropdown overlay={menu}>
                  <button>
                    <EllipsisHorizontalIcon className="w-6 h-6 text-primary500" />
                  </button>
                </Dropdown>
              ) : null}
            </>
          )
        },
      },
    ] || []

  return (
    <>
      <TopActionBar>
        <div className=" flex justify-between items-center w-full">
          <div className="border-2  h-8  rounded-5px w-[268px] flex  items-center px-2 bg-white ">
            <MagnifyingGlassIcon className=" text-neutral80 w-4" />
            <input
              placeholder="Search"
              className="bg-none w-full px-2 outline-none  text-sm h-7"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <Button
            color="primary"
            onClick={() => {
              navigate("/employee/dashboard/wallet/beneficiaries/create")
            }}
          >
            Add Beneficiary
          </Button>
        </div>
      </TopActionBar>
      <DashboardWrapper>
        <div className="">
          <div className="bg-white  p-4 rounded-5px">
            <Table
              loading={isLoadingBeneficiaries}
              columns={columns}
              dataSource={TableData}
            />
          </div>
        </div>
      </DashboardWrapper>
      {/* <AddBeneficiary
        modal={addModal}
        onClose={() => {
          setAddModal(!addModal)
        }}
      /> */}
      <DeleteBeneficiary
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        beneficiaryId={beneficiaryId}
      />
    </>
  )
}
export default Beneficiaries
