import GoogleIcon from "../../assets/images/googleicon.svg"
import { useGoogleLogin } from "@react-oauth/google"
import { Space } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

type Props = {
  loading: boolean

  handleLoginProcess: (
    email?: string | undefined,
    password?: string | undefined,
    token?: string | undefined,
    code?: string | undefined,
    provider?: string | undefined
  ) => Promise<void>
}

export const GoogleLogin = ({ loading, handleLoginProcess }: Props) => {
  const googleLogin = useGoogleLogin({
    flow: "implicit",
    onSuccess: async (codeResponse) => {
      await handleLoginProcess("", "", "", codeResponse?.access_token, "google")
    },
    onError(errorResponse) {
      console.log(errorResponse.error_description)
    },

    enable_serial_consent: true,
  })

  return (
    <div
      className="flex flex-col items-center cursor-pointer"
      onClick={(e) => {
        e.preventDefault()
        if (loading) return
        googleLogin()
      }}
    >
      {loading ? (
        <div className="flex gap-2 items-center">
          <div className="invisible ">
            <img
              src={GoogleIcon}
              alt="googleicon"
            />
          </div>
          <span>
            <Space>
              <LoadingOutlined size={20} />
            </Space>
          </span>
        </div>
      ) : (
        <>
          <div>
            <img
              src={GoogleIcon}
              alt="googleicon"
            />
          </div>
          <div className="mt-[8px] font-ibm font-medium text-[16px] leading-[20.8px] text-neutral700">
            Google
          </div>
        </>
      )}
    </div>
  )
}
