import { Button, DashboardContainer } from "components"
import React, { useState, useEffect } from "react"
import { useGet } from "utils/useFetch"

import { Link, useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { SurveyCard } from "./components/QuestionCard"

import { ICustomSurveyData } from "./interface/template"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"

const Templates: React.FC<{}> = () => {
  const myParam = useLocation().search
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const [showNewTemplate, setShowNewTemplate] = useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeleteId, setActiveDeleteId] = useState("")
  const [typeId, setTypeId] = useState("3")
  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`admin/surveys/selectcustomtemplates?typeId=${typeId}`],
    })
  }
  const { isLoading: isTemplateLoading, data: templateData } =
    useGet<ICustomSurveyData>({
      url: `admin/surveys/selectcustomtemplates?typeId=${typeId}`,
    })
  const { isLoading: isSubmittingDelete, mutate: submitDelete } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/performance/remove_reviewtemplate?id=${activeDeleteId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        setOpenDeleteConfirmation(false)
        invalidateQueries()

        {
          data.msg && toast.success(data.msg)
        }
      } else {
        setOpenDeleteConfirmation(false)
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      setOpenDeleteConfirmation(false)
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const handleClick = () => {
    if (showNewTemplate) {
      setShowNewTemplate(false)
    } else {
      setShowNewTemplate(true)
    }
  }
  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteConfirmation(true)
    setActiveDeleteId(String(id))
  }
  const handleDelete = () => {
    submitDelete()
  }

  return (
    <DashboardContainer>
      <div className="relative">
        <div className="bg-white rounded-5px p-[30px]">
          <div className=" flex  items-center justify-between ">
            <h5 className=" text-lg text-neutral800 font-extrabold ">
              Templates
            </h5>
            {/* <Button
              className="border h-8 bg-neutral20 rounded-5px w-auto text-neutral300 text-xs"
              title="Process selected payments"
              color="primary"
              onClick={() => {
                // handleClick()
                navigate("/dashboard/performance/reviews/create-template")
              }}
            >
              <PlusIcon className="w-4 stroke-2" />
              Create survey template
            </Button> */}
          </div>
          <div className=" mt-4 flex flex-col gap-4">
            {isTemplateLoading ? (
              <div className="h-[200px] py-5 flex justify-center items-center">
                <LoadingIndicatorWhite />
              </div>
            ) : (
              templateData?.data?.map((data, index) => (
                <SurveyCard
                  id={data.survey_template_id}
                  title={data.description}
                  subtitle={data.survey_template_type}
                  questions_length={1}
                  key={index}
                  handleOpenDeleteModal={handleOpenDeleteModal}
                  keyId={data.survey_template_key}
                />
              ))
            )}
          </div>
        </div>

        <NewModalWrapper
          open={openDeleteConfirmation}
          title="Delete Template"
          onClose={() => {
            setOpenDeleteConfirmation(false)
            setActiveDeleteId("")
          }}
        >
          <div className="px-5 pt-3 flex flex-col ">
            <p className="text-[#15294B] text-sm mb-6 ">
              If you delete this template,it would be removed from your list.
              Are you sure you want to continue?
            </p>
          </div>
          <div className="border py-5 px-6 flex gap-4 justify-end ">
            <Button
              title="cancel"
              color="secondary"
              onClick={() => {}}
            >
              Cancel
            </Button>

            <Button
              title="remove"
              color="primary"
              submitting={isSubmittingDelete}
              onClick={() => {
                handleDelete()
              }}
            >
              Confirm
            </Button>
          </div>
        </NewModalWrapper>
      </div>
    </DashboardContainer>
  )
}
export default Templates
