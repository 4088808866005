/**
 * An optimized textarea input.
 *
 * @param props
 * @returns textarea
 */
export const TextareaInput: React.FC<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = (props) => {
  return (
    <textarea
      rows={2}
      {...props}
      className={`rounded-[5px] text-sm w-full border border-neutral50  bg-white px-5 py-3 text-neutral600 focus:outline-0  placeholder:text-[#A6AEBB] ${props.className}`}
    />
  )
}
