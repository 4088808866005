import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { toast as reactToastify } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export interface Toast {
  id: string
  message: JSX.Element | string
  color?: string
  autohide?: number | boolean
}

export interface Confirm {
  title: JSX.Element | string
  message: JSX.Element | string
  context: "success" | "error" | "warn"
  action: string
  onConfirm?: () => Promise<any>
  onCancel?: () => Promise<any>
}

interface AppNotificationState {
  context: string
  isLoading?: boolean
  toastList: Toast[]
  confirmProps?: {
    title: Confirm["title"]
    message: Confirm["message"]
    context: Confirm["context"]
    action: Confirm["action"]
    onConfirm?: Confirm["onConfirm"]
    onCancel?: Confirm["onCancel"]
  }
}

interface AppNotificationMethods {
  setContext: (state: string) => void
  loading: (state: boolean) => void
  toastMachine: (
    message: Toast["message"],
    color: Toast["color"],
    autohide: Toast["autohide"]
  ) => void
  toast: {
    success: (message: Toast["message"], autohide?: Toast["autohide"]) => void
    error: (message: Toast["message"], autohide?: Toast["autohide"]) => void
    warn: (message: Toast["message"], autohide?: Toast["autohide"]) => void
    unauthorized: (
      message?: Toast["message"],
      autohide?: Toast["autohide"]
    ) => void
  }
  removeToast: (toast: Toast) => void
  toastId: () => string
  confirmMachine: (
    title: Confirm["title"],
    message: Confirm["message"],
    context: Confirm["context"],
    action: Confirm["action"],
    onConfirm?: Confirm["onConfirm"],
    onCancel?: Confirm["onCancel"]
  ) => void
  confirm: {
    success: (
      title: Confirm["title"],
      message: Confirm["message"],
      action: Confirm["action"],
      onConfirm?: Confirm["onConfirm"],
      onCancel?: Confirm["onCancel"]
    ) => void
    error: (
      title: Confirm["title"],
      message: Confirm["message"],
      action: Confirm["action"],
      onConfirm?: Confirm["onConfirm"],
      onCancel?: Confirm["onCancel"]
    ) => void
    warn: (
      title: Confirm["title"],
      message: Confirm["message"],
      action: Confirm["action"],
      onConfirm?: Confirm["onConfirm"],
      onCancel?: Confirm["onCancel"]
    ) => void
    unauthorized: (
      title: Confirm["title"],
      message: Confirm["message"],
      action: Confirm["action"],
      onConfirm?: Confirm["onConfirm"],
      onCancel?: Confirm["onCancel"]
    ) => void
  }
  closeConfirm: () => void
}

export const useAppNotificationStore = create<
  AppNotificationState & AppNotificationMethods
>()(
  devtools((set, get) => ({
    context: "",
    isLoading: false,
    setContext: (context) => {
      set({ context })
    },
    loading: (state) => {
      set({
        isLoading: state,
      })
    },
    toastList: [],
    toastId: () => {
      return `toast_${Math.floor(Math.random() * 99999)}`
    },
    removeToast(removedToast) {
      set({
        toastList: get().toastList.filter(
          (toast) => removedToast.id !== toast.id
        ),
      })
    },
    toast: {
      success: (message, autohide) =>
        get().toastMachine(message, "success", autohide!),
      error: (message, autohide) =>
        get().toastMachine(message, "error", autohide!),
      warn: (message, autohide) =>
        get().toastMachine(message, "warn", autohide!),
      unauthorized: (
        message = "You are not authorized to perform this action",
        autohide = true
      ) => get().toastMachine(message, "error", autohide),
    },
    toastMachine: (message, color, autohide = true) => {
      let toastFunction
      switch (color) {
        case "success":
          toastFunction = reactToastify.success
          break
        case "error":
          toastFunction = reactToastify.error
          break
        case "warn":
          toastFunction = reactToastify.warn
          break
        default:
          toastFunction = reactToastify
      }

      toastFunction(message, {
        autoClose:
          typeof autohide === "number"
            ? autohide * 1000
            : autohide
            ? 10000
            : false,
        position: reactToastify.POSITION.TOP_RIGHT,
      })
    },
    confirmProps: undefined,
    confirm: {
      success: (title, message, action, onConfirm?, onCancel?) =>
        get().confirmMachine(
          title,
          message,
          "success",
          action,
          onConfirm,
          onCancel
        ),
      error: (title, message, action, onConfirm?, onCancel?) =>
        get().confirmMachine(
          title,
          message,
          "error",
          action,
          onConfirm,
          onCancel
        ),
      warn: (title, message, action, onConfirm?, onCancel?) =>
        get().confirmMachine(
          title,
          message,
          "warn",
          action,
          onConfirm,
          onCancel
        ),
      unauthorized: (title, message, action, onConfirm?, onCancel?) =>
        get().confirmMachine(
          title,
          message,
          "error",
          action,
          onConfirm,
          onCancel
        ),
    },
    confirmMachine: (title, message, context, action, onConfirm, onCancel) => {
      set({
        confirmProps: {
          context: context,
          title,
          message,
          action,
          onConfirm:
            onConfirm === undefined
              ? async () => get().closeConfirm()
              : onConfirm,
          onCancel: onCancel === undefined ? async () => {} : onCancel,
        },
      })
    },
    closeConfirm: () => {
      set({
        confirmProps: undefined,
      })
    },
  }))
)
