import { Form } from "antd"
import { Button, DashboardContainer, H5, P2 } from "components"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CustomInput, CustomSelect } from "../custom-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAppNotificationStore, useAuthStore } from "stores"
import { usePost } from "utils/useFetch"
import { IState, ICountry } from "pages/dashboard/people/people/typings"
import { axiosInstance } from "utils/request"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const reqBody = {
  q: "",
  page: "",
}

const AddWorkplaceForm = () => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const [alpha2Code, setAlpha2Code] = useState<string>("NG")
  const [stateCode, setStateCode] = useState<string>("")
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const redirectToOnboarding =
    searchParams.get("redirect") === "onboarding-workplaces"

  const { isLoading: isCountriesLoading, data: countries } = usePost<
    ICountry[]
  >({
    url: "/commonutils/getcountries",
  })
  const {
    isLoading: isStatesLoading,
    data: states,
    refetch,
  } = usePost<IState[]>({
    url: `/commonutils/getstatesbycountrycode?id=${alpha2Code}`,
    enabled: !!alpha2Code,
  })

  const {
    isLoading: isTaxOfficesLoading,
    data: taxOffices,
    refetch: refetchTaxOffices,
  } = usePost<
    {
      description: string
      code: string
    }[]
  >({
    url: `/admin/company/gettaxofficesbystatecode?id=${stateCode}`,
    enabled: !!stateCode,
  })

  const {
    isLoading: isHousingLocationsLoading,
    data: housingLocations,
    refetch: refetchHousingLocationsOffices,
  } = usePost<
    {
      description: string
      code: string
    }[]
  >({
    url: `/admin/company/gethousinglocationsbystatecode?id=${stateCode}`,
    enabled: !!stateCode,
  })

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/add_officeaddress`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["admin/company/view/details"],
        })
        queryClient.invalidateQueries({
          queryKey: ["/admin/company/getofficeaddresses?canAdd=true"],
        })

        if (redirectToOnboarding) {
          navigate("/dashboard/company-onboarding")
          queryClient.invalidateQueries({
            queryKey: ["/account/getcompanysetupinfo"],
          })
        } else {
          navigate("/dashboard/company-details/manage-workplace")
        }

        mixPanelEvent("company-add-workplace-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const onFinish = (values: any) => {
    const { addressLine1, addressLine2, city, zip, phone, displayName } = values
    const item = states?.find((state) => state.description === values.state)
    const ctry = countries?.find(
      (country) => country.description === values.country
    )
    const taxOffice = taxOffices?.find(
      (taxOffice) => taxOffice.description === values.taxOffice
    )

    const nhf = housingLocations?.find(
      (housingLocation) =>
        housingLocation.description === values.housingLocation
    )

    submit({
      office_address_line_1: addressLine1,
      office_address_line_2: addressLine2 || "",
      office_address_city: city || "",
      office_address_state_code: item?.code || "",
      office_address_zip_code: zip || "",
      office_address_country_code: ctry?.alpha2Code || "",
      office_address_phone: phone || "",
      display_name: displayName,
      tax_office_name: taxOffice?.description || "",
      tax_office_code: taxOffice?.code || "",
      housing_location_name: nhf?.description || "",
      housing_location_code: nhf?.code || "",
    })
    mixPanelEvent("company-add-workplace-init")
  }

  return (
    <DashboardContainer>
      <div className="bg-white max-w-[978px] py-6 border-[1px] rounded-[5px] border-[rgba(108, 122, 147, 0.15)]">
        <div className="px-4 lg:px-9 pb-[14px] border-b border-neutral50">
          <H5 className="text-neutral700 font-medium">Add workplace</H5>

          <P2 className="text-neutral300 mt-1">
            To automate your payroll filings, we need to have your company’s
            accurate addresses. Please take a minute and enter them below.
          </P2>
        </div>

        <Form
          name="addWorkspace"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="px-4 lg:px-9 pt-[46px] pb-[50px] border-neutral50 border-b bg-white">
            <div className="flex flex-col lg:flex-row lg:gap-10 ">
              <div className="w-full lg:w-1/2">
                <CustomInput
                  label="Address line 1"
                  name="addressLine1"
                  required
                  maxLength={50}
                />
                <CustomInput
                  label="Address line 2"
                  name="addressLine2"
                  maxLength={50}
                />
                <CustomInput
                  label="City"
                  name="city"
                />
                <CustomSelect
                  label="Country"
                  name="country"
                  required
                  onChange={(selected) => {
                    const country = countries?.find(
                      (country) => country.description === selected
                    )
                    setAlpha2Code(country?.alpha2Code || "")
                    setTimeout(() => {
                      form.setFieldsValue({
                        state: "",
                        taxOffice: "",
                        housingLocation: "",
                      })
                      refetch()
                    }, 100)
                  }}
                  isLoading={isCountriesLoading}
                  options={
                    isCountriesLoading
                      ? []
                      : countries?.map((state) => ({
                          label: state.description,
                          value: state.description,
                        }))
                  }
                  placeholder="Please select"
                />
                {/* ---- States ---- */}
                <CustomSelect
                  label="State/Province"
                  name="state"
                  required
                  onChange={(selected) => {
                    const state = states?.find(
                      (state) => state.description === selected
                    )
                    setStateCode(state?.code || "")
                    setTimeout(() => {
                      form.setFieldsValue({
                        taxOffice: "",
                        housingLocation: "",
                      })
                      refetchTaxOffices()
                      refetchHousingLocationsOffices()
                    }, 100)
                  }}
                  isLoading={isStatesLoading}
                  options={
                    isStatesLoading
                      ? []
                      : states?.map((state) => ({
                          label: state.description,
                          value: state.description,
                        }))
                  }
                  placeholder="Please select"
                />
              </div>

              <div className="w-full lg:w-1/2">
                {/*  */}
                <CustomInput
                  label="Zip Code"
                  name="zip"
                />
                <CustomInput
                  label="Phone"
                  name="phone"
                  required
                  maxLength={15}
                />
                <CustomInput
                  label="Display Name"
                  name="displayName"
                  required
                />
                {/* ---- Tax Office ---- */}
                <CustomSelect
                  label="Tax Office"
                  name="taxOffice"
                  isLoading={isTaxOfficesLoading}
                  options={
                    isTaxOfficesLoading
                      ? []
                      : taxOffices?.map((state) => ({
                          label: state.description,
                          value: state.description,
                        }))
                  }
                  placeholder="Please select"
                />
                <CustomSelect
                  label="NHF Location"
                  name="housingLocation"
                  isLoading={isHousingLocationsLoading}
                  options={
                    isHousingLocationsLoading
                      ? []
                      : housingLocations?.map((state) => ({
                          label: state.description,
                          value: state.description,
                        }))
                  }
                  placeholder="Please select"
                />
              </div>
            </div>
          </div>

          <div className="flex gap-4 justify-end px-9 py-4">
            <Button
              color="secondary"
              onClick={() => {
                navigate("/dashboard/company-details/manage-workplace")
              }}
            >
              Cancel
            </Button>
            <Form.Item className="mb-0">
              <Button
                type="submit"
                disabled={isSubmitting}
                submitting={isSubmitting}
                color="primary"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </DashboardContainer>
  )
}

export default AddWorkplaceForm
