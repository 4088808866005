import React from "react";

import { formatCurrencyNoSymbol } from "utils/currency";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

import { useGet } from "utils/useFetch";
import { newBaseUrl } from "utils/newbaseurl";
import { formatDateReadable, formatTimeReadble } from "utils/formatDate";
import { Dropdown, Spin, Table } from "antd";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper";
import EWANotEnabled from "./EWANotEnabled";

const Invoice: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { isLoading: isInvoicesLoading, data: invoiceData } = useGet<any>({
    url: `${newBaseUrl}/v1/employer/invoices`,
    external: true,
  })
  const TableData =
    invoiceData?.data?.docs?.map((transaction: any, index: any) => {
      return {
        key: index,
        date: {
          date: formatDateReadable(String(transaction?.createdAt)),
          time: formatTimeReadble(String(transaction?.createdAt)),
        },

        amount: `₦ ${formatCurrencyNoSymbol(Number(transaction.amount))}`,
        paid: `₦ ${formatCurrencyNoSymbol(Number(transaction.amount_paid))}`,
        status: {
          id: transaction.status,
          title: transaction.status,
        },
        action: {
          id: transaction._id,
        },
      }
    }) || []
  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },

      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {amount}{" "}
          </span>
        ),
      },

      {
        title: "Status",
        dataIndex: "status",
        render: (status: { id: string; title: any; method: string }) => (
          <span
            className={`text-sm font-medium py-1 block text-center w-[80px] rounded-[4px]

                    ${
                      status?.title == "PAID"
                        ? "bg-success100 text-success800"
                        : ""
                    }

                    ${status?.title == "UNPAID" ? "bg-neutral40 " : ""}

                    `}
          >
            {status?.title == "PAID" ? "Paid" : "Pending"}
          </span>
        ),
      },
      {
        title: " ",
        dataIndex: "action",
        render: (action: { id: string }) => (
          // <div className={`items-center flex gap-[35px] `}>
          //   <img
          //     alt="print"
          //     src={print}
          //     className="h-8 w-8 cursor-pointer"
          //   />
          //   <img
          //     alt="download"
          //     src={download}
          //     className="h-8 cursor-pointer w-8"
          //   />
          // </div>
          <Dropdown
            menu={{
              items: actionItems(action),
            }}
          >
            <button>
              <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
            </button>
          </Dropdown>
        ),
      },
    ] || []
  const actionItems = (action: { id: string }) => {
    const { id } = action
    let arrayOptions: any = []
    {
      id &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "1",
            label: (
              <span
                onClick={() => navigate(`/dashboard/ewa/invoice/view?x=${id}`)}
              >
                View
              </span>
            ),
          },
        ])
    }

    return arrayOptions
  }
  return (
    <DashboardWrapper>
      {isInvoicesLoading ? (
        <div className="flex justify-center w-full h-screen items-center">
          <Spin />
        </div>
      ) : invoiceData ? (
        <div className="flex flex-col gap-5">
          <h5 className="text-lg font-bold text-neutral700 ">Invoice</h5>

          <div
            className="bg-white rounded-5px"
            id="ewa-invoice-listing"
          >
            <Table
              columns={TableColumns}
              dataSource={TableData}
              loading={isInvoicesLoading}
              className="text-[#42526D]"
              pagination={{
                defaultPageSize: 20,
              }}
              scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
            />
          </div>
        </div>
      ) : (
        <EWANotEnabled />
      )}
    </DashboardWrapper>
  )
}
export default Invoice
