import { useQuery } from "@tanstack/react-query"
import { Spin } from "antd"
import AuthModal from "AuthModal"
import axios from "axios"
// import Splashscreen from "Splashscreen"

import { Helmet } from "react-helmet"
import { useAppNotificationStore } from "stores"
import {
  CustomizationSettings,
  useCustomizationSettingsStore,
} from "stores/customization-settings"
import { customizationSettingsBaserUrl } from "utils/constants"
import getAccessibleColor from "utils/getAccessibleColor"
import getRGBColor from "utils/getRGBColor"
import { isPadeDomain } from "utils/utils"

interface Data {
  customization: CustomizationSettings
}
interface IRes {
  data: Data
  success: boolean
}

const AppEntryPoint = ({ children }: { children: React.ReactNode }) => {
  const { setCustomizationSettings, customizationSettings } =
    useCustomizationSettingsStore()
  const { toast } = useAppNotificationStore()
  // const { splashScreen } = useAuthStore()

  const { isFetching, data } = useQuery<IRes>({
    queryKey: ["customization-settings"],
    queryFn: async () => {
      // const domain = "payroll.egyptafrica.com"
      const domain = window?.location?.hostname
      const res = await axios.get(
        customizationSettingsBaserUrl + "/v1/integration/" + domain
      )

      return res.data
    },
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: isPadeDomain() === false,
    onSuccess: (data) => {
      if (data?.success) {
        localStorage.removeItem("customizationSettings")
        setCustomizationSettings(data?.data?.customization)
      } else {
        // toast.error(data?.message)
      }
    },
  })

  if (isFetching) {
    return (
      <div
        className="fixed inset-0 flex justify-center items-center bg-white"
        style={{
          zIndex: 9999999,
        }}
      >
        <Spin size="large" />
      </div>
    )
  }

  const primaryColor = getRGBColor(
    customizationSettings.brand.colorScheme,
    "primary"
  )
  const a11yColor = getRGBColor(
    getAccessibleColor(customizationSettings.brand.colorScheme),
    "a11y"
  )

  return (
    <>
      <Helmet>
        <title>
          {isPadeDomain() === true
            ? "PaidHR - The People Platform"
            : customizationSettings?.brand?.documentTitle}
        </title>
        <meta
          name="description"
          content={customizationSettings?.brand?.documentDescription}
        />
        {customizationSettings?.brand?.faviconUrl ? (
          <link
            rel="icon"
            href={customizationSettings?.brand?.faviconUrl}
          />
        ) : null}
        <style>
          {`
          :root {
             ${primaryColor};
              ${a11yColor};
          }
        `}
        </style>
      </Helmet>

      {children}
      <AuthModal />

      {/* {splashScreen && <Splashscreen />} */}
    </>
  )
}

export default AppEntryPoint
