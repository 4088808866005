import { ICountry, IState } from "pages/dashboard/people/people/typings"
import { usePost } from "utils/useFetch"

export const useGetCountries = () => {
  const {
    data: countries = [],
    isLoading,
    isFetching,
    refetch,
  } = usePost<ICountry[]>({
    url: `/commonutils/getcountries`,
    body: {
      q: "",
      page: "",
    },
  })

  return { countries, isLoading, isFetching, refetch }
}
export const useGetStates = ({
  countryCode = "NG",
}: {
  countryCode: string
}) => {
  const {
    data: states = [],
    isLoading,
    isFetching,
    refetch,
  } = usePost<IState[]>({
    url: `/commonutils/getstatesbycountrycode?id=${countryCode}`,
    enabled: !!countryCode,
    body: {
      q: "",
      page: "",
    },
  })

  return { states, isLoading, isFetching, refetch }
}
