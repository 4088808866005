import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"

type Props = {
  url: string
  body?: any
  [key: string]: any
}

export function useGet<T>({ url, body, ...rest }: Props) {
  const { isLoading, data, isError, refetch, isFetching, isPreviousData } =
    useQuery<T>({
      queryKey: [url],
      queryFn: async () => {
        const res = await axiosInstance.get(url, {
          ...body,
        })

        return res.data
      },
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000, // 1 day in milliseconds
      ...rest,
    })

  return { isLoading, data, isError, refetch, isFetching, isPreviousData }
}

export function usePost<T>({ url, body, ...rest }: Props) {
  const { isLoading, data, isError, refetch, isFetching } = useQuery<T>({
    queryKey: [url],
    queryFn: async () => {
      const res = await axiosInstance.post(url, {
        ...body,
      })

      return res.data
    },
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000, // 1 day in milliseconds
    keepPreviousData: true,
    ...rest,
  })

  return { isLoading, data, isError, refetch, isFetching }
}
