import { Button } from "components/buttons/Button"
import { useState } from "react"
import CustomModal from "./modals/CustomModal"
import { Link } from "react-router-dom"
import { H2 } from "components/typography/H2"

interface Props {
  url: string
  btnClassName?: string
  documentName?: string
}

const getFileFormat = (url: string) => {
  const extension = url.split(".").pop()
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "image"
    case "pdf":
      return "pdf"
    case "docx":
    case "xlsx":
      return "office"
    default:
      return "unsupported"
  }
}

const AllDocPreviewer = ({ url, btnClassName, documentName }: Props) => {
  const [showPreview, setShowPreview] = useState(false)

  const fileType = getFileFormat(url)
  const fileName = url.split("/").pop()

  return (
    <div>
      <CustomModal
        destroyOnClose
        open={showPreview}
        onCancel={() => {
          setShowPreview(false)
        }}
        title={documentName ? `Preview ${documentName}` : "Preview Document"}
        footer={
          <div className="flex justify-between items-center gap-4">
            <Link
              to={url}
              target="_blank"
              className="text-sm !text-primary500 font-circular"
            >
              Download Document
            </Link>
            <Button
              color="neutral"
              onClick={() => {
                setShowPreview(false)
              }}
            >
              Close
            </Button>
          </div>
        }
        width={fileType === "image" ? 600 : 800}
      >
        <div className="max-h-[800px] overflow-auto">
          <H2 className="text-padeBlack font-circular text-base">{fileName}</H2>
          <IframePreview
            url={url}
            fileType={fileType}
          />
        </div>
      </CustomModal>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault()
          setShowPreview(true)
        }}
        className={`${btnClassName} text-sm text-primary500 font-circular`}
      >
        {documentName ?? "Preview Document"}
      </button>
    </div>
  )
}

export default AllDocPreviewer

export const IframePreview = ({
  url,
  fileType,
}: {
  url: string
  fileType: string
}) => {
  const renderIframe = (src: string) => (
    <iframe
      src={src}
      title="file preview"
      width="100%"
      height="600px"
      style={{ border: "none" }}
    />
  )

  switch (fileType) {
    case "image":
      return (
        <img
          src={url}
          alt="Preview"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      )
    case "pdf":
      return renderIframe(url)
    case "office":
      return renderIframe(
        `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          url
        )}`
      )
    default:
      return <p>Unsupported file type</p>
  }
}
