import { useState } from "react"
import { Button, Label, RadioInput } from "components"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import SingleSchedule from "./sendMoney/SingleSchedule"
import MultipleSchedule from "./sendMoney/MultipleSchedule"

import { useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import { formatCurrencyNoSymbol } from "utils/currency"
import { Checkbox, Form } from "antd"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { availableFlags } from "../sampleData"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import SuccessModal from "../modals/SuccessModal"
import { usePost } from "utils/useFetch"
import { ICurrencies } from "../../integration/interface/kyc"

const CreateCurrencySchedule = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const location = useLocation()

  const myParam = useLocation().search
  const { currencyCode } = location.state || {}

  const x = new URLSearchParams(myParam).get("x")
  const { id } = useParams()

  const [transferType, setTransferType] = useState("Single")

  const [active, setActive] = useState(0)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [previewData, setPreviewData] = useState<any[]>([
    {
      beneficiary: "",
      beneficiary_id: "",
      beneficiary_type: "", //Individual or company
      account_number: "",
      account_name: "",
      amount: "0",
      role: "employee", //employee or contractor
      description: "",
      routing_type: "",
      routing_code: "",
    },
  ])

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        "/admin/payment_schedules/create_custom_payment_schedule",
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        data.msg && toast.success(data.msg)
        setShowSuccessModal(true)
        mixPanelEvent(`create-${currencyCode}-custom-schedule-success`)
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })

  const handleCreateSchedule = () => {
    const body = previewData.map((item, index) => {
      const baseData = {
        description: item?.description,
        amount: String(item?.amount),
        au_comment: "",
        state: "",
      }

      if (item?.role === "employee") {
        return {
          ...baseData,
          person_id: String(item?.beneficiary_id),
        }
      } else if (item?.role === "contractor") {
        return {
          ...baseData,
          contractor_id: String(item?.beneficiary_id),
        }
      }

      return baseData
    })
    mixPanelEvent(`create-${currencyCode}-custom-schedule-init`)
    submit(body)
  }
  const { isLoading: isCurrencyLoading, data: currencies } = usePost<
    ICurrencies[]
  >({
    url: "/commonutils/getcurrencies",
    body: {
      q: "",
      page: "",
    },
  })

  const currencyInfo = currencies?.find((flag) => flag.code === currencyCode)
  return (
    <DashboardWrapper>
      <div className=" flex flex-col items-start justify-start   gap-6 max-w-[800px] ">
        <div className=" h-full  w-full  ">
          <div className="w-full ">
            <SegmentWrapper
              title="Send Money "
              headerClassName=" xl:!px-[40px]"
              bodyClassName="!pb-0"
              isActive={active === 0 ? true : false}
              actionBtn={
                <div className="gap-[11px] flex items-center ">
                  <img
                    src={currencyInfo?.flagUrl}
                    alt=""
                    className=" h-[17px] w-[17px]"
                  />
                  <h2 className=" text-[#75829A] font-bold font-avenir  text-sm  ">
                    {currencyCode} Account
                  </h2>
                </div>
              }
            >
              <div className="w-full   bg-white  rounded-md ">
                <div className="px-4 md:px-6 lg:px-8 xl:px-[40px] ">
                  <Label
                    required="true"
                    className="font-circular text-sm text-padeBlack"
                  >
                    Choose type of transfer
                  </Label>
                  <div className="mt-2 flex items-center gap-10">
                    <label className="flex items-center justify-center">
                      <RadioInput
                        name="transfer-type"
                        onChange={() => {
                          setTransferType("Single")
                          setPreviewData([
                            {
                              beneficiary: "",
                              bank_name: "",
                              account_number: "",
                              amount: "",
                            },
                          ])
                        }}
                        checked={transferType == "Single" ? true : false}
                      />
                      <span className="ml-2 text-sm text-padeBlack">
                        Single
                      </span>
                    </label>
                    <label className="flex items-center justify-center">
                      <RadioInput
                        name="transfer-type"
                        onChange={() => {
                          setTransferType("Multiple")
                          setPreviewData([
                            {
                              beneficiary: "",
                              bank_name: "",
                              account_number: "",
                              amount: "",
                            },
                          ])
                        }}
                        checked={transferType == "Multiple" ? true : false}
                      />
                      <span className="ml-2 text-sm text-padeBlack">
                        Multiple
                      </span>
                    </label>
                  </div>
                </div>
                {transferType == "Single" ? (
                  <SingleSchedule
                    setPreviewData={setPreviewData}
                    found={currencyInfo}
                    handleProceed={() => {
                      setActive(1)
                    }}
                    currencyCode={currencyCode}
                  />
                ) : (
                  <MultipleSchedule
                    setPreviewData={setPreviewData}
                    handleProceed={() => {
                      setActive(1)
                    }}
                    found={currencyInfo}
                    currencyCode={currencyCode}
                  />
                )}
              </div>
            </SegmentWrapper>
          </div>
        </div>
        <div className="h-fit w-full  ">
          {" "}
          <FormSectionWrapper
            title="Confirm Transaction"
            headerClassName=" xl:!px-[40px]"
            isActive={active === 1 ? true : false}
            footerBtns={
              <div className="flex justify-end w-full gap-6 bg-white  items-center">
                <Button
                  title="Previous"
                  color="secondary"
                  onClick={() => setActive(0)}
                  type="button"
                >
                  Previous
                </Button>

                <Button
                  title="Next"
                  color="primary"
                  submitting={isSubmitting}
                  onClick={handleCreateSchedule}
                >
                  Proceed
                </Button>
              </div>
            }
          >
            {previewData.length > 0 ? (
              <div className="shadow-sm w-full  bg-white px-[10px] xl:!px-[40px]  rounded h-fit">
                {previewData.length > 0 && (
                  <div className="pb-[23px]">
                    <div className=" max-h-[300px] overflow-y-scroll scrollbar-hide ">
                      {previewData.map((item: any, index: any) => (
                        <div
                          key={index}
                          className={`space-y-4 ${
                            index !== previewData?.length - 1
                              ? "border-b border-[#C2C7D0] mb-4 pb-4"
                              : ""
                          }`}
                        >
                          <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              Beneficiary {index + 1}
                            </span>
                            <span className=" text-[#243757] font-bold text-sm truncate">
                              {item.beneficiary || "-"}
                            </span>
                          </div>
                          {currencyCode !== "NGN" && (
                            <div className="flex w-full justify-between items-center gap-4">
                              <span className="text-[#6C7A93] font-circular  text-sm">
                                Routing type
                              </span>
                              <span className="text-[#243757] font-bold text-sm">
                                {item.routing_type}({item?.routing_code})
                              </span>
                            </div>
                          )}
                          {currencyCode !== "NGN" && (
                            <div className="flex w-full justify-between items-center gap-4">
                              <span className="text-[#6C7A93] font-circular  text-sm">
                                Beneficiary type
                              </span>
                              <span className="text-[#243757] font-bold text-sm">
                                {item.beneficiary_type || "-"}
                              </span>
                            </div>
                          )}

                          <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              Account name
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.account_name || "-"}
                            </span>
                          </div>

                          <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              Account number
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.account_number || "-"}
                            </span>
                          </div>
                          <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              Amount
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {currencyInfo?.symbol}
                              {formatCurrencyNoSymbol(Number(item.amount || 0))}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="flex items-center gap-3 mt-2 ">
                  <Form.Item
                    name="scheduleConfirmation"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: "You must confirm the payment details above.",
                      },
                    ]}
                  >
                    <Checkbox className="text-sm text-[#5D6B82]">
                      I confirm the payment details above
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>
            ) : null}
          </FormSectionWrapper>
        </div>
        <SuccessModal
          title="Custom Schedule created successfully"
          subtitle={``}
          onClose={() => {
            setShowSuccessModal(false)
            navigate(-1)
          }}
          open={showSuccessModal}
          ctaButton={
            <div>
              <Button
                color="primary"
                onClick={() => {
                  setShowSuccessModal(false)
                  navigate("/dashboard/payment-schedules/custom")
                }}
              >
                View
              </Button>{" "}
            </div>
          }
        />
      </div>
    </DashboardWrapper>
  )
}

export default CreateCurrencySchedule
