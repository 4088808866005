import React from "react"
import { useAuthStore } from "stores"
import { useNavigate } from "react-router-dom"
import MoveFundsComp from "pages/dashboard/wallets/crossBorderPayments/movefunds/MoveFundsComp"

const MoveFundsEmp = () => {
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId || ""
  return <MoveFundsComp walletId={EmployeeWalletID} />
}

export default MoveFundsEmp
