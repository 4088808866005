import React, { forwardRef } from "react"
import PayslipTemplate from "./PayslipTemplate"

const ComponentToPrint = forwardRef(({ data }: any, ref: any) => {
  return (
    <div
      ref={ref}
      className=" pb-40"
      // id="report"
      // id="payslip"
    >
      <PayslipTemplate data={data} />
    </div>
  )
})

export default ComponentToPrint
