import { formatCurrencyNoSymbol } from "utils/currency"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { Withdrawal } from "../interface/Invoice"
import { Table } from "antd"

const WithdrawalRow: React.FC<{
  groupPersonWithdrawals: any
}> = ({ groupPersonWithdrawals }) => {
  const TableData =
    groupPersonWithdrawals.map((withdrawal: any) => {
      return {
        key: withdrawal?.employeeId,
        employee: `${withdrawal?.firstName} ${withdrawal?.lastName}`,
        email: withdrawal?.email,
        amount_aggregated: `₦${formatCurrencyNoSymbol(
          Number(withdrawal?.totalAmountRequested)
        )}`,
        // amount_withdrawn:`₦ ${formatCurrencyNoSymbol(Number(withdrawal.amountToPayBack))}`,
        fee: `₦ ${formatCurrencyNoSymbol(Number(withdrawal.fee))}`,
      }
    }) || []
  const TableColumns =
    [
      {
        title: "Employee",
        dataIndex: "employee",
        render: (employee: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {employee}{" "}
          </span>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        render: (email: string) => (
          <span className={` font-avenir text-sm text-[#42526D]`}>{email}</span>
        ),
      },
      {
        title: "Payback Amount",
        dataIndex: "amount_aggregated",
        render: (amount_aggregated: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {amount_aggregated}{" "}
          </span>
        ),
      },
    ] || []
  return (
    <>
      <Table
        columns={TableColumns}
        dataSource={TableData}
        // loading={isAdminLoading}
        className="text-[#42526D]"
        pagination={false}
        scroll={{
          y: "calc(100vh - 300px)",
          x: "max-content",
        }}
      />
    </>
  )
}
export default WithdrawalRow
