import { useMutation } from "@tanstack/react-query"
import { Form, Input, Spin, Switch } from "antd"
import { Button, DashboardContainer, ModalWrapper } from "components"
import { useGet } from "utils/useFetch"
import { LoadingIndicator } from "components/custom/Loader"
import { ProfileTemp } from "pages/dashboard/profile/component/ProfileTemp"
import ImageComponent from "components/custom/image"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore } from "stores"
import { useProfileStore } from "stores/profile"
import { axiosInstance } from "utils/request"
import EditPersonalDetails from "./component/EditPersonalDetails"

import { VerifyPasswordPrompt } from "pages/2fa/modals/VerifyPasswordPrompt"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useCustomizationSettingsStore } from "stores/customization-settings"

const Profile: React.FC<{}> = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { signOut, user } = useAuthStore()
  const [profile, setProfile] = useState(user)
  const { getProfile, isLoading } = useProfileStore()
  const { toast } = useAppNotificationStore()
  const [showEditPassword, setShowEditPassword] = useState(false)
  const [showEditPersonalDetails, setShowEditPersonalDetails] = useState(false)
  const [twoFaToggle, setTwoFaToggle] = useState(false)
  const { customizationSettings } = useCustomizationSettingsStore()

  const { isLoading: isProcessingPayment, mutate: process } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/settings/change_password`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      // invalidateQueries()

      if (data.status !== "error") {
        navigate("/auth/login")
        signOut()
      } else {
        // setShowEditPassword(false)

        setTimeout(() => toast.error(data?.msg || ""), 100)
      }
    },
    onError: (data: any) => {
      // setShowEditPassword(false)

      setTimeout(() => toast.error(data?.msg || ""), 100)
    },
  })
  const { isFetching: isLoadingPersonData, data: PersonData } = useGet<any>({
    url: "/admin/settings/view/index",
  })

  const { data: twoFaStatus, isLoading: gettingStatus } = useGet<any>({
    url: `/account/2fa/details`,
  })

  const onFinish = (values: any) => {
    process({
      oldPassword: values.current_password,
      newPassword: values.new_password,
    })
  }

  return (
    <>
      <DashboardWrapper>
        <div className=" pb-5 w-full">
          <div className="w-full flex justify-between">
            <h6 className="text-xl text-neutral500 font-bold mb-2">
              Account Preferences
            </h6>
          </div>
          <div className="flex flex-col h-full">
            <div className="lg:w-[80%] w-full bg-white h-auto border border-neutral30 relative  py-7 px-8">
              <div className=" flex items-center w-full justify-between mb-[50px]">
                <div className="flex items-center">
                  <>
                    <ImageComponent
                      src={
                        user?.userAvatarFilename ||
                        "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
                      }
                      alt="user"
                      className=" bg-iris80 h-[72px] w-[72px] rounded-full flex   justify-center items-center text-white text-[40px]  font-bold"
                    />
                  </>

                  <div className=" ml-3">
                    <h6 className=" text-base font-bold text-neutral500 mb-1">
                      {profile?.userFullName}
                    </h6>
                  </div>
                </div>
              </div>
              <div className=" mb-[50px]">
                <h6 className="mb-2  text-neutral300 text-sm">
                  Personal Details
                </h6>
                <div className="border   border-neutral50 w-full mb-4"></div>
                <ProfileTemp
                  title="Email"
                  subtitle="Your registered email address"
                  value={profile?.userName as string}
                />
                <ProfileTemp
                  title="Phone number"
                  subtitle="Your registered phone number"
                  value={profile?.userPhone as string}
                />
                <ProfileTemp
                  title="Birth  Date"
                  value={PersonData?.date_of_birth}
                />
                <div className="flex w-full justify-between">
                  <div className=" w-5/12"></div>
                  <div className=" w-5/12">
                    <Button
                      title="Previous"
                      color="secondary"
                      // disabled={currentTab === 1}
                      onClick={() => setShowEditPersonalDetails(true)}
                      className=" font-bold"
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </div>
              <div className="">
                <h6 className="mb-2  text-neutral300 text-sm">Security</h6>
                <div className="border   border-neutral50 w-full mb-4"></div>
                <ProfileTemp
                  title="Password"
                  value="*************"
                />
                <div className="flex w-full justify-between">
                  <div className=" w-5/12"></div>
                  <div className=" w-5/12">
                    <Button
                      title="Previous"
                      color="secondary"
                      // disabled={currentTab === 1}
                      className=" font-bold"
                      onClick={() => {
                        setShowEditPassword(true)
                      }}
                    >
                      Change Password
                    </Button>
                  </div>
                </div>

                <div className=" flex items-center  justify-between mt-8">
                  <div className=" w-5/12">
                    <h6 className=" text-sm font-bold text-neutral500 ">
                      Two- Factor Authentication
                    </h6>
                    <p className=" text-neutral100 mt-1 font-normal text-sm  ">
                      Adds an extra layer of security to your{" "}
                      {customizationSettings?.brand.platformName} to protect you
                      from unauthorised access and transactions.
                    </p>
                  </div>
                  <div className=" w-5/12 flex justify-start">
                    <p className=" text-neutral300 text-sm font-avenir">
                      <Switch
                        className={
                          twoFaToggle || twoFaStatus?.twoFactorEnabled
                            ? "!bg-primary500"
                            : "!bg-[#ededed] "
                        }
                        checked={twoFaStatus?.twoFactorEnabled}
                        onChange={(checked) => {
                          setTwoFaToggle(true)
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              {isLoading && (
                <div className="z-[2] flex  items-center justify-center py-10 bg-white bg-opacity-75  bottom-0   absolute left-0 top-0 right-0  ">
                  <div>
                    <LoadingIndicator />
                  </div>
                </div>
              )}
            </div>
          </div>
          {showEditPassword && (
            <ModalWrapper
              title="Change your password"
              className="w-[550px]"
              onClose={() => {
                setShowEditPassword(false)
              }}
            >
              <div className="border-t flex flex-col p-5 ">
                <div className="w-full border border-l-8 mb-4 border-l-[#F39F03] bg-[#FFF6E6] px-4 py-2 flex flex-col justify-center items-start">
                  <h6 className=" text-base text-neutral800 font-bold mb-[10px]">
                    Important Notice
                  </h6>
                  <p className=" text-subText3 text-sm">
                    You’ll have to sign in again after changing your password.
                  </p>
                </div>
                <div className="flex  w-full">
                  <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    rootClassName="w-full"
                  >
                    <Form.Item
                      label="Current password"
                      name="current_password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          //  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/
                          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                          message:
                            "Password must contain at least 8 characters, one uppercase, one lowercase and one number",
                        },
                      ]}
                      className="w-full"
                    >
                      <Input.Password className="w-full" />
                    </Form.Item>
                    <Form.Item
                      label="New password"
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          //  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/
                          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                          message:
                            "Password must contain at least 8 characters, one uppercase, one lowercase and one number",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label="Confirm new password"
                      name="confirm_password"
                      dependencies={["new_password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("new_password") === value
                            ) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            )
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                    // wrapperCol={ { offset: 8, span: 16 } }
                    >
                      <div className="flex justify-end border-t py-2">
                        <Button
                          color="primary"
                          title="submit"
                          className="w-[60px]"
                          submitting={isProcessingPayment}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </ModalWrapper>
          )}
          {showEditPersonalDetails && (
            <EditPersonalDetails
              closeModal={() => setShowEditPersonalDetails(false)}
              PersonData={PersonData}
            />
          )}

          {twoFaToggle && (
            <VerifyPasswordPrompt
              onClose={(status: boolean) => {
                setTwoFaToggle(false)
                if (status) {
                  if (user?.isAdmin == "True") {
                    navigate("/dashboard/twofa-setup")
                  } else {
                    navigate("/employee/dashboard/twofa-setup")
                  }
                }
              }}
            />
          )}
        </div>
      </DashboardWrapper>
    </>
  )
}

export default Profile
