import { forwardRef } from "react"

import ImageComponent from "components/custom/image"
import { useAuthStore } from "stores"
import { Table } from "antd"
import { IReviewDetailed, ReviewReportData } from "../interface/reviews"
import { useGet, usePost } from "utils/useFetch"
import { useLocation } from "react-router-dom"
import PadeLogo from "assets/logos/paidhr-logo.svg"

const ReviewReportToPrint = forwardRef(({ data }: any, ref: any) => {
  const myParam = useLocation().search
  const id = new URLSearchParams(myParam).get("id")
  const { user } = useAuthStore()
  const { isLoading: isReviewRoundsLoading, data: reviewInfo } =
    useGet<IReviewDetailed>({
      url: `/admin/performance/view/review?id=${id}`,
    })
  const { isLoading: isRangeLoading, data: ratingsBands } = usePost<any[]>({
    url: "/admin/performance/getratingbands",
  })
  const findPerformanceRating = (score: number): any | null => {
    const result = ratingsBands?.find(
      (band) => score >= band.lower && score <= band.upper
    )
    return result || null
  }

  const TableData =
    data?.data?.map((report: ReviewReportData) => {
      const ratingBand = findPerformanceRating(report.final_score)
      const rate = ratingBand ? ratingBand.description : "-"
      const rateRecommendation = ratingBand ? ratingBand.alias : "-"

      return {
        key: report.review_entity_id,
        employee: {
          name: report.full_name,
          avatar: report.avatar_url,
        },
        department: {
          title: report.department,
          role: report.employee_title,
        },
        performance: report.performance_score,
        behaviour: report.behavioural_score,
        self: report.self_score,
        others: report.others_score,
        final: report.final_score,
        rate: rate,
        recommendation: rateRecommendation,
      }
    }) || []
  const TableColumns =
    [
      {
        title: "Employee",
        dataIndex: "employee",
        render: (item: { name: string; avatar: string }) => (
          <div className="flex items-center gap-2">
            <span className="shrink-0">
              <ImageComponent
                className="w-6 h-6 rounded-full shrink-0"
                src={item.avatar}
                alt=""
              />
            </span>

            <span className="text-sm text-neutral500">{item.name}</span>
          </div>
        ),
      },
      {
        title: "Department",
        dataIndex: "department",
        render: (department: { title: string; role: string }) => (
          <div className="flex flex-col">
            <span className=" text-[#42526D] font-avenir text-sm">
              {department.title}{" "}
            </span>
            <span className="  text-neutral100 font-avenir text-[11px]">
              {department.role}{" "}
            </span>
          </div>
        ),
      },
      {
        title: "Performance ",
        dataIndex: "performance",
        render: (performance: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {performance}%{" "}
          </span>
        ),
      },
      {
        title: "Behaviour ",
        dataIndex: "behaviour",
        render: (behaviour: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {behaviour}%{" "}
          </span>
        ),
      },
      {
        title: "Self ",
        dataIndex: "self",
        render: (self: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">{self}% </span>
        ),
      },
      {
        title: "Others ",
        dataIndex: "others",
        render: (others: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">
            {others}%{" "}
          </span>
        ),
      },
      {
        title: "Final score",
        dataIndex: "final",
        render: (final: string) => (
          <span className=" text-[#42526D] font-avenir text-sm">{final}% </span>
        ),
      },
      {
        title: "Rate",
        dataIndex: "rate",
        render: (rate: string) => (
          <span className=" text-[#42526D] font-avenir text-sm capitalize">
            {rate}{" "}
          </span>
        ),
      },
      {
        title: "Recommendation",
        dataIndex: "recommendation",
        render: (recommendation: string) => (
          <span className=" text-[#42526D] font-avenir text-sm capitalize">
            {recommendation}{" "}
          </span>
        ),
      },
    ] || []

  return (
    <div
      ref={ref}
      className="bg-white shadow-card px-4 md:p-6"
    >
      <div className=" flex justify-between w-full items-center mb-6">
        <div>
          <h4 className="text-neutral700 font-bold  text-2xl mb-4">
            Performance Review Report
          </h4>
          <p className=" text-neutral500 text-sm leading-snug">
            Review period: {reviewInfo?.start_date}-{reviewInfo?.due_date}
          </p>
        </div>
        <div className=" ">
          <ImageComponent
            defaultImage={PadeLogo}
            src={user?.organisationAvatarFilename || ""}
            alt="org"
            className={` w-[200px] h-[100px] max-h-[200px] `}
          />
        </div>
      </div>
      <div className="mb-9 h-[117px] border-b bg-primary50 px-4 md:px-[30px] flex justify-between items-center py-5">
        <div className=" gap-4 flex items-center">
          <div className="">
            <p className=" text-neutral500 font-semibold text-lg leading-snug capitalize">
              {reviewInfo?.qualified_description} Report
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              Performance period : {reviewInfo?.period}
            </p>
          </div>
        </div>
        <div className="">
          <div className="">
            <p className=" text-neutral500 text-sm leading-snug">
              {user?.userOrganisation}
            </p>
          </div>
        </div>
      </div>

      <div className=" flex flex-col gap-4">
        <Table
          columns={TableColumns}
          scroll={{ x: `100%` }}
          dataSource={TableData}
          // loading={isPayslipLoading}
          className="text-[#42526D]"
          pagination={false}
          // pagination={{
          //   current,
          //   onChange: handlePaginationChange,
          //   // total: data?.total,
          //   defaultPageSize: 20,
          // }}
        />
      </div>
      {/* {Object.keys(groupedTaxSchedules).map((pfaName) => (
          <TaxRow
            pfaName={pfaName}
            groupedPensionSchedules={groupedTaxSchedules}
          />
        ))} */}
      {/* <OverallTotal groupedTaxSchedules={groupedTaxSchedules} /> */}
    </div>
  )
})

export default ReviewReportToPrint
