/**
 * An optimized text button.
 * Pass the `title` props, else `⚠` is returned
 */
export const TextButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  if (!props["title"]) return <span children={"⚠"} />
  return (
    <button
      aria-label={props.title}
      {...props}
      className={`p-0 hover:opacity-95 active:opacity-95 focus:opacity-95 disabled:opacity-50 focus:outline-none focus:shadow-none ${props.className}`}
    />
  )
}
