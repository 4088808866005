import FormSectionWrapper from "../people/people/FormSectionWrapper"
import CustomCard from "components/new-stuff/ui/CustomCard"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import StatCard from "components/new-stuff/ui/StatCard"
import React, { useRef } from "react"
import { Form, Select, Spin, Table } from "antd"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Button } from "components"

import { IViewInvoice, Withdrawal } from "./interface/Invoice"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { formatCurrencyNoSymbol } from "utils/currency"
import CustomInfoCard from "components/new-stuff/ui/CustomInfoCard"
import ReactToPrint from "react-to-print"
import WithdrawalRow from "./components/withdrawalRow"
import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const ViewInvoice = () => {
  const componentRef: any = useRef()
  const myParam = useLocation().search
  const invoiceId = new URLSearchParams(myParam).get("x")
  const { customizationSettings } = useCustomizationSettingsStore()

  const [form] = Form.useForm()
  const { isLoading: isAdminLoading, data: invoiceData } = useGet<IViewInvoice>(
    {
      url: `${newBaseUrl}/v1/employer/invoices/${invoiceId}/payment-note`,
      external: true,
    }
  )

  const invoiceInfo = [
    {
      title: "Issued on",
      value: invoiceData
        ? formatDateReadable(String(invoiceData?.data.invoice?.createdAt))
        : "",
      subTitle: "",
    },
    {
      title: "Total Amount",
      value: `₦${formatCurrencyNoSymbol(
        Number(invoiceData?.data.invoice?.amount || 0)
      )}`,
    },

    {
      title: "Billing From",
      value: `${customizationSettings?.brand.platformName} HCM Inc.`,
      subTitle: "13 Ore Close. Surulere, Lagos",
    },
    {
      title: "Billing To",
      value: invoiceData?.data.invoice?.company.name || "",
      subTitle: invoiceData?.data.invoice?.company.address || "",
    },

    { title: "Payment method", value: "Bank Transfer" },
  ]
  const bankInfo = [
    { title: "Account Number", value: "1023530905" },
    { title: "Bank Name", value: "United Bank for Africa" },
    { title: "Account Name", value: "Pade HCM Technology Limited" },
    // {    title:"Payment Description",
    //     value:"",
    // },
  ]

  function groupAndSumWithdrawals(withdrawals: any) {
    const groupedWithdrawals = withdrawals?.reduce(
      (acc: any, withdrawal: any) => {
        const employeeId = withdrawal.employee._id
        if (!acc[employeeId]) {
          acc[employeeId] = {
            employeeId: employeeId,
            firstName: withdrawal.employee.firstName,
            lastName: withdrawal.employee.lastName,
            email: withdrawal.employee.email,
            totalAmountRequested: 0,
          }
        }
        acc[employeeId].totalAmountRequested += withdrawal.amountRequested
        return acc
      },
      {}
    )

    const result = Object.values(groupedWithdrawals)
    return result
  }

  const groupedWithdrawals = groupAndSumWithdrawals(
    invoiceData?.data?.withdrawals || []
  )

  return (
    <DashboardWrapper>
      {isAdminLoading ? (
        <div className="flex justify-center items-center h-screen w-full">
          <Spin />
        </div>
      ) : (
        <>
          <div className="flex justify-end items-center mb-4">
            {componentRef && (
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="primary"
                    title="Download"
                    onClick={() => {
                      mixPanelEvent("ewa-payment-download-init")
                    }}
                  >
                    Download PDF
                  </Button>
                )}
                onAfterPrint={() => {
                  mixPanelEvent("ewa-payment-download-success")
                }}
                content={() => componentRef.current}
                documentTitle={`${invoiceData?.data?.invoice?.reference}_${
                  invoiceData &&
                  formatDateReadable(
                    String(invoiceData?.data.invoice?.createdAt)
                  )
                }.pdf`}
              />
            )}
          </div>
          <div ref={componentRef}>
            <CustomInfoCard
              title={`Payment ID: ${invoiceData?.data?.invoice?.reference}`}
              rootClassName=""
            >
              <div className="flex flex-col gap-5 mt-2 p-4 lg:p-6">
                <div className="flex flex-col lg:grid grid-cols-5  gap-4 sm:gap-[54px] ">
                  <div className=" col-span-3 grid  sm:grid-cols-2 justify-between  gap-2 sm:gap-5 rounded-5px py-4 px-4 sm:px-6   border ">
                    {invoiceInfo?.map((invoice, index) => (
                      <div
                        className={` flex flex-col gap-1  ${
                          index == 1 || index == 3 || index == 5
                            ? "sm:text-right"
                            : "text-left"
                        }`}
                      >
                        <h6 className=" text-[#5D6B82] font-normal">
                          {invoice.title}
                        </h6>
                        <h4 className="text-[#15294B] font-bold">
                          {invoice.value}
                        </h4>
                        <h6 className="text-[#5D6B82] font-normal">
                          {invoice.subTitle}
                        </h6>
                      </div>
                    ))}
                  </div>
                  <div className=" col-span-2 rounded-5px p-4 sm:p-6 border bg-[#F3F5FB] flex flex-col sm:grid grid-cols-2 gap-5 ">
                    <h4 className="col-span-2 text-[#15294B] font-bold text-base">
                      Make transfer payment to
                    </h4>

                    {bankInfo?.map((info, index) => (
                      <div
                        className={` flex flex-col gap-1  ${
                          index == 1 || index == 3 || index == 5
                            ? "sm:text-right"
                            : "text-left"
                        }`}
                      >
                        <h4 className="text-[#15294B] font-bold">
                          {info.title}
                        </h4>
                        <h6 className=" text-[#5D6B82] font-normal">
                          {info.value}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="">
                  <h4 className="text-base text-[#15294B] my-2  font-bold">
                    Items
                  </h4>
                  <div className="border rounded-5px p-4 sm:p-5">
                    <h4 className="text-[#15294B] pb-1 mb-2 border-b w-full font-bold">
                      Earned Wage Access Withdrawal breakdown{" "}
                    </h4>

                    <div className="flex flex-col gap-2 py-4">
                      <WithdrawalRow
                        groupPersonWithdrawals={groupedWithdrawals}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CustomInfoCard>
          </div>
        </>
      )}
    </DashboardWrapper>
  )
}

export default ViewInvoice
