import { Steps } from "antd"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import StatsPage from "./component/StatsPage"
import { Button } from "components"

const StatustoryPayment = () => {
  const [current, setCurrent] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()
  const { title, currency_code } = location.state || {}
  const onChange = (value: number) => {
    setCurrent(value)
  }
  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  const payments = [
    {
      id: "regular-payment-tax",
      title: "Tax Summary",
      content: (
        <StatsPage
          currency_code={currency_code}
          StatName="Tax"
          StatNameSmall="tax"
          ItemId="2"
          StatustoryId={2}
        />
      ),
    },

    {
      id: "regular-payment-pension",
      title: "Pension Summary",
      content: (
        <StatsPage
          currency_code={currency_code}
          StatName="Pension"
          StatNameSmall="pension"
          ItemId="3"
          StatustoryId={1}
        />
      ),
    },
    {
      id: "regular-payment-nhf",
      title: "NHF Summary",
      content: (
        <StatsPage
          currency_code={currency_code}
          StatName="NHF"
          StatNameSmall="nhf"
          ItemId="4"
          StatustoryId={3}
        />
      ),
    },

    {
      id: "regular-payment-nsitf",
      title: "NSITF Summary",
      content: (
        <StatsPage
          currency_code={currency_code}
          StatName="NSITF"
          StatNameSmall="nsitf"
          ItemId="9"
          StatustoryId={4}
        />
      ),
    },
    {
      id: "regular-payment-itf",
      title: "ITF Summary",
      content: (
        <StatsPage
          currency_code={currency_code}
          StatName="ITF"
          StatNameSmall="itf"
          ItemId="10"
          StatustoryId={5}
        />
      ),
    },
    {
      id: "regular-payment-ewa",
      title: "EWA Summary",
      content: (
        <StatsPage
          currency_code={currency_code}
          StatName="EWA"
          StatNameSmall="ewa"
          ItemId="8"
          showReportBtn={false}
          showPrepareBtn={false}
        />
      ),
    },
    // {
    //   id: "regular-payment-nhis",
    //   title: "NHIS summary",
    //   content: (
    //     <StatsPage
    //       currency_code={currency_code}
    //       StatName="EWA"
    //       StatNameSmall="ewa"
    //       ItemId="8"
    //     />
    //   ),
    // },
  ]
  return (
    <DashboardWrapper>
      <div className="relative">
        <h6 className="text-sm text-padeBlack font-circular mb-3">
          Payment for {title}
        </h6>
        <Steps
          current={current}
          onChange={onChange}
          labelPlacement="vertical"
          className="font-avenir !text-neutral300"
          progressDot={false}
          // size="small"
          // type="navigation"
          items={payments.map((item) => ({
            title: item.title,
            key: item?.title,
          }))}
        />
        <div>{payments[current].content}</div>

        <div className="fixed bottom-0 flex justify-end left-0 right-0 h-[64px] gap-4  py-3 px-16 bg-white ">
          <Button
            color="secondary"
            onClick={() => navigate(-1)}
          >
            Skip to Payouts
          </Button>
          {current < payments.length - 1 && (
            <Button
              color="primary"
              onClick={() => next()}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default StatustoryPayment
