import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import { useMutation } from "@tanstack/react-query"
import { Form, Input, Modal } from "antd"

import { Button, H7, P2 } from "components"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import ImageComponent from "components/custom/image"
import { useEffect, useState } from "react"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"

type Props = {
  onClose: Function
}

export const VerifyPasswordPrompt = ({ onClose }: Props) => {
  const [form] = Form.useForm()
  const { user } = useAuthStore()

  const [showPassword, setShowPassword] = useState<boolean>(false)

  const { toast } = useAppNotificationStore()

  useEffect(() => {
    return () => form.setFieldValue("password", "")
  }, [])

  const { isLoading: isLoadingConfirmPassword, mutate: submit } = useMutation({
    mutationFn: async (body: string) => {
      const res = await axiosInstance.post(`/admin/settings/signin`, {
        password: body,
      })
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status === "success") {
        form.resetFields()
        form.setFieldValue("password", "")
        onClose(true)
      } else {
        data?.msg && toast.error(data?.msg || "Invalid password")
      }
    },
  })
  return (
    <Modal
      open
      footer={null}
      onCancel={() => onClose()}
      title={
        <div>
          <H7 className="text-neutral500 font-bold">
            To continue, please confirm you're {user?.userFirstName}
          </H7>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          submit(values?.password)
        }}
      >
        <div className="px-8 md:px-16 py-8 h-full w-full flex flex-col gap-6 justify-center items-center">
          <P2 className="font-circular text-neutral400 font-light text-center">
            You're trying to view sensitive info. To keep your account secure,
            we need to confirm your identity.
          </P2>

          <div className="w-full flex flex-col gap-2 justify-center items-center">
            <ImageComponent
              className="object-cover rounded-full w-[70px]"
              src={
                user?.userAvatarFilename ??
                "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
              }
              alt="User Profile"
            />
            <H7 className="font-medium text-neutral700">{user?.userName}</H7>
          </div>
        </div>
        <Form.Item
          name={"password"}
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
          initialValue={""}
          label={
            <span className="font-avenir text-sm text-neutral400">
              Password
            </span>
          }
        >
          <Input
            type={showPassword ? "text" : "password"}
            suffix={
              <>
                {showPassword === true ? (
                  <EyeSlashIcon
                    onClick={() => setShowPassword(false)}
                    className="w-5 text-neutral80"
                  />
                ) : (
                  <EyeIcon
                    onClick={() => setShowPassword(true)}
                    className="w-5 text-neutral80"
                  />
                )}
              </>
            }
          />
        </Form.Item>
      </Form>
      <div className="mt-3 w-full flex justify-end items-center gap-4">
        <Button
          color="secondary"
          title="Confirm"
          onClick={() => {
            onClose()
          }}
          type="button"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          title="Confirm"
          onClick={(e) => {
            e.preventDefault()
            form.submit()
          }}
          submitting={isLoadingConfirmPassword}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}
