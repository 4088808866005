import GBPFlag from "assets/svg/uk_flag.svg"
import USFlag from "assets/svg/us_flag.svg"
import NigFlag from "assets/svg/nig_flag.svg"
import KenYFlag from "assets/svg/ken_flag.svg"

export const availableFlags = [
  {
    code: "GBP",
    flag: GBPFlag,
    subContent:
      "Your GBP Account  enables you to send money to your employees and contractors in GBP. You can also swap GBP to other currencies.",
    symbol: "£",
  },

  {
    code: "NGN",
    flag: NigFlag,
    subContent:
      "Your International NGN Account enables you to swap Naira to other currencies (USD, GBP etc) and withdraw back to your Local NGN balance.",
    symbol: "₦",
  },
  {
    code: "USD",
    flag: USFlag,
    subContent:
      "Your USD Account  enables you to send money to your employees and contractors in USD. You can also swap USD to other currencies.",
    symbol: "$",
  },
  {
    code: "KES",
    flag: KenYFlag,
    subContent:
      "Your KES Account  enables you to send money to your employees and contractors in KES. You can also swap KES to other currencies.",
    symbol: "Sh",
  },
]

interface Balance {
  currency: string
  balance: number
  previousBalance?: number
  _id: string
}

interface KycLevel {
  awaitingConfirmation: boolean
  level: number
  action: string
  status: string
  _id: string
}

interface VirtualAccountMeta {
  contractCode: string
  accountReference: string
  accountName: string
  currencyCode: string
  customerEmail: string
  customerName: string
  accounts: {
    bankCode: string
    bankName: string
    accountNumber: string
    accountName: string
  }[]
  collectionChannel: string
  reservationReference: string
  reservedAccountType: string
  status: string
  createdOn: string
  bvn: string
  restrictPaymentSource: boolean
}

interface VirtualAccount {
  _id: string
  accountNumber: string
  accountName: string
  bankName: string
  bankCode: string
  currency: string
  provider: string
  wallet: string
  isTemporary: boolean
  expired: boolean
  meta?: VirtualAccountMeta
  createdAt: string
  updatedAt: string
  __v: number
}

export interface ICurrencyUser {
  currencyName: string
  tag?: string
  flag: string // Assuming NigFlag is a string representing the URL or base64 data of the flag  tooltipContent?: string
  tooltipContent?: string
  subtitle?: string
  balance: number
  symbol: string
  pinSet: boolean
  bvnProvided: boolean
  _id: string
  firstName: string | null
  lastName: string | null
  commonName: string
  email: string
  phoneNumber: string
  walletId: string
  merchant: string
  balances: Balance[]
  status: string
  disabled: boolean
  userType: string
  kycLevels: KycLevel[]
  bvn: string
  phoneVerified: boolean
  countryCode: string
  companyName: string
  incorporationDate: string | null
  rcNumber: string
  virtualAccounts: VirtualAccount[]
  postNoDebit: boolean
  createdAt: string
  updatedAt: string
  __v: number
  userGroupId: string
}
