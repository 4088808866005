import { useLocation, useNavigate } from "react-router-dom"
import { useGet } from "utils/useFetch"
import { IPayrollReceipt } from "./interface/receipt"
import { useAuthStore } from "stores"
import { Spin, Tooltip } from "antd"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { isPadeDomain } from "utils/utils"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { formatCurrencyNoSymbol } from "utils/currency"

const ViewPayrollReceipt = () => {
  const { user } = useAuthStore()
  const myParam = useLocation().search
  const navigate = useNavigate()
  const { customizationSettings } = useCustomizationSettingsStore()
  const id = new URLSearchParams(myParam).get("id")
  const { isLoading, data } = useGet<IPayrollReceipt>({
    url: `/admin/payrolls/view/receipt?id=${id}`,
  })
  return (
    <DashboardWrapper>
      <Spin spinning={isLoading}>
        <div className="border bg-white px-4 lg:px-[30px] py-4 pb-6 ">
          <div className=" mb-4 py-4  ">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {data?.payroll_schedule} Payroll Summary - {data?.pay_date}
            </h5>
            <p className="text-black  text-opacity-50 text-sm leading-snug">
              Your employees were scheduled for payment on {data?.pay_date} for
              work done between {data?.start_date} and {data?.cut_off_date}.
              This payroll was processed on {data?.run_date} by{" "}
              {user?.userOrganisation}.
            </p>
          </div>
          <div className=" bg-white shadow-card  w-full lg:w-[600px] pb-3  ">
            <div className="flex flex-col w-full mb-6   ">
              <div className="flex items-center gap-4 py-6 border-b-2  border-dashed justify-between  px-4 lg:px-6">
                <h5 className=" text-base text-neutral700 font-bold ">
                  {" "}
                  Payroll Receipt
                </h5>{" "}
                <span
                  className="text-sm
               text-neutral700"
                >
                  {data?.record_guid}
                </span>
              </div>
              <div className="px-4 lg:px-6">
                <div className="my-6 flex flex-col gap-4">
                  <h5 className=" text-sm text-neutral700  font-semibold ">
                    <span className="text-black  text-opacity-50 text-sm mr-2 font-normal">
                      From:
                    </span>{" "}
                    {user?.userOrganisation}
                  </h5>{" "}
                  <h5 className=" text-sm text-neutral700  font-semibold  flex items-center">
                    <span className="text-black  text-opacity-50  mr-2 font-normal">
                      To:
                    </span>{" "}
                    Payroll Recipients
                    <Tooltip
                      title={
                        "Payroll recipients include the employees listed below plus the tax agencies (for taxes) and benefits agencies (for benefits) listed below."
                      }
                    >
                      <ExclamationCircleIcon className="h-4 w-4" />
                    </Tooltip>
                  </h5>
                </div>
                <span className="text-black text-opacity-50 text-sm  font-normal">
                  For funds paid on {data?.full_pay_date}{" "}
                </span>{" "}
                <div className="border-y-2 my-6 py-3 flex justify-between">
                  <h5 className=" text-base text-neutral700 font-bold ">
                    Total
                  </h5>{" "}
                  <h5 className=" text-base text-neutral700 font-bold ">
                    {" "}
                    ₦ {formatCurrencyNoSymbol(Number(data?.total_payment))}
                  </h5>{" "}
                </div>
                <p className="text-black text-opacity-50 te text-sm mb-4  font-normal">
                  These amounts may not represent all monies due to you from
                  government tax authorities, and do not include any amounts
                  transmitted outside the Pade platform.
                </p>
                {isPadeDomain() === true ? (
                  <>
                    <p className="text-black text-opacity-50 text-sm  font-normal">
                      © {customizationSettings?.brand.platformName}
                    </p>
                    <span className="font-avenir mt-1  text-neutral500">
                      {" "}
                      . Learn more about{" "}
                      {customizationSettings?.brand.platformName}{" "}
                      <a
                        href="https://support.padehcm.com/en/collections/9250722-payroll-management"
                        className="text-primary500 w-fit font-semibold underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>{" "}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </DashboardWrapper>
  )
}

export default ViewPayrollReceipt
