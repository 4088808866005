import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline"
import { Dropdown } from "antd"
import { ItemType } from "antd/es/menu/hooks/useItems"
import { FC } from "react"

type Props = {
  menuItems: ItemType[]
}

export const TableActionButton: FC<Props> = ({ menuItems = [] }) => {
  return (
    <Dropdown
      trigger={["click"]}
      menu={{
        items: menuItems,
      }}
    >
      <button>
        <EllipsisHorizontalIcon
          className="w-5 text-primary500"
          fontWeight="bold"
        />
      </button>
    </Dropdown>
  )
}
