import { formatCurrencyNoSymbol } from "./currency"
export const DivideByRate = (amount: number, rate: number) => {
  const formmattedAmount = formatCurrencyNoSymbol(
    Number(Number(amount) * (1 / (rate ?? 1))) || 0
  )
  return formmattedAmount
}
export const MultiplyByRate = (amount: number, rate: number) => {
  const formmattedAmount = formatCurrencyNoSymbol(
    Number(Number(amount) * rate) || 0
  )

  return formmattedAmount
}
export const UnformattedDivideByRate = (amount: number, rate: number) => {
  let unformattedAmount: number = Number(amount) * (1 / (rate ?? 1)) || 0
  let formattedAmount = unformattedAmount.toFixed(2)

  return formattedAmount
}

export const UnformattedMultiplyByRate = (amount: number, rate: number) => {
  const unformattedAmount = Number(Number(amount) * rate) || 0

  let formattedAmount = unformattedAmount.toFixed(2)

  return formattedAmount
}
