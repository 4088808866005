import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import React, { useState } from "react"
import { Button } from "components"
import { useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
import {
  ClipboardDocumentIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline"
import { Alert, Spin, Table, Tooltip } from "antd"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import { useAppNotificationStore, useAuthStore } from "stores"
import { ChevronRightIcon } from "@heroicons/react/24/solid"
import CustomCard from "components/new-stuff/ui/CustomCard"
import checkBlue from "assets/images/check_blue.svg"

import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"

import styled from "styled-components"
import { IUserWalletID, KycLevel } from "../../kyc/interface"
import { IBeneficiaries } from "../../beneficiaries/interface"
import { IEmployeeWalletRes } from "../transactions/interface"
import WalletTransactionsTable from "../transactions/component/Table"
import WalletNotEnabled from "../../WalletNotEnabled"

import MoveFundsLocal from "../modals/MoveFundsLocal"

export const WalletWrapper = styled.div`
  background-image: url(${"https://padefiles.blob.core.windows.net/images/a90d66b6-5216-4c3a-aff7-d30441a9b7f4.svg"});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: 5px;
`

export const getLatestCompletedKycLevel = (
  kycLevels: KycLevel[]
): KycLevel | null => {
  return (
    kycLevels?.find((level, index) => {
      if (level.status === "completed") {
        // Check if the next level exists and is not completed
        return (
          index + 1 >= kycLevels.length ||
          kycLevels[index + 1].status !== "completed"
        )
      }
      return false
    }) || null
  )
}
const ViewLocalCurrency = () => {
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId

  const navigate = useNavigate()
  const [_, copy] = useCopyToClipboard()
  const { toast } = useAppNotificationStore()

  const [activeButton, setActiveButton] = useState(1)
  const [hideDetails, setHideDetails] = useState(true)

  const [walletEnabled, setWalletEnabled] = useState(true)
  const [currentLevel, setCurrentLevel] = useState<null | KycLevel>(null)
  const [transferModal, setTransferModal] = useState(false)
  const { isLoading: isLoadingWalletData, data: walletData } =
    useQuery<IUserWalletID>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}?populate=virtualAccounts`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: IUserWalletID) => {
        if (data?.data?.onboarded) {
          setWalletEnabled(true)
        } else {
          setWalletEnabled(false)
        }
        const latestCompletedLevel = getLatestCompletedKycLevel(
          data?.data?.kycLevels
        )

        if (latestCompletedLevel) {
          setCurrentLevel(latestCompletedLevel)
        } else {
        }
      },
    })

  const currencyCode = "NGN"
  const { isLoading: isLoadingBeneficiaries, data: beneficiariesData } =
    useQuery<IBeneficiaries>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries?currency=NGN`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries?currency=NGN`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })
  const { isLoading: isTransactionsLoading, data: transactionsData } =
    useQuery<IEmployeeWalletRes>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transactions`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transactions`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })
  const mainBalance = walletData?.data?.balances?.find(
    (balance) => balance.currency === currencyCode
  )

  const levels = [
    {
      completed:
        walletData?.data?.kycLevels[0]?.status === "completed" ? true : false,
      level: 1,
    },
    {
      completed:
        walletData?.data?.kycLevels[1]?.status === "completed" ? true : false,
      level: 2,
    },
    {
      completed:
        walletData?.data?.kycLevels[2]?.status === "completed" ? true : false,
      level: 3,
    },
  ]

  return (
    <DashboardWrapper>
      <div className=" w-full">
        {isLoadingWalletData ? (
          <div className="h-screen w-full  flex justify-center items-center">
            <Spin />
          </div>
        ) : (
          <>
            {walletEnabled ? (
              <div className="gap-6 flex flex-col">
                <div>
                  {walletData &&
                    walletData?.data?.kycLevels[2]?.status === "failed" && (
                      <Alert
                        message={"Your KYC verification failed"}
                        type="error"
                        showIcon
                        icon={
                          <ExclamationTriangleIcon className=" h-6 lg:h-12 w-6 lg:w-12" />
                        }
                        closable
                        description={walletData?.data?.kycLevels[2]?.remarks}
                        action={
                          <div className="pr-5  hidden lg:flex">
                            <button
                              onClick={() => {
                                navigate(
                                  user?.userStatusId === "2" ||
                                    user?.userStatusId === "3"
                                    ? `/employee/susp-exit/wallet/kyc`
                                    : `/employee/dashboard/wallet/kyc`
                                )
                              }}
                              className="bg-[#EB3949] px-4 py-1 text-white rounded"
                            >
                              Re-upload Document{" "}
                            </button>
                          </div>
                        }
                        // className="invoice-alert font-avenir text-neutral500 text-sm w-full items-center mb-6"
                        className="bg-[#FEE7EC] invoice-alert font-avenir text-neutral500 text-sm w-full flex justify-between items-center mb-4"
                      />
                    )}
                  <h2 className="text-neutral500 font-bold font-avenir text-base mb-1">
                    Wallet
                  </h2>
                  <div className="flex flex-col xl:grid grid-cols-5  gap-4 lg:gap-7 mt-2 ">
                    <div className={`h-full w-full col-span-3`}>
                      <div className="w-full h-full  ">
                        <WalletWrapper
                          className={`px-4 lg:px-10 py-8 w-full flex items-center bg-primary700 h-full `}
                          id="wallet-balance"
                        >
                          <div className="w-full flex flex-col gap-4">
                            <div className="flex justify-between items-center ">
                              {" "}
                              <div className=" flex flex-col gap-[2px]">
                                <p className="text-[#98A2B3] text-base  font-semibold font-circular flex items-center">
                                  Your balance is
                                </p>
                                {hideDetails ? (
                                  <h3 className="text-a11y font-bold font-circular text-4xl lg:text-[40px]">
                                    ****
                                  </h3>
                                ) : (
                                  <>
                                    <p className="text-a11y font-bold font-circular text-4xl lg:text-[40px]">
                                      {mainBalance?.symbol}
                                      {mainBalance?.balance &&
                                        formatCurrencyNoSymbol(
                                          Number(mainBalance?.balance) || 0
                                        )}
                                    </p>
                                  </>
                                )}
                              </div>
                              <button
                                className=" flex"
                                onClick={() => setHideDetails(!hideDetails)}
                              >
                                {hideDetails ? (
                                  <EyeIcon className="text-a11y w-6 h-6 cursor-pointer" />
                                ) : (
                                  <EyeSlashIcon className="text-a11y w-6 h-6 cursor-pointer" />
                                )}
                              </button>
                            </div>
                            <div className="mt-[21px] flex justify-between items-center">
                              <div className="mt-3 flex items-center gap-3">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    setTransferModal(true)
                                  }}
                                  className="text-white"
                                >
                                  Send money
                                </Button>
                                {user?.userOrganisationId !== "108" && (
                                  <Button
                                    outline={true}
                                    onClick={() => {
                                      setTransferModal(true)
                                      navigate(
                                        "/employee/dashboard/wallet/cross-border/swap-money"
                                      )
                                    }}
                                    className="text-white"
                                    id="wallet-withdrawal-btn"
                                  >
                                    Swap
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </WalletWrapper>
                      </div>
                    </div>

                    <div className="border col-span-2 border-neutral20 flex  flex-col  gap-1 bg-white  p-5  rounded-5px ">
                      <div>
                        <h6 className=" font-bold text-base gap-1  flex items-center">
                          Your Wallet details
                          <span>
                            {" "}
                            <Tooltip
                              title={
                                "Deposits above N10,000 attracts a fee of 1.5% capped at N1,000 and deposits below N10,000 attracts a fee of N100"
                              }
                            >
                              <ExclamationCircleIcon className="h-5 w-5" />
                            </Tooltip>
                          </span>
                        </h6>
                        {currentLevel !== null && currentLevel?.level > 1 && (
                          <p className="text-sm text-bluesubtext mb-2">
                            Use this detail to transfer funds to your PaidHR
                            wallet.{" "}
                          </p>
                        )}
                      </div>
                      {currentLevel !== null && currentLevel?.level === 1 && (
                        <div className="mb-4">
                          <Alert
                            message={
                              "This wallet can only be used internally for PaidHR to PaidHR transactions."
                            }
                            type="info"
                            showIcon
                            className="  font-avenir text-neutral500 text-sm w-full flex justify-between items-center "
                          />
                        </div>
                      )}
                      {walletData &&
                        walletData?.data?.virtualAccounts?.length > 1 &&
                        currentLevel !== null &&
                        currentLevel?.level > 1 && (
                          <div className="my-1 grid grid-cols-3 sm:flex ">
                            {walletData?.data?.virtualAccounts?.map(
                              (title, index) => (
                                <button
                                  key={index}
                                  className={`${
                                    activeButton == index + 1
                                      ? "btn-active px-[10px]"
                                      : "btn-inactive"
                                  }`}
                                  onClick={() => {
                                    setActiveButton(index + 1)
                                  }}
                                >
                                  Account {index + 1}
                                </button>
                              )
                            )}
                          </div>
                        )}

                      {currentLevel !== null && currentLevel?.level === 1 ? (
                        <Table
                          dataSource={[
                            {
                              key: "3",
                              name: "Wallet ID",
                              value: {
                                title: walletData?.data?.walletId || "-",
                                key: "3",
                              },
                            },
                          ]}
                          columns={[
                            {
                              title: "",
                              dataIndex: "name",
                              key: "name",
                              render: (name: string) => (
                                <span
                                  className={` font-avenir text-sm text-[#42526D]`}
                                >
                                  {name}
                                </span>
                              ),
                            },
                            {
                              title: "",
                              dataIndex: "value",
                              key: "value",
                              render: (value: {
                                title: string
                                key: string
                              }) => (
                                <span
                                  className={` font-avenir text-sm text-[#42526D] lowercase`}
                                >
                                  {value.title}
                                  {value.key === "3" && (
                                    <button
                                      onClick={() => {
                                        copy(String(value.title))
                                        toast.success("Copied")
                                      }}
                                      className="ml-0 sm:ml-2 shrink-0  text-primary500 p-1 rounded-full"
                                    >
                                      <ClipboardDocumentIcon className="w-4 h-4" />
                                    </button>
                                  )}
                                </span>
                              ),
                            },
                          ]}
                          showHeader={false}
                          className="custom-table"
                          pagination={false}
                          scroll={{ x: `100%` }}
                        />
                      ) : walletData &&
                        walletData?.data?.virtualAccounts?.length > 0 ? (
                        walletData?.data?.virtualAccounts?.map((acc, index) => {
                          if (activeButton == index + 1) {
                            return (
                              <Table
                                dataSource={[
                                  {
                                    key: "1",
                                    name: "Bank Name",
                                    value: {
                                      title: acc?.bankName || "",
                                      key: "1",
                                    },
                                  },

                                  {
                                    key: "3",
                                    name: "Account Number",
                                    value: {
                                      title: acc?.accountNumber || "",
                                      key: "3",
                                    },
                                  },
                                  {
                                    key: "4",
                                    name: "Account Name",
                                    value: {
                                      title: acc?.accountName || "",
                                      key: "4",
                                    },
                                  },
                                  {
                                    key: "5",
                                    name: "Wallet ID",
                                    value: {
                                      title: walletData?.data?.walletId || "-",
                                      key: "5",
                                    },
                                  },
                                ]}
                                columns={[
                                  {
                                    title: "",
                                    dataIndex: "name",
                                    key: "name",
                                    render: (name: string) => (
                                      <span
                                        className={` font-avenir text-sm text-[#42526D]`}
                                      >
                                        {name}
                                      </span>
                                    ),
                                  },
                                  {
                                    title: "",
                                    dataIndex: "value",
                                    key: "value",
                                    render: (value: {
                                      title: string
                                      key: string
                                    }) => (
                                      <span
                                        className={` font-avenir text-sm text-[#42526D] `}
                                      >
                                        {value.title}
                                        {value.key === "3" && (
                                          <button
                                            onClick={() => {
                                              copy(String(value.title))
                                              toast.success("Copied")
                                            }}
                                            className="ml-0 sm:ml-2 shrink-0  text-primary500 p-1 rounded-full"
                                          >
                                            <ClipboardDocumentIcon className="w-4 h-4" />
                                          </button>
                                        )}
                                      </span>
                                    ),
                                  },
                                ]}
                                showHeader={false}
                                className="custom-table"
                                pagination={false}
                                scroll={{ x: `100%` }}
                              />
                            )
                          }
                        })
                      ) : (
                        <div className="text-xs text-neutral400 mt-14  flex justify-center items-center">
                          Your virtual account will appear here
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {currentLevel !== null && currentLevel?.level !== 3 && (
                  <div
                    className=" w-full  h-auto lg:h-[152px]  px-6 py-[14px] flex flex-col lg:flex-row gap-[36px] items-center"
                    style={{
                      backgroundImage: `url(https://padefiles.blob.core.windows.net/images/d8219681-de72-4b0d-a8b0-088d11ef140f.png)`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    {/* bg-[#CCDBFD]  */}
                    <div className="w-full lg:w-[240px] h-full bg-[#EAF1FF] py-5 px-2 rounded-[10px] flex flex-col items-center justify-between">
                      <h5 className="text-lg font-medium font-circular mb-4 lg:mb-1">
                        KYC Progress
                      </h5>
                      <div>
                        <div className="flex items-center gap-2  justify-center ">
                          {levels?.map((item, index) => (
                            <React.Fragment key={index}>
                              <div className="flex text-center justify-center items-center w-fit">
                                <div className=" flex flex-col items-center">
                                  <div
                                    className={`rounded-full h-5 w-5  border text-center font-bold flex justify-center items-center text-xs
            ${
              item.completed
                ? "bg-primary500 text-white"
                : "text-primary500 border-gray-400"
            }`}
                                  >
                                    {item.completed ? (
                                      <img
                                        src={checkBlue}
                                        alt=""
                                        className="h-4 w-4"
                                      />
                                    ) : (
                                      item.level
                                    )}
                                  </div>
                                </div>
                              </div>
                              {index < levels.length - 1 && (
                                <div className=" flex text-center justify-center items-center">
                                  <p className="bg-primary500 w-6 h-[2px]"></p>
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="mt-1 flex items-center  gap-5  justify-center ">
                          {levels?.map((it, index) => (
                            <p
                              key={index}
                              className="text-sm block  mt-0.5 font-circular font-normal"
                            >
                              Tier {it?.level}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <h6 className=" text-lg text-padeBlack font-circular font-medium">
                        {!currentLevel
                          ? "Complete Tier 1 KYC information"
                          : currentLevel?.level === 1
                          ? "Upgrade to Tier 2"
                          : currentLevel?.level === 2
                          ? "Upgrade to Tier 3"
                          : currentLevel?.level === 3
                          ? ""
                          : ""}
                      </h6>
                      <p className=" w-full xl:w-[587px] text-[#475367] text-sm mt-1 mb-[10px] break-words">
                        {!currentLevel
                          ? ` No verification required. However, your daily withdrawal limit
                is N100,000. You can increase this limit by completing your
                profile.`
                          : currentLevel?.level === 1
                          ? `Verify your BVN to get an account number, and enjoy more benefits.`
                          : currentLevel?.level === 2
                          ? "Verify your address information to increase your daily withdrawal limit to N2,000,000"
                          : currentLevel?.level === 3
                          ? "Completed"
                          : ""}{" "}
                      </p>

                      <button
                        className="text-primary500 flex items-center mb-4 lg:mb-0"
                        onClick={() =>
                          navigate(
                            user?.userStatusId === "2" ||
                              user?.userStatusId === "3"
                              ? `/employee/susp-exit/wallet/kyc`
                              : `/employee/dashboard/wallet/kyc`
                          )
                        }
                      >
                        Upgrade account
                        <span>
                          <ChevronRightIcon className="h-5 w-5 ml-1" />
                        </span>
                      </button>
                    </div>
                  </div>
                )}
                <div className=" h-[194px]">
                  <CustomCard
                    title="Quick Send"
                    ctaBtn={
                      <button
                        className="text-primary500 flex text-sm items-center font-avenir"
                        onClick={() =>
                          navigate(
                            user?.userStatusId === "2" ||
                              user?.userStatusId === "3"
                              ? "/employee/susp-exit/wallet/beneficiaries"
                              : "/employee/dashboard/wallet/beneficiaries"
                          )
                        }
                      >
                        See all beneficiaries{" "}
                        <span>
                          <ChevronRightIcon className="h-4 w-4 ml-1" />
                        </span>
                      </button>
                    }
                    rootClassName="h-full !px-0"
                    headerClassName="px-4 border-b border-[#F0F2F5]"
                  >
                    <div className=" flex px-[36px] py-6 items-center gap-[40px] justify-start overflow-scroll scrollbar-hide">
                      {beneficiariesData &&
                      beneficiariesData?.data?.length > 0 ? (
                        beneficiariesData?.data?.map((beneficiary, index) => (
                          <button
                            key={index}
                            className="w-auto flex flex-col items-center   "
                            onClick={() => {
                              if (beneficiary?.type === "bank") {
                                navigate(
                                  user?.userStatusId === "2" ||
                                    user?.userStatusId === "3"
                                    ? `/employee/susp-exit/wallet/send-money-bank?x=${beneficiary?._id}`
                                    : `/employee/dashboard/wallet/send-money-bank?x=${beneficiary?._id}`
                                )
                              } else {
                                navigate(
                                  user?.userStatusId === "2" ||
                                    user?.userStatusId === "3"
                                    ? `/employee/susp-exit/wallet/send-money-wallet?x=${beneficiary?._id}`
                                    : `/employee/dashboard/wallet/send-money-wallet?x=${beneficiary?._id}`
                                )
                              }
                            }}
                          >
                            {beneficiary?.accountName && (
                              <div className="h-12 w-12  rounded-full bg-[#E8F0FE] text-lg font-circular  text-center flex justify-center items-center">
                                {getAcronym(beneficiary?.accountName)}
                              </div>
                            )}
                            {beneficiary?.accountName && (
                              <h6 className="max-w-[110px] mt-2 text-[12px] text-[#101928] font-circular truncate   ">
                                {beneficiary?.accountName}
                              </h6>
                            )}
                            <p className="text-[#98A2B3] text-[11px] lowercase ">
                              {beneficiary?.bankName}
                            </p>
                          </button>
                        ))
                      ) : (
                        <div className="w-full h-full text-center">
                          Your beneficiaries will appear here
                        </div>
                      )}
                    </div>
                  </CustomCard>
                </div>
                <div className="">
                  <CustomCard
                    title="Recent Transactions"
                    ctaBtn={
                      <button
                        className="text-primary500 flex text-sm items-center font-avenir"
                        onClick={() =>
                          navigate(
                            user?.userStatusId === "2" ||
                              user?.userStatusId === "3"
                              ? "/employee/susp-exit/wallet/cross-border/transactions"
                              : "/employee/dashboard/wallet/cross-border/transactions"
                          )
                        }
                      >
                        See All
                      </button>
                    }
                  >
                    <WalletTransactionsTable
                      transactionsData={transactionsData?.data?.docs?.slice(
                        0,
                        4
                      )}
                      loading={isTransactionsLoading}
                    />
                  </CustomCard>
                </div>
                <MoveFundsLocal
                  open={transferModal}
                  onClose={() => setTransferModal(false)}
                  currentLevel={currentLevel}
                  currencyCode={currencyCode}
                />
              </div>
            ) : (
              <WalletNotEnabled />
            )}
          </>
        )}
      </div>
    </DashboardWrapper>
  )
}

export default ViewLocalCurrency
export const getAcronym = (fullName: string): string => {
  const nameParts = fullName?.split(" ")

  const acronym = nameParts
    ? `${nameParts[0][0]}${nameParts[0][1]}`.toUpperCase()
    : ""
  return acronym
}
