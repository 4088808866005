import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { Checkbox, Switch } from "antd"
import { AppSearchInput } from "components/inputs/AppSearchInput"
import React, { useEffect, useMemo, useState } from "react"
import { useAuthStore } from "stores"
import { capitalizeText } from "utils/capitalizeFirstLetter"
import { useGet } from "utils/useFetch"

type Props = {
  isEditMode?: boolean
  selectedPermissions: string[]
  setSelectedPermissions: React.Dispatch<React.SetStateAction<string[]>>
}

export const PermissionsPicker = ({
  isEditMode = true,
  selectedPermissions,
  setSelectedPermissions,
}: Props) => {
  const { data } = useGet<any>({
    url: `/admin/roles/select_permissions`,
  })

  //@: Local state to search through permissions
  const [search, setSearch] = useState("")

  //@: Local state to group permissions based on their segment and search permissions
  const permissions = useMemo(() => {
    const arr: any[] = []

    //@: Get all segments without 'All' string[]
    const segments = Array.from(
      new Set(data?.data?.map((x: any) => x?.segment))
    )
      ?.filter((x: any) => x !== "All")
      .sort()

    //Assign data to the matching segment
    for (let segment of segments) {
      arr.push({
        groupName: segment,
        groups: search
          ? data?.data
              ?.filter((it: any) => it?.segment === segment)
              ?.filter((x: any) =>
                (x?.name as string)
                  .trim()
                  .toLowerCase()
                  .replace(" ", "_")
                  .includes(search.toLowerCase().trim().replace(" ", "_"))
              )
          : data?.data?.filter((it: any) => it?.segment === segment),
      })
    }

    if (search) {
      return arr?.filter((permission: any) => {
        return (
          (permission.groups as any[]).some((group: any) =>
            (group.name as string)
              .trim()
              .toLowerCase()
              .replace(" ", "_")
              .includes(search.toLowerCase().trim().replace(" ", "_"))
          ) || permission.groupName.toLowerCase().includes(search.toLowerCase())
        )
      })
    }

    return arr
  }, [data, search])

  //@: Logged in user
  const { user } = useAuthStore()

  //@: Local state to manage the open state of the accordions
  const [selected, setSelected] = useState<Record<number, boolean> | null>(null)

  //@: Local state to manage assigning all permissions to a role
  const [assignAll, setAssignAll] = useState<boolean>(false)

  //@: Close all accordions if assignAll is true
  useEffect(() => {
    if (assignAll) {
      setSelected(null)
    }
  }, [assignAll])

  //@: Local state to handle the checked state of the switch button
  const checked = useMemo<boolean>(
    () => assignAll || selectedPermissions?.includes("154"),
    [assignAll, selectedPermissions]
  )

  return (
    <div className="w-full h-[90%] overflow-y-scroll pl-6 pb-4 pr-4 box-border">
      <div className="pt-4 pb-4 flex flex-col gap-1 mb-2">
        {isEditMode ? (
          <>
            <div className="text-[18px] text-[#15294B] font-[600]">
              Assign Permissions
            </div>
            <div className="text-[#42526D] text-[14px]">
              Define and assign the permissions and access for team members with
              this role. You can make changes to this much later
            </div>
            <div className="shadow-10 flex justify-between gap-3 py-3 px-4 mt-3 border rounded-lg">
              <div className="text-[#15294B] text-[14px] font-[600] items-center">
                Assign all permissions for this role ( except for transfer of
                account ownership, this can only be done by the account owner)
              </div>
              <div>
                <Switch
                  className={checked ? "!bg-primary500" : "!bg-[#ededed] "}
                  disabled={user?.isPrimaryAdmin !== "True"}
                  checked={checked}
                  onChange={(checked) => {
                    setAssignAll(checked)
                    if (checked) {
                      setSelectedPermissions(["154"])
                    } else {
                      setSelectedPermissions([])
                    }
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-[18px] text-[#15294B] font-[600]">
              Role Permissions
            </div>
            <div className="text-[#42526D] text-[14px]">
              See the lists of available permissions for this role
            </div>
          </>
        )}
      </div>
      <div className="mt-2 mb-4 w-full">
        <AppSearchInput
          className="!w-full"
          search={search}
          handleSearch={(value) => {
            setSearch(value)
          }}
        />
      </div>
      <div className="flex gap-4 flex-col">
        {permissions.map((permission, index) => (
          <PermissionAccordion
            data={permission}
            permissions={permissions}
            open={!assignAll ? (selected ? selected[index] : false) : true} // accordion opens by default, closes if assignAll is true, closes if the item index is true
            onClick={() => {
              if (assignAll) return
              const items = { ...selected }
              items[index] = items[index] ? false : true
              setSelected(items)
            }}
            handleChange={(selections: string[]) => {
              setSelectedPermissions(selections)
            }}
            selections={selectedPermissions}
            isEditMode={isEditMode}
          />
        ))}
      </div>
    </div>
  )
}

const PermissionAccordion = ({
  data,
  open,
  onClick,
  handleChange,
  selections,
  isEditMode,
  permissions,
}: {
  data: { groupName: string; groups: any[] }
  open: boolean | undefined
  onClick: Function
  handleChange: Function
  selections: string[]
  isEditMode: boolean
  permissions?: { groupName: string; groups: any[] }[]
}) => {
  const [segmentSelected, segmentPermissions] = useMemo(() => {
    const segmentPermissions = permissions
      ?.filter((x) => x.groupName === data?.groupName)
      .flatMap((x) => x.groups)
      .map((x) => x.id.toString())

    //@: List of segment permissions and all segment permissions are selected or not
    return [
      segmentPermissions?.every((x) => selections?.includes(x)),
      segmentPermissions,
    ]
  }, [permissions, data, selections])

  // @: 154 - Primary Admin Role
  // @: 153 - Secondary Admin Role

  return (
    <div className="border rounded-lg shadow-10 px-4 py-3 w-full h-full">
      <div className="grid grid-cols-[1fr,.6fr] pb-3 justify-between w-full cursor-pointer select-none items-center">
        <div className="text-[16px] text-[#15294B] font-[600] flex gap-2">
          <div>
            <Checkbox
              checked={
                segmentSelected ||
                selections?.includes("154") ||
                selections?.includes("153")
              }
              disabled={!isEditMode}
              onChange={(e) => {
                let items = [...selections]
                if (e.target.checked) {
                  items = Array.from(
                    new Set([...items, ...(segmentPermissions as string[])]) //@: Get unique items
                  )
                } else {
                  for (let id of segmentPermissions as string[]) {
                    items.splice(items.indexOf(id.toString()), 1) //@: Remove item from segment permissions
                  }
                }

                handleChange(items) //@: Update the permissions
              }}
            />
          </div>
          <div className="select-none"  onClick={() => onClick()}>{data?.groupName} Permissions</div>
        </div>

        <div className="flex justify-end">
          <ChevronDownIcon
            className={`ease-in-out transition-all w-5 ${
              !open && "rotate-180"
            }`}
            onClick={() => onClick()}
          />
        </div>
      </div>

      <div
        className={`${
          !open ? `flex flex-col gap-1` : "hidden"
        } ease-in-out transition-all flex flex-col`}
      >
        <div className="mb-2 text-[#15294B] text-[14px]">
          Tick the boxes to select permissions you want to assign to this role
        </div>
        {data?.groups?.map((group: any, index) => (
          <div className="flex gap-3 items-center">
            <Checkbox
              checked={
                selections?.includes("154") ||
                selections?.includes("153") ||
                selections?.includes(group?.id?.toString())
              }
              disabled={!isEditMode}
              onChange={(e) => {
                const items = [...selections]
                if (e.target.checked) {
                  items.push(group?.id.toString())
                } else {
                  items.splice(items.indexOf(group?.id.toString()), 1)
                }
                handleChange(items)
              }}
            />
            <div className="text-[#15294B] text-[14px] select-none">
              {group?.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
