/**
 * An optimized H5 component.
 *
 * @param props
 * @returns h5
 */
export const H5: React.FC<JSX.IntrinsicElements["h5"]> = (props) => {
  return (
    <h5
      {...props}
      className={`text-lg font-circular ${props.className}`}
    >
      {props.children}
    </h5>
  )
}
