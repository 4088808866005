import { DocumentArrowDownIcon } from "@heroicons/react/24/outline"
import { forwardRef } from "react"
import ReactToPrint from "react-to-print"

type Props = {
  title: string
}

export const AppPdfDownloadButton = forwardRef(({ title }: Props, ref: any) => {
  return (
    <ReactToPrint
      content={() => ref.current}
      trigger={() => (
        <button className="px-4 py-2 font-circular !text-sm text-white rounded-md bg-red-400 flex items-center">
          <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
          <span>PDF</span>
        </button>
      )}
      documentTitle={`${title}.pdf`}
    />
  )
})
