import PrevNextButton from "pages/dashboard/people/people/form/PrevNextButton"
import {
  Button,
  CheckboxInput,
  H7,
  Label,
  P2,
  TextAnchor,
} from "../../../../components"
import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import { Form } from "antd"
import { useAppNotificationStore, useAuthStore } from "stores"
import { KYCWalletBody } from "./interface/kyc"
import { usePost } from "utils/useFetch"
import { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { useNavigate } from "react-router-dom"
import { useCustomizationSettingsStore } from "stores/customization-settings"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

export const TermsAndConditionsTab = ({
  setActiveKey,
  isActive,
  redirectUrl,
}: {
  isActive: boolean
  userInfo: KYCWalletBody
  handleUpdateKYCInfo: Function
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>
  redirectUrl?: string
}) => {
  const navigate = useNavigate()
  const [termsAgreed, setTermsAgreed] = useState(false)
  const { toast } = useAppNotificationStore()
  const [form] = Form.useForm()
  const { customizationSettings } = useCustomizationSettingsStore()
  const queryClient = useQueryClient()

  const { isLoading: isSubmittingKYC, mutate: submitForVerification } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/company/submit_verification`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (redirectUrl) {
          navigate(redirectUrl)
          queryClient.invalidateQueries({
            queryKey: ["/admin/company/view/settings?id=verify&x="],
          })
          return
        }
        mixPanelEvent("settings-integration-payment-config-edit-success")
        toast.success(
          <div className="flex flex-col gap-1">
            <H7 className="">Excellent. Your KYC responses are with us</H7>{" "}
            <P2>
              We'll run them by our payment provider and create your PaidHR
              Wallet afterwards. We'll also contact you for more information -
              if required.{" "}
            </P2>{" "}
          </div>
        )
        navigate("/dashboard/wallets/overview")
        queryClient.invalidateQueries({
          queryKey: ["/admin/company/view/settings?id=verify&x="],
        })
      },
    })

  return (
    <FormSectionWrapper
      name="Terms and conditions"
      title="Terms and conditions"
      isActive={isActive}
      layout="vertical"
      form={form}
      // onFinish={onFinish}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      footerBtns={
        <div className="flex gap-3 justify-end">
          <Button
            color="secondary"
            title="Previous"
            onClick={() => setActiveKey(["3"])}
            type="button"
          >
            Previous
          </Button>

          <Button
            color="primary"
            title="Submit"
            onClick={() => {
              mixPanelEvent("settings-integration-payment-config-edit-init")
              submitForVerification()
            }}
            submitting={isSubmittingKYC}
            disabled={termsAgreed ? false : true}
          >
            Submit
          </Button>
        </div>
      }
    >
      <div className="h-full w-full py-10 bg-white">
        <div className="px-8 flex flex-col gap-7">
          <div className="flex gap-2">
            <CheckboxInput
              id="agree"
              className="w-4 h-4"
              checked={termsAgreed}
              onChange={() => setTermsAgreed(!termsAgreed)}
            />
            <Label
              htmlFor="agree"
              className="text-sm text-neutral500"
            >
              By clicking submit, you are agreeing to{" "}
              <TextAnchor
                title="Terms and Condition"
                href="https://www.paidhr.com/terms-of-use"
                className="text-primary500"
              >
                PaidHR's Terms and Conditions.
              </TextAnchor>
            </Label>
          </div>
        </div>
      </div>
    </FormSectionWrapper>
  )
}
