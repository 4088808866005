import { Alert, QRCode, Spin } from "antd"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { ReactNode, useState } from "react"

import { useGet } from "utils/useFetch"
import parser from "html-react-parser"
import { Button } from "components"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { AppOTPInput } from "components/inputs/AppOTPInput"

const useMessageAlert = (onSuccess?: Function) => {
  const alertMessage = (data: any) => {
    if (data?.status === "success") {
      data?.msg && toast.success(data?.msg || "Action successful")
      onSuccess?.()
    } else {
      data?.msg && toast.error(data?.msg || "An error has occurred.")
    }
  }

  return { alertMessage }
}

export default function TwoFaSetupPage() {
  const { data, isLoading } = useGet<any>({
    url: `/account/2fa/setup_info`,
  })
  const { data: twoFaStatus, isLoading: gettingStatus } = useGet<any>({
    url: `/account/2fa/details`,
  })

  const navigate = useNavigate()

  const { alertMessage: alertEnableMessage } = useMessageAlert(() => {
    navigate(-1)
  })
  const { alertMessage: alertDisableMessage } = useMessageAlert(() => {
    navigate(-1)
  })

  const { mutate, isLoading: enabling } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(`/account/2fa/enable`, {
        code: otp,
      })
      return res.data
    },
    onSuccess(data, variables, context) {
      alertEnableMessage(data)
    },
  })
  const { mutate: disable, isLoading: disabling } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(`/account/2fa/disable`, {
        code: otp,
      })
      return res.data
    },
    onSuccess(data, variables, context) {
      alertDisableMessage(data)
    },
  })

  const [otp, setOtp] = useState("")

  const disabled = !otp || otp.length < 6

  return (
    <DashboardWrapper>
      <Spin spinning={isLoading || gettingStatus}>
        <SegmentWrapper
          title="Two Factor Authentication"
          subtitle="Two factor authentication can keep the bad guys out even if they have your password."
        >
          {!twoFaStatus?.twoFactorSetup ? (
            <div className="lg:px-[60px] md:px-6 px-4 relative">
              <Alert
                type="info"
                showIcon
                message="Go through the following steps to setup this app in your authenticator app"
                className="w-[100%] lg:w-[60%]"
                icon={<InformationCircleIcon className="w-4 h-4" />}
              />
              <div className="flex flex-col gap-6 mt-5 mb-[10%]">
                <ContentRedender
                  title="1. Download the Authenticator app"
                  subtitle="Download a two-factor authenticator app like Microsoft Authenticator or Google Authenticator."
                />
                <ContentRedender
                  title="2. Scan QR Code"
                  subtitle={
                    parser(`Scan the QR Code or enter this key <strong>
                ${data?.secret_key}
                </strong> into your two-factor authenticator app.`) as string
                  }
                  content={
                    <QRCode
                      value={`${data?.barcode_url}`}
                      bordered={false}
                      type="svg"
                      size={250}
                    />
                  }
                />
                <ContentRedender
                  title="3. Verify Code"
                  subtitle="Once you have scanned the QR Code or entered the key, your two-factor authentication app will provide you with a 6-digit unique code. Enter the code to activate two-factor authentication."
                  content={
                    <AppOTPInput
                      otp={otp}
                      setOtp={setOtp}
                    />
                  }
                />
              </div>
            </div>
          ) : (
            <div className="lg:px-[60px] md:px-6 px-4 relative mb-[10%]">
              <ContentRedender
                title="Verify Code"
                subtitle="Enter the code from your two-factor authenticator app."
                content={
                  <AppOTPInput
                    otp={otp}
                    setOtp={setOtp}
                  />
                }
              />
            </div>
          )}

          <div className="flex justify-end gap-3 mt-6 border-t pr-6 pt-6 pb-4 absolute bottom-0 right-0 w-full bg-white">
            <Button
              color="secondary"
              onClick={() => {
                navigate(-1)
              }}
            >
              Cancel
            </Button>

            {twoFaStatus?.twoFactorEnabled ? (
              <Button
                color="primary"
                type="submit"
                submitting={disabling}
                onClick={() => {
                  disable()
                }}
                disabled={disabled}
              >
                Disable
              </Button>
            ) : (
              <Button
                color="primary"
                type="submit"
                submitting={enabling}
                onClick={() => {
                  mutate()
                }}
                disabled={disabled}
              >
                Enable
              </Button>
            )}
          </div>
        </SegmentWrapper>
      </Spin>
    </DashboardWrapper>
  )
}

const ContentRedender = ({
  title,
  content,
  subtitle,
}: {
  title: string
  subtitle: string
  content?: ReactNode
}) => {
  return (
    <div>
      <div className="text-[16px] font-[600] ">{title}</div>
      <div className="text-[14px] font-[400] text=[#42526D] pr-12">
        {subtitle}
      </div>
      {content && <div className="my-2">{content}</div>}
    </div>
  )
}
