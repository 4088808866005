import { Button } from "components"

const PrevNextButton = ({
  showPrevButton = true,
  onClickPrev,
  isEndOfForm = false,
  disabled = false,
  submitting = false,
  nextBtnTitle = "Next",
  prevBtnTitle = "Previous",
}: {
  showPrevButton?: boolean
  onClickPrev?: () => void
  isEndOfForm?: boolean
  disabled?: boolean
  submitting?: boolean
  nextBtnTitle?: string
  prevBtnTitle?: string
}) => {
  return (
    <div
      className={`mt-2 flex justify-end ${showPrevButton ? "gap-4 h-fit" : ""}`}
    >
      {showPrevButton && (
        <Button
          onClick={(e) => {
            e.preventDefault()
            onClickPrev?.()
          }}
          color="neutral"
          disabled={submitting}
        >
          {prevBtnTitle}
        </Button>
      )}

      <Button
        color="primary"
        type="submit"
        disabled={disabled}
        submitting={submitting}
      >
        {nextBtnTitle}
      </Button>
    </div>
  )
}

export default PrevNextButton
