import React, { useState } from "react"
import { Button, Label } from "components"
import AntToggle from "components/inputs/AntToggle"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { CustomInput, CustomSelect } from "components/new-stuff/form"
import { Form, Select, Spin } from "antd"
import { useGet } from "utils/useFetch"
import { IBanksData } from "pages/dashboard/wallets/interface/direct-debit"
import { newBaseUrl, walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { queryClient } from "index"
import { IResolveBank } from "../interface"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
interface Props {
  modal: boolean
  onClose: any
}
const AddBeneficiary = ({ modal, onClose }: Props) => {
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const [accountName, setAccountName] = useState("")
  const selectedBank = Form.useWatch("bank", form)
  const accountNumber = Form.useWatch("account_number", form)

  const { isLoading: getBanksLoading, data: banksData } = useQuery<any>({
    queryKey: [`${walletBaseUrl}/v1/wallets/banks`],
    queryFn: async () => {
      const res = await axiosInstance.get(`${walletBaseUrl}/v1/wallets/banks`, {
        headers: {
          api_key: walletPublicKey,
        },
      })
      return res.data
    },

    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,

    onSuccess: (data: any) => {},
  })

  const { isLoading: getBankDetails, data: bankDetails } =
    useQuery<IResolveBank>({
      queryKey: [`${walletBaseUrl}/v1/wallets/resolve-account`],
      queryFn: async () => {
        const res = await axiosInstance.post(
          `${walletBaseUrl}/v1/wallets/resolve-account`,
          {
            accountNumber: accountNumber,
            bankCode: selectedBank,
            currency: "NGN",
          },

          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },
      enabled: selectedBank !== "" && accountNumber?.toString().length === 10,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,

      onSuccess: (data: any) => {
        if (data.success) {
          setAccountName(data?.data?.accountName)
        } else {
        }
      },
    })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res: any = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res?.data
    },
    onSuccess: (data: any) => {
      onClose()

      queryClient.invalidateQueries({
        queryKey: [
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
        ],
      })
    },
    onError: (data: any) => {
      {
        data.message && toast.error(data.message)
      }
    },
  })
  const handleSummit = (values: any) => {
    submit({
      accountNumber: values?.account_number || "090404044593",
      bankCode: values?.bank,
      type: "bank",
      currency: "NGN",
      // "userGroupId": "padeCompanyId"
    })
  }
  return (
    <CustomModal
      width={603}
      open={modal}
      onCancel={() => {
        onClose()
      }}
      title="Add beneficiary"
      closable
    >
      <div className="h-full w-full flex flex-col gap-6 justify-center items-center">
        <div>
          <p className="text-[#7A8699] text-sm mb-4">
            Save a beneficiary to quickly make transfers and eliminate
            unnecessary repeated actions.
          </p>
          <Form
            layout="vertical"
            form={form}
            onFinish={accountName ? handleSummit : undefined}
          >
            <>
              <div className="w-full">
                <div>
                  <Form.Item
                    label={
                      <span className="text-neutral300 text-sm  font-circular ">
                        Bank name
                      </span>
                    }
                    name="bank"
                    rules={[
                      { required: true, message: "Please enter this field!" },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={getBanksLoading}
                      onChange={(value) => {
                        form.setFieldValue("account_number", "")
                        setAccountName("")
                      }}
                      options={banksData?.data?.map((item: any) => ({
                        label: item.name,
                        value: item.code,
                      }))}
                      filterOption={(input, option) => {
                        if (option) {
                          const value = (option?.label as string) || ""
                          return (
                            value
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          )
                        } else {
                          return false
                        }
                      }}
                      placeholder=""
                    />
                  </Form.Item>
                </div>
                <CustomInput
                  label="Account number"
                  name="account_number"
                  type="number"
                  required
                  extra={
                    selectedBank !== "" &&
                    accountNumber?.toString().length === 10 &&
                    getBankDetails ? (
                      <div>
                        <Spin className="h-4 w-4" />
                      </div>
                    ) : (
                      <span className="text-xs">{accountName}</span>
                    )
                  }
                  onChange={() => setAccountName("")}
                />
              </div>
              {/* )} */}
            </>
            <div className="justify-end items-center gap-4 flex">
              <Button
                color="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={isLoading || accountName === ""}
                submitting={isLoading}
                // onClick={handleAddBeneficiary}
              >
                Save{" "}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </CustomModal>
  )
}

export default AddBeneficiary
