export const formatDateReadable = (inputDate: string): string => {
  const date = new Date(inputDate)

  // Format the month using short form (e.g., "Jun")
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    date
  )

  const day = date.getDate()

  // Format the day with appropriate suffix
  const daySuffix =
    day === 1 || day === 21 || day === 31
      ? "st"
      : day === 2 || day === 22
      ? "nd"
      : day === 3 || day === 23
      ? "rd"
      : "th"

  const formattedDate = `${month} ${day}${daySuffix}, ${date.getFullYear()}`

  return formattedDate
}

export const formatTimeReadble = (inputDate: string): string => {
  const date = new Date(inputDate)

  // Format the month using short form (e.g., "Jun")
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    date
  )

  const day = date.getDate()

  // Format the day with appropriate suffix
  const daySuffix =
    day === 1 || day === 21 || day === 31
      ? "st"
      : day === 2 || day === 22
      ? "nd"
      : day === 3 || day === 23
      ? "rd"
      : "th"

  // Format the time as hours:minutes
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`

  return `${formattedTime}`
}
