import { useState } from "react"
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline"
import EWAAccountImg from "assets/illustrations/ewa-account.svg"
import { Link, useNavigate } from "react-router-dom"
import TransactionsTemplate from "../components/TransactionsTemplate"
import { IEarningsWalletData, IWithdrawalData } from "../interface/wallet"
import { useGet } from "utils/useFetch"
import { newBaseUrl, walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { formatCurrencyNoSymbol } from "utils/currency"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { useAppNotificationStore, useAuthStore } from "stores"
import { Button } from "components"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { useQuery } from "@tanstack/react-query"
import { IUserWalletID } from "../wallet/kyc/interface"
import { axiosInstance } from "utils/request"
import EWAToWallet from "./EWAToWallet"
export const divideEarningsByPercent = (number: number, percent: number) => {
  const decimalPercent = percent / 100
  const calculatedResult = number * decimalPercent
  return calculatedResult
}
const EarnedWageAccess = () => {
  const [_, copy] = useCopyToClipboard()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const EWAWalletWithdrawalDisabled =
    user?.userOrganisationId === "108" ? true : false

  const initialShowPopup = localStorage.getItem("popupDismissed") !== "true"
  const [showPopup, setShowPopup] = useState(
    EWAWalletWithdrawalDisabled ? false : initialShowPopup
  )

  const {
    isLoading: isEarningWalletLoading,
    data: earningWalletData,
    refetch,
  } = useGet<IEarningsWalletData>({
    url: `${newBaseUrl}/v1/earnings/wallet`,
    external: true,
    retry: 0,
    onSuccess: (data: any) => {
      mixPanelEvent("empl-ewa-page-load")
    },
  })
  const { isLoading: isLoadingWalletData, data: walletData } =
    useQuery<IUserWalletID>({
      queryKey: [
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}?populate=virtualAccounts`,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      enabled: EmployeeWalletID ? true : false,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const invalidateQueries = () => {
    refetch()
  }
  const { isLoading: isWithdrawalsLoading, data: withdrawalsData } =
    useGet<IWithdrawalData>({
      url: `${newBaseUrl}/v1/employee/withdrawals`,
      external: true,
      retry: 0,
      onSuccess: (data: any) => {
        mixPanelEvent("empl-ewa-transaction-listing")
      },
    })
  const paidHrData = [
    {
      title: "Your wallet ID",
      value: walletData?.data?.walletId || "-",
      subValue: walletData?.data?.walletId || "-",
    },
  ]
  const previewData = [
    {
      title: "Bank Name",
      value: earningWalletData?.data?.employee?.bankName || "-",
    },
    {
      title: "Account Name",
      value: earningWalletData?.data?.employee?.bankAccountName || "-",
    },
    {
      title: "Account Number",
      value: earningWalletData?.data?.employee?.bankAccountNumber || "-",
      subValue: true,
    },
  ]

  const today = new Date()

  const currentMonth = today.getMonth()
  const currentYear = today.getFullYear()

  const handleClosePopup = () => {
    setShowPopup(false)
    localStorage.setItem("popupDismissed", "true")
  }
  return (
    <DashboardWrapper>
      {isEarningWalletLoading ? (
        <div className=" z-50 h-screen flex justify-center items-center right-0 top-0 left-0 bottom-0 py-10">
          <LoadingIndicatorWhite />
        </div>
      ) : earningWalletData?.data ? (
        <div className="">
          <div className="lg:h-[284px] flex flex-col lg:grid grid-cols-5 gap-4 mb-10">
            <div className="col-span-3 h-full  flex flex-col">
              <div className="h-[112px] bg-primary500 py-[27px] px-4 lg:px-[30px] relative">
                <h6 className=" text-padeLightBlue font-circular text-sm mb-3">
                  Accrued amount (50% 0f your daily earnings){" "}
                </h6>
                <h4 className="text-white  font-bold font-circular text-xl">
                  ₦
                  {formatCurrencyNoSymbol(
                    Number(
                      divideEarningsByPercent(
                        earningWalletData?.data?.totalEarnings || 0,
                        50
                      )
                    )
                  )}
                </h4>
                <div className="absolute right-0 bottom-0">
                  <img
                    src={
                      "https://padefiles.blob.core.windows.net/images/9d15c5c9-b5cf-406d-b8cf-3452b72bf8e0.svg"
                    }
                    alt=""
                    className="w-[60px] h-[60px] lg:w-[98px] lg:h-[98px]"
                  />
                </div>
              </div>
              <div className="flex-grow flex-col   bg-white py-[23px] px-4 lg:px-[62px]">
                <div className=" flex justify-between w-full mb-[25px]">
                  <div>
                    <h6 className="font-circular  font-bold text-lg lg:text-[25px] text-padeBlack mb-1">
                      ₦
                      {formatCurrencyNoSymbol(
                        Number(earningWalletData?.data?.availableBalance ?? 0)
                      )}
                    </h6>
                    <p className="text-neutral300 font-circular text-xs">
                      Balance
                    </p>
                  </div>
                  <div className="  h-[59px] border border-[#E2E4E9]" />

                  <div>
                    <h6 className="font-circular  font-bold text-lg lg:text-[25px] text-padeBlack mb-1">
                      ₦
                      {formatCurrencyNoSymbol(
                        Number(earningWalletData?.data?.totalWithdrawn ?? 0)
                      )}
                    </h6>
                    <p className="text-neutral300 font-circular text-xs">
                      Amount Taken
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <Button
                    color="primary"
                    className="w-full"
                    onClick={() => {
                      if (earningWalletData?.data?.employee?.enrollmentStatus) {
                        navigate(
                          "/employee/dashboard/wallet/earned-wage-access/withdrawal"
                        )
                        mixPanelEvent("empl-ewa-withdrawal-process-started")
                      } else {
                        navigate(
                          "/employee/dashboard/wallet/earned-wage-access/pin"
                        )
                      }
                    }}
                  >
                    Withdraw
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-span-2  h-fit lg:h-full  bg-white rounded-5px py-5 lg:pt-[41px] px-4 lg:px-5">
              <div className="flex justify-between items-center">
                <div>
                  <h5 className=" text-sm lg:text-base font-circular text-padeBlack font-bold mb-0.5">
                    EWA Receiving Account
                  </h5>
                  <p className="text-[rgba(108,122,147,0.6)] font-avenir ">
                    Your withdrawals will now be sent to:{" "}
                  </p>
                </div>
                <img
                  src={EWAAccountImg}
                  alt=""
                  className="h-[45px] w-[45px] lg:h-[70px] lg:w-[70px]"
                />
              </div>
              {EWAWalletWithdrawalDisabled ? (
                <div className=" bg-[#EEF4FE] rounded px-4 lg:px-6 py-4 mt-10 flex-grow gap-3  flex flex-col">
                  {previewData?.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col lg:flex-row justify-between items-center"
                    >
                      <p className="text-[#6C7A93] font-circular text-xs lg:text-sm">
                        {item?.title}
                      </p>
                      <p className="text-padeBlack  font-avenir font-bold text-xs lg:text-[13px] capitalize items-center flex ">
                        {item?.value}
                        {item?.subValue && (
                          <button
                            onClick={() => {
                              copy(String(item?.value))
                              toast.success("Copied")
                            }}
                            className="ml-0 sm:ml-2 shrink-0  text-primary500 p-1 rounded-full"
                          >
                            <ClipboardDocumentIcon className="w-4 h-4" />
                          </button>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className=" flex-grow px-4 lg:px-5 py-4 mt-10 bg-[#EEF4FE] rounded ">
                  <h6 className="text-padeBlack font-circular font-bold text-base">
                    PaidHR Wallet
                  </h6>
                  <p className="text-[#6C7A9399]  font-avenir  text-sm">
                    Instant access with zero fees{" "}
                  </p>
                  <div className="gap-3 mt-4  flex flex-col">
                    {paidHrData?.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col lg:flex-row justify-between items-center"
                      >
                        <p className="text-[#6C7A93] font-circular text-xs lg:text-sm">
                          {item?.title}
                        </p>
                        <p className="text-padeBlack  font-avenir font-bold text-xs lg:text-[13px] capitalize items-center flex ">
                          {item?.value}
                          {item?.subValue && (
                            <button
                              onClick={() => {
                                copy(String(item?.value))
                                toast.success("Copied")
                              }}
                              className="ml-0 sm:ml-2 shrink-0  text-primary500 p-1 rounded-full"
                            >
                              <ClipboardDocumentIcon className="w-4 h-4" />
                            </button>
                          )}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mb-8">
            <Link
              to={"/employee/dashboard/christmas-village"}
              className="block"
              onClick={() => mixPanelEvent("christmas-village-init")}
            >
              <img
                src="https://padefiles.blob.core.windows.net/images/25d89059-95cd-484f-be96-6e2e14b681b1.png"
                className="min-h-[120px] object-contain max-h-[250px] object-left shadow-md"
                height={150}
              />
            </Link>
          </div>
          <CustomCard
            title="Recent Transactions"
            ctaBtn={
              <button
                className="text-primary500 text-sm"
                onClick={() =>
                  navigate(
                    "/employee/dashboard/wallet/earned-wage-access/transactions"
                  )
                }
              >
                See All
              </button>
            }
          >
            <TransactionsTemplate
              data={withdrawalsData?.data?.docs.slice(0, 5) || []}
              isLoading={isWithdrawalsLoading}
            />
          </CustomCard>
        </div>
      ) : (
        <div className="w-full min-h-[400px] justify-center items-center flex">
          <div className=" flex flex-col justify-center items-center w-full sm:w-[585px]  px-0 sm:px-8 font-avenir mt-20">
            <img
              src={
                "https://padefiles.blob.core.windows.net/images/f14b53b2-a4de-4df4-8eff-df5673a3ce0e.svg"
              }
              alt="NoWallet"
              className="w-[338px] h-[253px]"
            />
            <h5 className="mt-9 text-xl sm:text-2xl font-bold text-[#15294B] text-center font-circular">
              You're in, but your HR hasn't given you the green light for Earned
              Wage Access.!
            </h5>
            <p className="text-sm  text-bluesubtext mt-1 text-center mb-10 font-avenir">
              Don't miss out on instant access to your earnings.. Let's nudge
              your HR to get this amazing benefit rolling!
            </p>
          </div>
        </div>
      )}
      <EWAToWallet
        open={showPopup}
        onClose={handleClosePopup}
      />
    </DashboardWrapper>
  )
}
export default EarnedWageAccess
