import React from "react"
import { formatCurrencyNoSymbol } from "utils/currency"
import TotalEmployee from "assets/images/total_employee.svg"
import EmployeeLeave from "assets/images/employee_leave.svg"
import { PayoutCard } from "./PayoutCard"

const TopCards: React.FC<{
  data: any
  typeId?: string
}> = ({ data, typeId }) => {
  const cards = [
    ...(typeId === "2"
      ? [
          {
            title: "Total Tax",
            value: data?.tax_amount
              ? formatCurrencyNoSymbol(Number(data?.tax_amount))
              : 0,
            icon: EmployeeLeave,
          },
          {
            title: "PAYE",
            value: data?.paye_amount
              ? formatCurrencyNoSymbol(Number(data?.paye_amount))
              : 0,
            icon: EmployeeLeave,
          },
          {
            title: "WHT",
            value: data?.wht_amount
              ? formatCurrencyNoSymbol(Number(data?.wht_amount))
              : 0,
            icon: TotalEmployee,
          },
        ]
      : []),
    ...(typeId === "3"
      ? [
          {
            title: "Total Pension",
            value: data?.pension_amount
              ? formatCurrencyNoSymbol(Number(data?.pension_amount))
              : 0,
            icon: EmployeeLeave,
          },
        ]
      : []),
    ...(typeId === "4"
      ? [
          {
            title: "Total NHF",
            value: data?.nhf_amount
              ? formatCurrencyNoSymbol(Number(data?.nhf_amount))
              : 0,
            icon: EmployeeLeave,
          },
        ]
      : []),
    ...(typeId === "8"
      ? [
          {
            title: "Total EWA",
            value: data?.ewa_amount
              ? formatCurrencyNoSymbol(Number(data?.ewa_amount))
              : 0,
            icon: EmployeeLeave,
          },
        ]
      : []),
    ...(typeId === "9"
      ? [
          {
            title: "Total NSITF",
            value: data?.nsitf_amount
              ? formatCurrencyNoSymbol(Number(data?.nsitf_amount))
              : 0,
            icon: EmployeeLeave,
          },
        ]
      : []),
    ...(typeId === "10"
      ? [
          {
            title: "Total ITF",
            value: data?.itf_amount
              ? formatCurrencyNoSymbol(Number(data?.itf_amount))
              : 0,
            icon: EmployeeLeave,
          },
        ]
      : []),
  ]

  return (
    <div className=" flex overflow-x-auto scrollbar-hide  mb-4 gap-[11px] justify-start">
      {cards.map((card, index) => (
        <PayoutCard
          key={index}
          title={card.title}
          value={card.value}
          icon={card.icon}
          type={false}
        />
      ))}
    </div>
  )
}

export default TopCards
