import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import React, { useState } from "react"
import FormSectionWrapper from "../FormSectionWrapper"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useServerResponse } from "hooks/useServerResponse"
import { axiosInstance } from "utils/request"
import { useGet } from "utils/useFetch"
import { Form, Input } from "antd"
import { Button } from "components"
import { AppPeoplePicker } from "components/inputs/select/AppPeoplePicker"
import { useNavigate } from "react-router-dom"

type Props = {}

const className = "px-4 md:px-6 xl:px-[60px] pb-4"

const CreateAnnouncementGroup = (props: Props) => {
  const [activeForm, setActiveForm] = useState(0)
  const [form] = Form.useForm()
  const [peopleForm] = Form.useForm()
  const queryClient = useQueryClient()
  const [employees, setEmployees] = useState<string[]>([])

  const navigate = useNavigate()

  const { isLoading: isGetPeopleLoading, data: peopleData = [] } = useGet<
    any[]
  >({
    url: `/admin/people/getforselection?active=true`,
  })

  const goBackToGroupList = () =>
    navigate(`/dashboard/settings/announcement_groups`)

  const serveResponse = useServerResponse()
  const { mutate, isLoading } = useMutation({
    mutationFn: async (payload: IPayload) => {
      const peopleIDs =
        (payload?.person_list &&
          payload?.person_list?.map((it) =>
            peopleData?.find((x) => x.full_name === it)?.person_id.toString()
          )) ||
        []
      payload.person_list = peopleIDs as string[]
      const res = await axiosInstance.post(
        `/admin/announcements/add_group`,
        payload
      )
      return res.data
    },
    onSuccess(data, variables, context) {
      serveResponse(data)
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: [`/admin/announcements/selectgroups`],
        })
        goBackToGroupList()
      }
    },
  })
  const { mutateAsync: checkGroupName } = useMutation({
    mutationFn: async (description: string) => {
      const res = await axiosInstance.get(
        `/admin/announcements/check_group?description=${description}&id=-1`
      )
      return res.data
    },
  })
  return (
    <DashboardWrapper>
      <div className="flex flex-col gap-6">
        <FormSectionWrapper
          isActive={activeForm === 0}
          layout="vertical"
          title="Group Title"
          form={form}
          onFinish={() => {
            setActiveForm(1)
          }}
          footerBtns={
            <div className="flex justify-end gap-4 ">
              <Button
                color="secondary"
                onClick={() => {
                  goBackToGroupList()
                }}
              >
                Back
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={(e) => {
                  e.preventDefault()
                  form.submit()
                }}
              >
                Next
              </Button>
            </div>
          }
        >
          <div className={className}>
            <Form.Item
              name={"description"}
              validateTrigger="onBlur"
              validateDebounce={500}
              label="Title"
              validateStatus="validating"
              status="validating"
              rules={[
                {
                  validator(rule, value, callback) {
                    return new Promise(async (res, rej) => {
                      if (!value) {
                        return rej("Please add a group title")
                      }
                      const data = await checkGroupName(value)
                      if (data?.status === "success") {
                        return res("")
                      }
                      if (data?.msg && data?.status === "error") {
                        rej(data?.msg)
                      }
                    })
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </FormSectionWrapper>

        <FormSectionWrapper
          isActive={activeForm === 1}
          layout="vertical"
          title="Choose Employees"
          subtitle="Select employees that you want to add to this group"
          onFinish={(val: IPayload) => {
            val.description = form.getFieldValue("description")
            mutate(val)
          }}
          form={peopleForm}
          footerBtns={
            <div className="flex justify-end gap-4">
              <Button
                color="secondary"
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  setActiveForm(0)
                }}
              >
                Previous
              </Button>
              <Button
                submitting={isLoading}
                type="submit"
                color="primary"
                onClick={(e) => {
                  e.preventDefault()
                  peopleForm.submit()
                }}
              >
                Save
              </Button>
            </div>
          }
        >
          <div className={className}>
            <AppPeoplePicker
              employees={employees}
              fieldName="person_list"
              form={peopleForm}
              onUpdate={(items) => setEmployees(items)}
              useCustomData={false}
            />
          </div>
        </FormSectionWrapper>
      </div>
    </DashboardWrapper>
  )
}

export default CreateAnnouncementGroup

interface IPayload {
  description: string
  person_list: string[]
  update_type: string
}
