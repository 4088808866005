import Profile from "pages/dashboard/profile/Profile"

const EmployeeProfile: React.FC<{}> = () => {
  return (
    <div>
      <Profile />
    </div>
  )
}

export default EmployeeProfile
