import { Dropdown } from "antd"
import type { ItemType } from "antd/lib/menu/hooks/useItems"

const ResponsiveActinBtn = ({
  items,
  children,
}: {
  items?: ItemType[]
  children: React.ReactNode
}) => {
  return (
    <>
      <div className="lg:hidden h-full">
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
          }}
          className=""
        >
          <button className="border rounded-[4px] block h-full px-1 py-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M10.5 19C10.5 20.1 11.4 21 12.5 21C13.6 21 14.5 20.1 14.5 19C14.5 17.9 13.6 17 12.5 17C11.4 17 10.5 17.9 10.5 19Z" />
              <path d="M10.5 5C10.5 6.1 11.4 7 12.5 7C13.6 7 14.5 6.1 14.5 5C14.5 3.9 13.6 3 12.5 3C11.4 3 10.5 3.9 10.5 5Z" />
              <path
                d="M12.5 14C13.6 14 14.5 13.1 14.5 12C14.5 10.9 13.6 10 12.5 10C11.4 10 10.5 10.9 10.5 12"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Dropdown>
      </div>
      <div className="hidden lg:block">{children}</div>
    </>
  )
}

export default ResponsiveActinBtn
