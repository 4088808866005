import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"

/**
 * An optimized search input.
 */

export const SearchInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    noBorder?: boolean
    borderColor?: String
    placeholder?: String
  }
> = ({ borderColor, noBorder = false, placeholder, ...props }) => {
  return (
    <div
      className={`relative flex rounded-[4px] w-full gap-[12px]   my-auto h-9   items-center px-[12px] ${
        noBorder ? borderColor : "border-neutral80 border"
      }`}
    >
      <div className=" top-0  h-full flex items-center text-accentText cursor-pointer">
        <MagnifyingGlassIcon className=" text-neutral80 w-4" />
      </div>
      <input
        type={"search"}
        placeholder={placeholder}
        {...props}
        className={`bg-white items-center gap-2.5 placeholder:text-neutral80 z-10 text-neutral600 focus:!border-none focus:!ring-transparent focus:!outline-transparent ${props.className}`}
      />
      <div className="absolute top-0 right-100 h-full flex items-center m-1.5 text-neutral400 cursor-pointer">
        <ExclamationCircleIcon />
      </div>
    </div>
  )
}
