import { Form, Input, Spin } from "antd"
import React, { useEffect, useState } from "react"
import {
  CustomInput,
  CustomSelect,
} from "pages/dashboard/documents/custom-form"
import FormSectionWrapper from "pages/dashboard/extras/settings/FormSectionWrapper"
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/24/outline"
import { Button, DashboardContainer, Label } from "components"
import PrevNextButton from "pages/dashboard/extras/settings/form/PrevNextButton"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"

import { useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useGet, usePost } from "utils/useFetch"

import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { AddTemplateToSurvey } from "./AddTemplateToSurvey"
import AntDesignSelect from "components/antd/AntDesignSelect"
import { ICustomSurveyData } from "../../Templates/interface/template"
import { AddSurveyQuestion } from "./AddSurveyQuestion"

const FinishSurvey: React.FC<{}> = ({}) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")

  const [selectedTemplateId, setSelectedTemplateId] = useState("")

  const [errorMessage, setErrorMessage] = useState("")
  const [showNew, setShowNew] = useState(true)
  const [title, setTitle] = useState("")
  const {
    isLoading: isEmployeeLoading,
    data: templateData,
    isFetching: isFetchingEmpData,
  } = useGet<any>({
    url: `/admin/surveys/view/index?id=customize&x=${x}&s=`,
    enabled: !!x,
  })
  useEffect(() => {
    if (templateData) {
      setTitle(templateData?.survey?.description)
      templateData?.survey?.questions &&
      templateData?.survey?.questions.length > 0
        ? setShowNew(false)
        : setShowNew(true)
    }
  }, [templateData])

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`/admin/surveys/view/index?id=customize&x=${x}&s=`],
    })
  }

  const onSubmit = () => {
    if (x) {
      submitEdit({
        description: title,
        survey_id: x ? getIdFromKey(x || "") : "-1",
        survey_type_id: templateData?.survey?.survey_type_id,
        survey_template_id: selectedTemplateId,
        default_template: "no",
      })
    }
  }

  // FINISH SURVEY
  const { isLoading: isLoadingEdit, mutate: submitEdit } = useMutation({
    mutationFn: async (body: ITemplateBody) => {
      const res = await axiosInstance.post(`/admin/surveys/finish`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey`)

        {
          data.msg && toast.success(data.msg)
        }
      }
    },
  })

  return (
    <DashboardContainer>
      {x && isEmployeeLoading ? (
        <div className="h-[200px] items-center flex justify-center">
          <LoadingIndicatorWhite />
        </div>
      ) : (
        <div className="max-w-[1024px]   rounded-md space-y-[20px]">
          <div className="flex  gap-1 items-center">
            <h6 className=" uppercase text-neutral700 text-base  font-semibold ml-1">
              {templateData?.survey?.description}
            </h6>
          </div>
          <FormSectionWrapper
            name={`Finish ${templateData?.survey?.description}`}
            title={`Finish ${templateData?.survey?.description}`}
            subtitle="Once it has a title, you can continue setting it up "
            isActive={true}
            initialValues={{
              title: templateData?.survey?.description || "",
            }}
            layout="vertical"
            form={form}
            // onFinish={onFinish}
            style={{ maxWidth: 1024 }}
            autoComplete="off"
            footerBtns={
              <div className="flex gap-3 justify-end">
                <Button
                  color="primary"
                  title="Submit"
                  onClick={onSubmit}
                >
                  {isLoadingEdit ? <Spin /> : "Submit"}
                </Button>
              </div>
            }
            className="font-avenir"
          >
            <>
              <div className="grid grid-cols-2 gap-x-6  px-4 md:px-6 xl:px-[60px]">
                <div className="col-span-2">
                  <CustomInput
                    label="Survey title"
                    name="title"
                    required
                    className="w-full"
                    // onBlur={(e) => {
                    //   check(e.target.value)
                    // }}
                  />
                  {errorMessage !== "" && errorMessage}
                </div>
              </div>
              <AddSurveyQuestion
                x={x}
                isLoading={isFetchingEmpData}
                showNew={showNew}
                setShowNew={setShowNew}
                questions={templateData?.survey.questions || []}
                invalidateQueries={invalidateQueries}
              />
            </>
          </FormSectionWrapper>
        </div>
      )}
    </DashboardContainer>
  )
}
export default FinishSurvey
interface Values {
  title: string
  description: string
}
interface question {
  title: string
  alias: string
  questions: eachQuestion[]
}
interface eachQuestion {
  question_title: string
  question: string
  type_id: string
  type: string
  value_id: string
  value: string
}
interface ITemplateBody {
  survey_id: string
  survey_type_id: string
  description: string
  survey_template_id: string
  default_template: string
}
