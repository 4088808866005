import {
  Button,
  DashboardContainer,
  ModalWrapper,
  SearchInput,
} from "components"
import AntDCustomTable from "components/antd/AntDCustomTable"
import React, { useState, useEffect } from "react"
import { useGet, usePost } from "utils/useFetch"

import { Link, useLocation, useNavigate } from "react-router-dom"

import {
  EllipsisHorizontalIcon,
  InformationCircleIcon,
  PlusIcon,
} from "@heroicons/react/24/outline"
import { Dropdown, Input, Table } from "antd"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import getIdFromKey from "utils/getIdFromKey"

import moment from "moment"
import {
  IReviewListing,
  IReviewSetupData,
} from "pages/dashboard/tasks/performance/reviews/reviews/interface/reviews"
import MobileWarner from "components/new-stuff/ui/MobileWarner"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const ViewReviewListing: React.FC<{}> = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const taxId = getIdFromKey(x || "")
  const [search, setSearch] = useState("")
  const [activeReviewType, setActiveReviewType] = useState("Rounds")
  const [activeReviewId, setActiveReviewId] = useState("")
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeletId, setActiveDeleteId] = useState("")

  const { isLoading: isReviewRoundsLoading, data: reviewRoundsList } =
    useGet<IReviewListing>({
      url: `/admin/performance/selectreviewrounds?option_id=1&state_id=-1&status_id=-1`,
      onSuccess: (data: any) => {
        mixPanelEvent("empl-pf-review-listing")
      },
    })

  const TableData =
    reviewRoundsList?.data
      ?.filter((rev) => {
        const name = rev.qualified_description
        return name.toLowerCase().includes(search.toLowerCase())
      })
      .map((review, index) => {
        return {
          key: review.review_group_id,
          id: review.review_group_id,
          period: review?.start_date
            ? `(${review.start_date}-${review.due_date})`
            : `-`,
          title: review.qualified_description,
          status: review.review_group_state,
          action: review.review_group_key,
        }
      }) || []

  const TableColumns =
    [
      {
        title: "Title",
        dataIndex: "title",
        render: (title: string) => (
          <span className="font-avenir text-sm  text-[#42526D]">{title}</span>
        ),
      },
      {
        title: "Review Period",
        dataIndex: "period",
        render: (period: string) => (
          <div className="flex items-center gap-2 font-avenir text-sm  text-[#42526D]">
            {period}
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (statusText: string) => (
          <span
            className={` text-sm white  font-medium py-1 block text-center w-full rounded-[4px] font-avenir ${
              statusText === "Ready"
                ? "bg-success100 text-success800"
                : statusText === "Archived"
                ? "bg-danger100 text-danger500"
                : "bg-[#FEE3B1] text-[#E69702] w-full"
            }`}
          >
            {statusText}
          </span>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        render: (id: string) => (
          <Dropdown
            menu={{
              items: actionItems(id),
            }}
          >
            <button>
              <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
            </button>
          </Dropdown>
        ),
      },
    ] || []
  const actionItems = (id: string) => [
    {
      key: "1",
      label: <button onClick={() => handleClick(id)}>View</button>,
    },
    // {
    //   key: "2",
    //   label: (
    //     <button
    //       onClick={() => {
    //         setActiveDeleteId(getIdFromKey(id))
    //         setOpenDeleteConfirmation(true)
    //       }}
    //     >
    //       Delete
    //     </button>
    //   ),
    // },
  ]
  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: ["/admin/performance/selectreviewsettings"],
    })
    queryClient.invalidateQueries({
      queryKey: [
        `/admin/performance/selectreviewrounds?option_id=1&state_id=-1&status_id=-1`,
      ],
    })
  }
  const { isLoading: isSubmittingDelete, mutate: submitDelete } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/performance/remove_reviewtemplate?id=${activeDeletId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        setOpenDeleteConfirmation(false)
        invalidateQueries()

        {
          data.msg && toast.success(data.msg)
        }
      } else {
        setOpenDeleteConfirmation(false)

        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      setOpenDeleteConfirmation(false)

      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  //  three dots on each row

  const handleClick = (id: string) => {
    if (activeReviewId == id) {
      setActiveReviewId("")
    } else {
      navigate(`/employee/dashboard/reviews/view?id=${id}`)
    }
  }
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    // startTransition(() => {
    //   setTimeout(() => refetch(), 100)
    // })
  }

  return (
    <DashboardWrapper>
      <div className="relative">
        <div className="bg-white rounded-5px px-4 py-6 md:p-6">
          <h5 className=" text-lg text-neutral800 font-extrabold mb-4">
            Reviews
          </h5>
          <div className=" flex w-full  items-center justify-between ">
            <Input
              placeholder="Search"
              value={search}
              onChange={(e) => handleSearch(e)}
              // style={{ width: "180px" }}
              rootClassName="w-full sm:w-[250px]"
            />
          </div>

          <div className="mt-2">
            <Table
              columns={TableColumns}
              dataSource={TableData}
              loading={isReviewRoundsLoading}
              className="text-[#42526D] !font-avenir"
              scroll={{ x: `100%` }}
              pagination={{
                defaultPageSize: 20,
              }}
            />
          </div>
        </div>

        <NewModalWrapper
          open={openDeleteConfirmation}
          onClose={() => {
            setOpenDeleteConfirmation(false)
            setActiveDeleteId("")
          }}
        >
          <div className=" flex flex-col items-center">
            <div>
              <InformationCircleIcon
                width={70}
                height={70}
                className="text-[#FAB941]"
              />
            </div>

            <h6 className="text-[#15294B]  font-bold text-2xl my-3">
              Delete Template
            </h6>
            <p className="text-[#15294B] text-sm mb-6 text-center">
              If you delete this template,it would be removed from your list.
              Are you sure you want to continue?
            </p>
          </div>
          <div className="flex gap-4 ">
            <Button
              title="cancel"
              color="danger"
              className="w-full"
              onClick={() => {
                setOpenDeleteConfirmation(false)
                setActiveDeleteId("")
              }}
            >
              Cancel
            </Button>
            {isSubmittingDelete ? (
              <Button
                title="Next"
                color="primary"
                className="font-extrabold w-full"
              >
                <LoadingIndicatorWhite />
              </Button>
            ) : (
              <Button
                title="remove"
                color="primary"
                className="w-full"
                onClick={() => {
                  submitDelete()
                }}
              >
                Confirm
              </Button>
            )}
          </div>
        </NewModalWrapper>
      </div>
    </DashboardWrapper>
  )
}
export default ViewReviewListing
