import React from "react"
import { Switch } from "antd"

const AntToggle: React.FC<{
  name: string
  label: string
  checked?: boolean
  onChange: Function
  loading?: boolean
  disabled?: boolean
}> = ({ checked, onChange, loading, disabled }) => {
  const handleChange = (checked: boolean) => {
    console.log(`switch to ${checked}`)
    onChange(checked)
  }

  return (
    <Switch
      checked={checked}
      onChange={(e) => handleChange(e)}
      loading={loading}
      rootClassName={`${
        checked
          ? "!bg-primary500 border-[#38CB89]"
          : "!bg-[#ededed]   border-[#E69702]"
      } relative z-[1]`}
      disabled={disabled}
    />
  )
}
export default AntToggle
