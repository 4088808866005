import { Form, FormItemProps, Input, Select } from "antd"

const { Option } = Select

export const CustomInput = ({
  label,
  name,
  placeholder,
  required = false,
  maxLength = 50,
  onChange,
  onBlur,
  disabled,
  readOnly,
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      label={
        <span className="font-circular text-neutral300 text-sm  ">{label}</span>
      }
      name={name}
      rules={[
        { required, message: "Please enter this field!" },
        // {
        //   max: 150,
        //   message: "Input must not be more than 150 characters long",
        // },
      ]}
      validateTrigger="onBlur"
      {...restProps}
    >
      <Input
        onChange={onChange}
        placeholder={placeholder}
        className="text-neutral500  font-circular"
        onBlur={onBlur}
        maxLength={maxLength}
        readOnly={readOnly}
      />
    </Form.Item>
  )
}

export const CustomSelect = ({
  label,
  value,
  name,
  required = false,
  showSearch = true,
  placeholder,
  options,
  onChange,
  onSelectClick,
  isLoading = false,
  ...restProps
}: SelectItemProps) => {
  return (
    <Form.Item
      name={name}
      label={
        label && (
          <span className="font-circular text-neutral300 text-sm  ">
            {label}
          </span>
        )
      }
      rules={[{ required, message: "Please enter this field!" }]}
      {...restProps}
    >
      <Select
        loading={isLoading}
        showSearch={showSearch}
        placeholder={placeholder}
        onChange={onChange}
        value={value && value}
        allowClear
        onClick={onSelectClick}
        filterOption={(input, option) => {
          if (option) {
            const value = (option.value as string) || ""
            return value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } else {
            return false
          }
        }}
      >
        {options?.map((option, index) => (
          <Option
            key={index}
            value={option.value}
          >
            <span className="text-neutral500 font-circular">
              {option.label}
            </span>
          </Option>
        ))}
        {["first", "second"].map((item) => (
          <Option
            key={"empty" + item}
            disabled
            className="pointer-events-none"
          >
            {" "}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

type InputItemProps = FormItemProps & {
  maxLength?: number
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
}

type SelectItemProps = FormItemProps & {
  label?: string
  value?: string | null | any
  required?: boolean
  showSearch?: boolean
  onChange?: (e: any) => void
  onSelectClick?: VoidFunction
  placeholder: string
  options: { label: string; value: string }[] | undefined
  isLoading?: boolean
}
