import React from "react"

type props = {
  children: JSX.Element | JSX.Element[]
}

export const DashboardContainer: React.FC<props> = ({ children }) => {
  return (
    <div className="w-full h-full px-4 md:px-6 lg:px-10  py-5 mx-auto container">
      {children}
    </div>
  )
}
