import { Button, Form } from "antd"
import { Button as CustomButton } from "components"

const PrevNextButton = ({
  showPrevButton = true,
  onClickPrev,
  isEndOfForm = false,
  disabled = false,
}: {
  showPrevButton?: boolean
  onClickPrev?: () => void
  isEndOfForm?: boolean
  disabled?: boolean
}) => {
  return (
    <div
      className={`mt-2 flex justify-end ${showPrevButton ? "gap-4 h-fit" : ""}`}
    >
      {showPrevButton && (
        <CustomButton
          onClick={(e) => {
            e.preventDefault()
            onClickPrev?.()
          }}
          color="neutral"
          title="Previous"
          className="h-fit text-[#5D6B82] font-circular"
        >
          Previous
        </CustomButton>
      )}

      <Form.Item className="mb-0">
        <CustomButton
          color="primary"
          type="submit"
          disabled={disabled}
        >
          {isEndOfForm ? "Finish" : "Next"}
        </CustomButton>
      </Form.Item>
    </div>
  )
}

export default PrevNextButton
