import { Alert, Drawer, Form, Radio, Space } from "antd"
import React, { Dispatch, SetStateAction, useState } from "react"
import { ICurrencyInfo } from "../interface/payouts"
import { CloseOutlined } from "@ant-design/icons"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { AntDesignCheckbox } from "components/antd/AntDesignCheckbox"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { AppDownloadTemplateButton } from "components"

type Props = {
  open: boolean
  onClose: Dispatch<SetStateAction<boolean>>

  data: any
}
const DownloadPayment = ({ open, onClose, data }: Props) => {
  const [downloadVersion, setDownloadVersion] = useState("csv")
  const [redownloadVersion, setreDownloadVersion] = useState("csv")

  const [includeChequeProcessed, setIncludeChequeProcessed] = useState(false)
  return (
    <Drawer
      width={455}
      title={
        <span className=" text-padeBlack font-circular text-xl">
          Download Options{" "}
        </span>
      }
      open={open}
      // onClose={() => }
      closeIcon={false}
      className="bg-white relative"
      extra={
        <button onClick={() => onClose(false)}>
          <CloseOutlined className="h-6 w-6" />
        </button>
      }
    >
      <div className=" flex flex-col gap-10">
        {/* DOWNLOAD */}
        <div className="border-2 p-5 rounded">
          <h6 className="text-base text-padeBlack  font-circular mb-4">
            Download Payment File
          </h6>
          <p className="text-padeBlack font-circular text-sm mb-[31px]">
            Payment file will contain only unprocessed selected payments that
            have valid account details
          </p>
          <div className="my-4">
            <Form
              layout="vertical"
              className="[&_.ant-form-item]:!mb-1"
            >
              <AntDesignRadio
                label="Choose download version"
                name="access"
                value={downloadVersion}
              >
                <Space direction="horizontal">
                  <Radio
                    value="csv"
                    checked={downloadVersion === "csv" ? true : false}
                    onChange={() => {
                      setDownloadVersion("csv")
                    }}
                  >
                    CSV
                  </Radio>
                  <Radio
                    value="xlsx"
                    checked={downloadVersion === "xlsx" ? true : false}
                    onChange={() => {
                      setDownloadVersion("xlsx")
                    }}
                  >
                    XLS
                  </Radio>
                </Space>
              </AntDesignRadio>
              <AntDesignCheckbox
                id="agree"
                label={<p>Include Cheque Processed Payments.</p>}
                onChange={() =>
                  setIncludeChequeProcessed(!includeChequeProcessed)
                }
              />
            </Form>
            {includeChequeProcessed && (
              <div className="mt-4">
                {" "}
                <Alert
                  // message={alertInfo.title}
                  type="warning"
                  showIcon
                  // closable
                  icon={<ExclamationCircleIcon className=" h-6 w-6" />}
                  description={
                    "Only Cheque processed payments that have valid account details will be included"
                  }
                  className="invoice-alert font-avenir text-neutral500 text-sm w-full items-center mb-6"
                />
              </div>
            )}
          </div>
          <div className="w-full ">
            {downloadVersion == "csv" ? (
              <AppDownloadTemplateButton
                fileName={`${data?.description}`}
                url={
                  includeChequeProcessed
                    ? `/admin/payment_schedules/view/download_bulk_payment_csv_file_include_downloaded?id=${data?.payment_schedule_id}`
                    : `/admin/payment_schedules/view/download_bulk_payment_csv_file?id=${data?.payment_schedule_id}`
                }
                method="GET"
                type="csv"
                className="w-full"
                disabled={data?.can_download_payment_file ? false : true}
              />
            ) : (
              <AppDownloadTemplateButton
                fileName={`${data?.description}`}
                url={
                  includeChequeProcessed
                    ? `/admin/payment_schedules/view/download_bulk_payment_xls_file_include_downloaded?id=${data?.payment_schedule_id}`
                    : `/admin/payment_schedules/view/download_bulk_payment_xls_file?id=${data?.payment_schedule_id}`
                }
                method="GET"
                type="xlsx"
                className="w-full"
                disabled={data?.can_download_payment_file ? false : true}
              />
            )}
          </div>
        </div>
        {/* REDOWNLOAD */}
        <div className="border-2 p-5 rounded">
          <h6 className="text-base text-padeBlack  font-circular mb-4">
            Re-download Payment File
          </h6>
          <p className="text-padeBlack font-circular text-sm mb-[31px]">
            Payment file will contain only cheque processed payments that have
            valid account details.
          </p>
          <div className="my-4">
            <Form
              layout="vertical"
              className="[&_.ant-form-item]:!mb-1"
            >
              <AntDesignRadio
                label="Choose download version"
                name="access"
                value={redownloadVersion}
              >
                <Space direction="horizontal">
                  <Radio
                    value="csv"
                    checked={redownloadVersion === "csv" ? true : false}
                    onChange={() => {
                      setreDownloadVersion("csv")
                    }}
                  >
                    CSV
                  </Radio>
                  <Radio
                    value="xlsx"
                    checked={redownloadVersion === "xlsx" ? true : false}
                    onChange={() => {
                      setreDownloadVersion("xlsx")
                    }}
                  >
                    XLS
                  </Radio>
                </Space>
              </AntDesignRadio>
            </Form>
          </div>
          <div className="w-full ">
            {redownloadVersion == "csv" ? (
              <AppDownloadTemplateButton
                fileName={`${data?.description}`}
                url={`/admin/payment_schedules/view/redownload_bulk_payment_csv_file?id=${data?.payment_schedule_id}`}
                method="GET"
                type="csv"
                className="w-full"
                title="Re-download"
                disabled={data?.has_cheque_processed_payments ? false : true}
              />
            ) : (
              <AppDownloadTemplateButton
                fileName={`${data?.description}`}
                url={`/admin/payment_schedules/view/redownload_bulk_payment_xls_file?id=${data?.payment_schedule_id}`}
                method="GET"
                type="xlsx"
                className="w-full"
                title="Re-download"
                disabled={data?.has_cheque_processed_payments ? false : true}
              />
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default DownloadPayment
