import { UseMutateFunction, useMutation } from "@tanstack/react-query"
import { useErrorFeedback, useServerResponse } from "hooks/useServerResponse"
import {
  Dispatch,
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react"
import { useSearchParams } from "react-router-dom"
import { extendedAxiosInstance } from "utils/request"

export interface IJobPosting {
  title: string
  description: string
  status?: string
  pipeline: string
  level: string
  employmentType: string
  recruitmentDeadline: string
  location: string
  expectedSalaryCurrency: string
  expectedMinimumSalary: number
  expectedMaximumSalary: number
  showExpectedSalaryOnAdvert: boolean
  hiringManagerId: string
  hiringManagerName: string
  departmentId: string
  departmentName: string
  numberOfPositions: number
  candidateDetails?: CandidateDetailsEntity[]
  screenerQuestions?: ScreenerQuestionsEntity[]
  _id?: string
  _v?: string
}
export interface CandidateDetailsEntity {
  title: string
  showField: boolean
  isRequired: boolean
  isDefault: boolean
}
export interface ScreenerQuestionsEntity {
  answer?: string
  fieldType: string
  possibleValues: string[]
  question: string
  isRequired: boolean
  _id?: string
}

export interface IJobContext {
  formData: IJobPosting
  setFormData?: Dispatch<IJobPosting>
  postJob?: UseMutateFunction<void, unknown, IJobPosting, unknown>
  updateJob?: UseMutateFunction<void, unknown, IJobPosting, unknown>
  jobId?: string
}

export const JobPostingContext = createContext<IJobContext>({
  formData: {
    departmentId: "",
    departmentName: "",
    description: "",
    employmentType: "",
    expectedMaximumSalary: 0,
    expectedMinimumSalary: 0,
    expectedSalaryCurrency: "",
    hiringManagerId: "",
    hiringManagerName: "",
    level: "",
    location: "",
    numberOfPositions: 0,
    pipeline: "",
    recruitmentDeadline: "",
    showExpectedSalaryOnAdvert: false,
    title: "",
    candidateDetails: [],
    screenerQuestions: [],
  },
  jobId: "",
})

export const JobPostingContextContainer: FC<PropsWithChildren> = ({
  children,
}) => {
  const [formData, setFormData] = useState<IJobPosting>({
    departmentId: "",
    departmentName: "",
    description: "",
    employmentType: "",
    expectedMaximumSalary: 0,
    expectedMinimumSalary: 0,
    expectedSalaryCurrency: "",
    hiringManagerId: "",
    hiringManagerName: "",
    level: "",
    location: "",
    numberOfPositions: 0,
    pipeline: "",
    recruitmentDeadline: "",
    showExpectedSalaryOnAdvert: false,
    title: "",
    candidateDetails: [],
    screenerQuestions: [],
  })
  const [jobId, setJobId] = useState("")

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setJobId(searchParams.get("id") || "")
  }, [searchParams.get("id")])

  const { errorAlert } = useErrorFeedback()

  const { mutate: postJob } = useMutation({
    mutationFn: async (payload: IJobPosting) => {
      const res = await extendedAxiosInstance.post(`/v1/ats/jobs`, payload)
      return res.data
    },
    onSuccess(data, variables, context) {
      setJobId(data?.data?._id)
      setSearchParams({
        ...searchParams,
        id: data?.data?._id,
      })
    },
    onError(error, variables, context) {
      errorAlert(error)
    },
  })
  const { mutate: updateJob } = useMutation({
    mutationFn: async (payload: IJobPosting) => {
      const dataPayload = { ...payload }
      delete dataPayload._id
      delete dataPayload._v
      delete dataPayload.status
      const res = await extendedAxiosInstance.put(
        `/v1/ats/jobs/${jobId}`,
        dataPayload
      )
      return res.data
    },
    onSuccess(data, variables, context) {
      console.log(data)
    },
    onError(error, variables, context) {
      errorAlert(error)
    },
  })

  return (
    <JobPostingContext.Provider
      value={{
        formData,
        setFormData,
        postJob,
        updateJob,
        jobId,
      }}
    >
      {children}
    </JobPostingContext.Provider>
  )
}
