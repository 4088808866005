import { Form, InputNumber, Select, Spin } from "antd"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useEffect, useState } from "react"
import SwitchImg from "assets/svg/switch-vertical.svg"
import { Button } from "components"
import { formatCurrencyNoSymbol } from "utils/currency"
import { debounce } from "lodash"

import { useNavigate } from "react-router-dom"

import { useMutation, useQuery } from "@tanstack/react-query"

import { newBaseUrl, walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import {
  IWalletBalance,
  IWalletPade,
} from "pages/dashboard/wallets/interface/wallet"
import { ICurrenciesData } from "pages/dashboard/wallets/crossBorderPayments/interface"
import { availableFlags } from "pages/dashboard/wallets/crossBorderPayments/sampleData"
import SuccessModal from "pages/dashboard/wallets/crossBorderPayments/modals/SuccessModal"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { LoadingOutlined } from "@ant-design/icons"
import {
  DivideByRate,
  MultiplyByRate,
  UnformattedDivideByRate,
  UnformattedMultiplyByRate,
} from "utils/rate"
import { useGet } from "utils/useFetch"

const SwapMoney = ({ type }: { type: string }) => {
  const [form] = Form.useForm()
  const [timer, setTimer] = useState(15)
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const EmployeeWalletID =
    type === "admin" ? user?.organisationWalletId : user?.userWalletId
  // form.setFieldValue("from_currency", "NGN")
  const fromSelected = Form.useWatch("from_currency", form)
  const fromAmount = Form.useWatch("from_amount", form)
  const toAmount = Form.useWatch("to_amount", form)
  const toSelected = Form.useWatch("to_currency", form)
  const [active, setActive] = useState(1)
  const [isFromAmountChanging, setIsFromAmountChanging] = useState(true) // Track which input is being changed

  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const { isLoading: iswalletLoading, data: walletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const { isLoading: isSettingLoading, data: settingsInfo } = useQuery<any>({
    queryKey: [`settings`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/settings`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })

  const {
    isLoading: isLoadingRate,
    data: rateData,
    mutate: getRate,
  } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/conversion-rate`,
        body ?? {
          sourceCurrency: fromSelected,
          destinationCurrency: toSelected,
        },
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      const rate = data?.data?.rate

      if (isFromAmountChanging && fromAmount) {
        form.setFieldValue(
          "to_amount",
          UnformattedMultiplyByRate(fromAmount, rate)
        )
      } else if (!isFromAmountChanging && toAmount) {
        form.setFieldValue(
          "from_amount",
          UnformattedDivideByRate(toAmount, rate)
        )
      }
    },
    onError: (data: any) => {
      toast.error(data?.response?.data?.message)
    },
  })

  const { isLoading: isLoadingCurrencies, data: availableCurrencies } =
    useQuery<ICurrenciesData>({
      queryKey: [`${walletBaseUrl}/v1/misc/wallet-currencies`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/misc/wallet-currencies`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/swap-currency`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.success) {
        setShowSuccessModal(true)
        type === "admin"
          ? mixPanelEvent(`swap-money-success`)
          : mixPanelEvent(`empl-swap-money-success`)
      } else {
        data.msg && toast.error(data.msg)
        // mixPanelEvent("wallet-withdraw-failed")
      }
    },
    onError: (data: any) => {
      toast.error(data?.response?.data?.message)
    },
  })

  const mergedOptions = availableCurrencies?.data?.map((item) => {
    const flagData = availableFlags.find((flag) => flag.code === item.currency)
    return {
      ...item,
      flag: flagData?.flag || null,
      subContent: flagData?.subContent || "",
    }
  })
  const fromSymbol = availableCurrencies?.data?.find(
    (curr) => curr.currency === fromSelected
  )?.symbol
  const toSymbol = availableCurrencies?.data?.find(
    (curr) => curr.currency === toSelected
  )?.symbol

  const handleFromInputChange = (value: number | null) => {
    setIsFromAmountChanging(true)
    form.setFieldValue("from_amount", value)
    const body = {
      sourceCurrency: fromSelected,
      destinationCurrency: toSelected,
    }

    if (rateData) {
      const rate = rateData?.data?.rate
      form.setFieldValue(
        "to_amount",
        UnformattedMultiplyByRate(Number(value), rate)
      )
    } else {
      fromSelected && toSelected ? getRate(body) : {}
    }
  }

  const handleToInputChange = (value: number | any) => {
    setIsFromAmountChanging(false)
    form.setFieldValue("to_amount", value)
    const body = {
      sourceCurrency: fromSelected,
      destinationCurrency: toSelected,
    }
    if (rateData) {
      const rate = rateData?.data?.rate

      form.setFieldValue(
        "from_amount",
        UnformattedDivideByRate(Number(value), rate)
      )
    } else {
      fromSelected && toSelected ? getRate(body) : {}
    }
  }

  const handleToSelectChange = (value: any) => {
    setIsFromAmountChanging(true)
    form.setFieldValue("to_currency", value)
    form.setFieldValue("to_amount", "")
    const body = {
      sourceCurrency: fromSelected,
      destinationCurrency: value ?? toSelected,
    }
    if (fromSelected && toSelected && fromAmount) {
      getRate(body)
    } else if (fromSelected && fromAmount && !toSelected) {
      getRate(body)
    }
  }

  const fromData = [
    {
      title: "You’re converting ",
      value: `${fromSymbol} ${formatCurrencyNoSymbol(fromAmount)}`,
    },
    {
      title: "From",
      img: availableFlags.find((flag) => flag.code === fromSelected)?.flag,
      value: `${fromSelected} Account`,
    },
  ]
  const ToData = [
    {
      title: "Receiving account",
      img: availableFlags.find((flag) => flag.code === toSelected)?.flag,
      value: `${toSelected} Account`,
    },
    {
      title: "Amount to be received",
      value: `${toSymbol}${formatCurrencyNoSymbol(Number(toAmount))}`,
    },
    // {
    //   title: "What you pay in fees",
    //   value: `${toSymbol}${formatCurrencyNoSymbol(0)}`,
    //   // value: `N${formatCurrencyNoSymbol(540000)}`,
    // },
    {
      title: "Conversion",
      value: `${fromSymbol}1 = ${toSymbol} ${formatCurrencyNoSymbol(
        Number(rateData?.data?.rate),
        {
          minimumFractionDigits: 7,
          maximumFractionDigits: 7,
        }
      )}`,
      // value: `${fromSymbol}1 = ${toSymbol}${formatCurrencyNoSymbol(
      //   Number(rateData?.data?.rate),
      //   7
      // )}`,
      subValue: `${timer} secs`,
    },
    // { title: "Swap completion date", value: "Should arrive in 24HRs" },
  ]

  const previewData = [
    // { title: "Processing Fee", value: `N${formatCurrencyNoSymbol(20000)}` },
    {
      title: "You’ll be debited",
      value: `${fromSymbol} ${fromAmount || 0}`,
    },
    {
      title: "You’ll receive",
      value: `${toSymbol}${formatCurrencyNoSymbol(Number(toAmount))}`,
    },
    {
      title: "Conversion rate",
      value: `${fromSymbol}1 = ${toSymbol}${formatCurrencyNoSymbol(
        Number(rateData?.data?.rate),
        {
          minimumFractionDigits: 7,
          maximumFractionDigits: 7,
        }
      )}`,

      subValue: `${timer} secs`,
    },
  ]
  const handleSubmit = () => {
    type === "admin"
      ? mixPanelEvent(`swap-money-init`)
      : mixPanelEvent(`empl-swap-money-init`)
    submit({
      amount: Number(fromAmount),
      sourceCurrency: fromSelected,
      destinationCurrency: toSelected,
      walletId: EmployeeWalletID,
      rateToken: rateData?.data?.rateId,
    })
  }
  const onFinish = () => {
    setActive(2)
  }

  const getFromFilteredOptions = (selectedValue: string) => {
    const updatedOptions =
      selectedValue === "GBP"
        ? mergedOptions?.filter((item) => item.currency !== "USD")
        : // : mergedOptions?.filter((item) => item.currency !== "GBP")
          mergedOptions?.filter((item) => item.currency)
    return updatedOptions?.filter(
      (item: any) => item.currency !== selectedValue
    )
  }

  const getFilteredOptions = (selectedValue: string) => {
    const updatedOptions =
      selectedValue === "USD"
        ? mergedOptions?.filter((item) => item.currency !== "GBP")
        : selectedValue === "GBP"
        ? mergedOptions?.filter((item) => item.currency !== "USD")
        : mergedOptions
    return updatedOptions?.filter(
      (item: any) => item.currency !== selectedValue
    )
  }

  return (
    <DashboardWrapper>
      <div className=" flex flex-col items-start justify-start mt-5 gap-[23px]  w-full xl:w-[970px] ">
        <FormSectionWrapper
          title="Swap money"
          subtitle="Enter amount and select currency to swap"
          headerClassName=" xl:!px-[40px]"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          isActive={active === 1 ? true : false}
          footerBtns={
            <div className="flex justify-end w-full gap-3">
              <Button color="primary">Proceed</Button>
            </div>
          }
        >
          <Spin spinning={isLoadingCurrencies}>
            <div className="px-4 md:px-6 lg:px-8 xl:px-[40px] pb-10 w-full">
              <div className=" w-full">
                <p className="text-padeBlack  text-sm mb-1">From</p>
                <div className=" border rounded-5px w-full h-[70px] px-6  flex justify-between items-center">
                  <Form.Item
                    rootClassName="!mb-0"
                    name="from_currency"
                    rules={[
                      { required: true, message: "Please enter this field!" },
                    ]}
                  >
                    <Select
                      showSearch
                      options={getFromFilteredOptions(toSelected)?.map(
                        (item: any) => ({
                          label: (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={item.flag}
                                alt={`${item.currency} flag`}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              <span>{item.currency} Account</span>
                            </span>
                          ),
                          value: item?.currency,
                        })
                      )}
                      className="curr_input !border-none rounded-[20px]"
                      placeholder="Select from account"
                      value={fromSelected}
                      filterOption={(input: any, option: any) =>
                        option?.label?.props.children[1]?.props.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                  <div className="flex  items-center">
                    <Form.Item
                      label=""
                      className="cross_input"
                      name="from_amount"
                      rootClassName="!mb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please input the amount you want to swap",
                        },
                        () => ({
                          validator: (_, value) => {
                            const minimum =
                              settingsInfo?.data?.currencyConversionRanges?.find(
                                (set: any) =>
                                  set.sourceCurrency === fromSelected
                              )?.lowerLimit ?? 6000
                            if (value && Number(value) < minimum) {
                              return Promise.reject(
                                `Minimum amount is ${formatCurrencyNoSymbol(
                                  minimum
                                )}`
                              )
                            }
                            return Promise.resolve()
                          },
                        }),
                        () => ({
                          validator: (_, value) => {
                            const balance = findBalance(
                              walletData?.data?.balances,
                              fromSelected
                            )
                            if (value && Number(value) > balance) {
                              return Promise.reject(
                                "Insufficient funds in wallet"
                              )
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <InputNumber<number>
                        disabled={!fromSelected}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) =>
                          value?.replace(/,/g, "") as unknown as number
                        }
                        className="text-xl border-none focus:ring-0 active:ring-0 focus:outline-none active:outline-none focus:border-none active:border-none font-bold font-circular text-right"
                        placeholder="0"
                        rootClassName="min-w-[200px]"
                        style={{
                          appearance: "textfield",
                          MozAppearance: "textfield",
                          WebkitAppearance: "none",
                        }}
                        onChange={(value) => handleFromInputChange(value)}
                        // onBlur={() => {
                        //   handleFromInputChange(fromAmount)
                        // }}
                      />
                    </Form.Item>
                    <span className="font-circular text-sm text-padeBlack">
                      {fromSelected}
                    </span>
                  </div>
                </div>
                {fromSelected && walletData && (
                  <p className="text-[#5D6B82] font-medium font-circular text-sm mt-1">
                    Account Balance:{fromSymbol}
                    {findBalance(
                      walletData.data.balances,
                      fromSelected
                    ).toLocaleString()}
                  </p>
                )}
              </div>
              {/* {isLoadingRate ? (
              <div className="my-10 flex justify-center gap-[43px] items-center">
                <Spin
                  spinning
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24 }}
                      spin
                    />
                  }
                />
              </div>
            ) : ( */}
              <div className="my-10 flex justify-center gap-[43px] items-center">
                <p className="font-circular text-sm font-bold">
                  Switch accounts
                </p>
                {isLoadingRate ? (
                  <Spin spinning={isLoadingRate} />
                ) : (
                  <p>
                    <img
                      src={SwitchImg}
                      alt=""
                    />
                  </p>
                )}
              </div>
              {/* )} */}
              <div className=" w-full">
                <p className="text-padeBlack  text-sm mb-1">To</p>
                <div className="border rounded-5px w-full h-[70px] px-6  flex justify-between items-center">
                  <Form.Item
                    rootClassName="!mb-0"
                    className=""
                    name="to_currency"
                    rules={[
                      { required: true, message: "Please enter this field!" },
                    ]}
                  >
                    <Select
                      showSearch
                      options={getFilteredOptions(fromSelected)?.map(
                        (item: any) => ({
                          label: (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={item.flag}
                                alt={`${item.currency} flag`}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "8px",
                                }}
                              />
                              <span>{item.currency} Account</span>
                            </span>
                          ),
                          value: item?.currency,
                        })
                      )}
                      className="curr_input !border-none rounded-[20px]"
                      placeholder="Select to account"
                      value={toSelected}
                      filterOption={(input: any, option: any) =>
                        option?.label?.props.children[1]?.props.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        handleToSelectChange(value)
                      }}
                    />
                  </Form.Item>
                  <div className="flex  items-center">
                    {" "}
                    <Form.Item
                      className="cross_input"
                      name="to_amount"
                      rootClassName="!mb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please input the amount you want to swap",
                        },
                      ]}
                    >
                      <InputNumber<number>
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) =>
                          value?.replace(/,/g, "") as unknown as number
                        }
                        className="text-xl border-none focus:ring-0 focus:outline-none active:outline-none active:ring-0 focus:border-none active:border-none font-bold font-circular text-right"
                        placeholder="0"
                        rootClassName="min-w-[200px]"
                        // type="number"
                        style={{
                          appearance: "textfield",
                          MozAppearance: "textfield",
                          WebkitAppearance: "none",
                        }}
                        onChange={(value) => handleToInputChange(value)}
                        // onBlur={() => {
                        //   handleToInputChange(toAmount)
                        // }}
                      />
                    </Form.Item>
                    <span className="font-circular text-sm text-padeBlack">
                      {toSelected}
                    </span>
                  </div>
                </div>

                {toSelected && walletData && (
                  <p className="text-[#5D6B82] font-medium font-circular text-sm mt-1">
                    Account Balance:{toSymbol}
                    {findBalance(
                      walletData.data.balances,
                      toSelected
                    ).toLocaleString()}
                  </p>
                )}
              </div>

              {rateData && (
                <div className=" mt-10 bg-[#F5F5F5] rounded p-4 flex flex-col gap-5">
                  {previewData?.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <p className="text-[#6C7A93] font-circular  text-sm">
                        {item?.title}
                      </p>
                      <p className="text-padeBlack  font-circular font-medium text-sm">
                        {item?.value}
                        {item?.subValue && (
                          <span className="ml-[10px] text-[#EB3949] text-sm font-bold font-circular">
                            {item?.subValue}{" "}
                          </span>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Spin>
        </FormSectionWrapper>

        {/* REVIEW */}
        <FormSectionWrapper
          title="Review Transaction"
          headerClassName=" xl:!px-[40px]"
          isActive={active === 2 ? true : false}
          form={form}
          onFinish={onFinish}
          // style={{ width: 970 }}
          autoComplete="off"
          layout="vertical"
          footerBtns={
            <div className="flex justify-end w-full gap-3">
              <Button
                color="neutral"
                type="button"
                onClick={(e) => {
                  setActive(1)
                }}
              >
                Back
              </Button>
              <Button
                color="primary"
                disabled={isLoading}
                // type="submit"
                submitting={isLoading}
                onClick={handleSubmit}
              >
                Proceed to Swap
              </Button>
            </div>
          }
        >
          <div className="px-4 md:px-6 lg:px-8 xl:px-[40px] pb-10  w-full flex flex-col gap-6">
            <div className=" w-full">
              <p className="text-padeBlack  text-sm mb-1">From</p>
              <div className=" border rounded-5px w-full flex flex-col gap-5 py-4 px-5">
                {fromData?.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <p className="text-[#6C7A93] font-circular  text-sm">
                      {item?.title}
                    </p>
                    <div className="flex items-center">
                      {item?.img && (
                        <img
                          src={item?.img}
                          alt=""
                          className=" h-6 w-6 mr-2"
                        />
                      )}
                      <p className="text-padeBlack  font-circular font-medium text-sm">
                        {item?.value}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" w-full">
              <p className="text-padeBlack  text-sm mb-1">To</p>
              <div className=" border rounded-5px w-full flex flex-col gap-5 py-4 px-5">
                {ToData?.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <p className="text-[#6C7A93] font-circular  text-sm">
                      {item?.title}
                    </p>
                    <div className="flex items-center">
                      {item?.img && (
                        <img
                          src={item?.img}
                          alt=""
                          className=" h-6 w-6 mr-2"
                        />
                      )}
                      <p className="text-padeBlack  font-circular font-medium text-sm">
                        {item?.value}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </FormSectionWrapper>
      </div>

      <SuccessModal
        title="Payment Queued for Processing"
        subtitle={`Check the status of your transaction, on the transaction page.`}
        onClose={() => {
          setShowSuccessModal(false)
          form.resetFields()
          navigate(-1)
        }}
        open={showSuccessModal}
        // handleSubmit={handleSubmit}
      />
    </DashboardWrapper>
  )
}

export default SwapMoney

export const findBalance = (
  balances: IWalletBalance[] | undefined,
  currencyCode: string
) => {
  const balanceObj = balances?.find((balance) => {
    return balance.currency === currencyCode
  })

  return balanceObj ? balanceObj.balance : 0
}
