const langFormat: string = "en-NG"

export const formatCurrencyNoSymbol = (
  amount: number,
  options?: Intl.NumberFormatOptions
) =>
  new Intl.NumberFormat(langFormat, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
  }).format(amount)
