import RequireRoutePermit from "RequireRoutePermit"
import { Spin } from "antd"
import React, { Suspense } from "react"
import { Navigate, Route } from "react-router-dom"

const RequestWrapper = React.lazy(
  () => import("../pages/dashboard/people/requests/RequestWrapper")
)

const ChooseRequest = React.lazy(
  () => import("../pages/dashboard/people/requests/ChooseRequest")
)

const LeaveRequests = React.lazy(
  () => import("../pages/dashboard/people/requests/all/leaves/LeaveRequests")
)

const CreateLeaveRequest = React.lazy(
  () =>
    import("../pages/dashboard/people/requests/all/leaves/CreateLeaveRequest")
)

const ViewLeavePolicy = React.lazy(
  () =>
    import(
      "../pages/dashboard/people/requests/policies/leave-policy/ViewLeavePolicy"
    )
)
const ViewDocumentPolicy = React.lazy(
  () =>
    import(
      "pages/dashboard/people/requests/policies/document-policies/ViewDocumentPolicy"
    )
)

const ViewExitPolicy = React.lazy(
  () =>
    import(
      "pages/dashboard/people/requests/policies/exit-policies/ViewExitPolicy"
    )
)

const ViewLoanPolicy = React.lazy(
  () =>
    import(
      "pages/dashboard/people/requests/policies/loan-salaries-policies/ViewLoanPolicy"
    )
)

const ViewRedeploymentPolicy = React.lazy(
  () =>
    import(
      "pages/dashboard/people/requests/policies/redeployment-policies/ViewRedeploymentPolicy"
    )
)

const ViewAdvancePolicy = React.lazy(
  () =>
    import(
      "pages/dashboard/people/requests/policies/loan-salaries-policies/ViewAdvancePolicy"
    )
)

const LoanRequests = React.lazy(
  () => import("../pages/dashboard/people/requests/all/loans/LoanRequests")
)

const SalaryAdvanceRequests = React.lazy(
  () =>
    import(
      "../pages/dashboard/people/requests/all/salary-advances/SalaryAdvanceRequests"
    )
)

const DocumentRequests = React.lazy(
  () =>
    import("../pages/dashboard/people/requests/all/documents/DocumentRequests")
)
const ExitRequests = React.lazy(
  () => import("../pages/dashboard/people/requests/all/exits/ExitRequests")
)
const RedeploymentRequests = React.lazy(
  () =>
    import(
      "../pages/dashboard/people/requests/all/redeployments/RedeploymentRequests"
    )
)

const CreateLoanRequest = React.lazy(
  () => import("../pages/dashboard/people/requests/all/loans/CreateLoanRequest")
)
const CreateEmployeeDocumentRequest = React.lazy(
  () =>
    import(
      "../pages/dashboard/people/requests/all/documents/CreateEmployeeDocumentRequest"
    )
)
const CreateSalaryAdvanceRequest = React.lazy(
  () =>
    import(
      "../pages/dashboard/people/requests/all/salary-advances/CreateSalaryAdvanceRequest"
    )
)
const CreateExitRequest = React.lazy(
  () => import("../pages/dashboard/people/requests/all/exits/CreateExitRequest")
)
const BulkExitRequests = React.lazy(
  () => import("../pages/dashboard/people/requests/all/exits/BulkExitsUpload")
)
const CreateRedeploymentRequest = React.lazy(
  () =>
    import(
      "../pages/dashboard/people/requests/all/redeployments/CreateRedeploymentRequest"
    )
)
const BulkRedeployments = React.lazy(
  () =>
    import(
      "../pages/dashboard/people/requests/all/redeployments/BulkUploadRedeployment"
    )
)

function Loading() {
  return (
    <div
      className="fixed right-0 top-20 bottom-0 left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-white"
      style={{
        zIndex: 9999999,
      }}
    >
      <Spin />
    </div>
  )
}

const Requests = () => {
  return (
    <>
      <Route path="list">
        <Route
          path=""
          element={<Navigate to="choose" />}
        />
        <Route
          path="choose"
          element={<ChooseRequest />}
        />
        <Route
          path="all"
          element={<RequestWrapper />}
        />
        <Route
          path="leaves"
          element={<LeaveRequests />}
        />
        <Route
          path="documents"
          element={<DocumentRequests />}
        />
        <Route
          path="exits"
          element={<ExitRequests />}
        />
        <Route
          path="redeployments"
          element={<RedeploymentRequests />}
        />
        <Route
          path="loans"
          element={<LoanRequests />}
        />
        <Route
          path="salary-advances"
          element={<SalaryAdvanceRequests />}
        />
      </Route>
      <Route path="leave">
        <Route
          path="create"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_create_leave_requests"]}
            >
              <CreateLeaveRequest />
            </RequireRoutePermit>
          }
        />

        <Route
          path="policy/:key"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_view_leave_policies"]}
            >
              <ViewLeavePolicy />
            </RequireRoutePermit>
          }
        />
      </Route>
      <Route path="loan">
        <Route
          path="create"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_create_loan_requests"]}
            >
              <CreateLoanRequest />
            </RequireRoutePermit>
          }
        />
        <Route
          path="policy/:key"
          element={
            <RequireRoutePermit allowedPermissions={["can_view_loan_policies"]}>
              <ViewLoanPolicy />
            </RequireRoutePermit>
          }
        />
      </Route>
      <Route path="salary-advance">
        <Route
          path="create"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_view_salary_advance_requests"]}
            >
              <CreateSalaryAdvanceRequest />
            </RequireRoutePermit>
          }
        />
        <Route
          path="policy/:key"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_view_salary_advance_policies"]}
            >
              <ViewAdvancePolicy />
            </RequireRoutePermit>
          }
        />
      </Route>
      <Route path="document">
        <Route
          path="create"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_create_document_requests"]}
            >
              <CreateEmployeeDocumentRequest />
            </RequireRoutePermit>
          }
        />
        <Route
          path="policy/:key"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_view_document_policies"]}
            >
              <ViewDocumentPolicy />
            </RequireRoutePermit>
          }
        />
      </Route>
      <Route path="exit">
        <Route
          path="create"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_create_exit_requests"]}
            >
              <CreateExitRequest />
            </RequireRoutePermit>
          }
        />
        <Route
          path="bulk-upload"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_create_exit_requests"]}
            >
              <BulkExitRequests />
            </RequireRoutePermit>
          }
        />
        <Route
          path="policy/:key"
          element={
            <RequireRoutePermit allowedPermissions={["can_view_exit_policies"]}>
              <ViewExitPolicy />
            </RequireRoutePermit>
          }
        />
      </Route>
      <Route path="redeployment">
        <Route
          path="create"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_create_redeployment_requests"]}
            >
              <CreateRedeploymentRequest />
            </RequireRoutePermit>
          }
        />
        <Route
          path="bulk-upload"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_create_redeployment_requests"]}
            >
              <BulkRedeployments />
            </RequireRoutePermit>
          }
        />
        <Route
          path="policy/:key"
          element={
            <RequireRoutePermit
              allowedPermissions={["can_view_redeployment_policies"]}
            >
              <ViewRedeploymentPolicy />
            </RequireRoutePermit>
          }
        />
      </Route>
    </>
  )
}

export default Requests
