import { useAuthStore } from "stores"

/**
 * @param {string[]} allowedPermissions - array of permissions to check
 * @returns {boolean[]} array of booleans that corresponds to the allowedPermissions array
 * @description checks if the user has the permission to access the page
 * @example const [canView, canEdit, canDelete] = usePermissions({ allowedPermissions: ["can_view", "can_edit", "can_delete"] })
 */

const usePermissions = ({
  allowedPermissions,
}: {
  allowedPermissions: string[]
}) => {
  const { user } = useAuthStore()
  const userPermissions = user?.userPermissions

  const isPrimaryAdmin =
    user?.isPrimaryAdmin === "True" ||
    user?.userRoles?.includes("Primary Admin")
  const isSecondaryAdmin =
    user?.isSecondaryAdmin === "True" ||
    user?.userRoles?.includes("Secondary Admin")

  const permissions = allowedPermissions?.map((allowedPerm) => {
    const isAllowed = userPermissions?.some((userPerm) => {
      return userPerm.alias === allowedPerm
    })

    return isPrimaryAdmin || isSecondaryAdmin || isAllowed
  })

  return permissions
}

export default usePermissions
