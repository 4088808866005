export function exportTableToCSV(filename) {
  var csv = []
  var rows = document.querySelectorAll("table tr, .period-title")
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td, th, div")
    for (var j = 0; j < cols.length; j++)
      row.push(cols[j].innerText.replace(/,/g, "").replace(/₦/g, ""))
    csv.push(row.join(","))
  }
  downloadCSV(csv.join("\n"), filename)
}

function downloadCSV(csv, filename) {
  var csvFile
  var downloadLink
  csvFile = new Blob([csv], { type: "text/csv" })
  downloadLink = document.createElement("a")
  downloadLink.download = filename
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = "none"
  document.body.appendChild(downloadLink)
  downloadLink.click()
}
