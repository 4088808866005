import { formatCurrencyNoSymbol } from "utils/currency"

const TaxRow: React.FC<{
  pfaName: any
  groupedPensionSchedules: any
}> = ({ pfaName, groupedPensionSchedules }) => {
  const totalRenumeration = groupedPensionSchedules[pfaName].reduce(
    (total: any, schedule: any) => total + Number(schedule.amount),
    0
  )

  const totalPAYE = groupedPensionSchedules[pfaName].reduce(
    (total: any, schedule: any) => total + Number(schedule.tax_amount),
    0
  )

  return (
    <>
      <div className="border-b  border-black py-2  grid grid-cols-9 mb-1  items-start gap-4 ">
        <div className=" flex-wrap  font-avenir   text-sm text-[#42526D]">
          {pfaName !== "null" ? pfaName : "Unspecified Tax Office"}
        </div>
        <div className=" col-span-8  flex flex-col gap-2">
          {groupedPensionSchedules[pfaName].map((schedule: any, index: any) => (
            <div
              className={`py-1 flex-grow   grid-cols-7 grid gap-2 ${
                index == groupedPensionSchedules.length - 1
                  ? "border-none"
                  : "border-b border-cardgrey"
              } `}
            >
              <div className="col-span-2">
                <span className="flex-wrap  font-avenir   text-sm text-[#42526D]">
                  {schedule.full_name}
                </span>
              </div>
              <div className="">
                <span className="flex-wrap  font-avenir   text-sm text-[#42526D]">
                  {schedule.employee_title || "-"}
                </span>
              </div>
              <div className="">
                <span className="flex-wrap  font-avenir   text-sm text-[#42526D]">
                  {schedule.nationality || "-"}
                </span>
              </div>
              <div className="">
                <span className="flex-wrap  font-avenir   text-sm text-[#42526D]">
                  {schedule.tin || "-"}
                </span>
              </div>
              <div className="">
                <span className="flex-wrap  font-avenir   text-sm text-[#42526D]">
                  ₦{formatCurrencyNoSymbol(Number(schedule.amount))}
                </span>
              </div>
              <div className="">
                <span className="flex-wrap  font-avenir   text-sm text-[#42526D]">
                  ₦{formatCurrencyNoSymbol(Number(schedule?.tax_amount))}
                </span>
              </div>
            </div>
          ))}
          <div className="grid grid-cols-7 py-2 gap-2">
            <div className="col-span-2"></div>
            <div className=""></div>
            <div className=""></div>

            <div className="">
              <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
                Tax office total
              </span>
            </div>
            <div className="">
              <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
                ₦{formatCurrencyNoSymbol(totalRenumeration)}
              </span>
            </div>
            <div className="">
              <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
                ₦{formatCurrencyNoSymbol(totalPAYE)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TaxRow
