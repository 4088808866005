import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import { Form, Input as AntInput, DatePicker } from "antd"
import { useState } from "react"

import { Input } from "../../../../components"
import PrevNextButton from "pages/dashboard/people/people/form/PrevNextButton"
import { CustomInput, CustomSelect } from "pages/dashboard/people/people/form"
import { useGet, usePost } from "utils/useFetch"
import { IIndustries, IKYCData, KYCWalletBody } from "./interface/kyc"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import dayjs from "dayjs"
import { convertCalendar } from "utils/formateDate"

type Props = {
  next: (index: number) => void
  previous: (index: number) => void
}

export const BusinessInfoTab = ({
  setActiveKey,
  isActive,
  setUserInfo,
  userInfo,
}: {
  isActive: boolean
  userInfo: KYCWalletBody
  setUserInfo: Function
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const [form] = Form.useForm()
  const { TextArea } = AntInput
  const [email, setEmail] = useState("")

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: KYCWalletBody) => {
      const res = await axiosInstance.post(
        `/admin/company/update_verification`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        mixPanelEvent("settings-integration-payment-config-edit-success")
      }
    },
  })

  const onFinish = (values: Values) => {
    const {
      businessName,
      industry,
      email,
      businessDescription,
      businessAddress,
      websiteAddress,
      rcNumber,
      incDate,
    } = values

    mixPanelEvent("settings-integration-payment-config-edit-init")
    setUserInfo({
      ...userInfo,
      registered_name: businessName,
      industry_id:
        industry == kyc?.verification?.industry
          ? String(kyc?.verification?.industry_id)
          : String(userInfo?.industry_id),
      email: email,
      description: businessDescription,
      full_address: businessAddress,
      website: websiteAddress,
      registration_number: rcNumber,
      registration_date: convertCalendar(incDate),
    })

    submit({
      ...userInfo,
      registered_name: businessName,
      industry_id:
        industry == kyc?.verification?.industry
          ? String(kyc?.verification?.industry_id)
          : String(userInfo?.industry_id),
      email: email,
      description: businessDescription,
      full_address: businessAddress,
      website: websiteAddress,
      registration_number: rcNumber,
      registration_date: convertCalendar(incDate),
    })
    setActiveKey(["4"])
  }
  // const {
  //   isFetching: isValidatingEmail,
  //   data: isValidEmail,
  //   isError: IsValidatingEmailError,
  // } = useGet<ICheckEmailData>({
  //   url: `/admin/people/checkemail?email=${email}&person_id=-1`,
  //   enabled: !!email,
  // })
  const reqBody = {
    q: "",
    page: "",
  }

  const { isLoading: isIndustriesLoading, data: industries } = usePost<
    IIndustries[]
  >({ url: "/admin/company/getindustries", body: reqBody })

  const { isLoading: isGetKYCLoading, data: kyc } = useGet<IKYCData>({
    url: `/admin/company/view/settings?id=verify&x=`,
  })
  return (
    <FormSectionWrapper
      name="Business Info"
      title="Business Info"
      isActive={isActive}
      subtitle="Submit important information about your business."
      initialValues={{
        businessName: kyc?.verification?.registered_name,
        industry: kyc?.verification?.industry,
        email: kyc?.verification?.email,
        businessDescription: kyc?.verification?.description,
        businessAddress: kyc?.verification?.full_address,
        websiteAddress: kyc?.verification?.website,
        incDate: kyc?.verification?.registration_date
          ? dayjs(kyc?.verification?.registration_date, "DD/MM/YYYY")
          : "",
        rcNumber: kyc?.verification?.registration_number,
      }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      footerBtns={<PrevNextButton onClickPrev={() => setActiveKey(["2"])} />}
    >
      <div className="flex flex-col md:grid grid-cols-2 gap-x-10 px-4 md:px-6 xl:px-[50px]">
        <div className="col-span-2">
          <CustomInput
            label="Registered Business name"
            name="businessName"
            required
          />
        </div>
        <Form.Item
          label={
            <span className="font-circular text-neutral300 text-sm  ">
              Incorporation date
            </span>
          }
          name="incDate"
          rules={[{ required: true, message: "Please enter this field!" }]}
        >
          <DatePicker
            className="w-full  "
            rootClassName="!text-sm"
            format={"DD/MM/YYYY"}
            disabledDate={(date) => {
              return date?.isAfter(dayjs())
            }}
          />
        </Form.Item>
        <CustomInput
          label="Business address"
          name="businessAddress"
          required
        />
        <CustomSelect
          label="Industry"
          name="industry"
          required
          isLoading={isIndustriesLoading}
          onChange={(selected) => {
            const industry = industries?.find(
              (industry) => industry.description === selected
            )
            setUserInfo({
              ...userInfo,
              industry_id: industry?.industryId,
            })
          }}
          options={
            isIndustriesLoading
              ? []
              : industries?.map((industry) => ({
                  label: industry.description,
                  value: industry.description,
                }))
          }
          placeholder=""
        />
        <CustomInput
          label="Website address"
          name="websiteAddress"
          required
        />
        <Form.Item
          label={
            <span className="font-circular text-neutral300 text-sm ">
              Business Email address
            </span>
          }
          name="email"
          className="!text-neutral300"
          hasFeedback
          rules={[
            { required: true, message: "Please enter this field!" },
            {
              type: "email",
              message: "Please enter a valid email address!",
            },
          ]}
          required
        >
          <Input
            type="email"
            placeholder="Enter Email"
            id="email"
            className="py-auto h-8"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <CustomInput
          label="RC number"
          name="rcNumber"
          required
        />

        <div className="col-span-2">
          <Form.Item
            label={
              <span className="font-circular text-neutral300 text-sm ">
                Business Description
              </span>
            }
            rules={[{ required: false, message: "Please enter this field!" }]}
            required
            name="businessDescription"
          >
            <TextArea
              rows={4}
              required
            />
          </Form.Item>
        </div>
      </div>
    </FormSectionWrapper>
  )
}
interface Values {
  businessName: string
  industry: string
  email: string
  businessDescription: string
  businessAddress: string
  websiteAddress: string
  rcNumber: string
  incDate: string
}
