import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { APP_ID } from "utils/constants"
import { request } from "utils/request"
import { IFilterOptions } from "stores/interface"
import { IFilterPage } from "./interface/page"

interface ProfileState {
  profile: IProfile[]
  isLoading: boolean
  isSearching: boolean
}

export interface IProfile {
  userRoles: string[]
  isPrimaryAdmin: string
  isSecondaryAdmin: string
  isAdmin: string
  isConsultant: string
  isActive: string
  userAvatarFilename: string
  userName: string
  userPhone: string
  userId: string
  userFirstName: string
  userLastName: string
  userFullName: string
  lastLogin: string
  isUserOrganisation: string
  userOrganisationId: string
  userOrganisation: string
  userOrganisationEmail: string
  userOrganisationTelephone: string
  userOrganisationMobilePhone: string
  nextInvoiceStartDate: string
  nextInvoiceStartDate_Short: string
  nextInvoiceEndDate: string
  nextInvoiceEndDate_Short: string
  lastPaymentDate: string
  lastPaymentDate_Short: string
  lastPaymentAmount: string
  lastPaymentInvoiceNumber: string
  hasPendingInvoice: string
  hasDueInvoice: string
  activeEmployees: string
  organisationChildren: string
  parentOrganisationId: string
  organisationPlan: string
  organisationPlanAmount: string
  nextInvoiceDaysRemaining: string
  startDate: string
  startDate_Short: string
  subscriptionId: string
  billingMode: string
  billingFrequency: string
  newBillingMode: string
  newBillingFrequency: string
  newOrganisationPlan: string
  secretKey: string
  threshold: string
  lastInvoiceId: string
  countryCode: string
  country: string
  currencyCode: string
  currencySymbol: string
  qualifiedCurrency: string
  billingCurrencyCode: string
  billingCurrencySymbol: string
  isBillingNotificationEnabled: string
  organisationPlanBaseAmount: string
  databaseConfigKey: string
  buffer: string
  paystackPublicKey: string
  fileUploadLimit: string
  adminsCanSwitchOrganisations: string
  controlOrganisationId: null
  unreadNotificationCount: string
  unreadNotifications: null
  userKey: string
  personKey: string
  organisationAvatarFilename: string
  // memberOrganisations:          MemberOrganisation[];
}

export interface MemberOrganisation {
  organisationId: number
  organisationTypeId: null
  organisationStatusId: null
  parentOrganisationId: null
  planId: null
  hostDatabaseId: number
  organisationCode: null
  fullName: string
  legalName: null
  email: null
  telephone: null
  mobilePhone: null
  addressLine1: null
  addressLine2: null
  addressCity: null
  addressStateId: null
  zipCode: null
  fullAddress: null
  representativeUserId: null
  description: null
  uniqueId: null
  createdDate: null
  recordGuid: string
  isDeleted: null
  isActive: null
  children: null
  billingModeId: null
  billingFrequencyId: null
  billingDiscount: null
  newPlanId: null
  newBillingModeId: null
  newBillingFrequencyId: null
  isBillingVatApplied: null
  isBillingWhtApplied: null
  secretKey: null
  hasPendingInvoice: null
  hasDueInvoice: null
  activeEmployees: null
  threshold: null
  trialDays: null
  isManaged: null
  lastInvoiceId: null
  trialEndDate: null
  nextInvoiceStartDate: null
  nextInvoiceEndDate: null
  nextInvoiceDaysRemaining: null
  trialDaysRemaining: null
  lastPaymentNotificationRequestId: null
  countryId: null
  isBillingNotificationEnabled: null
  isInitialized: null
  hasPayoutSubaccount: null
  earnipayId: null
}

interface profileMethods {
  getProfile: () => Promise<IProfile[]>
}

export const useProfileStore = create<ProfileState & profileMethods>()(
  devtools(
    persist(
      (set, get) => ({
        profile: [],
        isLoading: false,
        isError: false,
        isSearching: false,
        getProfile: async () => {
          set({
            isLoading: true,
          })
          try {
            const req = await request.get({
              url: "account/getusersession",

              //   params: new URLSearchParams({
              //     statusId: String(params.status),
              //   }),
              //   body: {
              //     search: params.search || "",
              //     start: String(params.start || 0),
              //     length: String(params.length || 10),
              //   },
            })

            set({
              profile: req,
            })

            set({
              isLoading: false,
            })

            return req
          } catch (error) {
            console.warn(error)
          }
        },
      }),
      {
        name: `${APP_ID}.assets`,
      }
    )
  )
)
