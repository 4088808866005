import React, { useEffect, useState } from "react"
import {
  ArrowLeftIcon,
  ArrowRightOnRectangleIcon,
  BellIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  LockClosedIcon,
  UserCircleIcon,
  BellAlertIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline"
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom"
import PadeLogoNoName from "assets/logos/pade-logo-noname.svg"
import { useAuthStore } from "stores"

import { useGet } from "utils/useFetch"
import { ArrowRightIcon } from "@heroicons/react/24/solid"
import { Badge, Dropdown, Spin } from "antd"
import ImageComponent from "components/custom/image"
import { P2 } from "components/typography/P2"
import { throttle } from "utils/throttle"
import { TextLink } from "components/links/TextLink"
import { createPortal } from "react-dom"
import { isPadeDomain } from "utils/utils"

type SubPageType = {
  title?: string
  path: string
}

type PageType = {
  title: string
  path: string
  subPages?: SubPageType[]
}

const UserProfile: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isSidebarOpen, toggleSidebar }) => {
  const navigate = useNavigate()
  const { signOut, user, authToken, loginSwitch } = useAuthStore()
  const [toSwitch, toggleToSwitch] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const handleSignout = () => {
    signOut()
  }

  // const handleSwitch = async () => {
  //   toggleToSwitch(true)

  //   const userId = localStorage.getItem("bgr")

  //   const response = await loginSwitch(userId || "")
  //   if (response.status === "Success") {
  //     const authToken = response.access_token
  //     const encodedAuthToken = encodeURIComponent(authToken)
  //     const encodedUserId = encodeURIComponent(userId || "")

  //     const redirectUrl =
  //       "https://portal.paidhr.com/account/switch?token=" +
  //       encodedAuthToken +
  //       "&code=" +
  //       encodedUserId
  //     // window.open(redirectUrl, "_blank");
  //     window.location.href = redirectUrl
  //   } else {
  //     toggleToSwitch(false)
  //     navigate("/auth/login")
  //   }
  // }

  const { data, isLoading } = useGet<any>({
    url: "/employee/notifications/select",
  })
  const handleCloseDropdown = () => {
    setDropdownOpen(false)
  }
  return (
    <div className="relative h-auto">
      {toSwitch ? (
        <>
          {createPortal(
            <div className=" flex  items-center justify-center py-10 bg-a11y/50 h-screen w-screen fixed inset-0 z-[9999999] ">
              <Spin size="large" />
            </div>,
            document.body
          )}
        </>
      ) : null}

      <div className="flex items-center gap-4 md:gap-6">
        <Dropdown
          trigger={["click"]}
          dropdownRender={(menu) => (
            <div className="bg-white rounded-lg drop-shadow-2xl  border py-4">
              <div className="px-4 font-semibold font-avenir ">
                Notifications
              </div>
              <hr className="my-2 text-neutral50" />
              {data?.data?.filter((it: any) => it?.is_read !== "True")?.length >
              0 ? (
                <div className="w-full px-4">
                  <hr className="my-2 text-neutral50" />
                  <Link
                    to="notifications"
                    className="w-full flex gap-3 items-center px-1  text-neutral500 font-avenir"
                  >
                    <ArrowRightIcon className="w-4 h-4" />
                    Read All
                  </Link>
                </div>
              ) : (
                <div className="w-full">
                  <div className="flex gap-2 items-center px-4 my-6">
                    <div className="bg-primary500 text-a11y p-2 rounded-[100%] flex justify-center align-middle">
                      <BellAlertIcon className="w-4 h-4" />
                    </div>

                    <div className="text-[#74788d] font-avenir">
                      Your unread notifications will appear here
                    </div>
                  </div>
                  <hr className="my-2 text-neutral50" />
                  <Link
                    to="notifications"
                    className="w-full flex gap-3 justify-center  text-neutral500 text-center px-4 font-avenir"
                  >
                    <ArrowRightIcon className="w-4 h-4" />
                    Read All
                  </Link>
                </div>
              )}
            </div>
          )}
        >
          <button className="cursor-pointer flex">
            <Badge
              showZero
              count={
                data?.data?.filter((it: any) => it?.is_read !== "True")
                  ?.length || null
              }
            >
              {/* <div className="rounded-full p-2.5 bg-primary500 bg-opacity-[18%]"> */}
              <BellIcon className="w-6 stroke-2 text-primary500" />
              {/* </div> */}
            </Badge>
          </button>
        </Dropdown>

        {/* ===================== */}
        {/* ====Avatar & Name==== */}
        <Dropdown
          trigger={["click"]}
          open={dropdownOpen}
          onOpenChange={() => {
            setDropdownOpen(!dropdownOpen)
          }}
          dropdownRender={(menu) => (
            <div>
              <div className="w-full  p-4 bg-white rounded-lg drop-shadow-2xl border">
                <div className="flex flex-col w-full gap-5">
                  <div className="flex items-center gap-3">
                    {/* <div className=" ">
                      <img
                        className=" rounded-full border-[4px] border-primary50 w-9 aspect-square"
                        src={
                          user?.userAvatarFilename ??
                          "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
                        }
                        alt="User Profile"
                      />
                    </div> */}
                    <div className="flex flex-col flex-wrap w-full">
                      <P2 className="font-semibold font-avenir text-neutral500 whitespace-nowrap">
                        {user?.userFullName}
                      </P2>
                      <p className="text-neutral300 font-avenir flex-wrap  text-xs w-11/12  ">
                        {user?.userName}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-2">
                    {user?.isAdmin === "False" ? null : (
                      <Link
                        to="/dashboard/"
                        className="flex w-full gap-3 rounded-[4px] px-1 py-2 item text-neutral500 hover:bg-primary500 hover:text-a11y"
                        onClick={handleCloseDropdown}
                      >
                        <UserCircleIcon className="w-4 stroke-2" />
                        <P2 className="font-avenir">Admin Profile</P2>
                      </Link>
                    )}
                    <Link
                      to="/employee/dashboard/personal"
                      className="flex w-full gap-3 rounded-[4px] px-1 py-2 item text-neutral500 hover:bg-primary500 hover:text-a11y"
                      onClick={handleCloseDropdown}
                    >
                      <UserCircleIcon className="w-4 stroke-2" />
                      <P2 className="font-avenir">My Profile</P2>
                    </Link>

                    <Link
                      to="/employee/dashboard/profile"
                      className="flex w-full rounded-[4px] gap-3 px-1 py-2 item text-neutral500 hover:bg-primary500 hover:text-a11y"
                      onClick={handleCloseDropdown}
                    >
                      <UserCircleIcon className="w-4 stroke-2" />
                      <P2 className=" font-avenir">Preferences</P2>
                    </Link>
                  </div>
                </div>
                <hr className="my-2 text-neutral50" />
                {/* {isPadeDomain() === true ? (
                  <button
                    onClick={handleSwitch}
                    className="w-full rounded-[4px] flex gap-3 items-center px-1 py-2 text-neutral500 hover:bg-primary500 hover:text-a11y"
                  >
                    <ArrowTopRightOnSquareIcon className="w-4 stroke-2" />
                    <P2 className=" font-avenir">Switch to old version</P2>
                  </button>
                ) : null} */}

                {user?.lastLogin ? (
                  <div className="flex w-full gap-3 px-1 py-2 item text-neutral500">
                    <LockClosedIcon className="w-4 stroke-2" />
                    <P2 className="text-sm  font-avenir">
                      Last Login: {user?.lastLogin}{" "}
                    </P2>
                  </div>
                ) : null}

                <button
                  onClick={handleSignout}
                  className="w-full rounded-[4px] flex gap-3 items-center px-1 py-2 text-neutral500 hover:bg-primary500 hover:text-a11y"
                >
                  <ArrowRightOnRectangleIcon className="w-4 stroke-2" />
                  <P2 className=" font-avenir">Log Out</P2>
                </button>
              </div>
            </div>
          )}
        >
          <div className="flex items-center gap-2 cursor-pointer">
            <div className="rounded-full border-[4px] border-primary50 w-9 h-9 md:w-[45px] md:h-[45px] flex justify-center items-center ">
              <ImageComponent
                className="object-cover rounded-full h-full w-full"
                src={
                  user?.userAvatarFilename ??
                  "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
                }
                alt="User Profile"
              />
            </div>
            <P2 className="hidden md:block font-semibold font-avenir text-neutral500">
              {user?.userFullName}
            </P2>
            <ChevronUpIcon
              className={`hidden md:block ease-in-out rotate-180 transition-all w-4 stroke-[3px] text-neutral500`}
            />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export const TopNavigationBar: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isSidebarOpen, toggleSidebar }) => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const pages: PageType[] = [
    {
      title: "Dashboard",
      path: "/employee/dashboard",
    },
    {
      title: `Welcome to ${user?.userOrganisation}`,
      path: "/employee/dashboard/welcome",
    },
    {
      title: "People",
      path: "/employee/dashboard/people",
    },
    {
      title: "Job and Pay",
      path: "/employee/dashboard/job-and-pay",
    },
    {
      title: "Personal",
      path: "/employee/dashboard/personal",
    },
    {
      title: "Requests",
      path: "/employee/dashboard/request/leaves",
      subPages: [
        { path: "/employee/dashboard/request/leaves", title: "Leave Requests" },
        {
          path: "/employee/dashboard/request/leaves/create",
          title: "Create Leave Requests",
        },
        { path: "/employee/dashboard/request/loan", title: "Loan Requests" },
        {
          path: "/employee/dashboard/request/loan/create",
          title: "Create Loan Requests",
        },
        {
          path: "/employee/dashboard/request/salary-advance",
          title: "Salary Advance",
        },
        {
          path: "/employee/dashboard/request/salary-advance/create",
          title: "Create Salary Advance",
        },
        {
          path: "/employee/dashboard/request/documents",
          title: "Document Requests",
        },
        {
          path: "/employee/dashboard/request/documents/create",
          title: "Create Document Requests",
        },
        {
          path: "/employee/dashboard/request/exit",
          title: "Exit Requests",
        },
        {
          path: "/employee/dashboard/request/exit/create",
          title: "Create Exit Requests",
        },
      ],
    },
    {
      title: "Requests",
      path: "/employee/dashboard/requests/all",
      subPages: [
        {
          path: "/employee/dashboard/requests/leave-balances",
          title: "Leave Balances",
        },
        { path: "/employee/dashboard/request/leaves", title: "Leave Requests" },
        {
          path: "/employee/dashboard/requests/all/create-leave",
          title: "Create Leave Requests",
        },
        {
          path: "/employee/dashboard/requests/all/create-loan",
          title: "Create Loan Requests",
        },
        {
          path: "/employee/dashboard/requests/all/create-salaryadvance",
          title: "Create Salary Advance",
        },
        {
          path: "/employee/dashboard/requests/all/create-document",
          title: "Create Document Requests",
        },
        {
          path: "/employee/dashboard/requests/all/create-exit",
          title: "Create Exit Requests",
        },
        {
          path: "/employee/dashboard/requests/all/create-redeployment",
          title: "Create Redeployment Requests",
        },
      ],
    },
    {
      title: "Documents",
      path: "/employee/dashboard/document",
    },
    {
      title: "Benefits",
      path: "/employee/dashboard/benefits",
    },
    {
      title: "Payables",
      path: "/employee/dashboard/payables",
    },
    {
      title: "Payables",
      path: "/employee/dashboard/payables/new",
    },
    {
      title: "Christmas Village",
      path: "/employee/dashboard/christmas-village",
    },
    {
      title: "Wallet",
      path: "#",
      subPages: [
        {
          path: "/employee/dashboard/wallet/overview",
          title: "Overview",
        },
        {
          path: "/employee/dashboard/wallet/kyc",
          title: "Update KYC",
        },
        {
          path: "/employee/dashboard/wallet/create-pin",
          title: "Pin",
        },
        {
          path: "/employee/dashboard/wallet/beneficiaries",
          title: "Beneficiaries",
        },
        {
          path: "/employee/dashboard/wallet/beneficiaries/create",
          title: "Add Beneficiary",
        },
        {
          path: "/employee/dashboard/wallet/send-money-bank",
          title: "Send Money",
        },
        {
          path: "/employee/dashboard/wallet/send-money-wallet",
          title: "Send Money",
        },
        {
          path: "/employee/dashboard/wallet/transactions",
          title: "Transactions",
        },
        {
          path: "/employee/dashboard/wallet/bill-payments",
          title: "Bill Payments",
        },
        {
          path: "/employee/dashboard/wallet/bill-payments/transactions",
          title: "Bill Payments",
        },
        {
          path: "/employee/dashboard/wallet/bill-payments/transactions/details",
          title: "Transaction",
        },
        {
          path: "/employee/dashboard/wallet/bill-payments/buy",
          title: "Bill Payments",
        },
        {
          path: "/employee/dashboard/wallet/earned-wage-access/overview",
          title: "Earned Wage Access",
        },
        {
          path: "/employee/dashboard/wallet/earned-wage-access/transactions",
          title: "Withdrawal History",
        },

        {
          path: "/employee/dashboard/wallet/earned-wage-access/pin",
          title: "Transaction Pin",
        },
        {
          path: "/employee/dashboard/wallet/earned-wage-access/withdrawal",
          title: "Withdraw",
        },
        // CROSS BORDER
        {
          path: "/employee/dashboard/wallet/cross-border/overview",
          title: "Wallet Balances",
        },
        {
          path: "/employee/dashboard/wallet/cross-border/view",
          title: "View Currency",
        },
        {
          path: "/employee/dashboard/wallet/cross-border/local",
          title: "View Currency",
        },
        {
          path: "/employee/dashboard/wallet/cross-border/transactions",
          title: "Transactions",
        },
        {
          path: "/employee/dashboard/wallet/cross-border/transfers",
          title: "Transfers",
        },
        {
          path: "/employee/dashboard/wallet/cross-border/swap-money",
          title: "Swap Money",
        },
        {
          path: "/employee/dashboard/wallet/cross-border/send-money",
          title: "Send Money",
        },
        {
          path: "/employee/dashboard/wallet/cross-border/move-funds",
          title: "Move Funds",
        },
      ],
    },
    {
      title: "Bill Payments",
      path: "/employee/dashboard/bill-payments",
    },
    {
      title: "Payslips",
      path: "/employee/dashboard/payslip",
      subPages: [
        {
          path: "/employee/dashboard/payslip/view-payslip",
          title: "View Payslip",
        },
      ],
    },
    {
      title: "Assets",
      path: "/employee/dashboard/assets",
    },
    {
      title: "Tasks",
      path: "/employee/dashboard/tasks",
      subPages: [
        {
          path: "/employee/dashboard/tasks/*",
          title: "View Task",
        },
      ],
    },
    {
      title: "Performance",
      path: "/employee/dashboard/performance",
      subPages: [
        {
          path: "/employee/dashboard/overview/*",
          title: "Overview",
        },
        {
          path: "/employee/dashboard/objectives/*",
          title: "Objectives",
        },
        {
          path: "/employee/dashboard/conversations/*",
          title: "Conversations",
        },
        {
          path: "/employee/dashboard/feedback/*",
          title: "Feedback",
        },
      ],
    },
    {
      title: "Reviews",
      path: "/employee/dashboard/reviews",
    },
    {
      title: "Disciplinary",
      path: "/employee/dashboard/disciplinary",
      subPages: [
        {
          path: "/employee/dashboard/disciplinary/create-violation",
          title: "Record Violation",
        },
        {
          path: "/employee/dashboard/disciplinary/violations/*",
          title: "",
        },
      ],
    },
    {
      title: "Notes",
      path: "/employee/dashboard/notes",
    },
    {
      title: "Dashboard",
      path: "/employee/dashboard",
      subPages: [
        {
          path: "/employee/dashboard/notifications",
          title: "Notifications",
        },
      ],
    },
  ]

  const { pathname } = useLocation()
  const [currentPage, setCurrentPage] = useState<PageType>()
  const [currentSubPage, setCurrentSubPage] = useState<SubPageType>()
  const [isSubPage, toggleIsSubPage] = useState<boolean>(false)

  // find current page & subpage
  useEffect(() => {
    return throttle(() => {
      let page = pages.find((page) => page.path === pathname)
      setCurrentPage(page)

      const subPages = pages
        .map((page) => page.subPages!)
        .filter(Boolean)
        .reduce((a, e) => [...a, ...e], [])

      const subPage = subPages.find(
        (page) => matchRoutes([page], pathname) !== null
      )!

      if (page === undefined && subPage !== undefined) {
        setCurrentSubPage(subPage)
        toggleIsSubPage(true)

        page = pages.find((page) => {
          return (
            page.subPages !== undefined &&
            page.subPages.length > 0 &&
            page.subPages.find((page) => page.path === subPage.path)
          )
        })
        setCurrentPage(page)
      } else {
        setCurrentSubPage(undefined)
        toggleIsSubPage(false)
      }
    }, 200)
  }, [pages, pathname])

  return (
    <div className=" flex items-center justify-between w-full h-full px-4 md:px-6 lg:px-10 mx-auto ">
      {/* ========================= */}
      {/* ====Logo===== */}
      <div className="flex gap-4 md:hidden ">
        {isPadeDomain() ? (
          <img
            src={PadeLogoNoName}
            alt="PaidHR Logo"
            className=""
            width={30}
            height={30}
          />
        ) : // <img
        //   src={customizationSettings?.brand?.menuLogoUrl}
        //   alt=""
        //   className="w-[100px]"
        // />
        null}

        {/* ========================= */}
        {/* ====Menu Drawer Btn===== */}
        <button
          className=" w-[27px] "
          type="button"
          onClick={() => {
            toggleSidebar(!isSidebarOpen)
            document.body.classList.add("overflow-hidden")
          }}
        >
          <Bars3Icon className="w-6 h-6" />
        </button>
      </div>
      <div className="flex gap-4">
        {isSubPage || pathname.includes("christmas-village") ? (
          <button
            className="hidden md:flex items-center"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="w-5 h-5 text-neutral800" />
          </button>
        ) : null}
        <div className="hidden md:flex items-center gap-2">
          {currentPage !== undefined ? (
            isSubPage === true ? (
              currentSubPage?.title === undefined ||
              currentSubPage.title.length === 0 ? (
                <TextLink
                  to={currentPage.path}
                  title={currentPage.title}
                  className="text-base font-semibold no-underline text-neutral700 font-avenir"
                >
                  {currentPage.title}
                </TextLink>
              ) : (
                <>
                  <TextLink
                    to={currentPage.path}
                    title={currentPage.title}
                    className="text-base font-semibold no-underline text-neutral700 font-avenir"
                  >
                    {currentPage.title}
                  </TextLink>
                  <ChevronRightIcon className="w-4 stroke-[3] text-neutral900" />
                  <p className="text-base font-semibold  font-avenir text-neutral100">
                    {currentSubPage?.title!}
                  </p>
                </>
              )
            ) : (
              <p className="text-base font-semibold font-avenir text-neutral700">
                {currentPage?.title}
              </p>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <UserProfile
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
    </div>
  )
}
