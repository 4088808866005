import { Form, Modal, Spin } from "antd"
import React, { useEffect, useState } from "react"
import {
  IAddUserRequest,
  ICustomRoleRequest,
  IRole,
} from "../interface/interface"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { Button } from "components"
import { AppPeoplePicker } from "components/inputs/select/AppPeoplePicker"
import { useGet, usePost } from "utils/useFetch"
import usePermissions from "hooks/usePermissions"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"

type Props = {
  role: IRole
  onClose: Function
  isCustomRole?: boolean
}

export const AssignMembersModal = ({
  role,
  onClose,
  isCustomRole = false,
}: Props) => {
  const { toast } = useAppNotificationStore()

  const [form] = Form.useForm()

  const [employees, setEmployees] = useState<string[]>([])

  const { data, isLoading: isGetting } = usePost<any>({
    url: `/admin/company/getadmins?includePrimary=${isCustomRole}`,
    body: {
      q: "",
      page: "",
    },
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: IAddUserRequest) => {
      const res = await axiosInstance.put(`/admin/roles/update_users`, data)
      return res.data
    },
    onSuccess: (data) => {
      if (data.status == "success") {
        data.msg && toast.success(data.msg)
        onClose(true)
      } else {
        data.msg && toast.error(data.msg)
      }
    },
  })

  useEffect(() => {
    form.setFieldValue(
      "employees",
      role?.roleMembers?.map((x) => x?.nick_name)
    )
    setEmployees(role?.roleMembers?.map((x) => x?.nick_name) || [])
  }, [role])

  const [canAddMember, canRemove] = usePermissions({
    allowedPermissions: [
      "can_change_team_member_roles",
      "can_remove_team_members",
    ],
  })

  return (
    <CustomModal
      onCancel={() => onClose()}
      title={
        `Assign members to ${role?.description}`.replace(/(role)/gi, " ") +
        " role"
      }
      open
      footer={null}
    >
      <Spin spinning={isGetting}>
        <>
          <Form
            form={form}
            className=""
            layout="vertical"
          >
            <div className="py-4">
              <AppPeoplePicker
                form={form}
                employees={employees}
                fieldName="employees"
                onUpdate={(values) => {
                  setEmployees(values)
                }}
                useCustomData
                filteredEmployees={data?.map((it: any) => ({
                  full_name: it?.text,
                }))}
                isDisabled
                label=""
                maxTagCount={5}
              />
            </div>
          </Form>

          <div className="flex w-full justify-end pt-4 gap-3 mt-8">
            <Button
              color="secondary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              submitting={isLoading}
              onClick={(e) => {
                e.preventDefault()
                if (canRemove && canAddMember) {
                  if (!employees.length) return

                  mutate({
                    role_id: role?.role_id?.toString() || "",
                    assignments: employees?.map((x: string) =>
                      data?.find((it: any) => it?.text === x)?.id?.toString()
                    ),
                  })
                } else {
                  toast.error(
                    "You don't have the required permission to perform this operation"
                  )
                }
              }}
            >
              Done
            </Button>
          </div>
        </>
      </Spin>
    </CustomModal>
  )
}
