import { Form, Upload } from "antd"
import { Button } from "components"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { useState } from "react"
import { PlusOutlined } from "@ant-design/icons"
import { axiosInstance } from "utils/request"
import { newBaseUrl } from "utils/newbaseurl"
import { useMutation } from "@tanstack/react-query"
import { useAppNotificationStore } from "stores"
import type { UploadFile, UploadProps } from "antd/es/upload/interface"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import SuccessModal from "../modals/SuccessModal"
import { CustomInput } from "pages/dashboard/people/requests/form"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline"

const UploadFlag = () => {
  const { toast } = useAppNotificationStore()
  const [form] = Form.useForm()
  const [_, copy] = useCopyToClipboard()

  const [isLoading, setIsLoading] = useState(false)

  const [file, setFile] = useState<any>(null)
  const [imgUrl, setImgUrl] = useState("")
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [currency, setCurrency] = useState("")
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const { isLoading: isAddingFile, mutate: submitFile } = useMutation({
    mutationFn: async (formData: FormData) => {
      const res = await axiosInstance.post(`${newBaseUrl}/v1/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      return res?.data
    },
    onSuccess: (data) => {
      toast.success("File uploaded successfully!")
      setShowSuccessModal(true)
      setImgUrl(data?.data)
    },
  })

  const handleUpload = () => {
    if (file) {
      const formData = new FormData()
      formData.append("file", file)
      submitFile(formData)
    }
  }

  const getBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const latestFileList = newFileList.slice(-1)
    if (latestFileList[0]?.status === "done") {
      getBase64(latestFileList[0].originFileObj as File).then((url) => {
        setFile(latestFileList[0].originFileObj)
      })
    }
    setFileList(latestFileList)
  }

  const customRequest = async (options: any) => {
    setTimeout(() => {
      options.onSuccess("ok")
    }, 0)
  }

  const uploadButton = (
    <button
      style={{ border: 0, background: "none" }}
      type="button"
    >
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload flag</div>
    </button>
  )

  return (
    <DashboardWrapper>
      <FormSectionWrapper
        isActive={true}
        name="UPLOAD FLAGS"
        title="UPLOAD FLAGS"
        subtitle="Upload flags for all countries"
        form={form}
        layout="vertical"
        footerBtns={
          <div className="flex justify-end items-center gap-4 ">
            <Button
              color="primary"
              type="submit"
              disabled={isAddingFile}
              submitting={isAddingFile}
              onClick={(e) => {
                e.preventDefault()
                handleUpload()
              }}
            >
              Submit
            </Button>
          </div>
        }
      >
        <div className="space-y-6 pb-4">
          <div className="px-4 md:px-6 lg:px-8 xl:px-[60px] lg:gap-x-10 items-start">
            <CustomInput
              label="Currency"
              name="currency"
              onChange={(e) => setCurrency(e.target.value)}
              required
            />
            <div className="border border-[#6c7a9326] mt-[10px] rounded-5px ]">
              <div className="w-full px-[29px] py-6 flex justify-center">
                <div className="">
                  <Upload
                    listType="picture-circle"
                    fileList={fileList}
                    onChange={handleChange}
                    customRequest={customRequest}
                    beforeUpload={() => true}
                  >
                    {fileList.length === 0 && uploadButton}
                  </Upload>
                </div>
              </div>
            </div>
          </div>
          <SuccessModal
            title={`${currency} Flag uploaded successfully`}
            subtitle={
              <div className="items-center flex">
                <p>{imgUrl}</p>
                <button
                  onClick={() => {
                    copy(String(imgUrl))
                    toast.success("Copied")
                  }}
                  className="ml-0 sm:ml-2 shrink-0  text-primary500 p-1 rounded-full"
                >
                  <ClipboardDocumentIcon className="w-4 h-4" />
                </button>
              </div>
            }
            onClose={() => {
              setShowSuccessModal(false)
            }}
            open={showSuccessModal}
            ctaButton={
              <div className="mt-2">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    setFileList([])
                    setIsLoading(false)
                    setCurrency("")
                    setShowSuccessModal(false)
                    form.setFieldValue("currency", "")
                  }}
                >
                  Reset Form
                </Button>{" "}
              </div>
            }
          />
        </div>
      </FormSectionWrapper>
    </DashboardWrapper>
  )
}

export default UploadFlag
