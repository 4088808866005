import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import StarFilled from "assets/svg/star_filled.svg"
import StarUnFilled from "assets/svg/star_unfilled.svg"
import React, { useState, useEffect } from "react"
import { Button, ModalWrapper } from "components"
import { useAppNotificationStore } from "stores"
import { Select, Space, Spin, Tag } from "antd"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import ReviewViewKeyResults from "./ReviewViewKeyResults"
export const EmployeeRating: React.FC<{
  hasRelationship: boolean
  reviewHasEnded: boolean
  questions: any[]
  ratings: any[]
  employeeReviewId: string | number
  invalidateQueries: Function
  isLoadingEmpData: boolean
  personForKR: string
  periodID: string
  ReviewEntityKey: string
}> = ({
  hasRelationship,
  reviewHasEnded,
  questions,
  ratings,
  employeeReviewId,
  invalidateQueries,
  isLoadingEmpData,
  personForKR,
  periodID,
  ReviewEntityKey,
}) => {
  const { toast } = useAppNotificationStore()
  const [openSubmitReviewModal, setOpenSubmitReviewModal] = useState(false)
  const [activeQuestion, setActiveQuestion] = useState("")
  const [activeResponseId, setActiveResponseId] = useState("")

  const [showKeyResultModal, setshowKeyResultModal] = useState(false)
  const [activeResponses, setActiveResponses] = useState({
    text: "",
    rate: 0,
    dropdown: "",
  })

  interface IReviewResponse {
    employee_review_id: string
    review_question_id: string
    response: string
    type: string
  }

  const { isLoading: isReviewResponseLoading, mutate: saveResponse } =
    useMutation({
      mutationFn: async (body: IReviewResponse) => {
        const res = await axiosInstance.post(
          `/performance/save_review_response`,
          body
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data) {
          invalidateQueries()
          mixPanelEvent("pf-review-answer-question-success")
          {
            data.msg && toast.success(data.msg)
          }

          setActiveResponses({
            text: "",
            rate: 0,
            dropdown: "",
          })
        }
      },
      onError: (data: any) => {
        {
          data.msg && toast.error(data.msg)
        }
      },
    })

  const { isLoading: isReviewSubmit, mutate: submitReview } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/performance/submit_review?employee_review_id=${employeeReviewId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        invalidateQueries()
        setOpenSubmitReviewModal(false)
        mixPanelEvent("pf-review-submit-success")
        {
          data.msg && toast.success(data.msg)
        }

        setActiveResponses({ text: "", rate: 0, dropdown: "" })
      }
    },
    onError: (data: any) => {
      setOpenSubmitReviewModal(false)
      {
        data.msg && toast.error(data.msg)
      }
    },
  })

  const handleRatingFunction = (questionId: number, rate: string) => {
    const body = {
      employee_review_id: String(employeeReviewId),
      review_question_id: String(questionId),
      response: String(rate),
      type: "rating",
    }
    saveResponse(body)
  }
  useEffect(() => {
    setActiveResponseId("")
    setActiveResponses({
      text: "",
      rate: 0,
      dropdown: "",
    })
  }, [employeeReviewId])

  const handleTextboxFunction = (questionId: number) => {
    const body = {
      employee_review_id: String(employeeReviewId),
      review_question_id: String(questionId),
      response: activeResponses.text,
      type: "text",
    }
    saveResponse(body)
  }
  const handleDropdownFunction = (questionId: number, value: string) => {
    const body = {
      employee_review_id: String(employeeReviewId),
      review_question_id: String(questionId),
      response: value,
      type: "text",
    }
    saveResponse(body)
  }

  return (
    <div className="mt-8">
      <div className=" flex flex-col gap-4">
        <div className="bg-white rounded-5px shadow-card">
          <div className="flex justify-between w-full border items-center pr-6">
            <h4 className="text-sm sm:text-base font-extrabold text-neutral700 my-4  px-4 sm:px-[22px]">
              Review questions
            </h4>
            {/* <button
              className="text-primary500"
              onClick={() => setshowKeyResultModal(true)}
            >
              View Details
            </button> */}
          </div>
          <div className="border border-neutral50 w-full mb-[14px]" />

          {questions.length ? (
            <div className="pb-[43px] ">
              {questions.map((ques, index) => (
                <div
                  key={index}
                  className=" px-4  sm:px-[22px] pb-5 flex gap-4 flex-col "
                >
                  <div className="border-2 border-cardgrey rounded-lg bg-white py-4 px-3 sm:px-[30px]">
                    <div
                      className="flex items-center justify-between w-full  cursor-pointer "
                      onClick={() => {
                        if (activeQuestion == ques.review_question_id) {
                          setActiveQuestion("")
                        } else {
                          setActiveQuestion(ques.review_question_id)
                        }
                      }}
                    >
                      {" "}
                      <div>
                        <h6 className=" text-sm sm:text-base text-neutral600  font-bold">
                          {index + 1}. {ques?.description}
                        </h6>
                        <p className="text-xs sm:text-sm text-bluesubtext  mt-1">
                          {ques?.alias}
                        </p>
                      </div>
                      <div className="border  border-neutral50 rounded-full h-7 w-7 flex justify-center items-center text-center ">
                        {activeQuestion == ques.review_question_id ? (
                          <ChevronDownIcon className=" text-primary500  w-4 h-4" />
                        ) : (
                          <ChevronRightIcon className=" text-primary500  w-4 h-4" />
                        )}
                      </div>
                    </div>
                    {/* RESPONSE TYPES */}

                    {/* {ques.review_question_id && ( */}
                    {activeQuestion == ques.review_question_id && (
                      <div className="mt-4 border-t">
                        {/* TEXT BOX AND RATING */}
                        {ques?.review_response_type_id == 4 && (
                          <div className="border-t pt-4">
                            <h6 className=" text-sm text-subText3">
                              Rating and textbox{" "}
                              {ques?.response && (
                                <Space
                                  size={[0, 2]}
                                  wrap
                                >
                                  <Tag
                                    color="gold"
                                    className="font-medium ml-1 py-1 text-sm"
                                  >
                                    {activeResponseId !== "" &&
                                    activeResponseId ==
                                      ques?.review_question_id &&
                                    activeResponses.rate !== 0
                                      ? activeResponses.rate
                                      : ques?.response?.description}
                                  </Tag>
                                </Space>
                              )}
                            </h6>
                            <div className=" my-4 grid grid-cols-5 items-center   justify-evenly">
                              {ratings.map((rate, index) => {
                                let starSrc

                                if (
                                  activeResponseId !== "" &&
                                  activeResponseId ==
                                    ques?.review_question_id &&
                                  activeResponses.rate !== 0
                                ) {
                                  starSrc =
                                    index + 1 <= Number(activeResponses.rate)
                                      ? StarFilled
                                      : StarUnFilled
                                } else {
                                  starSrc =
                                    index + 1 <= ques?.response?.description
                                      ? StarFilled
                                      : StarUnFilled
                                }
                                return (
                                  <div
                                    key={index}
                                    className=" w-full flex justify-center items-center"
                                    onClick={() => {
                                      setActiveResponses({
                                        ...activeResponses,
                                        rate: rate?.rating_id,
                                      })
                                      setActiveResponseId(
                                        ques?.review_question_id
                                      )
                                      handleRatingFunction(
                                        ques?.review_question_id,
                                        rate?.rating_id
                                      )
                                    }}
                                  >
                                    <img
                                      src={starSrc}
                                      alt="stars"
                                      className="w-6 h-6"
                                    />
                                  </div>
                                )
                              })}
                            </div>

                            <div className="border-2 rounded-5px  px-4 sm:px-[31px] py-3 bg-[#FAFAFA] mt-4">
                              <h6 className="font-bold text-[#15294B] text-sm mb-1">
                                Assessment legend
                              </h6>
                              <div className="  sm:grid grid-cols-5 w-full my-2 gap-5 ">
                                {ratings?.length &&
                                  ratings?.map((rate, index) => (
                                    <div
                                      key={index}
                                      className="w-full  text-center text-neutral400  sm:h-20   gap-1 flex flex-row sm:flex-col  justify-between sm:justify-evenly items-center py-2 sm:py-0"
                                    >
                                      <div className="flex items-center gap-1 sm:px-2 h-5">
                                        {[1, 2, 3, 4, 5].map(
                                          (rate_inside, index) => (
                                            <div
                                              key={index}
                                              className=" w-full flex justify-center items-center"
                                            >
                                              <img
                                                src={
                                                  rate_inside <= rate?.rating_id
                                                    ? StarFilled
                                                    : StarUnFilled
                                                }
                                                alt="stars"
                                                className="w-5 h-5"
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <span className=" text-xs sm:text-sm font-medium flex justify-center items-center text-neutral500 px-1 text-right sm:text-start ">
                                        {rate?.description}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="mt-1 rounded-lg border border-cardgrey h-[90px] flex flex-col justify-between  px-4 py-1">
                              <textarea
                                className="h-full  flex-grow  outline-none text-neutral500"
                                placeholder="Your text here..."
                                defaultValue={
                                  activeResponseId !== "" &&
                                  activeResponseId ==
                                    ques?.review_question_id &&
                                  activeResponses.text !== ""
                                    ? activeResponses.text
                                    : ques?.response?.alias
                                }
                                onChange={(e) => {
                                  setActiveResponses({
                                    ...activeResponses,
                                    text: e.target.value,
                                  })
                                  setActiveResponseId(ques?.review_question_id)
                                }}
                                onBlur={() => {
                                  handleTextboxFunction(
                                    ques?.review_question_id
                                  )
                                }}
                              />
                            </div>
                            <div className="flex items-center mt-2 justify-end">
                              <Button
                                color={
                                  isReviewResponseLoading
                                    ? "secondary"
                                    : "primary"
                                }
                                onClick={() => {
                                  handleTextboxFunction(
                                    ques?.review_question_id
                                  )
                                  mixPanelEvent(
                                    "pf-review-answer-question-init"
                                  )
                                }}
                              >
                                {isReviewResponseLoading ? <Spin /> : "Save"}
                              </Button>
                            </div>
                          </div>
                        )}
                        {/* TEXT BOX */}
                        {ques?.review_response_type_id == 2 && (
                          <div className="border-t pt-4">
                            <h6 className=" text-sm text-subText3 mb-4">
                              Textbox{" "}
                            </h6>

                            <div className="rounded-lg border border-cardgrey h-[90px] flex flex-col justify-between  px-4 py-1">
                              <textarea
                                className="h-full  flex-grow  outline-none text-neutral500"
                                placeholder="Your text here..."
                                value={
                                  activeResponseId !== "" &&
                                  activeResponseId ==
                                    ques?.review_question_id &&
                                  activeResponses.text !== ""
                                    ? activeResponses.text
                                    : ques?.response?.description
                                }
                                onChange={(e) => {
                                  setActiveResponses({
                                    ...activeResponses,
                                    text: e.target.value,
                                  })
                                  setActiveResponseId(ques?.review_question_id)
                                }}
                                onBlur={() => {
                                  handleTextboxFunction(
                                    ques?.review_question_id
                                  )
                                }}
                              />
                            </div>
                            <div className="flex items-center mt-2 justify-end">
                              <Button
                                color="primary"
                                onClick={() => {
                                  mixPanelEvent(
                                    "pf-review-answer-question-init"
                                  )
                                  handleTextboxFunction(
                                    ques?.review_question_id
                                  )
                                }}
                              >
                                {isReviewResponseLoading ? <Spin /> : "Save"}
                              </Button>
                            </div>
                          </div>
                        )}
                        {/* RATING */}
                        {ques?.review_response_type_id == 3 && (
                          <div className="border-t pt-4">
                            <h6 className=" text-sm text-subText3">
                              Rating{" "}
                              {ques?.response && (
                                <Space
                                  size={[0, 2]}
                                  wrap
                                >
                                  <Tag
                                    color="gold"
                                    className="font-medium ml-1 py-1 text-sm"
                                  >
                                    {activeResponseId !== "" &&
                                    activeResponseId ==
                                      ques?.review_question_id &&
                                    activeResponses.rate !== 0
                                      ? activeResponses.rate
                                      : ques?.response?.description}
                                  </Tag>
                                </Space>
                              )}
                            </h6>

                            <div className=" my-4 grid grid-cols-5 items-center   justify-evenly">
                              {ratings?.length &&
                                ratings?.map((rate, index) => {
                                  let starSrc

                                  if (
                                    activeResponseId !== "" &&
                                    activeResponseId ==
                                      ques?.review_question_id &&
                                    activeResponses.rate !== 0
                                  ) {
                                    starSrc =
                                      index + 1 <= Number(activeResponses.rate)
                                        ? StarFilled
                                        : StarUnFilled
                                  } else {
                                    starSrc =
                                      index + 1 <= ques?.response?.description
                                        ? StarFilled
                                        : StarUnFilled
                                  }
                                  return (
                                    <div
                                      key={index}
                                      className=" w-full flex justify-center items-center"
                                      onClick={() => {
                                        setActiveResponses({
                                          ...activeResponses,
                                          rate: rate?.rating_id,
                                        })
                                        setActiveResponseId(
                                          ques?.review_question_id
                                        )
                                        // handleRatingFunction(
                                        //   ques?.review_question_id,
                                        //   rate?.rating_id
                                        // )
                                      }}
                                    >
                                      <img
                                        src={starSrc}
                                        alt="stars"
                                        className="w-6 h-6"
                                      />
                                    </div>
                                  )
                                })}
                            </div>

                            <div className="  grid grid-cols-5 w-full mt-2">
                              {ratings?.length &&
                                ratings?.map((rate, index) => (
                                  <div
                                    className="w-full text-center text-neutral400 border flex flex-col"
                                    key={index}
                                  >
                                    <span className="h-10 border text-sm flex justify-center items-center text-neutral500 px-1 ">
                                      {rate?.rating_id}
                                    </span>
                                    <span className="h-10 border text-sm flex justify-center items-center text-neutral500 px-1 ">
                                      {rate?.description}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                        {/* DROPDOWN */}
                        {ques?.review_response_type_id == 1 && (
                          <div className="border-t pt-4">
                            <h6 className=" text-sm text-subText3">Dropdown</h6>
                            <div className=" my-4 flex items-center rounded-5px  justify-evenly border">
                              <Select
                                showSearch
                                optionFilterProp="children"
                                defaultValue={
                                  ques?.response && ques?.response?.description
                                }
                                // filterOption={(input, option) =>
                                //   (option?.label ?? "")
                                //     .toLowerCase()
                                //     .includes(input.toLowerCase())
                                // }
                                style={{ width: "100%", height: "100%" }}
                                placeholder="Select Option"
                                onChange={(value) => {
                                  handleDropdownFunction(
                                    ques?.review_question_id,
                                    value
                                  )
                                }}
                                bordered={false}
                                // onSearch={onSearch}
                                optionLabelProp="label"
                                options={ques?.response_options
                                  .split(";")
                                  ?.map((res: string) => ({
                                    // value:dpt.personId,
                                    value: res,
                                    label: res,
                                  }))}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div className="px-[22px]">
                <Button
                  color="primary"
                  title="review"
                  onClick={() => {
                    setOpenSubmitReviewModal(true)
                  }}
                >
                  Submit Review
                </Button>
              </div>
            </div>
          ) : (
            <div className="h-40  flex justify-center items-center">
              <h6 className="text-neutral-500">
                No available review questions
              </h6>
            </div>
          )}
        </div>
      </div>
      {openSubmitReviewModal && (
        <ModalWrapper
          title="Submit Review"
          onClose={() => {
            setOpenSubmitReviewModal(false)
          }}
        >
          <div className="px-5 pt-3 flex flex-col">
            <p className="text-[#15294B] text-sm mb-6 ">
              You’ll be able to modify your responses until the review ends or
              is closed. Click Confirm to continue.
            </p>
          </div>
          <div className="border py-5 px-6 flex gap-4 justify-end items-center ">
            <Button
              color="secondary"
              onClick={() => {
                setOpenSubmitReviewModal(false)
              }}
            >
              Cancel
            </Button>

            <Button
              title="update"
              color="primary"
              submitting={isReviewSubmit}
              onClick={() => {
                submitReview()
                mixPanelEvent("pf-review-submit-init")
              }}
            >
              Confirm
            </Button>
          </div>
        </ModalWrapper>
      )}

      <NewModalWrapper
        onClose={() => setshowKeyResultModal(false)}
        // className="!w-[712px]"
        width={712}
        title="Key Results"
        open={showKeyResultModal}
      >
        <div className="px-4 lg:px-5 py-2 flex flex-col gap-4 overflow-y-auto">
          <ReviewViewKeyResults ReviewEntityKey={ReviewEntityKey} />
        </div>
      </NewModalWrapper>

      {/* {isReviewResponseLoading || isLoadingEmpData ? (
        <div className="absolute flex justify-center bottom-0 items-center right-0 left-0 top-0  bg-white bg-opacity-70">
          <LoadingIndicatorWhite />
        </div>
      ) : null} */}
    </div>
  )
}
