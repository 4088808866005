import { InformationCircleIcon } from "@heroicons/react/24/outline"

import { Table, Tag, Tooltip } from "antd"
import { useAuthStore } from "stores"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { formatCurrencyNoSymbol } from "utils/currency"
// import { TransRes } from "../interface"
import { useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"
import { ICurrenciesData } from "pages/dashboard/wallets/crossBorderPayments/interface"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import ViewTransferDetails from "../ViewTransferDetails"
import { useState } from "react"
import { title } from "process"
import { availableFlags } from "utils/flags"

interface props {
  transactionsData: any[] | undefined
  loading: boolean
}
const TransfersTable = ({ transactionsData, loading }: props) => {
  const { user } = useAuthStore()
  const [activeReference, setActiveReference] = useState("")
  const [showDetails, setShowDetails] = useState(false)

  const availableCurrencies = [
    ...new Set(
      transactionsData?.map((item) => item?.meta?.destinationCurrency)
    ),
  ]

  const TableData =
    transactionsData?.map((item: any) => {
      const currencyInfo = availableFlags.find((flag) => {
        if (item.currency === "NGN" || item.currency === "GBP") {
          return flag.code === item?.currency
        } else {
          return flag.code === item?.meta?.destinationCurrency
        }
      })
      const USDSymbol = availableFlags?.find(
        (flag) => flag.code === "USD"
      )?.symbol

      return {
        key: item._id,
        date: {
          date: formatDateReadable(String(item?.createdAt)),
          time: formatTimeReadble(String(item?.createdAt)),
        },
        amount: {
          value:
            item.currency === "NGN" || item.currency === "GBP"
              ? item?.amount
              : item.meta?.receivingAmount,
          valueInUSD:
            item?.currency !== "NGN" &&
            item.currency !== "GBP" &&
            item?.meta?.destinationCurrency !== "USD" &&
            item.amount,
          symbolInUSD: USDSymbol,
          symbol: currencyInfo?.symbol,
        },
        charge: {
          amount: item.fee,
          symbol: item.currency === "NGN" ? currencyInfo?.symbol : USDSymbol,
        },
        // method: item.paymentMethod || "-",
        reference: item.reference,
        recipient:
          item.currency == "NGN"
            ? item?.accountName
            : `${item.beneficiaryFirstName} ${item?.beneficiaryLastName}` ||
              "-",
        bank: {
          bank_name: item.bankCode || "-",
          account_number: item.accountNumber,
        },
        currency: {
          flag: currencyInfo?.flag,
          currency_code:
            item.currency === "NGN" || item.currency === "GBP"
              ? item?.currency
              : item.meta?.destinationCurrency,
        },
        remark: item.description,
        status: {
          status: item.status,
          statusMessage: item?.statusMessage,
        },
        type: item.product || "-",
        currencyInfo,
        action: item.reference,
      }
    }) || []

  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` text-padeBlack font-circular text-sm`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: {
          value: string
          charge: string
          symbol: string
          symbolInUSD: string
          valueInUSD: string
        }) => (
          <div className={` text-sm  flex-nowrap`}>
            <p className="text-padeBlack font-circular text-sm">
              {amount?.symbol}
              {formatCurrencyNoSymbol(Number(amount.value))}
            </p>
            {amount?.valueInUSD && (
              <span className="font-avenir text-xs text-neutral300 flex`">
                ~ {amount?.symbolInUSD}
                {formatCurrencyNoSymbol(Number(amount.valueInUSD))}
              </span>
            )}
          </div>
        ),
      },
      {
        title: "Charge",
        dataIndex: "charge",
        render: (charge: { amount: string; symbol: string }) => (
          <div className={`text-padeBlack font-circular text-sm capitalize`}>
            {charge?.amount ? (
              <p>
                {charge?.symbol}
                {formatCurrencyNoSymbol(Number(charge?.amount))}
              </p>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        title: "Currency",
        dataIndex: "currency",
        filters:
          availableCurrencies?.map((item) => ({
            text: item,
            value: item,
          })) || [],
        onFilter: (value: any, record: any) => {
          return record?.currency?.currency_code === value
        },
        render: (currency: { flag: any; currency_code: any }) => (
          <div className="flex items-center gap-2">
            <div>
              <img
                src={currency?.flag}
                alt=""
                className="h-6 w-6"
              />
            </div>
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {currency?.currency_code}
            </span>
          </div>
        ),
      },
      {
        title: "Recipient",
        dataIndex: "recipient",
        render: (recipient: string) => (
          <div className={`text-padeBlack font-circular text-sm`}>
            <p>{recipient}</p>
          </div>
        ),
      },
      {
        title: "Account",
        dataIndex: "bank",
        render: (bank: { bank_name: string; account_number: string }) => (
          <div className={`text-padeBlack font-circular text-sm flex-nowrap`}>
            <p>{bank.bank_name}</p>
            <span className="text-xs flex-nowrap`">{bank.account_number}</span>
          </div>
        ),
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (type: string) => (
          <div className={`text-padeBlack font-circular text-sm capitalize`}>
            <p>{type}</p>
          </div>
        ),
      },
      {
        title: "Reference",
        dataIndex: "reference",
        render: (reference: string) => (
          <div
            className={`text-padeBlack font-circular text-sm  overflow-hidden`}
          >
            <p>{reference}</p>
          </div>
        ),
      },
      // {
      //   title: "Method",
      //   dataIndex: "method",
      //   render: (method: string) => (
      //     <div className={`text-padeBlack font-circular text-sm capitalize`}>
      //       <p>{method}</p>
      //     </div>
      //   ),
      // },
      {
        title: "Description",
        dataIndex: "remark",
        render: (remark: string) => (
          <div
            className={`text-padeBlack font-circular text-sm   overflow-hidden`}
          >
            <p>{remark}</p>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (status: { status: string; statusMessage: string }) => (
          <div className="flex items-center ">
            <Tag
              color={
                status.status === "successful"
                  ? "success"
                  : status.status === "failed"
                  ? "error"
                  : status?.status === "processing"
                  ? "warning"
                  : "default"
              }
            >
              {status.status}
            </Tag>
            {status.status === "failed" && (
              <Tooltip
                title={status?.statusMessage}
                className=" cursor-pointer"
              >
                <InformationCircleIcon className="h-4 w-4" />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        render: (action: string) => (
          <button
            onClick={() => {
              setActiveReference(action)
              setShowDetails(true)
            }}
            className="text-primary500"
          >
            View
          </button>
        ),
      },
    ] || []

  return (
    <>
      <Table
        dataSource={TableData}
        columns={TableColumns}
        loading={loading}
        className="text-[#42526D]"
        pagination={{
          defaultPageSize: 20,
        }}
        scroll={{
          y: "calc(100vh - 300px)",
          x: "max-content",
        }}
      />
      {activeReference && (
        <ViewTransferDetails
          reference={activeReference}
          onClose={() => {
            setActiveReference("")
            setShowDetails(false)
          }}
          open={showDetails}
        />
      )}
    </>
  )
}
export default TransfersTable
