import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js"
import { Bar } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, BarElement)

const StackedBarchart = ({ currency, thick, radius, labels, datasets }) => {
  var data = {
    labels: labels,

    datasets: datasets,
  }

  var options = {
    color: "#fff",
    maintainAspectRatio: false,
    plugins: {
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart - Stacked",
      // },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,

        grid: {
          display: true,
        },
      },
    },
  }

  return (
    <div className=" bg-white-white h-full  w-full">
      <Bar
        height={270}
        data={data}
        options={options}
      />
    </div>
  )
}

export default StackedBarchart
