import { useMutation } from "@tanstack/react-query"
import { Form, Input } from "antd"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import React, { useState } from "react"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"
import { axiosInstance } from "utils/request"
type Props = {
  onClose: () => void
  invalidateQueries: () => void
  open: boolean
  paymentId: string
  x: string
  currency?: string
}

export const DeselectPayment = ({
  onClose,
  open,
  paymentId,
  x,
  currency,
  invalidateQueries,
}: Props) => {
  const { toast } = useAppNotificationStore()
  const [comment, setComment] = useState("")
  const { isLoading: isSubmittingDeselectAll, mutate: submitDeselectAll } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/batch_unupdate_payment_schedule_items?id=${paymentId}&currency_code=${currency}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          onClose()
          invalidateQueries()
          setComment("")
          mixPanelEvent("pay-schedule-payout-deselect-success")
        } else {
          onClose()
          setComment("")
        }
      },
      onError: (data) => {
        onClose()
        setComment("")
      },
    })

  return (
    <NewModalWrapper
      open={open}
      title="Deselect Payouts"
      className="w-[750px]"
      onClose={onClose}
    >
      <div className="px-5 py-3 flex flex-col">
        <p className="text-[#15294B] text-sm mb-6 ">
          All selected payouts pending processing will be unselected. Click
          Confirm to continue.
        </p>
      </div>
      <div className="border py-5 px-6 flex gap-4 justify-end ">
        <Button
          title="cancel"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          title="update"
          color="primary"
          submitting={isSubmittingDeselectAll}
          onClick={() => {
            mixPanelEvent("pay-schedule-payout-deselect-init")
            submitDeselectAll()
          }}
        >
          Confirm
        </Button>
      </div>
    </NewModalWrapper>
  )
}

export default DeselectPayment
