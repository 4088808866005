import {
  CheckCircleIcon,
  ClipboardDocumentIcon,
  EnvelopeIcon,
  HomeIcon,
  PhoneIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline"
import { Drawer } from "antd"
import { Button } from "components"

import React, { useRef } from "react"
import { formatCurrencyNoSymbol } from "utils/currency"
import DownloadReceipt from "../Component/DownloadReceipt"
import ReactToPrint from "react-to-print"
import { useAppNotificationStore } from "stores"
type Props = {
  onClose: Function
  filtered?: boolean
  open: boolean
  payment: any
}

export const ViewPayoutModal = ({ onClose, open, payment }: Props) => {
  const componentRef: any = useRef()
  const { toast } = useAppNotificationStore()
  const transaction = [
    // { title: "Date of transfer", value: "" },
    { title: "Description", value: payment?.description },
    { title: "Payment Method", value: payment?.payment_method },
    {
      title: "Amount",
      value: `₦ ${formatCurrencyNoSymbol(payment?.amount || 0)}`,
    },
    { title: "Status", value: payment?.payment_schedule_item_status },
    {
      title: "Transfer reference",
      value: payment?.reference_number,
      key: "reference",
    },
  ]

  return (
    <>
      <Drawer
        title=""
        open={open}
        onClose={() => onClose()}
        className=""
        closable={false}
        width={500}
      >
        <div className="h-full w-full flex flex-col justify-between font-avenir">
          <div className="w-full flex-col flex gap-4 max-h-full ">
            <h5 className="text-[#15294B] font-bold text-lg">
              Transfer Details
            </h5>
            <div className="">
              {transaction.map((trans, index) => (
                <div className="border-b-2 border-dashed py-4 flex justify-between items-center ">
                  <h6 className=" text-[15px] font-light text-[#42526D]">
                    {trans.title}
                  </h6>
                  <h6
                    className={`    text-[#15294B] text-[15px] font-bold flex items-center

                  ${trans.value === "Succeeded" && "!text-[#0BCE88]"}
                  ${trans.value === "Failed" && "text-[#EB3949]"}

                  ${
                    trans.title == "Transfer reference" &&
                    "!text-[#004AF5]  text-sm font-extralight"
                  }


                    `}
                  >
                    {trans.value == "Succeeded" && (
                      <CheckCircleIcon className="w-5 mr-0.5 h-5 text-[#0BCE88] " />
                    )}

                    {trans.value == "Failed" && (
                      <XCircleIcon className="w-5 mr-0.5 h-5 text-[#EB3949]" />
                    )}
                    {trans.value}
                    {trans.title == "Transfer reference" && (
                      <ClipboardDocumentIcon
                        className="w-4 h-4 ml-0.5 text-[#15294B] "
                        onClick={() => {
                          navigator.clipboard.writeText(trans?.value)
                          toast.success("Copied")
                        }}
                      />
                    )}
                  </h6>
                </div>
              ))}
            </div>
            <div className=" mt-[90px] flex flex-col gap-5">
              <h5 className="text-[#15294B] text-base font-bold ">
                Beneficiary Details
              </h5>
              <div className="flex flex-col px-[10px] gap-2 ">
                <p className="text-[#15294B] font-bold  text-sm flex items-center">
                  <UserIcon className="w-5 h-5 mr-1" />
                  {payment?.full_name
                    ? payment?.full_name
                    : payment?.beneficiary_name}
                </p>
                <p className="text-[#15294B] font-bold  text-sm flex items-center ">
                  <HomeIcon className="w-5 h-5 mr-1" />
                  {payment?.bank_name}
                </p>
                {payment?.email && (
                  <p className="text-[#15294B] font-bold  text-sm flex items-center">
                    <EnvelopeIcon className="w-5 h-5 mr-1" />
                    {payment?.email}
                  </p>
                )}
              </div>
              <div>
                {componentRef && (
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        color="primary"
                        title="download"
                      >
                        Download Receipt
                      </Button>
                    )}
                    content={() => componentRef.current}
                    documentTitle={`${payment?.reference_number}.pdf`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <div className="hidden">
        <DownloadReceipt
          ref={componentRef}
          payment={payment}
        />
      </div>
    </>
  )
}

export default ViewPayoutModal
