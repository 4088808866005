import { Avatar, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import { useMemo, useState } from "react"
import { IRole } from "./interface/interface"
import { Button } from "components"
import { AppSearchInput } from "components/inputs/AppSearchInput"
import { TableActionButton } from "components/buttons/TableActionButton"
import ResponsiveActinBtn from "components/new-stuff/ui/ResponsiveActinBtn"
import { useGet } from "utils/useFetch"
import { useNavigate } from "react-router-dom"
import { DeleteCustomRoleModal } from "./modals/DeleteCustomRoleModal"
import { AssignMembersModal } from "./modals/AssignMembersModal"
import { useAppNotificationStore, useAuthStore } from "stores"
import { ChangePrimaryAdminModal } from "./modals/ChangePrimaryAdminModal"

type Props = {
  type: "custom" | "system"
}

export const RolesDataList = ({ type }: Props) => {
  const [search, setSearch] = useState("")

  const { toast } = useAppNotificationStore()

  const { user } = useAuthStore()

  const navigate = useNavigate()

  const { data, isLoading, refetch } = useGet<any>({
    url: `/admin/roles/select_role_details?is_default=${
      type === "system" ? true : false
    }`,
  })

  const [itemToDelete, setItemToDelete] = useState<IRole>()
  const [itemToAssignMember, setItemToAssignMembers] = useState<IRole>()
  const [assignPrimaryAdmin, setAssignPrimaryAdmin] = useState<IRole>()

  const tableData = useMemo(() => {
    if (search) {
      return data?.data.filter((role: IRole) => {
        const name = role?.description
        return (
          name?.toLowerCase()?.includes(search.toLowerCase()) &&
          role?.role_id !== 3
        )
      })
    }
    return data?.data.filter((role: IRole) => {
      return role?.role_id !== 3
    })
  }, [data, search, type])

  const columns: ColumnsType<IRole> = [
    {
      title: "Role",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Description",
      dataIndex: "alias",
      align: "left",
      width: "40%",
      render(value, record, index) {
        return <>{record?.alias || ""}</>
      },
    },
    {
      title: "Members",
      width: "30%",
      render(value, record, index) {
        const canTakeActionOnPrimaryAdmin = record?.role_id === 1
        return (
          <div className="flex gap-2 items-center">
            {record?.roleMembers?.length > 0 ? (
              <Tooltip
                arrow
                autoAdjustOverflow
                placement="left"
                title={
                  <div className="flex flex-col gap-1">
                    {record.roleMembers?.map((role) => (
                      <div className="flex gap-2 items-center">
                        <div>
                          <Avatar
                            src={role?.avatar_url}
                            size={"small"}
                          />
                        </div>
                        <div>{role?.nick_name}</div>
                      </div>
                    ))}
                  </div>
                }
                trigger={"hover"}
                className="cursor-pointer"
              >
                <div>
                  {record?.roleMembers?.length}&nbsp;
                  {record?.roleMembers?.length === 1 ? "member" : "members"}
                </div>
              </Tooltip>
            ) : (
              "No member"
            )}

            <div
              className={`cursor-pointer whitespace-nowrap text-primary500  py-1 px-2 text-[12px] select-none rounded-[5px]`}
              onClick={() => {
                if (canTakeActionOnPrimaryAdmin) {
                  if (user?.isPrimaryAdmin === "True") {
                    setAssignPrimaryAdmin(record)
                  } else {
                    toast.error(
                      "You don't have sufficient permission to change the primary admin"
                    )
                  }
                } else {
                  setItemToAssignMembers(record)
                }
              }}
            >
              {canTakeActionOnPrimaryAdmin ? "Change Admin" : "Assign members"}
            </div>
          </div>
        )
      },
    },
    {
      title: "",
      render(value, record, index) {
        const viewLink = `view/${record?.role_key}`
        return (
          <TableActionButton
            menuItems={
              type === "custom"
                ? [
                    {
                      key: "1",
                      label: (
                        <button
                          className="w-full text-left"
                          onClick={() => navigate(viewLink)}
                        >
                          View
                        </button>
                      ),
                    },
                    // {
                    //   key: "3",
                    //   label: (
                    //     <button
                    //       className="w-full text-left"
                    //       onClick={() => {
                    //         setItemToAssignMembers(record)
                    //       }}
                    //     >
                    //       Assign members
                    //     </button>
                    //   ),
                    // },
                    {
                      key: "2",
                      label: (
                        <button
                          className="w-full text-left"
                          onClick={() => {
                            setItemToDelete(record)
                          }}
                        >
                          Delete
                        </button>
                      ),
                    },
                  ]
                : [
                    {
                      key: "1",
                      label: (
                        <button
                          className="w-full text-left"
                          onClick={() => navigate(viewLink)}
                        >
                          View
                        </button>
                      ),
                    },
                    // {
                    //   key: "3",
                    //   label: (
                    //     <button
                    //       className="w-full text-left"
                    //       onClick={() => {
                    //         setItemToAssignMembers(record)
                    //       }}
                    //     >
                    //       Assign members
                    //     </button>
                    //   ),
                    // },
                  ]
            }
          />
        )
      },
    },
  ]

  return (
    <div className="flex flex-col justify-between h-full w-full">
      <div className="bg-white  flex items-center justify-between gap-4 py-4 px-4">
        <div className="w-full  flex items-center gap-4 justify-between">
          <AppSearchInput
            search={search}
            handleSearch={(value) => setSearch(value)}
          />

          {type === "custom" && (
            <ResponsiveActinBtn
              items={[
                {
                  key: "1",
                  label: (
                    <button
                      onClick={() => {
                        navigate("create")
                      }}
                    >
                      Create custom role
                    </button>
                  ),
                },
              ]}
            >
              <Button
                color="primary"
                className="h-full"
                onClick={() => {
                  navigate("create")
                }}
              >
                Create Custom Role
              </Button>
            </ResponsiveActinBtn>
          )}
        </div>
      </div>

      <Table
        columns={columns}
        loading={isLoading}
        dataSource={tableData}
        scroll={{
          y: "calc(100vh - 300px)",
          x: "max-content",
        }}
        pagination={false}
        rowClassName="text-neutral400 font-avenir"
      />

      {itemToDelete && (
        <DeleteCustomRoleModal
          role={itemToDelete}
          onClose={(status: boolean) => {
            setItemToDelete(undefined)
            if (status) {
              refetch()
            }
          }}
        />
      )}
      {itemToAssignMember && (
        <AssignMembersModal
          role={itemToAssignMember}
          onClose={(status: boolean) => {
            setItemToAssignMembers(undefined)
            if (status) {
              refetch()
            }
          }}
          isCustomRole={type === "system" ? false : true}
        />
      )}
      {assignPrimaryAdmin && (
        <ChangePrimaryAdminModal
          role={assignPrimaryAdmin}
          onClose={(status: boolean) => {
            setAssignPrimaryAdmin(undefined)
            if (status) {
              refetch()
            }
          }}
        />
      )}
    </div>
  )
}
