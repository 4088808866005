import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import React, { useRef, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "antd"
import { Button } from "components"
import ConfirmPin from "./ConfirmPin"

const CreatePin = () => {
  const [otp, setOtp] = useState<string[]>(["", "", "", ""])
  const [updatedPin, setUpdatedPin] = useState("")
  const navigate = useNavigate()
  const otpInputs = useRef<(HTMLInputElement | null)[]>([])
  const [confirmPin, setConfirmPin] = useState(false)
  const [isPinComplete, setIsPinComplete] = useState(false)

  // Effect to check if PIN input is complete
  useEffect(() => {
    setIsPinComplete(otp.every((digit) => digit !== ""))
  }, [otp])

  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) return // Only allow numeric input

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)
    setUpdatedPin(newOtp.join(""))

    // Move focus to the next input field if available
    if (
      value !== "" &&
      index < otp.length - 1 &&
      otpInputs.current[index + 1]
    ) {
      otpInputs.current[index + 1]?.focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData("text")
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault()
      return
    }

    const newOtp = pastedData.split("").slice(0, otp.length)
    setOtp(newOtp)
    setUpdatedPin(newOtp.join(""))
  }

  const handleNext = () => {
    if (isPinComplete) {
      setConfirmPin(true)
    }
  }

  return (
    <DashboardWrapper>
      {!confirmPin ? (
        <div className="flex justify-center w-full items-center">
          <div className="flex flex-col justify-between w-full items-center bg-white py-[67px] px-4 max-w-[991px]">
            <h6 className="text-2xl text-padeBlack font-circular mb-[10px] font-bold">
              Secure Your Money{" "}
            </h6>
            <p className="text-sm mb-[22px]">
              Set a 4 digit PIN to authorise transactions
            </p>
            <div className="xl:w-[575px] mb-10">
              <Alert
                message={
                  "Please note that this PIN will be used to authorise other transactions on PaidHR"
                }
                type="info"
                showIcon
                className="font-avenir text-neutral500 text-sm w-full flex justify-between items-center"
              />
            </div>
            <img
              src={
                "https://padefiles.blob.core.windows.net/images/66cc977a-f451-46d4-8e2e-24b6a82479de.svg"
              }
              alt="password"
              className="w-[59px] h-[59px]"
            />
            <p className="text-sm text-bluesubtext mt-7 mb-5">
              Please enter your PIN
            </p>

            <div className="flex gap-[22px]">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="password"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onPaste={handlePaste}
                  ref={(ref) => {
                    otpInputs.current[index] = ref
                  }}
                  className="border w-[60px] h-[60px] rounded-5px text-center text-lg font-bold active:border-primary500"
                  style={{
                    fontFamily: "PasswordFont", // Replace with your custom font name
                  }}
                />
              ))}
            </div>
            <div className="mt-10 w-[318px]">
              <Button
                color="primary"
                className={`w-full `}
                onClick={handleNext}
                disabled={!isPinComplete}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <ConfirmPin
          pin={updatedPin}
          setConfirmPin={setConfirmPin}
        />
      )}
    </DashboardWrapper>
  )
}

export default CreatePin
