import { useEffect, useState } from "react"

import { Select } from "antd"
import { DashboardContainer, H7 } from "components"
import { useNavigate } from "react-router"

import EwaBasicDetails from "./components/EwaBasicDetails"
import CompanySettings from "./components/CompanySettings"
import { useGet } from "utils/useFetch"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { TermsConditions } from "./components/TermsConditions"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"

const { Option } = Select
const EwaSettings = () => {
  const navigate = useNavigate()
  const [activeKey, setActiveKey] = useState<string[]>(["2"])
  const [ewaBody, setEwaBody] = useState<IEwaBody>({
    ewa_company_id: "",
    tin: "",
    registered_name: "",
    email: "",
    phone: "",
    full_address: "",
    registration_number: "",
    withdrawal_fee_payer: "",
    withdrawal_fee_payer_id: "",
    withdrawal_stop_day: "",
    can_employees_withdraw: "yes",
    is_approval_required: "",
  })
  const { isLoading: isGetEwaLoading, data: EwaDetails } = useGet<any>({
    url: `/admin/company/view/settings?id=ewa&x=`,
  })
  const ewaSettings: IEwaSettings = EwaDetails?.ewaConfigSetting

  useEffect(() => {
    if (EwaDetails) {
      setEwaBody({
        ewa_company_id: "",
        tin: ewaSettings?.tin,
        registered_name: ewaSettings?.registered_name,
        email: ewaSettings?.email,
        phone: ewaSettings?.phone,
        full_address: ewaSettings?.full_address || "",
        registration_number: ewaSettings?.registration_number || "",
        withdrawal_fee_payer: String(ewaSettings?.withdrawal_fee_payer) || "",
        withdrawal_fee_payer_id:
          String(ewaSettings?.withdrawal_fee_payer_id) || "",
        withdrawal_stop_day: ewaSettings?.withdrawal_stop_day || "",
        // can_employees_withdraw: ewaSettings?.can_employees_withdraw?"yes": "no",
        can_employees_withdraw: "yes",
        is_approval_required: ewaSettings?.is_approval_required || "",
      })
    }
  }, [EwaDetails])

  return (
    <DashboardWrapper>
      {isGetEwaLoading ? (
        <div className="h-screen flex justify-center  items-center">
          <LoadingIndicatorWhite />
        </div>
      ) : (
        <div className="max-w-[1024px] mt-6  rounded-md space-y-[20px]">
          {/* <EwaBasicDetails
            active={activeKey.includes("1")}
            setActiveKey={setActiveKey}
            ewaBody={ewaBody}
            setEwaBody={setEwaBody}
          /> */}
          <CompanySettings
            active={activeKey.includes("2")}
            setActiveKey={setActiveKey}
            ewaBody={ewaBody}
            setEwaBody={setEwaBody}
          />
          <TermsConditions
            setActiveKey={setActiveKey}
            active={activeKey.includes("3")}
          />
        </div>
      )}
    </DashboardWrapper>
  )
}

export default EwaSettings

export interface IEwaSettings {
  ewa_key: null
  company_key: null
  ewa_id: null
  ewa_status_id: number | string
  ewa_status: null
  registered_name: string
  registration_number: null
  tin: string
  email: string
  payroll_run_day: string
  phone: string
  full_address: null
  withdrawal_fee_payer_id: any
  withdrawal_fee_payer: null
  withdrawal_stop_day: null
  can_employees_withdraw: null
  is_approval_required: null
  is_terms_accepted: null
  is_deleted: null
  is_active: null
}

export interface IEwaBody {
  ewa_company_id: string
  tin: string
  registered_name: string
  email: string
  phone: string
  full_address: string
  registration_number: string
  withdrawal_fee_payer: string
  withdrawal_fee_payer_id: string
  withdrawal_stop_day: string
  can_employees_withdraw: string
  is_approval_required: string
}
