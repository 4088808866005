import { useMutation } from "@tanstack/react-query"
import { Form, Input } from "antd"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import React, { useState } from "react"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
type Props = {
  onClose: () => void
  invalidateQueries: () => void
  open: boolean
  paymentId: string
}

export const DeletePayment = ({
  onClose,
  open,
  paymentId,
  invalidateQueries,
}: Props) => {
  const { toast } = useAppNotificationStore()
  const [comment, setComment] = useState("")
  const { isLoading: isSubmittingDelete, mutate: submitDelete } = useMutation({
    mutationFn: async (body: string) => {
      const res = await axiosInstance.post(
        `/admin/payment_schedules/remove_payment_schedule_item?id=${paymentId}&comment=${body}`
      )
      return res.data
    },
    onSuccess: (data) => {
      console.log(data)
      if (data.status === "success") {
        onClose()
        {
          data.msg && toast.success(data.msg)
        }
        mixPanelEvent("pay-schedule-payout-remove-success")
        invalidateQueries()
      } else {
        onClose()

        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      onClose()

      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  return (
    <NewModalWrapper
      open={open}
      title="Remove Payment"
      className="w-[750px]"
      onClose={onClose}
    >
      <div className="px-5 pt-3">
        <p className=" text-neutral300 mb-5 text-sm">
          Beneficiary won’t get paid if you remove this payment. Provide reason
          to continue.
        </p>
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: "Please input your reason!",
            },
          ]}
        >
          <Input
            className="h-10 mb-5"
            placeholder="Reason"
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="border py-5 px-6 flex gap-4 justify-end items-center ">
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          submitting={isSubmittingDelete}
          // onClick={onFinish}
          disabled={comment == "" ? true : false}
          onClick={() => {
            mixPanelEvent("pay-schedule-payout-remove-init")
            submitDelete(comment)
          }}
        >
          Remove
        </Button>
      </div>
    </NewModalWrapper>
  )
}

export default DeletePayment
