import { Spin } from "antd"
export const LoadingIndicatorWhite: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = () => {
  return <Spin />
}

type props = {
  children?: JSX.Element | JSX.Element[]
  message?: string
  loading?: "true" | "false"
}

export const LoaderWhite: React.FC<props> = ({
  loading,
  children,
  message,
}) => {
  return (
    <>
      {loading === "true" ? (
        <div className="h-full w-full p-3 flex justify-center items-center gap-2">
          <LoadingIndicatorWhite className="" />
          <span>{message}</span>
        </div>
      ) : (
        children
      )}
    </>
  )
}
