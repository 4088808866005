import { Modal } from "antd"
import React from "react"
import type { ModalProps } from "antd"
import { H7 } from "components/typography/H7"

interface Props extends Omit<ModalProps, "title" | "footer"> {
  title: string | React.ReactNode
  subtitle?: string | React.ReactNode
  footer?: React.ReactNode | null
  titleIcon?: React.ReactNode
}

const CustomModal = ({
  title,
  footer,
  titleIcon,
  children,
  subtitle,
  ...rest
}: Props) => {
  return (
    <Modal
      title={
        <div className="flex gap-2 px-4 md:px-6 p-4 border-b">
          {titleIcon}
          <div>
            <H7 className="font-medium text-neutral600 font-circular">
              {title}
            </H7>

            <p className="text-neutral100 text-sm font-light font-avenir">
              {subtitle}
            </p>
          </div>
        </div>
      }
      footer={null}
      wrapClassName="[&_.ant-modal-content]:!p-0"
      {...rest}
    >
      <div className="mt-4 px-4 md:px-6 pb-4">
        <div className="f">{children}</div>
      </div>

      {footer ? (
        <div className="w-full border-t mt-6 px-4 md:px-6 p-4 ">{footer}</div>
      ) : null}
    </Modal>
  )
}

export default CustomModal
