import { useMutation } from "@tanstack/react-query"
import { Alert, Checkbox, Spin } from "antd"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { toast } from "react-toastify"
import { ILoginResponse, useAuthStore } from "stores/auth"
import { parseQuery } from "utils/parseQuery"
import { axiosInstance } from "utils/request"
import { useQuery } from "hooks/useQuery"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { AppOTPInput } from "components/inputs/AppOTPInput"
import { useAuthModalStore } from "stores/auth-modal"

export const VerifyOtp = ({
  onSuccess,
  loginResponse,
}: {
  onSuccess?: () => void
  loginResponse: ILoginResponse
}) => {
  const [otp, setOtp] = useState("")
  const [timer, setTimer] = useState<number>(0)
  const [verifying, setVerifying] = useState(false)
  const [sendToEmail, setSendToEmail] = useState<boolean>(false)
  const [canRequestNewCode, setCanRequestNewCode] = useState<boolean>(false)
  const query = useQuery(useLocation().search)
  const urlQuery = parseQuery(useLocation().search)
  const navigate = useNavigate()
  const {
    open: openAuthModal,
    close: closeAuthModal,
    isOpen: isAuthModalOpen,
  } = useAuthModalStore()

  const disabled = !otp || otp.length < 6

  const { verifyTwoFACode } = useAuthStore()

  useEffect(() => {
    setTimer(30)
  }, [])

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) return prevTimer - 1
        else return prevTimer
      })
    }, 1000)
    return () => clearInterval(interval)
    //eslint-disable-next-line
  }, [])

  const { mutate: handleResend, isLoading: resending } = useMutation<any>({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/account/2fa/sendcode`,
        {
          // email: loginResponse?.user,
        },
        {
          headers: {
            Accept: "application/json",
            access_token: `${loginResponse?.access_token}`,
          },
        }
      )
      return res.data
    },

    onSuccess(data, variables, context) {
      if (data?.status === "success") {
        data?.msg && toast.success(data?.msg)
        setTimer(30)
      }
    },
  })

  const { mutate: requestCode, isLoading: requesting } = useMutation<any>({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/account/2fa/sendcode`,
        {
          // email: loginResponse?.user,
        },
        {
          headers: {
            Accept: "application/json",
            access_token: `${loginResponse?.access_token}`,
          },
        }
      )
      return res.data
    },

    onSuccess(data, variables, context) {
      if (data?.status === "success") {
        data?.msg && toast.success(data?.msg)
        setCanRequestNewCode(true)
        setTimer(30)
      } else {
        data?.msg && toast.error("Please refresh and try again.")
      }
    },
  })

  const handleVerifyCode = async () => {
    setVerifying(true)
    try {
      const response = await verifyTwoFACode(
        loginResponse.access_token,
        sendToEmail,
        otp
      )

      if (response.status === "Success") {
        mixPanelEvent("auth-sign-in", String(response?.user) || "")
        //successful login
        if (isAuthModalOpen) {
          closeAuthModal()
        } else {
          if (
            query.get("redirect") &&
            !query.get("redirect")?.includes("login")
          ) {
            const redirectUrl = new URL(query.get("redirect") as string)
            const redirectPath = redirectUrl.pathname
            const redirectSearch = redirectUrl.search

            navigate(redirectPath + redirectSearch, {
              replace: true,
            })
          } else {
            const userResponse = response.user

            if (userResponse?.isAdmin === "True") {
              if (
                userResponse.setupPercentage === "100" ||
                userResponse?.setupStatus === "Approved"
              ) {
                navigate("/dashboard/")
              } else {
                navigate("/dashboard/company-onboarding")
              }
            } else {
              if (userResponse.userStatusId === "1") {
                navigate("/employee/dashboard/")
              } else {
                navigate("/employee/dashboard/welcome")
              }
            }
          }
        }
      } else if (response.status === "Failed") {
        toast.error("Invalid code")
      } else {
        toast.error(response?.message)
      }
    } catch (err: any) {
      toast.error(err?.msg)
    } finally {
      setVerifying(false)
    }
  }

  return (
    <Spin spinning={verifying || requesting}>
      <div className="bg-white flex flex-col items-center justify-between gap-4 py-4 w-full px-6">
        <div className="flex flex-col items-center justify-between gap-4">
          <div className="text-[24px] font-[700]">
            Two Factor Authentication
          </div>
          <div className="text-[16px] font-[400]">
            Enter the 6-digit code from your authenticator app
          </div>
          <div className="w-full px-4">
            <AppOTPInput
              otp={otp}
              setOtp={setOtp}
            />
          </div>

          <div className="mt-2">
            <div className="flex gap-1 items-center">
              <Checkbox
                checked={sendToEmail}
                onChange={(e) => {
                  setSendToEmail(e.target.checked)
                  if (e.target.checked) {
                    requestCode()
                  }
                }}
              />
              <div className="text-[15px] font-[400]">
                Send code to my mailbox.
              </div>
            </div>
          </div>
          {sendToEmail && (
            <Alert
              type="warning"
              showIcon
              message="Remember to uncheck this box if you regain access to your authenticator device."
              className="my-2"
              icon={<InformationCircleIcon className="w-4 h-4" />}
            />
          )}
          {sendToEmail && canRequestNewCode && (
            <div className="flex gap-2">
              <div>Didn't receive OTP?</div>
              <div
                className={`font-[600] ${
                  timer === 0
                    ? "cursor-pointer text-primary500 underline"
                    : "cursor-auto"
                }`}
                onClick={() => {
                  if (timer !== 0) return
                  handleResend()
                }}
              >
                {resending
                  ? "Resending ..."
                  : timer > 0
                  ? `Please wait ... (${timer})`
                  : "Resend"}
              </div>
            </div>
          )}

          <div className="mt-4">
            <Button
              color="primary"
              disabled={disabled}
              onClick={() => {
                handleVerifyCode()
              }}
            >
              Verify code
            </Button>
          </div>
        </div>
        {/* <div className="cursor-pointer text-primary600 font-bold mt-3 text-left w-full">
          Return to sign in
        </div> */}
      </div>
    </Spin>
  )
}
