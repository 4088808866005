import { useState } from "react"
import countries from "../../utils/countries.json"
import { SelectOption } from "./SelectOption"

/**
 * An optimized input.
 */
export const PhoneInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    error?: boolean
    setValue?: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<any>
  }
> = (props) => {
  const [selected, setSelected] = useState<any>("+234")
  // const [field, meta, helpers] = useField(props)

  return (
    <div className="relative flex gap-1">
      <select
        className="w-2/6 bg-white px-3"
        onChange={(e) => {
          // let phone = props.value!.toString()!
          // if (phone.startsWith(selected))
          //   phone = phone.replace(selected, e.currentTarget.value)
          // else phone = e.currentTarget.value.concat(phone)
          // props.setValue && props.setValue(props?.name!, phone)!
          setSelected(e.currentTarget.value)
        }}
        defaultValue={countries.find((c) => c.name === "Nigeria")?.dial_code}
      >
        {countries.map((country, index) => (
          <SelectOption
            key={index}
            value={country.dial_code}
            className="bg-white active:outline-none focus:outline-0 focus:border-primary500"
          >
            {country.dial_code === selected ? (
              <>{country.dial_code}</>
            ) : (
              <>
                {country.name} {country.dial_code}
              </>
            )}
          </SelectOption>
        ))}
      </select>
      <input
        type={"tel"}
        {...props}
        className={`rounded-[5px] w-full h-10 border border-neutral80 focus:border-primary500 active:border-primary500 invalid:bor der-danger500 bg-white px-5 py-3 text-base leading-[0px] placeholder:text-neutral80 placeholder:text-sm text-neutral600 focus:outline-0 ${props.className}`}
      />
      {/* <div className="absolute top-0 right-100 h-full flex items-center m-1.5 text-neutral400 cursor-pointer">
        <ExclamationCircleIcon  />
      </div> */}
    </div>
  )
}
