import { useMutation } from "@tanstack/react-query"
import { Form, Input } from "antd"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import React, { useState } from "react"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"
import { axiosInstance } from "utils/request"
type Props = {
  onClose: () => void
  invalidateQueries: () => void
  open: boolean
  paymentId: string
  x: string
  currency?: string
}

export const ReversePayment = ({
  onClose,
  open,
  paymentId,
  x,
  currency,
  invalidateQueries,
}: Props) => {
  const { toast } = useAppNotificationStore()
  const [comment, setComment] = useState("")
  const { isLoading: isSubmittingReverseAll, mutate: submitReverseAll } =
    useMutation({
      mutationFn: async (body: string) => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/reverse_payment_schedule_items?id=${paymentId}&comment=${body}&currency_code=${currency}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          onClose()
          {
            data.msg && toast.success(data.msg)
          }
          mixPanelEvent("pay-schedule-payout-reverse-success")
          invalidateQueries()
          setComment("")
        } else {
          {
            data.msg && toast.error(data.msg)
          }
          onClose()
          setComment("")
        }
      },
      onError: (data: any) => {
        onClose()
        setComment("")
        {
          data.msg && toast.error(data.msg)
        }
      },
    })
  const { isLoading: isSubmittingReverseSingle, mutate: submitReverseSingle } =
    useMutation({
      mutationFn: async (body: string) => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/reverse_payment_schedule_item?id=${paymentId}&comment=${body}&currency_code=${currency}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          onClose()
          setComment("")
          {
            data.msg && toast.success(data?.msg)
          }
          mixPanelEvent("pay-schedule-payout-reverse-success")
          invalidateQueries()
        } else {
          onClose()
          setComment("")
          toast.error(data?.msg || "Failed to reverse payment")
        }
      },
      onError: (data: any) => {
        onClose()
        setComment("")
        toast.error(data?.msg || "Failed to reverse payment")
      },
    })

  return (
    <NewModalWrapper
      open={open}
      title={
        paymentId == getIdFromKey(x || "")
          ? "Reverse Payments"
          : "Reverse Payment"
      }
      className="w-[750px]"
      onClose={onClose}
    >
      <div className="px-5 py-3">
        <p className=" text-neutral300 mb-5 text-sm">
          {paymentId == getIdFromKey(x || "")
            ? `Only successful payments will be reversed. Provide reason to
                continue.`
            : `This payment will be deducted from future schedules for this beneficiary. Provide reason to continue.`}
        </p>
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: "Please input your reason!",
            },
          ]}
        >
          <Input
            className="h-10 mb-5"
            placeholder="Reason"
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="border py-5 px-6 flex gap-4 justify-end ">
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          submitting={isSubmittingReverseAll || isSubmittingReverseSingle}
          disabled={comment == "" ? true : false}
          onClick={() => {
            mixPanelEvent("pay-schedule-payout-reverse-init")
            {
              paymentId == getIdFromKey(x || "")
                ? submitReverseAll(comment)
                : submitReverseSingle(comment)
            }
          }}
        >
          Reverse
        </Button>
      </div>
    </NewModalWrapper>
  )
}

export default ReversePayment
