import React, { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DashboardContainer, H6, H7, P2, P3 } from "../../../../components"
import { Steps } from "antd"

import { useAppNotificationStore } from "../../../../stores"
import { BankAccountInfoTab } from "./BankAccountInfoTab"
import { BusinessInfoTab } from "./BusinessInfoTab"
import { TermsAndConditionsTab } from "./TermsAndConditionsTab"
import BankAccountInfo from "./BankForm/BankAccountInfo"

type FlowIndicator = {
  index: number
  title: string
  current: boolean
  completed: boolean
}
const CompanyVerification: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const [currentPage, setCurrentPage] = useState(0)
  // const setInvite = useAddNewEmployeeFormStore((state) => state.setInvite)
  // const invite = useAddNewEmployeeFormStore((state) => state.invite)
  const [flowIndicators, updateFlowIndicators] = useState<FlowIndicator[]>([
    { index: 1, title: "Bank account info", current: true, completed: false },
    { index: 2, title: "Business Info", current: false, completed: false },
    {
      index: 3,
      title: "Terms and conditions",
      current: false,
      completed: false,
    },
  ])

  return (
    <DashboardContainer>
      <div className="w-full mx-auto flex flex-col gap-4">
        <div className="w-full h-full">
          <BankAccountInfo />
        </div>
      </div>
    </DashboardContainer>
  )
}

export default CompanyVerification
