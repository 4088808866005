import RequireRoutePermit from "RequireRoutePermit"
import NHFReport from "pages/dashboard/paymentSchedules/Regular/nhf/NHFReport"

import PensionReport from "pages/dashboard/paymentSchedules/Regular/pension/PensionReport"
import BulkUploadCustomPayment from "pages/dashboard/paymentSchedules/Custom/BulkUploadCustomPayment"
import ContractorPaymentSchedule from "pages/dashboard/paymentSchedules/Custom/contractor/ContractorPaymentSchedule"
import EmployeePaymentSchedule from "pages/dashboard/paymentSchedules/Custom/employee/EmployeePaymentSchedule"
import TaxReport from "pages/dashboard/paymentSchedules/Regular/Tax/TaxReport"
import Reversals from "pages/dashboard/paymentSchedules/Reversals"
import React from "react"
import { Route } from "react-router-dom"
import NSITFReport from "pages/dashboard/paymentSchedules/Regular/Nsitf/NSITFReport"
import ViewPaymentDetails from "pages/dashboard/paymentSchedules/crossborder/ViewPaymentDetails"
import StatustoryPayment from "pages/dashboard/paymentSchedules/crossborder/statustory/StatustoryPayment"
import PreviewCustomSchedule from "pages/dashboard/paymentSchedules/Custom/PreviewCustomSchedule"

const RegulerPayments = React.lazy(
  () => import("../pages/dashboard/paymentSchedules/Regular/RegularPayments")
)
const CustomPayments = React.lazy(
  () => import("../pages/dashboard/paymentSchedules/Custom/CustomPayments")
)
const EditCustomPayments = React.lazy(
  () => import("../pages/dashboard/paymentSchedules/Custom/EditCustomPayment")
)

const NewCustomSchedule = React.lazy(
  () => import("../pages/dashboard/paymentSchedules/Custom/NewCustomSchedule")
)
// const Transfer = React.lazy(
//   () => import("../pages/dashboard/paymentSchedules/Transfer")
// )
const PaymentRoutes = () => {
  return (
    <>
      <Route
        path="regular"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <RegulerPayments />
          </RequireRoutePermit>
        }
      />
      <Route
        path="custom"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <CustomPayments />
          </RequireRoutePermit>
        }
      />
      <Route
        path="reversals"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <Reversals />
          </RequireRoutePermit>
        }
      />
      <Route
        path="custom/edit"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <EditCustomPayments />
          </RequireRoutePermit>
        }
      />
      <Route
        path="view-payment"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            {/* <ViewPayments /> */}
            <ViewPaymentDetails />
          </RequireRoutePermit>
        }
      />
      <Route
        path="preview-payment"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            {/* <ViewPayments /> */}
            <PreviewCustomSchedule />
          </RequireRoutePermit>
        }
      />

      <Route
        path="view-regular-payment"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <ViewPaymentDetails />
            {/* <ViewRegularTabs /> */}
          </RequireRoutePermit>
        }
      />
      <Route
        path="statustory"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <StatustoryPayment />
            {/* <ViewRegularTabs /> */}
          </RequireRoutePermit>
        }
      />
      {/* <Route
        path="view-regular-payment"
        element={<ViewRegularPayments />}
      /> */}

      <Route
        path="view-tax-payment/report"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <TaxReport />
          </RequireRoutePermit>
        }
      />

      <Route
        path="view-pension-payment/report"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <PensionReport />
          </RequireRoutePermit>
        }
      />

      <Route
        path="view-nhf-payment/report"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <NHFReport />
          </RequireRoutePermit>
        }
      />
      <Route
        path="view-nsitf-payment/report"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <NSITFReport />
          </RequireRoutePermit>
        }
      />
      <Route
        path="custom/new"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <NewCustomSchedule />
          </RequireRoutePermit>
        }
      />
      <Route
        path="custom/employees"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <EmployeePaymentSchedule />
          </RequireRoutePermit>
        }
      />
      <Route
        path="custom/contractors"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <ContractorPaymentSchedule />
          </RequireRoutePermit>
        }
      />
      <Route
        path="custom/upload"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_pay_schedules"]}>
            <BulkUploadCustomPayment />
          </RequireRoutePermit>
        }
      />
    </>
  )
}

export default PaymentRoutes
