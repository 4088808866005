import { Button, P1 } from "components"
import React, { useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { Select, Table, Timeline } from "antd"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useGet } from "utils/useFetch"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { IEmployee } from "stores/employee/types"
import dayjs from "dayjs"

interface IPayslipYear {
  id: number
  text: string
}

const tabs = [
  {
    index: 0,
    title: "Payslips",
  },
  {
    index: 1,
    title: "Paygrade History",
  },
]

const CompensationHistory = () => {
  const [currentSubPage, setCurrentSubPage] = useState<number>(0)

  return (
    <>
      <div className="h-full flex justify-between items-center gap-6 px-4 md:px-6 lg:px-10 border-y border-y-neutral40 bg-white overflow-x-auto">
        <div className="h-full flex items-center gap-4 sm:gap-6">
          {tabs.map((item, index) => (
            <div
              className={` py-5 h-full relative flex items-center cursor-pointer`}
              onClick={() => setCurrentSubPage(item.index)}
              key={index}
            >
              <P1
                className={`leading-none ${
                  item.index === currentSubPage
                    ? "font-semibold text-primary500"
                    : "text-neutral100"
                }`}
              >
                {item.title}
              </P1>
              {item.index === currentSubPage && (
                <div className="w-full max absolute bottom-0 mt-5 h-[3px] bg-primary500" />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="w-full mx-auto flex flex-col gap-4">
        <div className="w-full bg-[#ededed]min-h-screen flex flex-col gap-10">
          <DashboardWrapper>
            <div className={currentSubPage === 0 ? "block" : "hidden"}>
              <EmployeePayslips />
            </div>
            <div className={currentSubPage === 1 ? "block" : "hidden"}>
              <PaygradeHistory />
            </div>
          </DashboardWrapper>
        </div>
      </div>
    </>
  )
}
export default CompensationHistory

const EmployeePayslips: React.FC<{}> = () => {
  const currentYear = new Date().getFullYear()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const [selectedYear, setSelectedYear] = useState(currentYear)

  const navigate = useNavigate()

  const { isLoading: isPayslipLoading, data } = useGet<any>({
    url: `/admin/employees/view/payslips?id=${id}&x=${selectedYear}`,
    enabled: !!selectedYear,
  })

  const { isLoading: isPayslipYearLoading, data: payslipYears } = useGet<
    IPayslipYear[]
  >({
    url: "employee/payslips/getpayrollyears",
  })

  const TableData =
    data?.payslips?.map((payslip: any) => ({
      key: payslip.payroll_key,
      netpay: `${payslip?.currency_symbol} ${formatCurrencyNoSymbol(
        Number(payslip.net_pay)
      )}`,

      date: {
        paydate: payslip.pay_date,
      },

      action: {
        payslip_id: payslip.payroll_key,
      },
    })) || []

  const TableColumns =
    [
      {
        title: "Pay Date",
        dataIndex: "date",
        render: (date: { paydate: string }) => (
          <span className="whitespace-nowrap text-[#42526D] font-avenir text-sm">
            Paid on {date.paydate}
          </span>
        ),
      },
      {
        title: "Net pay",
        dataIndex: "netpay",
        render: (netpay: string) => (
          <span className="whitespace-nowrap text-[#42526D] font-avenir text-sm">
            {netpay}{" "}
          </span>
        ),
      },

      {
        title: "Action",
        dataIndex: "action",
        render: (action: { payslip_id: string }) => (
          <Link
            to={`/dashboard/employee/payslip/view?id=${action.payslip_id}&x=${data?.person_key}`}
          >
            <span
              className={`text-sm font-medium py-1 block text-primary500 text-center w-[80px] rounded-[4px]

            `}
            >
              View
            </span>
          </Link>
        ),
      },
    ] || []

  const handleChangePeriod = (value: any) => {
    let owner = value.split("-")
    let name = owner[0]
    owner = owner[1]

    setSelectedYear(owner)
  }
  return (
    <>
      <div className="bg-white  flex items-center justify-between gap-4 py-[10px] px-4">
        <Select
          showSearch={false}
          optionFilterProp="children"
          defaultValue={selectedYear && selectedYear}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Select Year"
          onChange={handleChangePeriod}
          optionLabelProp="label"
          options={payslipYears?.map((prd, index) => ({
            value: `${prd.text}-${prd.id}`,
            label: `${prd.text}`,
          }))}
          className="w-[120px]"
        />

        <div className="shrink-0">
          <Button
            onClick={() => {
              navigate(
                `/dashboard/employee/payslips/all?id=${selectedYear}&x=${id}`
              )
            }}
            color="primary"
          >
            <span className="">View All </span>
          </Button>
        </div>
      </div>
      <Table
        columns={TableColumns}
        dataSource={TableData}
        scroll={{ x: true }}
        loading={isPayslipLoading}
        className="text-[#42526D]"
      />
    </>
  )
}

const PaygradeHistory = () => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { isLoading: isLoadingCompensation, data: compensation } =
    useGet<IEmployee>({
      url: `admin/employees/view/index_compensation_info?id=${id}`,
    })

  const TableColumns = [
    {
      title: "Paygrade",
      key: "description",
      render: (_: any, record: any) => record.description || record.alias,
    },
    {
      title: "Annual Gross",
      dataIndex: "annual_gross",
      key: "annual_gross",
      width: 150,
    },
    {
      title: "Action By",
      dataIndex: "nick_name",
      key: "nick_name",
    },
    {
      title: "Time Stamp",
      dataIndex: "time_stamp",
      key: "time_stamp",
      render: (timeStamp: string) => {
        return dayjs(timeStamp).format("DD MMM, YYYY HH:mm:ss A")
      },
    },
  ]

  return (
    <div className="f">
      <div>
        <Table
          columns={TableColumns}
          dataSource={compensation?.employeePaygradeHistory || []}
          loading={isLoadingCompensation}
          key={"employee_paygrade_history_id"}
          scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
        />
      </div>
    </div>
  )
}
