import { InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline"
import { Form, Input, Spin } from "antd"
import React, { useState } from "react"
import { Button, ModalWrapper } from "components"
import { CustomSelect } from "pages/dashboard/documents/custom-form"
import FormSectionWrapper from "pages/employee-dashboard/FormSectionWrapper"
import { ReviewTemplateQuestion } from "../interface/template"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { TemplateCard } from "./TemplateCard"
import getIdFromKey from "utils/getIdFromKey"

export const allResponseType = [
  {
    id: "1",
    title: "Rating scale",
  },
  {
    id: "2",
    title: "Text box",
  },
  {
    id: "3",
    title: "Text box and Rating scale",
  },
]
export const allCoreValues = [
  {
    id: "1",
    title: "Honesty",
  },
  {
    id: "2",
    title: "Integrity",
  },
  {
    id: "3",
    title: "Respect",
  },
  {
    id: "4",
    title: "Humility",
  },
]

export const AddTemplateQuestion: React.FC<{
  activeKey: boolean
  setActiveKey: Function
  showNew: boolean
  setShowNew: Function
  questions: ReviewTemplateQuestion[] | any[]
  invalidateQueries: Function
  x?: string | null
  isLoading: boolean
}> = ({
  activeKey,
  setActiveKey,
  showNew,
  setShowNew,

  questions,
  invalidateQueries,
  isLoading,
  x,
}) => {
  const [form] = Form.useForm()
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeletId, setActiveDeleteId] = useState("")
  const [activeQuestion, setActiveQuestion] = useState({
    question_title: "",
    question: "",
    type_id: "",
    type: "",
    value_id: "",
    value: "",
  })

  const { isLoading: isLoadingAddQuestion, mutate: submitAddQuestion } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/surveys/add_template_question?id=${getIdFromKey(
            x || ""
          )}&description=${activeQuestion.question_title}&alias=${
            activeQuestion.question
          }&response_type_id=${activeQuestion.type_id}&response_option=${
            activeQuestion.type
          }`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data) {
          invalidateQueries()
          setShowNew(false)
          setActiveQuestion({
            question_title: "",
            question: "",
            type_id: "",
            type: "",
            value_id: "",
            value: "",
          })
        } else {
        }
      },
    })
  const handleAddQuestions = () => {
    submitAddQuestion()
  }
  const handleChange = (value: any) => {
    console.log(value)

    let owner = value.split("-")
    let title = owner[0]
    let id = owner[1]

    setActiveQuestion({
      ...activeQuestion,
      type_id: id,
      type: title,
    })
  }

  const { isLoading: isdeleting, mutate: deleteTempQuestion } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/surveys/delete_template_question?id=${activeDeletId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        setOpenDeleteConfirmation(false)
        setActiveDeleteId("")
        invalidateQueries()
        setShowNew(false)
        setActiveQuestion({
          question_title: "",
          question: "",
          type_id: "",
          type: "",
          value_id: "",
          value: "",
        })
      }
    },
  })

  const handleOpenDelete = (id: any) => {
    console.log(id)

    setOpenDeleteConfirmation(true)
    setActiveDeleteId(id)
  }

  return (
    <FormSectionWrapper
      name="Template questions"
      title="Template questions"
      subtitle="Create survey questions to add to template"
      isActive={activeKey}
      layout="vertical"
      form={form}
      // onFinish={onFinishQuestion}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      footerBtns={
        <div className="flex gap-3 justify-end">
          <Button
            color="secondary"
            title="Previous"
            onClick={() => setActiveKey(["1"])}
          >
            Previous
          </Button>

          <Button
            color="primary"
            title="Submit"
            onClick={() => {}}
          >
            Next
          </Button>
        </div>
      }
      className="font-avenir"
    >
      <div className="flex flex-col  gap-1 px-4 md:px-6 xl:px-[60px]">
        <h6 className=" text-neutral700 font-extrabold text-sm">Questions</h6>
        <div className="flex flex-col gap-4">
          {isLoading ? (
            <div className="w-full flex  justify-center">
              <LoadingIndicatorWhite />
            </div>
          ) : (
            questions?.map((quest, index) => (
              <TemplateCard
                id={quest.survey_template_question_id}
                key={index}
                question_title={quest.description}
                question={quest.alias}
                type_id={String(quest?.review_response_type_id)}
                type={quest?.response_options}
                handleOpenDelete={handleOpenDelete}
                invalidateQueries={invalidateQueries}
              />
            ))
          )}
        </div>
        {showNew && (
          <div className=" border border-neutral30 py-6 px-3 rounded-5px mt-4">
            <div className=" bg-[#f3f5fbcf] p-2 rounded-5px h-auto border border-neutral30 flex flex-col justify-between gap-2 ">
              <Input
                type={"text"}
                name={""}
                // id="mobile-number"
                // required={true}
                placeholder="Title"
                value={activeQuestion.question_title}
                onChange={(e) => {
                  setActiveQuestion({
                    ...activeQuestion,
                    question_title: e.target.value,
                  })
                }}
                className="px-3 border-none focus:border border-[#C2C7D0] bg-transparent"
              />
              <Input
                type={"text"}
                name={""}
                // id="mobile-number"
                // required={true}
                placeholder="Question"
                value={activeQuestion.question}
                onChange={(e) => {
                  setActiveQuestion({
                    ...activeQuestion,
                    question: e.target.value,
                  })
                }}
                className="px-3 border-none focus:border border-[#C2C7D0] bg-transparent"
              />

              <div className="flex items-center justify-between w-full  mt-1">
                <div className=" flex gap-2  items-center">
                  {" "}
                  <CustomSelect
                    label=""
                    name="type"
                    value={activeQuestion?.type == "" ? null : ""}
                    onChange={handleChange}
                    className="bg-[#f3f5fbcf] w-[157px] mb-0"
                    options={allResponseType?.map((state) => ({
                      label: `${state.title}`,
                      value: `${state.title}-${state.id}`,
                    }))}
                    placeholder="Response type"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-2 mt-2">
              {/* <Button
                      title="Previous"
                      color="secondary"
                      className="font-extrabold"
                    >
                      Cancel
                    </Button> */}

              <Button
                title="Next"
                color="primary"
                className="font-extrabold"
                disabled={
                  activeQuestion.question_title == "" ||
                  activeQuestion.type_id == ""
                    ? true
                    : false
                }
                onClick={() => handleAddQuestions()}
              >
                {isLoadingAddQuestion ? <Spin /> : "Save"}
              </Button>
            </div>
          </div>
        )}
        {!showNew && (
          <button
            className="mt-6   text-sm text-primary500 flex items-center gap-2 mb-3"
            onClick={() => {
              setShowNew(!showNew)
            }}
          >
            <PlusIcon className="w-4 stroke-2" />
            <p>Add a question</p>
          </button>
        )}
        {openDeleteConfirmation && (
          <ModalWrapper
            title="Delete Template Question"
            onClose={() => {
              setOpenDeleteConfirmation(false)
              setActiveDeleteId("")
            }}
          >
            <div className="px-5 pt-3 flex flex-col items-center">
              <p className="text-[#15294B] text-sm mb-6 text-center">
                If you delete this template question,it would be removed from
                your list. Are you sure you want to continue?
              </p>
            </div>
            <div className="border py-5 px-6 flex gap-4 ">
              <Button
                title="cancel"
                color="secondary"
                onClick={() => {
                  setOpenDeleteConfirmation(false)
                  setActiveDeleteId("")
                }}
              >
                Cancel
              </Button>

              <Button
                color="primary"
                submitting={isdeleting}
                onClick={() => {
                  deleteTempQuestion()
                }}
              >
                Confirm
              </Button>
            </div>
          </ModalWrapper>
        )}
      </div>
    </FormSectionWrapper>
  )
}
