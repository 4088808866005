import RequireRoutePermit from "RequireRoutePermit"

import React from "react"
import { Route, Navigate } from "react-router-dom"

const ProfileWrapper = React.lazy(
  () => import("../pages/dashboard/people/employee/Profile")
)
const PayAndJob = React.lazy(
  () => import("../pages/dashboard/people/employee/job-and-pay/JobAndPay")
)
const Personal = React.lazy(
  () => import("../pages/dashboard/people/employee/personal/Personal")
)
const Request = React.lazy(
  () => import("../pages/dashboard/people/employee/requests/Requests")
)
const LeaveBalances = React.lazy(
  () =>
    import("pages/dashboard/people/employee/requests/components/LeaveBalances")
)

const Documents = React.lazy(
  () => import("../pages/dashboard/people/employee/documents/Documents")
)

const Benefit = React.lazy(
  () => import("../pages/dashboard/people/employee/benefits/Benefit")
)

const Assets = React.lazy(
  () => import("../pages/dashboard/people/employee/assets/Assets")
)

const Tasks = React.lazy(
  () => import("../pages/dashboard/people/employee/tasks/Task")
)

const Performance = React.lazy(
  () => import("../pages/dashboard/people/employee/performance/Performance")
)

const Disciplinary = React.lazy(
  () => import("../pages/dashboard/people/employee/disciplinary/Disciplinary")
)

const Notes = React.lazy(
  () => import("../pages/dashboard/people/employee/notes/Notes")
)
const EmployeeLogs = React.lazy(
  () => import("../pages/dashboard/people/employee/logs/EmployeeLogs")
)

const ProfileRoutes = () => {
  return (
    <>
      <Route
        path=""
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_employee_details"]}
          >
            <ProfileWrapper />
          </RequireRoutePermit>
        }
      >
        <Route
          path=""
          element={<Navigate to="job-and-pay" />}
        />

        <Route
          path="job-and-pay"
          element={<PayAndJob />}
        />
        <Route
          path="personal"
          element={<Personal />}
        />
        <Route
          path="requests"
          element={<Request />}
        />

        <Route
          path="documents"
          element={<Documents />}
        />
        <Route
          path="benefits"
          element={<Benefit />}
        />
        <Route
          path="assets"
          element={<Assets />}
        />
        <Route
          path="tasks"
          element={<Tasks />}
        />
        <Route
          path="performance"
          element={<Performance />}
        />

        <Route
          path="disciplinary"
          element={<Disciplinary />}
        />

        <Route
          path="notes"
          element={<Notes />}
        />
        <Route
          path="logs"
          element={<EmployeeLogs />}
        />
      </Route>
      <Route
        path="/dashboard/employee/:employeeKey/requests/leave-balances"
        element={
          <RequireRoutePermit
            allowedPermissions={["can_view_employee_details"]}
          >
            <LeaveBalances />
          </RequireRoutePermit>
        }
      />
    </>
  )
}

export default ProfileRoutes
