type props = {
  title?: string
  date?: string
  description?: string
  name?: string
  color?: string
  img?: string
  alias?: string | null
  state?: string | null
}
export const TaskCard: React.FC<props> = ({
  title,
  date,
  description,
  alias,
  state,
}) => {
  return (
    <div
      className={`min-h-[64px] py-2 mb-3  border-purple-500 border-l-4 px-4 flex flex-col justify-center border border-cardgrey rounded-5px`}
    >
      <div className="flex items-center justify-between mb-[7px]">
        <p className="text-sm font-semibold  text-neutral500">{title}</p>
        <p className="text-xs font-light  text-subText3">Due:{date}</p>
      </div>
      <div>
        <p className="w-11/12  text-xs font-light leading-4  text-subText3">
          {description}
        </p>
      </div>
    </div>
  )
}
