import { useEffect, useState } from "react"
import { useAppNotificationStore } from "stores"

// Define your breakpoints as an object with keys and minimum widths
const breakpoints: Record<"xs" | "sm" | "md" | "lg" | "xl", number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export function useScreenBreakpoints(
  breakpoint: "xs" | "sm" | "md" | "lg" | "xl"
) {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      // Find the current breakpoint based on the window width
      // for (const breakpoint in breakpoints) {
      //   const found = breakpoints[breakpoint]
      //   if (width >= breakpoints[breakpoint]) {
      //     setCurrentBreakpoint(breakpoint)
      //   }
      // }
      setCurrentBreakpoint(width >= breakpoints[breakpoint])
    }

    // Call the handleResize function on component mount
    handleResize()

    // Add a window resize event listener
    window.addEventListener("resize", handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return currentBreakpoint
}

export const useAlert = <T>(onSuccess?: Function) => {
  const { toast } = useAppNotificationStore()

  const alert = (data: any, message?: string) => {
    if (data.status == "success") {
      data?.msg && setTimeout(() => toast.success(data?.msg), 100)
      onSuccess?.()
    } else {
      data?.msg && setTimeout(() => toast.error(data?.msg), 100)
    }
  }

  return { alert }
}
