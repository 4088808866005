import React from "react"
import defaultImg from "../../assets/images/avatar.svg"

type Props = {
  src: string
  alt: string
  className: string
  defaultImage?: string
} & React.ImgHTMLAttributes<HTMLImageElement>

const ImageComponent: React.FC<Props> = ({
  src,
  alt,
  className,
  defaultImage,
  ...rest
}) => {
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultImage ? defaultImage : defaultImg
  }

  return (
    <img
      src={src}
      alt={alt || "image"}
      onError={handleImageError}
      className={className}
      {...rest}
    />
  )
}

export default ImageComponent
