import React from "react"
import { useAppNotificationStore, useForm } from "../../stores"
import { Toast } from "../../stores/app-notification"
import { IconButton } from "../buttons/IconButton"
import { Button } from "../buttons/Button"
import {
  CheckIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline"
import { CheckCircleIcon } from "@heroicons/react/24/solid"
import { P2 } from "../typography/P2"

export const NotificationBar: React.FC<{}> = () => {
  const { toastList, removeToast, confirmProps, closeConfirm } =
    useAppNotificationStore()
  const { isSubmitting } = useForm()

  return (
    <>
      {toastList.length > 0 && (
        <div
          className={`fixed h-fit w-[393px] flex flex-col gap-3 top-0 right-10 ${
            toastList.length > 0 && "p-3 h-fit overflow-y-clip"
          } z-50`}
          style={{
            zIndex: 99999,
          }}
        >
          {toastList.length > 0 &&
            toastList.map((toast: Toast) => (
              <div
                key={toast.id}
                className={`relative z-50 md:min-w-[393px] md:w-[393px] min-w-[90%] mx-auto shadow-md h-fit md:py-4 py-3 px-3 gap-5 w-full flex justify-center items-center ${
                  toast.color === "success"
                    ? "bg-[#38CB89] text-white"
                    : toast.color === "error"
                    ? "bg-danger400 text-white"
                    : ""
                }`}
              >
                <IconButton
                  title="remove"
                  onClick={() => removeToast(toast)}
                  className={`absolute right-0 top-0 m-1 opacity-20 hover:opacity-100 hover:bg-opacity-70 ${toast.color}`}
                >
                  <XMarkIcon className="w-5" />
                </IconButton>

                {/* {toast.color?.includes("success") === true && (
                  <CheckCircleIcon className="w-8 h-8 min-w-min" />
                )}
                {toast.color?.includes("error") === true && (
                  <ExclamationTriangleIcon className="w-7 h-7 min-w-min" />
                )} */}

                <span className="font-semibold">{toast.message}</span>
              </div>
            ))}
        </div>
      )}

      {confirmProps !== undefined && (
        <div className="absolute z-40 h-full flex justify-center top-0 bottom-0 left-0 right-0 bg-[#1E1E1E]/60">
          <div className="mt-[70%] md:mt-[40%] lg:mt-[10%] h-fit w-[90%] md:w-[50%] lg:w-[25%] md:min-w-[488px]">
            <div
              className={`h-full bg-white shadow-lg pb-4 flex flex-col justify-between gap-4`}
            >
              <div className="border-b p-3 flex justify-between">
                <P2>{confirmProps.title}</P2>
                <XMarkIcon
                  onClick={async () => {
                    await confirmProps.onCancel!()
                    closeConfirm()
                  }}
                  className="w-5 cursor-pointer text-"
                />
              </div>
              <div className="px-6 py-4">
                <div
                  className={`w-full p-3 flex justify-start items-start gap-5 ${
                    confirmProps.context === "success"
                      ? "bg-success50"
                      : "bg-danger50"
                  }`}
                >
                  {confirmProps.context === "success" ? (
                    <CheckIcon
                      className={`w-6 h-6 text-success500 font-semibold min-w-min`}
                    />
                  ) : (
                    <ExclamationTriangleIcon
                      className={`w-6 h-6 text-danger500 font-semibold min-w-min`}
                    />
                  )}
                  <P2 className="text-neutral400 text-center">
                    {confirmProps.message}
                  </P2>
                </div>
              </div>

              <div className="w-full flex gap-2 px-6">
                <Button
                  title="cancel"
                  color="secondary"
                  onClick={async () => {
                    await confirmProps.onCancel!()
                    closeConfirm()
                  }}
                  disabled={isSubmitting}
                  className={`w-full text-neutral500 border-neutral500 font-semibold`}
                >
                  Cancel
                </Button>
                <Button
                  title={confirmProps.action}
                  color={
                    confirmProps.context === "success" ? "success" : "danger"
                  }
                  onClick={async () => {
                    await confirmProps.onConfirm!()
                  }}
                  disabled={isSubmitting}
                  className={`w-full font-semibold`}
                >
                  {confirmProps.action}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
