/**
 * An optimized label.
 *
 * @param props
 * @returns label
 */
export const Label: React.FC<
  React.LabelHTMLAttributes<HTMLLabelElement> & { required?: "true" | "false" }
> = (props) => {
  return (
    <label
      {...props}
      className={`text-neutral500 text-sm font-avenir ${props.className}`}
    >
      {props.children}{" "}
      {props.required === "true" && <span className="text-danger500">*</span>}
    </label>
  )
}
