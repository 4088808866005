import { Link, LinkProps } from "react-router-dom"

/**
 * An optimized link button.
 * Pass the `title` props, else `⚠` is returned
 */
export const LinkButton: React.FC<
  LinkProps &
    React.RefAttributes<HTMLAnchorElement> & {
      // size?: "sm" | "md" | "lg"
      color?:
        | "primary"
        | "secondary"
        | "success"
        | "danger"
        | "danger-outline"
        | "muted"
        | "neutral"
        | "neutral-outline"
      outline?: Boolean
    }
> = (props) => {
  let dynamicClasses = ""

  switch (props.color) {
    case "primary":
      dynamicClasses =
        "bg-primary500 hover:bg-primary700 text-a11y hover:text-a11y focus:ring-primary500 active:bg-primary700 focus:bg-primary700"
      break
    case "secondary":
      dynamicClasses =
        "bg-neutral20 border border-neutral30 hover:bg-neutral30 active:bg-neutral30 focus:bg-neutral30 text-neutral300 hover:text-neutral300 focus:ring-neutral100"
      break
    case "success":
      dynamicClasses =
        "bg-success500 hover:bg-success700 active:bg-success800 focus:bg-success800 text-white"
      break
    case "danger":
      dynamicClasses =
        "bg-danger500 hover:bg-danger700 active:bg-danger800 focus:bg-danger800 text-white hover:text-white"
      break
    case "danger-outline":
      dynamicClasses =
        "box-border border-2 border-danger500 bg-white hover:bg-danger100 active:bg-danger100 focus:bg-danger100 active:border-danger800 focus:border-danger800 text-danger500 hover:text-danger500"
      break
    case "neutral":
      dynamicClasses =
        "bg-neutral20 border border-neutral30 hover:bg-neutral30 active:bg-neutral30 focus:bg-neutral30 text-neutral300 hover:text-neutral300 focus:ring-neutral100"
      break
    case "neutral-outline":
      dynamicClasses =
        "box-border border-2 border-neutral30 bg-transparent hover:bg-neutral30 hover:text-neutral300 text-neutral10"
      break

    default:
      break
  }

  return (
    <Link
      aria-label={props.title}
      {...props}
      className={`rounded-5px disabled:opacity-50 focus:outline-none focus:shadow-none disabled:cursor-not-allowed py-2 px-4 text-sm  font-avenir focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 font-semibold transition-all duration-100 ease-linear ${dynamicClasses}
      ${
        props.outline
          ? "bg-transparent border border-[#42526D] hover:border-[#9babc720] hover:bg-[#9babc720] active:bg-[#9babc720] focus:bg-[#9babc720]  text-[#42526D]"
          : ""
      }

      ${props.className}
      `}
    >
      <span
        className={`w-full h-full inline-flex gap-2 justify-center items-center whitespace-nowrap`}
      >
        {props.children}
      </span>
    </Link>
  )
}
