export const timeformat = {
  // weekday: 'short',
  year: "numeric",
  month: "short",
  day: "numeric",
  hour12: false,
} as const

export const formatDate = (date: any) => {
  const unformattedDate = new Date(date && date)
  const formattedDate = new Intl.DateTimeFormat("en-US", timeformat).format(
    unformattedDate
  )
  return formattedDate
}

export const formatISODate = (ISOdate: any) => {
  let date = new Date(ISOdate)
  return date.toDateString()
}

export const convertCalendar = (calendar: any) => {
  const date = new Date(calendar).toLocaleDateString('en-GB');
  return date
}