import { P1 } from "../typography/P1"

type props = {
  title: string
  active: boolean
  action?: Function
  id?: string
}

export const ActionBarTab: React.FC<props> = ({
  title,
  active,
  action,
  id,
}) => {
  return (
    <div
      id={id}
      className={` h-full relative flex items-center  ${
        action !== undefined && "cursor-pointer py-4"
      }`}
      onClick={() => action!()}
    >
      <P1
        className={`whitespace-nowrap leading-none ${
          active === true ? "font-semibold text-primary500" : "text-neutral100"
        }`}
      >
        {title}
      </P1>
      {active === true && (
        <div className="w-full max absolute bottom-0 h-[3px] bg-primary500" />
      )}
    </div>
  )
}
