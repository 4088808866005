import React from "react"
import { LinkButton } from "../buttons/LinkButton"
import Unauthorized from "../../assets/svg/unauthorized.svg"

const UnauthourizedPage: React.FC<{
  type: "employee" | "admin"
}> = ({ type = "employee" }) => {
  return (
    <div className="w-full h-[70vh]  flex justify-center items-center">
      <div className="w-[601px] h-full bg-white p-4 flex flex-col justify-center items-center ">
        <div className="text-center flex flex-col items-center ">
          <div className="h-[132px] w-[200px] flex justify-center items-center mb-2 ">
            <img
              src={Unauthorized}
              alt="User Profile"
            />
          </div>
          <h5 className="font-bold text-[32px] text-black mb-2">
            <code>Unauthorized Access </code>
          </h5>
          <h6 className="text-center text-sm text-neutral600">
            Oops! It seems you don't have the necessary permissions to access
            this page.
          </h6>
        </div>
        <div className="w-full flex justify-center mt-6">
          <LinkButton
            title="Return to dashboard"
            to={type === "employee" ? "/employee/dashboard" : "/dashboard"}
            color="primary"
            className=""
          >
            Return to dashboard
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export default UnauthourizedPage
