import { useMutation } from "@tanstack/react-query"
import { Form, Input } from "antd"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import React, { useState } from "react"
import { useAppNotificationStore, useAuthStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"
import { axiosInstance } from "utils/request"
type Props = {
  onClose: () => void
  invalidateQueries: () => void
  open: boolean
  paymentId: string
  x: string
  currency?: string
}

export const RetryPayment = ({
  onClose,
  open,
  paymentId,
  x,
  invalidateQueries,
  currency,
}: Props) => {
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const [comment, setComment] = useState("")
  const { isLoading: isSubmittingRetry, mutate: submitRetry } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/payment_schedules/retry_failed_payment_new?id=${paymentId}&currency_code=${currency}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        onClose()
        {
          data.msg && toast.success(data.msg)
        }
        mixPanelEvent("pay-schedule-payout-retry-success")

        invalidateQueries()
      } else {
        onClose()
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      onClose()
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const { isLoading: isSubmittingRetryAll, mutate: submitRetryAll } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/retry_failed_payments_new?id=${paymentId}&currency_code=${currency}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          onClose()
          {
            data.msg && toast.success(data.msg)
          }
          mixPanelEvent("pay-schedule-payout-retry-success")

          invalidateQueries()
        } else {
          {
            data.msg && toast.error(data.msg)
          }
          onClose()
        }
      },
      onError: (data: any) => {
        {
          data.msg && toast.error(data.msg)
        }
        onClose()
      },
    })
  return (
    <NewModalWrapper
      open={open}
      title={
        paymentId == getIdFromKey(x || "") ? "Retry Payments" : "Retry Payment"
      }
      className="w-[750px]"
      onClose={onClose}
    >
      <div className="px-5 py-3">
        <p className=" text-neutral300 mb-5 text-sm">
          {paymentId == getIdFromKey(x || "")
            ? "Retry all failed payments"
            : "Retry this failed payment"}
        </p>
      </div>
      <div className="border py-5 px-6 flex gap-4 justify-end  ">
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          submitting={isSubmittingRetry || isSubmittingRetryAll}
          onClick={() => {
            mixPanelEvent("pay-schedule-payout-retry-init")
            {
              paymentId == getIdFromKey(x || "")
                ? submitRetryAll()
                : submitRetry()
            }
          }}
        >
          Retry
        </Button>
      </div>
    </NewModalWrapper>
  )
}

export default RetryPayment
