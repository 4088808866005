import { matchRoutes, useLocation, useNavigate } from "react-router-dom"
import { TopActionBar } from "../layouts/TopActionBar"
import { ActionBarTab } from "./ActionBarTab"
import { LinkButton } from "components/buttons/LinkButton"
import ResponsiveActinBtn from "components/new-stuff/ui/ResponsiveActinBtn"
import { P1 } from "components/typography/P1"

export const CustomizationSettingsTopActionBar = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <div className="h-full flex justify-between items-center gap-6 px-4 md:px-6 lg:px-10 border-y border-y-neutral40 bg-white overflow-x-auto">
      <div className="h-full flex items-center gap-4 sm:gap-6">
        {[
          {
            title: "Fields",
            route: "/dashboard/settings/customization/custom-fields",
          },
          {
            title: "Groups",
            route: "/dashboard/settings/customization/custom-fields/groups",
          },
        ].map((item, index) => (
          <>
            <div
              className={` py-5 h-full relative flex items-center cursor-pointer`}
              onClick={() => navigate(item.route)}
              key={index}
            >
              <P1
                className={`leading-none ${
                  item.route === pathname
                    ? "font-semibold text-primary500"
                    : "text-neutral100"
                }`}
              >
                {item.title}
              </P1>
              {item.route === pathname && (
                <div className="w-full max absolute bottom-0 mt-5 h-[3px] bg-primary500" />
              )}
            </div>
          </>
        ))}
      </div>

      <div className="">
        <ResponsiveActinBtn
          items={[
            {
              key: "1",

              label: (
                <button
                  onClick={() => {
                    navigate(
                      "/dashboard/settings/customization/custom-fields/new"
                    )
                  }}
                  className="font-avenir"
                >
                  Create new custom field
                </button>
              ),
            },
            {
              key: "2",

              label: (
                <button
                  onClick={() => {
                    navigate(
                      "/dashboard/settings/customization/custom-fields/bulk-upload"
                    )
                  }}
                  className="font-avenir"
                >
                  Bulk Upload fields
                </button>
              ),
            },
          ]}
        >
          <div className="flex items-center gap-3">
            <LinkButton
              title="Bulk Upload fields"
              to={"/dashboard/settings/customization/custom-fields/bulk-upload"}
              color="neutral"
            >
              Bulk Upload fields
            </LinkButton>
            <LinkButton
              title="Create new custom filed"
              to="/dashboard/settings/customization/custom-fields/new"
              color="primary"
            >
              Create new custom field
            </LinkButton>
          </div>
        </ResponsiveActinBtn>
      </div>
    </div>
  )
}
