import { createPortal } from "react-dom"
import { useNavigate } from "react-router-dom"
import { IconButton } from "../buttons/IconButton"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { H6 } from "components/typography/H6"

type props = {
  title?: string
  subTitle?: string
  className?: string
  children: JSX.Element | JSX.Element[]
  onClose?:Function
}

export const ModalPortal: React.FC<props> = ({
  title,
  subTitle,
  className,
  children,
  onClose
}) => {
  const navigate = useNavigate()

  return createPortal(
    <div className="fixed z-40 h-screen w-screen flex justify-center rounded items-center top-0 bottom-0 left-0 right-0 bg-[#1E1E1E]/60 overflow-hidden">
      <div className={`relative w-full h-auto bg-white rounded-none overflow-y-auto b pb-5 ${className}`}>
       {title?(
         <div className=" flex items-center justify-between py-4 px-5 bg-[#F0F3F7]">
          <div className=" flex flex-col items-start justify-center">
            <h6 className="font-bold text-center text-neutral500 text-base">
             {title}
                </h6>
                {subTitle&&(
                <p className=" text-neutral300 text-sm">{subTitle}</p>

                )}
          </div>
          <div className="cursor-pointer">
            <IconButton
              title="Close"
              onClick={() =>{
                onClose?(onClose()):( navigate(-1))
              }}
            >
              <XMarkIcon className="w-4 h-4" />
            </IconButton>
          </div>
        </div>):(
  <div className="py-6 px-5 bg-[#F0F3F7] cursor-pointer flex justify-end items-center ">
            <IconButton
              title="Close"
              onClick={() =>{
                onClose?(onClose()):( navigate(-1))
              }}
            >
              <XMarkIcon className="w-4 h-4" />
            </IconButton>
          </div>
        )}


        <div className="px-5">{children}</div>
      </div>
    </div>,
    document.getElementById("portal")!
  )
}
