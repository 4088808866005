import { useAppNotificationStore } from "stores"
import { toast as appToast } from "react-toastify"

export const useServerResponse = () => {
  const { toast } = useAppNotificationStore()
  return function (data: any) {
    if (data.status === "success" && data?.msg) {
      toast.success(data?.msg)
    } else {
      data?.msg && toast.error(data?.msg)
    }
  }
}

export const useErrorFeedback = () => {
  const { toast } = useAppNotificationStore()
  const errorAlert = (error: any) => {
    error = error?.response?.data?.message

    const err = Array.isArray(error) && error?.length ? error?.[0] : error

    err && toast ? toast.error(err) : appToast.error(err)
  }
  return { errorAlert }
}
export const usePublicErrorFeedback = () => {
  const errorAlert = (error: any) => {
    error = error?.response?.data?.message

    const err = Array.isArray(error) && error?.length ? error?.[0] : error

    err && appToast.error(err)
  }
  return { errorAlert }
}
