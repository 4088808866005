import React, { FC, useMemo } from "react"
import { formatCurrencyNoSymbol } from "utils/currency"

type Props = {
  data: any
  groupedEmployeeData: any[]
  groupPayPeriodsData: any[]
  user: any
  activeItem: any
}

export const DetailsSummaryTable: FC<Props> = ({
  activeItem,
  data,
  groupPayPeriodsData,
  groupedEmployeeData,
  user,
}) => {
  const items = useMemo(() => {
    let elements: any[] = []

    let rowCount = 0
    for (let it of groupedEmployeeData) {
      rowCount++

      let dataCount = 1
      if (data?.customInfo) {
        const groupedData = [...it?.groups]

        const getUniqueItems = (arr: string[]) => {
          return Array.from(new Set(arr))
        }

        let count = 1
        if (data?.employeeEarnings) {
          const countForEarnings = Array.from(
            new Set(
              groupedData
                ?.filter(
                  (x: any) =>
                    x?.payroll_item_type_id == 1 ||
                    x?.payroll_item_type_id == 3 ||
                    x?.payroll_item_type_id == 4
                )
                ?.map((it: any) => it?.description)
            )
          )?.length

          count = Math.max(count, countForEarnings)
        }

        if (data?.deductions) {
          const countForDeductions = getUniqueItems(
            groupedData
              ?.filter((x: any) => x?.payroll_item_type_id == 2)
              ?.map((it: any) => it?.description)
          )?.length

          count = Math.max(count, countForDeductions)
        }

        if (data?.contributions) {
          const countForContributions = getUniqueItems(
            groupedData
              ?.filter((x: any) => x?.payroll_item_type_id == 5)
              ?.map((v: any) => v?.description)
          )?.length
          count = Math.max(count, countForContributions)
        }

        if (data?.totals) {
          const countForTotals = 2
          count = Math.max(count, countForTotals)
        }
        dataCount = count
      }

      const finalCount = dataCount

      for (let i = 0; i < finalCount; i++) {
        const currencySymbol = it?.currency_symbol
        elements?.push(
          <>
            <tr
              className={`${
                rowCount % 2 == 0 && "bg-[#f8f9fa] "
              } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
            >
              {i == 0 && it?.full_name ? (
                <td className="border-r-[#000] border-r-1 p-3">
                  {it?.full_name}
                  <br />
                  {it?.identification_number}
                </td>
              ) : i == 1 ? (
                <td className="border-r-[#000] border-r-1 p-3">
                  {it?.employee_title}
                  <br />
                  <>
                    Days worked: {it?.days_worked}/{it?.total_days}
                  </>
                </td>
              ) : (
                <td className="border-r-[#000] border-r-1 p-3"></td>
              )}
              {data?.employeeEarnings ? (
                <>
                  {(() => {
                    if (
                      it?.groups?.filter(
                        (i: any) =>
                          i.payroll_item_type_id == 1 ||
                          i.payroll_item_type_id == 3 ||
                          i.payroll_item_type_id == 4
                      )?.length > i
                    ) {
                      const descriptions: any[] = Array.from(
                        new Set(
                          it?.groups
                            ?.filter(
                              (it: any) =>
                                (it?.payroll_item_type_id == 1 ||
                                  it?.payroll_item_type_id == 3 ||
                                  it?.payroll_item_type_id == 4) &&
                                it?.description
                            )

                            ?.map((b: any) => b?.description)
                            ?.flat()
                        )
                      )

                      const amounts = (desc: string) =>
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              (it?.payroll_item_type_id == 1 ||
                                it?.payroll_item_type_id == 3 ||
                                it?.payroll_item_type_id == 4) &&
                              it?.description == desc
                          )

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>
                                <span>{descriptions[i] || "NA"}</span>
                              </td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </>
                      )
                    }

                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              ) : (
                <>
                  {i == 0 ? (
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 1 ||
                              it?.payroll_item_type_id == 3 ||
                              it?.payroll_item_type_id == 4
                          )

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  ) : (
                    <td className="border-r-[#000] border-r-1 p-3"></td>
                  )}
                </>
              )}
              {data?.deductions ? (
                <>
                  {(() => {
                    if (
                      it?.groups?.filter(
                        (i: any) => i.payroll_item_type_id == 2
                      )?.length > i
                    ) {
                      const descriptions: any[] = Array.from(
                        new Set(
                          it?.groups
                            ?.filter(
                              (it: any) =>
                                it?.payroll_item_type_id == 2 && it?.description
                            )

                            ?.map((b: any) => b?.description)
                        )
                      )

                      const amounts = (desc: string) =>
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 2 &&
                              it?.description == desc
                          )

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>
                                <span>{descriptions[i]}</span>
                              </td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td className="border-r-[#000] border-r-1 p-3"></td>
                            </>
                          )}
                        </>
                      )
                    }
                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              ) : (
                <>
                  {i == 0 ? (
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter((it: any) => it?.payroll_item_type_id == 2)

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  ) : (
                    <td className="border-r-[#000] border-r-1 p-3"></td>
                  )}
                </>
              )}
              {data?.contributions && (
                <>
                  {(() => {
                    if (
                      it?.groups?.filter(
                        (i: any) => i.payroll_item_type_id == 5
                      )?.length > i
                    ) {
                      const descriptions: any[] = Array.from(
                        new Set(
                          it?.groups
                            ?.filter(
                              (it: any) =>
                                it?.payroll_item_type_id == 5 && it?.description
                            )

                            ?.map((b: any) => b?.description)
                        )
                      )

                      const amounts = (desc: string) =>
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 5 &&
                              it?.description == desc
                          )

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>
                                <span>{descriptions[i]}</span>
                              </td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </>
                      )
                    }
                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              )}
              {data?.employeeTaxes ? (
                <>
                  {(() => {
                    if (
                      it?.groups?.filter(
                        (i: any) =>
                          i.payroll_item_type_id == 1 ||
                          i.payroll_item_type_id == 3 ||
                          i.payroll_item_type_id == 4
                      )?.length > i
                    ) {
                      const descriptions: any[] = Array.from(
                        new Set(
                          it?.groups
                            ?.filter(
                              (it: any) =>
                                (it?.payroll_item_type_id == 1 ||
                                  it?.payroll_item_type_id == 3 ||
                                  it?.payroll_item_type_id == 4) &&
                                it?.description
                            )

                            ?.map((b: any) => b?.description)
                            ?.flat()
                        )
                      )

                      const amounts = (desc: string) =>
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              (it?.payroll_item_type_id == 1 ||
                                it?.payroll_item_type_id == 3 ||
                                it?.payroll_item_type_id == 4) &&
                              it?.description == desc
                          )

                          ?.map((x: any) => x?.tax_amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>{descriptions[i] || "NA"}</td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </>
                      )
                    }
                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              ) : (
                <>
                  {i == 0 ? (
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 1 ||
                              it?.payroll_item_type_id == 3 ||
                              it?.payroll_item_type_id == 4
                          )

                          ?.map((x: any) => x?.tax_amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  ) : (
                    <td className="border-r-[#000] border-r-1"></td>
                  )}
                </>
              )}
              {data?.totals ? (
                <>
                  {(() => {
                    if (i == 0) {
                      return (
                        <>
                          <td>Net Pay</td>
                          <td className="border-r-[#000] border-r-1 p-3">
                            {currencySymbol}
                            {personNetPay(it)}
                          </td>
                        </>
                      )
                    }
                    if (i == 1) {
                      return (
                        <>
                          <td>Employer Cost</td>
                          <td className="border-r-[#000] border-r-1 p-3">
                            {currencySymbol}
                            {formatCurrencyNoSymbol(
                              it?.groups

                                ?.filter(
                                  (it: any) =>
                                    it?.payroll_item_type_id == 1 ||
                                    it?.payroll_item_type_id == 3 ||
                                    it?.payroll_item_type_id == 4
                                )

                                ?.map((x: any) =>
                                  x?.amount > 0 ? x?.amount : 0
                                )
                                ?.reduce(
                                  (a: any, b: any) => Number(a) + Number(b),
                                  0
                                ) -
                                it?.groups

                                  ?.filter(
                                    (it: any) => it?.payroll_item_type_id == 2
                                  )

                                  ?.map((x: any) =>
                                    x?.deducted_amount > 0
                                      ? x?.deducted_amount
                                      : 0
                                  )
                                  ?.reduce(
                                    (a: any, b: any) => Number(a) + Number(b),
                                    0
                                  ) +
                                it?.groups

                                  ?.filter(
                                    (it: any) =>
                                      (it?.payroll_item_type_id == 2 &&
                                        data?.statutoryBenefits
                                          ?.map((g: any) => g?.description)
                                          .includes(it?.description)) ||
                                      it?.description ==
                                        "Health Service Provider"
                                  )

                                  ?.map((x: any) =>
                                    x?.amount > 0 ? x?.amount : 0
                                  )
                                  ?.reduce(
                                    (a: any, b: any) => Number(a) + Number(b),
                                    0
                                  ) +
                                it?.groups

                                  ?.filter(
                                    (it: any) =>
                                      (it?.payroll_item_type_id == 5 &&
                                        data?.statutoryBenefits
                                          ?.map((g: any) => g?.description)
                                          .includes(it?.description)) ||
                                      it?.description?.includes(
                                        "Earned Wage Withdrawal"
                                      )
                                  )

                                  ?.map((x: any) =>
                                    x?.amount > 0 ? x?.amount : 0
                                  )
                                  ?.reduce(
                                    (a: any, b: any) => Number(a) + Number(b),
                                    0
                                  )
                            )}
                          </td>
                        </>
                      )
                    }

                    if (i > 1)
                      return (
                        <>
                          <td></td>
                          <td className="border-r-[#000] border-r-1"></td>
                        </>
                      )
                  })()}
                </>
              ) : (
                <>
                  {i == 0 ? (
                    <>
                      <td className="border-r-[#000] border-r-1 p-3">
                        {currencySymbol}
                        {personNetPay(it)}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-r-[#000] border-r-1 p-3"></td>
                    </>
                  )}
                </>
              )}
            </tr>

            {data?.customInfo && i + 1 === finalCount ? (
              <tr
                className={`${
                  rowCount % 2 == 0 && "bg-[#f8f9fa] "
                } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
              >
                <td className="p-3"></td>
                {data?.employeeEarnings ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 1 ||
                              it?.payroll_item_type_id == 3 ||
                              it?.payroll_item_type_id == 4
                          )

                          ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.deductions ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter((it: any) => it?.payroll_item_type_id == 2)

                          ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.contributions ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter((it: any) => it?.payroll_item_type_id == 5)

                          ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.employeeTaxes ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 1 ||
                              it?.payroll_item_type_id == 3 ||
                              it?.payroll_item_type_id == 4
                          )

                          ?.map((x: any) =>
                            x?.tax_amount > 0 ? x?.tax_amount : 0
                          )
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.totals ? (
                  <>
                    <td></td>
                    <td className="border-r-[#000] border-r-1 p-3"></td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
              </tr>
            ) : (
              <></>
            )}
          </>
        )
      }
    }

    const activePayrollData = activeItem?.employeeData
      ?.map((v: any) => [...v?.groups])
      .flat()

    const currencySymbol = activePayrollData?.[0]?.currency_symbol

    elements.push(
      <>
        <tr
          className={`${
            rowCount % 2 == 0 && "bg-[#f8f9fa] "
          } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
        >
          <td className="p-3">Payroll Totals</td>
          {data?.employeeEarnings ? (
            <>
              <td>Total Earnings</td>
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter(
                      (it: any) =>
                        it?.payroll_item_type_id == 1 ||
                        it?.payroll_item_type_id == 3 ||
                        it?.payroll_item_type_id == 4
                    )

                    ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3">
              {currencySymbol}
              {formatCurrencyNoSymbol(
                activePayrollData

                  ?.filter(
                    (it: any) =>
                      it?.payroll_item_type_id == 1 ||
                      it?.payroll_item_type_id == 3 ||
                      it?.payroll_item_type_id == 4
                  )

                  ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                  ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
              )}
            </td>
          )}
          {data?.deductions ? (
            <>
              <td>Total Deductions</td>
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter((it: any) => it?.payroll_item_type_id == 2)

                    ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3">
              {currencySymbol}
              {formatCurrencyNoSymbol(
                activePayrollData

                  ?.filter((it: any) => it?.payroll_item_type_id == 2)

                  ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                  ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
              )}
            </td>
          )}
          {data?.contributions ? (
            <>
              <td>Total Contributions</td>
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter((it: any) => it?.payroll_item_type_id == 5)

                    ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3">
              {currencySymbol}
              {formatCurrencyNoSymbol(
                activePayrollData

                  ?.filter((it: any) => it?.payroll_item_type_id == 5)

                  ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                  ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
              )}
            </td>
          )}
          {data?.employeeTaxes ? (
            <>
              <td>Total Tax</td>
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter(
                      (it: any) =>
                        it?.payroll_item_type_id == 1 ||
                        it?.payroll_item_type_id == 3 ||
                        it?.payroll_item_type_id == 4
                    )

                    ?.map((x: any) => (x?.tax_amount > 0 ? x?.tax_amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3">
              {currencySymbol}
              {formatCurrencyNoSymbol(
                activePayrollData

                  ?.filter(
                    (it: any) =>
                      it?.payroll_item_type_id == 1 ||
                      it?.payroll_item_type_id == 3 ||
                      it?.payroll_item_type_id == 4
                  )

                  ?.map((x: any) => (x?.tax_amount > 0 ? x?.tax_amount : 0))
                  ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
              )}
            </td>
          )}
          {data?.totals ? (
            <>
              <td>Net Pay</td>
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {totalNetPay(activePayrollData)}
              </td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3"></td>
          )}
        </tr>
        <tr
          className={`${
            rowCount % 2 == 0 && "bg-[#f8f9fa] "
          } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
        >
          <td className="p-3"></td>
          {data?.employeeEarnings ? (
            <>
              <td></td>
              <td className="border-r-[#000] border-r-1 p-3"></td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3"></td>
          )}
          {data?.deductions ? (
            <>
              <td></td>
              <td className="border-r-[#000] border-r-1 p-3"></td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3"></td>
          )}
          {data?.contributions ? (
            <>
              <td></td>
              <td className="border-r-[#000] border-r-1 p-3"></td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3"></td>
          )}
          {data?.employeeTaxes ? (
            <>
              <td></td>
              <td className="border-r-[#000] border-r-1 p-3"></td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3"></td>
          )}
          {data?.totals ? (
            <>
              <td>Employer Cost</td>
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter(
                      (it: any) =>
                        it?.payroll_item_type_id == 1 ||
                        it?.payroll_item_type_id == 3 ||
                        it?.payroll_item_type_id == 4
                    )

                    ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0) -
                    activePayrollData

                      ?.filter((it: any) => it?.payroll_item_type_id == 2)

                      ?.map((x: any) =>
                        x?.deducted_amount > 0 ? x?.deducted_amount : 0
                      )
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0) +
                    activePayrollData

                      ?.filter(
                        (it: any) =>
                          (it?.payroll_item_type_id == 2 &&
                            data?.statutoryBenefits
                              ?.map((x: any) => x?.description)
                              ?.includes(it?.description)) ||
                          it?.description == "Health Service Provider"
                      )

                      ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0) +
                    activePayrollData

                      ?.filter(
                        (it: any) =>
                          (it?.payroll_item_type_id == 5 &&
                            data?.statutoryBenefits
                              ?.map((x: any) => x?.description)
                              ?.includes(it?.description)) ||
                          it?.description?.includes("Earned Wage Withdrawal")
                      )

                      ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            </>
          ) : (
            <td className="border-r-[#000] border-r-1 p-3"></td>
          )}
        </tr>
      </>
    )

    return elements
  }, [groupedEmployeeData, data])

  return (
    <table className="text-[#495057] font-avenir !table-auto w-full">
      <thead className="bg-gray-100  pt-1 text-left">
        <tr className="box-border p-2 text-[rgba(0, 0, 0, 0.88)] font-[600] border-b-[#f0f0f0] border !text-start">
          <th
            colSpan={1}
            className="border-r-[#000]  text-start"
          >
            <span className="py-3 text-start">Employee Information</span>
          </th>
          {data?.employeeEarnings ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] text-start py-3"
              >
                <span className="py-3 text-start">Earnings</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] text-start py-3"
              >
                <span className="py-3 text-start"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 p-3 text-start"
            >
              <span className="py-3">Total Earnings</span>
            </th>
          )}
          {data?.deductions ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3 text-start">Deductions</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3 text-start"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="py-3 text-start">Total Deductions</span>
            </th>
          )}
          {data?.contributions && (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3 text-start">Employer Contributions</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3 text-start"></span>
              </th>
            </>
          )}
          {data?.employeeTaxes ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3">Tax</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="py-3 text-start">Total Tax</span>
            </th>
          )}
          {data?.totals ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3">Totals</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="py-3">Net Pay</span>
            </th>
          )}
        </tr>
        <tr className="box-border p-2">
          {data?.customInfo && <th className="border-r-[#000] border-r-1"></th>}
          {data?.employeeEarnings ? (
            <>
              <th className="">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3">
                <span className="p-3">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {data?.deductions ? (
            <>
              <th className="">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3">
                <span className="p-3">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {data?.contributions && (
            <>
              <th className="">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3">
                <span className="p-3">Amount</span>
              </th>
            </>
          )}
          {data?.employeeTaxes ? (
            <>
              <th className="">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3">
                <span className="p-3">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {data?.totals ? (
            <>
              <th className="">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3">
                <span className="p-3">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </table>
  )
}

const personNetPay = (it: any) => {
  const peopleInDepartment = Array.from(
    new Set(it.groups?.map((v: any) => v?.person_id))
  )

  const afterTaxAmounts = it?.groups?.filter(
    (it: any) =>
      it?.payroll_item_type_id == 1 ||
      it?.payroll_item_type_id == 3 ||
      it?.payroll_item_type_id == 4
  )
  const deductionsAmounts = it?.groups?.filter(
    (it: any) => it?.payroll_item_type_id == 2
  )

  const finalArray: any[] = []

  for (let person of peopleInDepartment) {
    const afterTaxAmountForEmployee = afterTaxAmounts
      .filter((x: any) => x.person_id == person)
      ?.map((v: any) =>
        v?.after_tax_amount
          ? v?.after_tax_amount > 0
            ? v?.after_tax_amount
            : 0
          : 0
      )
      ?.reduce((a: any, b: any) => a + b, 0)
    const deductionAmountForEmployee = deductionsAmounts
      .filter((x: any) => x.person_id == person)
      ?.map((v: any) =>
        v?.deducted_amount
          ? v?.deducted_amount > 0
            ? v?.deducted_amount
            : 0
          : 0
      )
      ?.reduce((a: any, b: any) => a + b, 0)

    finalArray.push(
      afterTaxAmountForEmployee - deductionAmountForEmployee > 0
        ? afterTaxAmountForEmployee - deductionAmountForEmployee
        : 0
    )
  }

  return formatCurrencyNoSymbol(finalArray.reduce((a, b) => a + b, 0))
}
const totalNetPay = (it: any) => {
  const peopleInDepartment = Array.from(
    new Set(it?.map((v: any) => v?.person_id))
  )

  const afterTaxAmounts = it?.filter(
    (it: any) =>
      it?.payroll_item_type_id == 1 ||
      it?.payroll_item_type_id == 3 ||
      it?.payroll_item_type_id == 4
  )
  const deductionsAmounts = it?.filter(
    (it: any) => it?.payroll_item_type_id == 2
  )

  const finalArray: any[] = []

  for (let person of peopleInDepartment) {
    const afterTaxAmountForEmployee = afterTaxAmounts
      .filter((x: any) => x.person_id == person)
      ?.map((v: any) =>
        v?.after_tax_amount
          ? v?.after_tax_amount > 0
            ? v?.after_tax_amount
            : 0
          : 0
      )
      ?.reduce((a: any, b: any) => a + b, 0)
    const deductionAmountForEmployee = deductionsAmounts
      .filter((x: any) => x.person_id == person)
      ?.map((v: any) =>
        v?.deducted_amount
          ? v?.deducted_amount > 0
            ? v?.deducted_amount
            : 0
          : 0
      )
      ?.reduce((a: any, b: any) => a + b, 0)

    finalArray.push(
      afterTaxAmountForEmployee - deductionAmountForEmployee > 0
        ? afterTaxAmountForEmployee - deductionAmountForEmployee
        : 0
    )
  }

  return formatCurrencyNoSymbol(finalArray.reduce((a, b) => a + b, 0))
}
