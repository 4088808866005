import { FC, useMemo } from "react"

import { useAuthStore } from "stores"
import { DetailsSummaryTable } from "./DetailsSummaryTable"

type Props = {
  data: any
}

export const DetailsSummary: FC<Props> = ({ data }) => {
  const { user } = useAuthStore()

  const payPeriods = useMemo(() => {
    if (data?.payrollItems && data?.payrollItems?.length) {
      const grouped: any[] = data?.payrollItems
      const payrollItemIds = Array.from(
        new Set(grouped.map((it) => it?.payroll_id))
      )

      const payperiodData: any[] = []
      for (let item of payrollItemIds) {
        const filtered = grouped
          ?.filter((it) => it?.payroll_id === item)
          .sort((a: any, b: any) =>
            a?.description?.localeCompare(b?.description)
          )
        payperiodData.push({
          payroll_item_type_id: item,
          pay_period: grouped?.find((v) => v?.payroll_id == item)?.pay_period,
          payroll_month: grouped?.find((v) => v?.payroll_id == item)
            ?.payroll_month,
          employeeData: (() => {
            const employeeData: any[] = []
            const employees = Array.from(
              new Set(grouped?.map((it) => it?.person_id))
            )

            for (let person of employees) {
              const filteredData = filtered?.filter(
                (it) => it?.person_id == person
              )
              employeeData.push({
                ...grouped?.find((it) => it?.person_id == person),
                groups: filteredData,
              })
            }

            return employeeData
          })(),
        })
      }

      return payperiodData
    } else {
      return []
    }
  }, [data])

  if (data?.payrollItems && data?.payrollItems?.length)
    return (
      <div className="flex flex-col gap-2 w-full font-avenir">
        <div className="w-full">
          <div className="text-lg text-neutral500 font-semibold">
            Employee Earnings
          </div>
          <div className="w-full">
            {payPeriods?.map((x) => (
              <div className="mt-[40px] w-full">
                <div className="flex gap-2 items-center w-full my-3 period-title">
                  <div className="text-[16px] font-[700] text-neutral500">
                    {x?.payroll_month} Payroll
                    <br />
                  </div>
                  <div className="text-sm text-neutral500 font-semibold">
                    Payroll period: {x?.pay_period}
                  </div>
                </div>

                <div className="max-w-[100%] overflow-x-scroll">
                  <DetailsSummaryTable
                    data={data}
                    groupPayPeriodsData={payPeriods}
                    groupedEmployeeData={x?.employeeData}
                    user={user}
                    activeItem={x}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )

  return (
    <div className="max-w-full overflow-scroll">
      <div className="mt-[40px] w-full">
        <div className="text-lg text-neutral500 font-semibold">
          Payroll Journal Report
        </div>
      </div>
      <div className="w-full">
        <></>
      </div>
    </div>
  )
}
