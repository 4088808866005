export const PayoutCard: React.FC<{
  id?: string
  title: string
  value: string | number
  icon: string
  handleClick?: Function
  type: boolean
  currency?: string
}> = ({ id, title, value, icon, handleClick, type, currency }) => {
  return (
    <div
      id={id}
      className={`h-[88px] w-[262px]  relative cursor-pointer rounded-[5px] p-3 shadow-card  flex items-center justify-start gap-2 ${
        type ? "!bg-background border-2 border-primary500" : "bg-white"
      } `}
      onClick={() => {
        handleClick ? handleClick() : () => {}
      }}
    >
      {type && (
        <button
          className="absolute px-1 top-1 right-1 bg-primary500 text-white text-[13px] font-medium rounded-sm"
          onClick={() => {
            handleClick ? handleClick() : () => {}
          }}
        >
          Reset
        </button>
      )}
      <div className="h-[30px] w-[30px] sm:h-[45px] sm:w-[45px] flex justify-center items-center">
        <img
          src={
            icon ||
            "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
          }
          alt="User Profile"
        />
      </div>
      <div className="h-full flex flex-col  justify-center gap-2">
        <p className="text-sm  text-padeBlack font-medium font-circular">
          {title}
        </p>
        <h4 className="text-padeBlack  text-[20px] font-bold font-circular">
          {currency ?? "₦"} {value}
        </h4>
      </div>
    </div>
  )
}
