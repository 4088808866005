import { Table, Tag, Tooltip } from "antd"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import {
  ICurrencyInfo,
  IPaymentScheduleItem,
  IPayoutsResponse,
} from "./interface/payouts"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useGet } from "utils/useFetch"
import getIdFromKey from "utils/getIdFromKey"
import { useLocation } from "react-router-dom"
import { ColumnsType } from "antd/es/table"
import { availableFlags } from "utils/flags"
import { antIcon } from "pages/dashboard/dashboard/Dashboard"
import { useAuthStore } from "stores"
import { useState } from "react"
import DowntimeModal from "pages/employee-dashboard/wallet/components/DowntimeModal"
const ViewGlobal = ({
  currencies,
}: {
  currencies: ICurrencyInfo[] | undefined
}) => {
  const myParam = useLocation().search
  const { user } = useAuthStore()
  const x = new URLSearchParams(myParam).get("x")
  const {
    isLoading: isPayoutLoading,
    data: payoutData,
    refetch: refetchPayout,
    isFetching,
  } = useGet<IPayoutsResponse>({
    url: `admin/payment_schedules/selectpaymentscheduleitems_payout?id=${getIdFromKey(
      x || ""
    )}`,
  })
  const [showDownTimeModal, setShowDownTimeModal] = useState(
    user?.paymentProviderStatus === "DOWN" ? true : false
  )
  const uniqueBankNames = [
    ...new Set(payoutData?.data?.map((pay: any) => pay.bank_name)),
  ]
  const uniqueStatusNames = [
    ...new Set(
      payoutData?.data?.map((pay: any) => pay.payment_schedule_item_status)
    ),
  ]

  const bankFilters = uniqueBankNames?.map((bankName) => ({
    text: bankName,
    value: bankName,
  }))
  const statusFilters = uniqueStatusNames?.map((status) => ({
    text: status,
    value: status,
  }))
  const TableData =
    payoutData?.data?.map((record, index) => ({
      key: index,
      ...record,
      flag: availableFlags?.find((item) => item?.code === record?.currency_code)
        ?.flag,
    })) || []
  const TableColumns: ColumnsType<IPaymentScheduleItem> = [
    {
      title: "Beneficiary",
      dataIndex: "beneficiary",
      render: (_: any, record: IPaymentScheduleItem) => (
        <div className="flex items-center gap-2">
          <div className="flex flex-col">
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {record?.beneficiary_name || record?.full_name}
            </span>
            {Number(record.payment_schedule_item_type_id) > 1 && (
              <Tag
                color="processing"
                className="w-[115px] text-xs font-medium"
              >
                {record.payment_schedule_item_type_id == "3"
                  ? "Pension Schedule"
                  : record.payment_schedule_item_type_id == "4"
                  ? "NHF Schedule"
                  : record.payment_schedule_item_type_id == "2"
                  ? "Tax Schedule"
                  : record.payment_schedule_item_type_id == "8"
                  ? "EWA Repayment"
                  : record.payment_schedule_item_type_id == "9"
                  ? "NSITF Schedule"
                  : ""}
              </Tag>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Account",
      dataIndex: "bank",
      width: "25%",
      filters: bankFilters,
      onFilter: (value: boolean | Key, record: IPaymentScheduleItem) => {
        // Convert value to string for comparison
        const filterValue = String(value)
        return record?.bank_name === filterValue
      },
      render: (_: any, record: IPaymentScheduleItem) =>
        record?.source_currency_code === "USD" && record?.account_number ? (
          <div className="flex flex-col">
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {record?.routing_code}
              {record?.routing_type ? `(${record?.routing_type})` : "-"}
            </span>
            <span
              className={`font-circular flex flex-wrap text-xs text-padeBlack`}
            >
              {record.account_number}
            </span>
          </div>
        ) : record?.currency_code === "NGN" && record?.bank_name ? (
          <div className="flex flex-col">
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {record?.bank_name}
            </span>
            <span
              className={`font-circular flex flex-wrap text-xs text-padeBlack`}
            >
              {record.account_number}
            </span>
          </div>
        ) : record?.currency_code !== "NGN" && record?.account_number ? (
          <div className="flex flex-col">
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {record?.routing_code}
              {record?.routing_type ? `(${record?.routing_type})` : "-"}
            </span>
            <span
              className={`font-circular flex flex-wrap text-xs text-padeBlack`}
            >
              {record.account_number ?? "-"}
            </span>
          </div>
        ) : (
          <div className="flex flex-col">
            <Tooltip title="Employee is missing details required to run payroll. ">
              <ExclamationTriangleIcon className="h-6 w-6 text-danger500" />
            </Tooltip>
          </div>
        ),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      filters:
        currencies?.map((item) => ({
          text: item.currency_code,
          value: item.currency_code,
        })) || [],
      onFilter: (value: any, record: any) => {
        return record?.currency_code === value
      },
      render: (_: any, record: any) => (
        <div className="flex items-center gap-2">
          <div>
            <img
              src={record?.flag}
              alt=""
              className="h-6 w-6"
            />
          </div>
          <span
            className={`font-circular flex flex-wrap text-sm text-padeBlack`}
          >
            {record?.currency_code}
          </span>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_: any, record: IPaymentScheduleItem) => (
        <div className="flex flex-col">
          <span
            className={`whitespace-nowrap font-avenir text-sm text-padeBlack`}
          >
            {record?.currency_symbol ? (
              record?.currency_symbol
            ) : (
              <ExclamationTriangleIcon className="h-6 w-6 text-danger500" />
            )}
            {formatCurrencyNoSymbol(Number(record.amount))}
          </span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: statusFilters,
      onFilter: (value: boolean | Key, record: IPaymentScheduleItem) => {
        const filterValue = String(value)
        return record?.payment_schedule_item_status === filterValue
      },
      render: (_: any, record: IPaymentScheduleItem) => (
        <div className="">
          <div className="flex items-center gap-1">
            <Tag
              color={
                record.payment_schedule_item_status_id === "1"
                  ? "green"
                  : record.payment_schedule_item_status_id === "2"
                  ? "orange"
                  : record.payment_schedule_item_status_id === "3"
                  ? "red"
                  : record.payment_schedule_item_status_id === "4"
                  ? "red"
                  : "default"
              }
            >
              {record?.payment_schedule_item_status}
            </Tag>
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className="">
      <div className=" flex flex-row gap-[11px]">
        {currencies?.map((item, index) => (
          <div
            key={index}
            className="h-[89px] w-[262px] bg-white rounded-5px px-8 text-[24px] text-padeBlack font-circular flex items-center"
          >
            {item?.currency_symbol}
            {formatCurrencyNoSymbol(Number(item?.total))}
          </div>
        ))}
      </div>
      <div className="mt-[26px]">
        <Table
          rowSelection={{
            type: "checkbox",
          }}
          columns={TableColumns}
          dataSource={TableData}
          loading={{ spinning: isPayoutLoading, indicator: antIcon }}
          className="text-[#42526D]"
          pagination={{
            defaultPageSize: 20,
          }}
          scroll={{
            y: "calc(100vh - 300px)",
            x: "max-content",
          }}
        />
      </div>
      <DowntimeModal
        open={showDownTimeModal}
        onClose={() => setShowDownTimeModal(false)}
      />
    </div>
  )
}

export default ViewGlobal
type Key = string | number | symbol | bigint
