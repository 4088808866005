import { Button, Form } from "antd"
import { Button as CustomButton } from "components"

const PrevNextButton = ({
  showPrevButton = true,
  onClickPrev,
  isEndOfForm = false,
  disabled = false,
}: {
  showPrevButton?: boolean
  onClickPrev?: () => void
  isEndOfForm?: boolean
  disabled?: boolean
}) => {
  return (
    <div
      className={`mt-2 flex justify-end ${showPrevButton ? "gap-4 h-fit" : ""}`}
    >
      {showPrevButton && (
        <CustomButton
          onClick={(e) => {
            e.preventDefault()
            onClickPrev?.()
          }}
          color="neutral"
          title="Previous"
          className="h-fit text-[#5D6B82] font-avenir"
        >
          Previous
        </CustomButton>
      )}

      <Form.Item className="mb-0">
        <Button
          type="primary"
          className="rounded-5px disabled:opacity-50 focus:outline-none focus:shadow-none disabled:cursor-not-allowed py-[7px] px-4 text-sm min-w-[106px] bg-primary500 hover:bg-primary700 active:bg-primary800 focus:bg-primary800 text-white shadow-none h-auto font-avenir"
          htmlType="submit"
          disabled={disabled}
        >
          {isEndOfForm ? "Finish" : "Next"}
        </Button>
      </Form.Item>
    </div>
  )
}

export default PrevNextButton
