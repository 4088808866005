import { Form, FormItemProps, Input, Select, Radio, InputNumber } from "antd"
import React, { useState } from "react"
export const CustomInputNumber = ({
  label,
  name,
  required = false,
  maxLength = 50,
  onChange,
  disabled = false,
  max,
  value,
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      label={
        <span className="font-circular text-neutral300 text-sm ">{label}</span>
      }
      name={name}
      rules={[
        {
          validator: (rule, value) => {
            if (max && value > max) {
              return Promise.reject(`Value is above the set weight of ${max}`)
            }
            return Promise.resolve()
          },
        },
        { required, message: "Please enter this field!" },
      ]}
      {...restProps}
      validateTrigger="onChange"
    >
      <InputNumber
        value={value}
        // onChange={onChange}
        onChange={onChange}
        // maxLength={maxLength}
        disabled={disabled}
        // max={Number(max)}
        // max={max !== undefined ? Number(max) : undefined}
        style={{ width: "100%" }}
      />
    </Form.Item>
  )
}
type InputItemProps = FormItemProps & {
  maxLength?: number | undefined
  label: string | React.ReactNode
  onChange?: React.ChangeEventHandler<HTMLInputElement> | any
  required?: boolean
  disabled?: boolean
  max?: number | undefined
  value?: any
}
