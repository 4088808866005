import { Tag } from "antd"
import { useNavigate } from "react-router-dom"

export const SurveyCard: React.FC<{
  title: string
  keyId: string
  subtitle: string | null
  id: string | number
  questions_length: number
  handleOpenDeleteModal: Function
}> = ({
  title,
  subtitle,
  id,
  questions_length,
  handleOpenDeleteModal,
  keyId,
}) => {
  const navigate = useNavigate()
  return (
    <div className="border border-neutral40 bg-white  w-full gap-5 h-[61px] flex  items-center justify-between rounded-5px px-6">
      <div>
        <h6 className=" text-neutral700  font-bold text-sm">{title}</h6>{" "}
        <Tag className="text-neutral100 text-xs">{subtitle}</Tag>
      </div>
      <div className="flex items-center gap-3">
        {/* <p className="text-bluesubtext text-sm font-medium">
          {questions_length} Questions
        </p> */}
        <p
          className="text-primary500 text-sm font-medium cursor-pointer"
          onClick={() => navigate(`/dashboard/survey/template?x=${keyId}`)}
        >
          Edit
        </p>
        <p
          className="text-danger400 text-sm font-medium cursor-pointer"
          onClick={() => handleOpenDeleteModal(id)}
        >
          Delete
        </p>
      </div>
    </div>
  )
}
