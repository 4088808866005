import { useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { usePostPreview } from "../hooks/report-hooks"
import { Spin } from "antd"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useAuthStore } from "stores"
import { formatCurrencyNoSymbol } from "utils/currency"
import { ReportDownloadHeading } from "../component/ReportDownloadHeading"

const PaybandSummaryPreviewPage = () => {
  const { state } = useLocation()

  const payrollExemption = state?.data

  const navigate = useNavigate()

  useEffect(() => {
    if (!state?.data) {
      navigate(`/dashboard/report/payband-summary`)
    }
  }, [])

  const { getting, data: items } = usePostPreview(payrollExemption)

  const data: IPaybandReport = items

  const ref: any = useRef() //for pdf report printing

  const { user } = useAuthStore()

  const allowances = data?.paybandReportSummaryValues
    ?.filter((x) => x?.allowance_id > 0 && x?.description)
    ?.sort((a, b) => a?.allowance_id - b?.allowance_id)
  const statutoryBenefits = data?.paybandReportSummaryValues
    ?.filter((x) => x?.benefit_id > 0 && x?.description)
    ?.sort((a, b) => a?.benefit_id - b?.benefit_id)
  // const taxReliefs = data?.paybandReportSummaryValues
  //   ?.filter((x) => x?.relief_id > 0 && x?.description)
  //   ?.sort((a, b) => a?.relief_id - b?.relief_id)

  return (
    <DashboardWrapper>
      <Spin spinning={getting}>
        <div className="font-avenir w-[100%]">
          <ReportDownloadHeading
            title={data?.report_download_title}
            ref={null}
            showPdfDownload={false}
          />
          <div
            className=" bg-white p-4 flex gap-2 flex-col w-[100%] box-border"
            ref={ref}
          >
            <div className="py-4 border-b border-gray-100">
              <div className="flex justify-between  gap-2 items-center">
                <div className=" text-lg font-[600] text-[#495057] ">
                  Payband Summary Report
                </div>
                {data?.logoUploaded && (
                  <div className="h-[50px] w-[50px]">
                    <img
                      className="float-right object-contain clear-left"
                      src={`${user?.organisationAvatarFilename}`}
                      alt="logo"
                      height="100%"
                      width="100%"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between gap-3 items-center">
              <div className="text-[#495057]">
                Report created on: {data?.report_date}
              </div>
              <div className="flex justify-end  flex-col items-end">
                <div className="text-[#495057]">{user?.userOrganisation}</div>
                <div className="text-[#495057]">
                  {data?.officeAddress?.address_line_1}
                </div>
                <div className="text-[#495057]">
                  {data?.officeAddress?.address_city}&nbsp;
                  {data?.officeAddress?.address_state}&nbsp;
                  {data?.officeAddress?.zip_code}&nbsp;
                  {data?.officeAddress?.country}
                </div>
              </div>
            </div>

            <div className="max-w-full overflow-x-auto [&_td]:p-3 [&_td]:whitespace-nowrap">
              <table className="w-full min-w-[800px]">
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  {data?.alias && <td></td>}
                  {data?.routineAnnualGross && <td></td>}
                  {data?.monthlyGross && <td></td>}
                  {data?.routineMonthlyGross && <td></td>}
                  {data?.annualNet && <td></td>}
                  {data?.routineAnnualNet && <td></td>}
                  {data?.routineMonthlyNet && <td></td>}
                  {data?.annualTax && <td></td>}
                  {(() => {
                    if (data?.paybandReportSummaryValues?.length > 0) {
                      const items = allowances
                      return Array(items.length * 2)
                        .fill(items.length * 2)
                        .map((it, index) => (
                          <td className="font-bold">
                            {index == 0 ? "Allowances" : ""}
                          </td>
                        ))
                    }
                    return <></>
                  })()}
                  {(() => {
                    if (data?.paybandReportSummaryValues.length > 0) {
                      const items = statutoryBenefits
                      return Array(items.length * 4)
                        .fill(items.length * 4)
                        .map((it, index) => (
                          <td className="font-bold">
                            {index == 0 ? "Statutory Benefits" : ""}
                          </td>
                        ))
                    }
                    return <></>
                  })()}
                  {/* {(() => {
                    if (data?.paybandReportSummaryValues.length > 0) {
                      const items = taxReliefs
                      return Array(items.length * 2)
                        .fill(items.length * 2)
                        .map((it, index) => (
                          <td className="font-bold">
                            {index == 0 ? "Tax Reliefs" : ""}
                          </td>
                        ))
                    }
                    return <></>
                  })()} */}
                </tr>
                <tr>
                  <td>Payband</td>
                  <td>Gross (Annual)</td>
                  <td>Net (Monthly)</td>
                  <td>Tax (Monthly)</td>
                  {data?.alias && <td>Alias</td>}
                  {data?.routineAnnualGross && <td>Regular gross (Annual)</td>}
                  {data?.monthlyGross && <td>Gross (Monthly)</td>}
                  {data?.routineMonthlyGross && (
                    <td>Regular gross (Monthly)</td>
                  )}
                  {data?.annualNet && <td>Net (Annual)</td>}
                  {data?.routineAnnualNet && <td>Regular net (Annual)</td>}
                  {data?.routineMonthlyNet && <td>Regular net (Monthly)</td>}
                  {data?.annualTax && <td>Tax (Annual)</td>}

                  {(() => {
                    if (data?.paybandReportSummaryValues.length > 0) {
                      const items = allowances
                      return items?.map((it) => (
                        <>
                          <td>{it?.description} (Prorated)</td>
                          <td>{it?.description} (Annual)</td>
                        </>
                      ))
                    }
                    return <></>
                  })()}
                  {(() => {
                    if (data?.paybandReportSummaryValues.length > 0) {
                      const items = statutoryBenefits
                      return items?.map((it) => (
                        <>
                          <td>{it?.description} (Monthly Deduction)</td>
                          <td>{it?.description} (Monthly Contribution)</td>
                          <td>{it?.description} (Annual Deduction)</td>
                          <td>{it?.description} (Annual Contribution)</td>
                        </>
                      ))
                    }
                    return <></>
                  })()}
                  {/* {(() => {
                    if (data?.paygradeReportSummaryValues.length > 0) {
                      const items = taxReliefs
                      return items?.map((it) => (
                        <>
                          <td>{it?.description} (Prorated)</td>
                          <td>{it?.description} (Annual)</td>
                        </>
                      ))
                    }
                    return <></>
                  })()} */}
                </tr>
                <tbody>
                  {data?.paybandReportSummaries?.map((record) => (
                    <tr>
                      <td>{record?.payband}</td>
                      <td>
                        <>
                          {getAmount(
                            record?.currency_symbol,
                            record?.annual_gross
                          )}
                        </>
                      </td>
                      <td>
                        <>
                          {getAmount(
                            record?.currency_symbol,
                            record?.monthly_net
                          )}
                        </>
                      </td>
                      <td>
                        <>
                          {getAmount(
                            record?.currency_symbol,
                            record?.monthly_tax
                          )}
                        </>
                      </td>
                      {data?.alias && <td>{record.alias}</td>}
                      {data?.routineAnnualGross && (
                        <td>
                          {getAmount(
                            record?.currency_symbol,
                            record?.routine_annual_gross
                          )}
                        </td>
                      )}
                      {data?.monthlyGross && (
                        <td>
                          {getAmount(
                            record?.currency_symbol,
                            record?.monthly_gross
                          )}
                        </td>
                      )}
                      {data?.routineMonthlyGross && (
                        <td>
                          {getAmount(
                            record?.currency_symbol,
                            record?.routine_monthly_gross
                          )}
                        </td>
                      )}
                      {data?.annualNet && (
                        <td>
                          {getAmount(
                            record?.currency_symbol,
                            record?.annual_net
                          )}
                        </td>
                      )}
                      {data?.routineAnnualNet && (
                        <td>
                          {getAmount(
                            record?.currency_symbol,
                            record?.routine_annual_net
                          )}
                        </td>
                      )}
                      {data?.routineMonthlyNet && (
                        <td>
                          {getAmount(
                            record?.currency_symbol,
                            record?.routine_monthly_net || 0
                          )}
                        </td>
                      )}
                      {data?.annualTax && (
                        <td>
                          {getAmount(
                            record?.currency_symbol,
                            record?.annual_tax
                          )}
                        </td>
                      )}
                      {allowances?.map((allowance) => {
                        const cellData =
                          data?.paybandReportSummaryAllowances?.find(
                            (t) =>
                              t?.payband_id == record?.payband_id &&
                              allowance?.allowance_id == t?.allowance_id
                          )
                        return (
                          <>
                            <td>
                              {getAmount(
                                record?.currency_symbol,
                                cellData?.prorated_amount || 0
                              )}
                            </td>
                            <td>
                              {getAmount(
                                record?.currency_symbol,
                                cellData?.amount || 0
                              )}
                            </td>
                          </>
                        )
                      })}
                      {statutoryBenefits?.map((statutoryBenefit) => {
                        const cellData =
                          data?.paybandReportSummaryStatutoryBenefits?.find(
                            (t) =>
                              t?.payband_id == record?.payband_id &&
                              statutoryBenefit?.benefit_id ==
                                t?.statutory_benefit_id
                          )
                        return (
                          <>
                            <td>
                              {getAmount(
                                record?.currency_symbol,
                                cellData?.prorated_deduction_amount || 0
                              )}
                            </td>
                            <td>
                              {getAmount(
                                record?.currency_symbol,
                                cellData?.prorated_contribution_amount || 0
                              )}
                            </td>
                            <td>
                              {getAmount(
                                record?.currency_symbol,
                                cellData?.deduction_amount || 0
                              )}
                            </td>
                            <td>
                              {getAmount(
                                record?.currency_symbol,
                                cellData?.contribution_amount || 0
                              )}
                            </td>
                          </>
                        )
                      })}
                      {/* {taxReliefs?.map((taxRelief) => {
                        const cellData =
                          data?.paygradeReportSummaryTaxReliefs?.find(
                            (t) =>
                              t?.paygrade_id == record?.paygrade_id &&
                              taxRelief?.relief_id == t?.tax_relief_id
                          )
                        return (
                          <>
                            <td>
                              {getAmount(
                                record?.currency_symbol,
                                cellData?.prorated_amount || 0
                              )}
                            </td>
                            <td>
                              {getAmount(
                                record?.currency_symbol,
                                cellData?.amount || 0
                              )}
                            </td>
                          </>
                        )
                      })} */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Spin>
    </DashboardWrapper>
  )
}

export default PaybandSummaryPreviewPage

const getAmount = (symbol: string, amount: number) => {
  return `${symbol}${formatCurrencyNoSymbol(amount)}`
}

interface IPaybandReport {
  range?: null
  grouping?: null
  report_date: string
  report_download_title: string
  payrollItems?: null
  employeeBenefits?: null
  employeeRequests?: null
  employeeLeaveBalances?: null
  payrollChanges?: null
  payrollExemptions?: null
  customFields?: null
  employeeCustomFields?: null
  employeeSummary?: null
  officeAddress: OfficeAddress
  persons?: null
  homeAddresses?: null
  nextofKins?: null
  payrollReportSummaries?: null
  payrollReportSummaryValues?: null
  payrollReportSummaryAllowances?: null
  payrollReportSummaryEarnings?: null
  statutoryBenefits?: null
  reviewRound?: null
  paygradeReportSummaries?: null
  paygradeReportSummaryAllowances?: null
  paygradeReportSummaryStatutoryBenefits?: null
  paygradeReportSummaryTaxReliefs?: null
  paygradeReportSummaryValues?: null
  paybandReportSummaries: PaybandReportSummariesEntity[]
  paybandReportSummaryAllowances?: PaybandReportSummaryAllowancesEntity[]
  paybandReportSummaryStatutoryBenefits: PaybandReportSummaryStatutoryBenefitsEntity[]

  paybandReportSummaryValues: PaybandReportSummaryValuesEntity[]
  pensionSchedules?: null
  taxSchedules?: null
  nhfSchedules?: null
  nsitfSchedules?: null
  itfSchedules?: null
  earnedWageWithdrawals?: null
  organisationAvatarFilename?: null
  organisationName?: null
  logoUploaded: boolean
  colspan: number
  qualifiedPayrollDate?: null
  payPeriod?: null
  payrollStatusId?: null
  employeeEarnings: boolean
  employeeTaxes: boolean
  deductions: boolean
  contributions: boolean
  totals: boolean
  customInfo: boolean
  employeeTitle: boolean
  hireDate: boolean
  department: boolean
  location: boolean
  gender: boolean
  daysWorked: boolean
  pension: boolean
  nhis: boolean
  nhf: boolean
  lifeAssurance: boolean
  currentYear: number
  employeeNumber: boolean
  paygrade: boolean
  benefit?: null
  alias: boolean
  routineAnnualGross: boolean
  monthlyGross: boolean
  routineMonthlyGross: boolean
  annualNet: boolean
  routineAnnualNet: boolean
  routineMonthlyNet: boolean
  annualAllowances: boolean
  annualStatutoryBenefits: boolean
  annualTaxReliefs: boolean
  annualTax: boolean
  createdDate: boolean
  employeeRequestTypeId: number
  liability: boolean
  pensionReferenceNumber?: null
  taxIdentificationNumber?: null
  housingReferenceNumber?: null
  nsitfReferenceNumber?: null
  itfReferenceNumber?: null
  paymentScheduleItemStatusId: number
  scheduleDate?: null
}
interface OfficeAddress {
  office_address_key?: null
  office_address_id: number
  address_line_1: string
  address_line_2?: null
  address_city: string
  address_state: string
  state_code: string
  country: string
  country_code: string
  zip_code: string
  phone: string
  display_name: string
  full_address?: null
  tax_office_name?: null
  tax_office_code?: null
  housing_location_name?: null
  housing_location_code?: null
  tax_id?: null
  has_children?: null
  is_deleted: boolean
  is_active: boolean
}
interface PaybandReportSummariesEntity {
  payband_id: number
  payband: string
  alias: string
  annual_gross: number
  qualified_annual_gross: number
  routine_annual_gross: number
  monthly_gross: number
  qualified_monthly_gross: number
  routine_monthly_gross: number
  daily_gross: number
  qualified_daily_gross: number
  routine_daily_gross: number
  routine_annual_net: number
  annual_net: number
  routine_monthly_net?: null
  monthly_net: number
  daily_net: number
  routine_daily_net: number
  annual_tax: number
  monthly_tax: number
  daily_tax: number
  lower_annual_gross: number
  lower_qualified_annual_gross: number
  lower_routine_annual_gross: number
  lower_monthly_gross: number
  lower_qualified_monthly_gross: number
  lower_routine_monthly_gross: number
  lower_daily_gross: number
  lower_qualified_daily_gross: number
  lower_routine_daily_gross: number
  lower_routine_annual_net: number
  lower_annual_net: number
  lower_routine_monthly_net?: null
  lower_monthly_net: number
  lower_daily_net: number
  lower_routine_daily_net: number
  lower_annual_tax: number
  lower_monthly_tax: number
  lower_daily_tax: number
  currency_country_code?: null
  currency_code: string
  currency_symbol: string
}
interface PaybandReportSummaryAllowancesEntity {
  id: number
  payband_id?: number
  allowance_id: number
  allowance: string
  amount: number
  prorated_amount: number
  lower_amount: number
  lower_prorated_amount: number
}
interface PaybandReportSummaryStatutoryBenefitsEntity {
  id: number
  payband_id?: number | null
  statutory_benefit_id: number
  statutory_benefit: string
  deduction_amount?: number | null
  contribution_amount: number
  prorated_deduction_amount: number
  prorated_contribution_amount: number
  lower_deduction_amount: number
  lower_contribution_amount: number
  lower_prorated_deduction_amount: number
  lower_prorated_contribution_amount: number
}

interface PaybandReportSummaryValuesEntity {
  id: number
  description: string
  allowance_id: number
  benefit_id: number
}
