import {
  ArrowLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"
import { Dropdown, Form, Table } from "antd"
import React, { useState, useEffect } from "react"
import { useGet } from "utils/useFetch"
import getIdFromKey from "utils/getIdFromKey"
import {
  IReviewDetailed,
  IReviewRoundsReviewees,
  IReviewRoundsRevieweesData,
  IReviewRoundsReviewers,
  IReviewRoundsReviewersData,
} from "./interface/reviews"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { useLocation, useNavigate } from "react-router-dom"
import { Button, Input } from "components"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import ImageComponent from "components/custom/image"

import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
export const TeamCard: React.FC<{
  title: string
  id?: string
  eligible?: string
  complete?: string
  employees?: any[]
  active?: boolean
  handleClick: Function
  handleUserClick: Function
  handleRemoveAssignee: Function
}> = ({
  title,
  id,
  active,
  eligible,
  complete,
  employees,
  handleClick,
  handleUserClick,
  handleRemoveAssignee,
}) => {
  const TableData =
    employees?.map((emp: any, index: any) => {
      return {
        key: index,
        id: emp.review_entity_key,
        name: {
          full_name: emp.full_name,
          img: emp.avatar_url,
        },
        email: emp.email,
        role: emp.employee_title,
        period: {
          period_title: emp.period,
          period_range: emp.period_range,
        },
        view: {
          id: emp.review_entity_key,
          personId: emp.person_id,
          name: emp.full_name,
        },
        status: emp.is_feedback_shared ? true : false,
      }
    }) || []

  const TableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name: { full_name: string; img: string }) => (
        <div className="flex items-center gap-2">
          <span className="shrink-0">
            <ImageComponent
              className="w-6 h-6 rounded-full"
              src={
                name.img ||
                "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
              }
              alt=""
            />
          </span>

          <div className="flex flex-col">
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]
                  `}
            >
              {name.full_name}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (email: string) => (
        <span
          className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
        >
          {email}{" "}
        </span>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role: string) => (
        <span
          className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
        >
          {role}{" "}
        </span>
      ),
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (status: boolean) => (
    //     <span
    //       className={`text-sm font-medium py-1 block text-center w-[90px] rounded-[4px]
    //               ${
    //                 status
    //                   ? "bg-success100 text-success800"
    //                   : "bg-neutral40 text-neutral500"
    //               }
    //               `}
    //     >
    //       {status ? "Completed" : "Not started"}
    //     </span>
    //   ),
    // },
    {
      title: "",
      dataIndex: "view",
      render: (view: { id: string; personId: string; name: string }) => (
        <Dropdown
          menu={{
            items: actionItems(view),
          }}
        >
          <button>
            <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
          </button>
        </Dropdown>
        // <div onClick={() => >
        //   {" "}
        //   <ChevronRightIcon className=" text-primary500  w-5 h-5" />
        // </div>
      ),
    },
  ]
  const actionItems = (view: {
    id: string
    personId: string
    name: string
  }) => [
    // {
    //   key: "1",
    //   label: (
    //     <button onClick={() => handleUserClick(view.id, view.personId)}>
    //       View
    //     </button>
    //   ),
    // },
    {
      key: "2",
      label: (
        <button
          onClick={() => {
            handleRemoveAssignee(view.personId, view.name)
          }}
        >
          Remove
        </button>
      ),
    },
  ]
  return (
    <div className="border border-neutral40 bg-white  w-full gap-5">
      <div
        className="px-5 h-[80px] flex justify-between items-center  cursor-pointer "
        onClick={() => handleClick(title)}
      >
        <div>
          <h6 className="text-sm text-[#243757] mb-3 font-extrabold">
            {title}
          </h6>
          <p className=" text-neutral500 text-sm">
            {employees?.length} employees
            {/* ,2 not eligible */}
          </p>
        </div>
        <div
          className=""
          onClick={() => handleClick(title)}
        >
          <ChevronDownIcon className=" text-primary500  w-5 h-5" />
        </div>
      </div>
      {active && (
        <div className=" px-5 bg-[#FAFAFA]">
          <Table
            columns={TableColumns}
            dataSource={TableData}
            // loading={isPaymentLoading}
            className="text-[#42526D] bg-[#FAFAFA] "
            scroll={{ x: `100%` }}
          />
        </div>
      )}
    </div>
  )
}
const ViewReviewDetailed: React.FC<{}> = ({}) => {
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const myParam = useLocation().search
  const id = new URLSearchParams(myParam).get("id")
  const navigate = useNavigate()
  const [openRefreshConfirmation, setOpenRefreshConfirmation] = useState(false)
  const { isLoading: isReviewRoundsLoading, data: reviewRoundsList } =
    useGet<IReviewDetailed>({
      url: `/admin/performance/view/review?id=${id}`,
    })

  const [teams, setTeams] = useState<any>([])
  const [teamsReviewers, setTeamsReviewers] = useState<any>([])

  const [openRemoveConfirmation, setOpenRemoveConfirmation] = useState(false)
  const [activeRemoveId, setActiveRemoveId] = useState({
    name: "",
    id: "",
  })
  const { isLoading: isRevieweesRoundsLoading, data: revieweesRoundsList } =
    useGet<IReviewRoundsRevieweesData>({
      url: `/admin/performance/selectreviewroundreviewees?review_group_id=${getIdFromKey(
        id || ""
      )}`,
    })
  const { isLoading: isReviewersRoundsLoading, data: reviewersRoundsList } =
    useGet<IReviewRoundsReviewersData>({
      url: `/admin/performance/selectreviewroundreviewers?review_group_id=${getIdFromKey(
        id || ""
      )}`,
    })

  let isPartOfReview: any = null

  const personKey = Number(getIdFromKey(user?.personKey || ""))
  isPartOfReview =
    revieweesRoundsList?.data.find(
      (rev) => Number(rev.person_id) === personKey
    ) ||
    reviewersRoundsList?.data.find((rev) => Number(rev.person_id) === personKey)

  let newArray
  const groupByIds = (arr: any) => {
    const hash = Object.create(null)
    // result = []
    const result: IReviewRoundsReviewees[] = []
    arr.forEach((el: any) => {
      if (!hash[el.department]) {
        hash[el.department] = []
        result.push(hash[el.department])
      }
      hash[el.department].push(el)
    })

    // SORT ARRAY
    newArray = result.reverse()

    return result
  }
  const handlePrevious = () => {
    navigate("/employee/dashboard/reviews")
  }
  useEffect(() => {
    if (revieweesRoundsList) {
      const result = groupByIds(revieweesRoundsList.data)

      if (result) {
        setTeams(result)
      }
    }
  }, [revieweesRoundsList])

  useEffect(() => {
    if (reviewersRoundsList) {
      const result = groupByIds(reviewersRoundsList.data)
      if (result) {
        setTeamsReviewers(result)
      }
    }
  }, [reviewersRoundsList])

  const [activeId, setActiveId] = useState("")
  const [personId, setPersonId] = useState("")
  const [activeUserId, setActiveUserId] = useState("")
  const [comment, setComment] = useState("")
  const [activeReviewType, setActiveReviewType] = useState("Reviewees")

  const handleClick = (id: string) => {
    if (activeId == id) {
      setActiveId("")
    } else {
      setActiveId(id)
    }
  }
  const handleUserClick = (entity_id: string, person_id: string) => {
    setActiveUserId(entity_id)
    setPersonId(person_id)
    navigate(
      `/employee/dashboard/reviews/view-employee?e=${entity_id}&p=${person_id}&g=${
        reviewRoundsList?.review_group_key || ""
      }`
    )
  }
  const handleRemoveAssignee = (id: string, name: string) => {
    setActiveRemoveId({
      name: name,
      id: id,
    })
    setOpenRemoveConfirmation(true)
  }
  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [
        `/admin/performance/selectreviewroundreviewees?review_group_id=${getIdFromKey(
          id || ""
        )}`,
      ],
    })
    queryClient.invalidateQueries({
      queryKey: [
        `/admin/performance/selectreviewroundreviewers?review_group_id=${getIdFromKey(
          id || ""
        )}`,
      ],
    })
  }
  const { isLoading: isSubmittingRemove, mutate: submitRemove } = useMutation({
    mutationFn: async (personId: any) => {
      const res = await axiosInstance.post(
        `/admin/performance/remove_review_group_person?id=${getIdFromKey(
          id || ""
        )}`,
        {
          person_id: String(personId.id),
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        setOpenRemoveConfirmation(false)
        invalidateQueries()
        setActiveRemoveId({
          name: "",
          id: "",
        })
        {
          data.msg && toast.success(data.msg)
        }
      } else {
        setOpenRemoveConfirmation(false)
        setActiveRemoveId({
          name: "",
          id: "",
        })
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      setOpenRemoveConfirmation(false)
      setActiveRemoveId({
        name: "",
        id: "",
      })
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const { isLoading: isSubmittingRefreshSingle, mutate: submitRefreshSingle } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/performance/refresh_review_group?id=${getIdFromKey(id || "")}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          setOpenRefreshConfirmation(false)
          invalidateQueries()

          {
            data.msg && toast.success(data.msg)
          }
        } else {
          setOpenRefreshConfirmation(false)
          {
            data.msg && toast.error(data.msg)
          }
        }
      },
      onError: (data: any) => {
        setOpenRefreshConfirmation(false)

        {
          data.msg && toast.error(data.msg)
        }
      },
    })
  return (
    <div>
      <DashboardWrapper>
        <div className="bg-white font-avenir rounded-5px p-4 sm:p-[30px]">
          <div className="flex flex-col sm:flex-row items-start gap-2 sm:items-center w-full justify-between  mb-3">
            <div className="flex items-center gap-3">
              <ArrowLeftIcon
                className="  w-5 h-5"
                onClick={() => handlePrevious()}
              />
              <h6 className=" text-neutral700  font-extrabold">
                {reviewRoundsList?.qualified_description}
              </h6>
            </div>
            <div className="flex items-center gap-2">
              {isPartOfReview && (
                <Button
                  title="refresh"
                  color="primary"
                  onClick={() => {
                    mixPanelEvent("empl-pf-review-launch-success")

                    isPartOfReview?.employee_review_key && revieweesRoundsList
                      ? handleUserClick(
                          revieweesRoundsList?.data[0]?.review_entity_key,
                          isPartOfReview?.person_key
                        )
                      : handleUserClick(
                          isPartOfReview?.review_entity_key,
                          isPartOfReview?.person_key
                        )
                  }}
                >
                  Launch Review
                  <ChevronDoubleRightIcon className="w-4 h-4" />
                </Button>
              )}
            </div>
          </div>
          <div className="flex gap-4 mb-3">
            <h6 className="text-neutral500 text-sm ">
              {reviewRoundsList?.start_date}-{reviewRoundsList?.due_date}
            </h6>
            {/* <button> All Teams</button>{" "} */}
          </div>
          <div className="h-10 grid grid-cols-2 my-4 border-b">
            {["Reviewees", "Reviewers"].map((tab, index) => (
              <button
                key={index}
                className={`  py-2 text-sm font-medium text-subText3  px-3 ${
                  activeReviewType == tab &&
                  "border-primary500 border-b-2 !text-primary500 font-semibold"
                }`}
                onClick={() => {
                  setActiveReviewType(tab)
                }}
              >
                {tab}
              </button>
            ))}
          </div>
          {activeReviewType == "Reviewees" ? (
            <div className="">
              {isRevieweesRoundsLoading ? (
                <div className="h-[200px] py-5 flex justify-center items-center">
                  <LoadingIndicatorWhite />
                </div>
              ) : teams.length ? (
                teams.map((team: IReviewRoundsReviewees[], index: any) => (
                  <TeamCard
                    key={index}
                    // id={team.id}
                    title={team[0].department}
                    employees={team}
                    active={activeId == team[0].department ? true : false}
                    handleClick={handleClick}
                    handleUserClick={handleUserClick}
                    handleRemoveAssignee={handleRemoveAssignee}
                  />
                ))
              ) : (
                <div className="h-40  flex justify-center items-center">
                  <h6 className="text-neutral-500">No available reviewees</h6>
                </div>
              )}
            </div>
          ) : (
            <div className="">
              {isReviewersRoundsLoading ? (
                <div className="h-[200px] py-5 flex justify-center items-center">
                  <LoadingIndicatorWhite />
                </div>
              ) : teamsReviewers.length ? (
                teamsReviewers.map(
                  (team: IReviewRoundsReviewers[], index: any) => (
                    <TeamCard
                      key={index}
                      // id={team.id}
                      title={team[0].department}
                      employees={team}
                      active={activeId == team[0].department ? true : false}
                      handleClick={handleClick}
                      handleUserClick={handleUserClick}
                      handleRemoveAssignee={handleRemoveAssignee}
                    />
                  )
                )
              ) : (
                <div className="h-40  flex justify-center items-center">
                  <h6 className="text-neutral-500">No available reviewers</h6>
                </div>
              )}
            </div>
          )}
        </div>
      </DashboardWrapper>
      {openRemoveConfirmation && (
        <NewModalWrapper
          title=""
          onClose={() => {
            setOpenRemoveConfirmation(false)
            setActiveRemoveId({
              name: "",
              id: "",
            })
          }}
        >
          <div className=" flex flex-col items-center">
            <div>
              <InformationCircleIcon
                width={70}
                height={70}
                className="text-[#FAB941]"
              />
            </div>

            <h6 className="text-[#15294B]  font-bold text-2xl my-3">
              Remove {activeRemoveId.name}
            </h6>
            <p className="text-[#15294B] text-sm mb-6 text-center">
              If you remove {activeRemoveId.name} from this review round, all
              references to them will be removed and any responses saved by/for
              them will be deleted. This action can’t be undone. Provide reason
              to continue.
            </p>
          </div>
          <div className="px-5">
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please input your reason!",
                },
              ]}
            >
              <Input
                className="h-10 mb-5"
                placeholder="Reason"
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="border py-5 px-6 flex gap-4 ">
            <Button
              title="cancel"
              color="danger"
              className="w-full"
              onClick={() => {
                setOpenRemoveConfirmation(false)
                setActiveRemoveId({
                  name: "",
                  id: "",
                })
              }}
            >
              Cancel
            </Button>
            {isSubmittingRemove ? (
              <Button
                title="Next"
                color="primary"
                className="font-extrabold w-full"
              >
                <LoadingIndicatorWhite />
              </Button>
            ) : (
              <Button
                title="remove"
                color="primary"
                className="w-full"
                onClick={() => {
                  submitRemove(activeRemoveId)
                }}
              >
                Confirm
              </Button>
            )}
          </div>
        </NewModalWrapper>
      )}
    </div>
  )
}
export default ViewReviewDetailed
