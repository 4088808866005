import { useMutation } from "@tanstack/react-query"
import { EventCard } from "./EventCard"
import { axiosInstance } from "utils/request"
import { toast } from "react-toastify"

type eventProps = {
  eventArray?: any
}
export const EventTagsBirthday: React.FC<eventProps> = ({
  eventArray = [],
}) => {
  const today = new Date()
  const formattedToday = today.toLocaleString("en-us", {
    month: "short",
    day: "numeric",
  })

  const { isLoading, mutate: sendBirthdayCard } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `admin/employees/send_birthday_card?id=${body}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
      } else {
        toast.error(data?.msg)
      }
    },
  })

  const handleSendCard: HandleSendCardFunction = (person) => {
    sendBirthdayCard(String(person))
  }
  return (
    <>
      {eventArray?.length === 0 ? (
        <div className="flex mt-[100px] h-full items-center justify-center ">
          <h6 className="text-sm  text-neutral700">No Available Birthday</h6>
        </div>
      ) : (
        <div>
          {eventArray?.map((birthday: any, index: any) => (
            <EventCard
              key={index}
              title={birthday?.full_name}
              date={
                formattedToday === birthday?.date_of_birth_short
                  ? "Today"
                  : birthday?.date_of_birth_short
              }
              description={birthday?.employee_title}
              color="border-l-primary500"
              subtitle={
                formattedToday === birthday?.date_of_birth_short
                  ? "Send birthday card"
                  : ""
              }
              subtitleAction={
                formattedToday === birthday?.date_of_birth_short
                  ? () => handleSendCard(String(birthday.person_id))
                  : () => {}
              }
              img={birthday?.avatar_url}
            />
          ))}

          {/* <div className="flex justify-end">
            <p className="mt-1 text-xs  text-subText3">View All</p>
          </div> */}
        </div>
      )}
    </>
  )
}
type HandleSendCardFunction = (person: string | undefined) => void
