import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { Input } from "antd"
import React, { Dispatch, FC, useState, useTransition } from "react"

export const AppSearchInput: FC<{
  search: string
  handleSearch: Dispatch<string>
  className?: string
}> = ({ search, handleSearch, className }) => {
  const [transition] = useTransition()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Input
      placeholder="Search"
      prefix={<MagnifyingGlassIcon className="w-4 h-4 text-neutral-300" />}
      value={search}
      onChange={(e) => handleSearch(e.target.value)}
      className={className + " w-full lg:w-[280px]"}
      allowClear
      bordered
    />
  )
}
