import React, { Suspense, useEffect } from "react"
import { App, Layout, Spin } from "antd"
import { useState } from "react"
import { useAuthStore } from "stores"
import { Outlet } from "react-router-dom"
import { SideNavigationBar } from "./SideNavigationBar"
import { TopNavigationBar } from "./TopNavigationBar"
import { NotificationBar } from "./NotificationBar"
import { isPadeDomain } from "utils/utils"
import Intercom from "@intercom/messenger-js-sdk"

const { Header, Content } = Layout

const SuspendedExitedEmployeeLayout: React.FC = () => {
  const { user } = useAuthStore()
  const [isSidebarOpen, toggleSidebar] = useState(false)

  const { isAuthenticated, signOut } = useAuthStore((state) => state)

  useEffect(() => {
    if (!isAuthenticated) {
      signOut(true)
    }
  }, [isAuthenticated])

  isPadeDomain() === true &&
    Intercom({
      app_id: "bi604cch",
      user_id: user?.userId,
      name: user?.userFullName,
      email: user?.userName,
    })

  return (
    <App
      notification={{
        placement: "topRight",
      }}
    >
      <Layout>
        <SideNavigationBar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />

        <Layout>
          <Header className="bg-white p-0 sticky top-0 z-10 w-full border-b md:pl-[280px] h-[70px]">
            <TopNavigationBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
          </Header>
          <Content className=" ">
            <div className="bg-[#F3F5FB] min-h-[100dvh] md:pl-[280px] font-avenir employee__layout">
              <Suspense fallback={<Loading />}>
                <Outlet />
              </Suspense>
              {/* <Dashboard /> */}
            </div>
          </Content>
        </Layout>
      </Layout>

      <NotificationBar />
    </App>
  )
}

export default SuspendedExitedEmployeeLayout

function Loading() {
  return (
    <div
      className="fixed right-0 top-20 bottom-0 left-0 md:left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-white"
      style={{
        zIndex: 9999999,
      }}
    >
      <Spin />
    </div>
  )
}
