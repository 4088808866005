import React, { useRef, useState } from "react"
import { useGet } from "utils/useFetch"
import { useLocation } from "react-router-dom"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { Button } from "components"
import ReactToPrint from "react-to-print"
import ComponentToPrint from "pages/employee-dashboard/payslip/component/ComponentToPrint"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import EmailPayslipModal from "./component/EmailPayslipModal"

const EmployeeViewPayslip: React.FC<{}> = () => {
  const myParam = useLocation().search

  const x = new URLSearchParams(myParam).get("x")

  const id = new URLSearchParams(myParam).get("id")
  const componentRef: any = useRef()
  const [showEmailModal, setShowEmailModal] = useState(false)
  const { isLoading: isPayslipLoading, data } = useGet<any>({
    url: `/payroll_items/view/payslip_admin?id=${id}&x=${x}`,
  })

  return (
    <DashboardWrapper>
      <div className="relative ">
        <div className="flex items-center justify-between mb-8">
          <h6 className=" text-bluesubtext text-lg font-medium">
            Employee Payslip
          </h6>

          <div className="flex justify-center items-center gap-4">
            {data && (
              <Button
                color="primary"
                title="Download"
                onClick={() => {
                  setShowEmailModal(true)
                }}
              >
                Email
              </Button>
            )}
            {data && componentRef && (
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="primary"
                    title="Download"
                  >
                    Download
                  </Button>
                )}
                content={() => componentRef.current}
                documentTitle={`${data?.download_title}-payslips.pdf`}
              />
            )}
          </div>
        </div>
        <>
          {isPayslipLoading ? (
            <div className="h-screen flex w-full justify-center items-center">
              <LoadingIndicatorWhite />
            </div>
          ) : (
            <ComponentToPrint
              ref={componentRef}
              data={data}
            />
            // <div
            //   className=" pb-40"
            //   id="payslip"
            // >
            //   <PayslipTemplate data={data} />
            // </div>
          )}
        </>
        {showEmailModal && (
          <EmailPayslipModal
            payrollId={id || ""}
            personId={data?.person_id}
            closeModal={() => setShowEmailModal(false)}
          />
        )}
      </div>
    </DashboardWrapper>
  )
}
export default EmployeeViewPayslip
