import { InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline"
import { Form, Input, Spin } from "antd"
import React, { useState } from "react"
import { Button, ModalWrapper } from "components"
import { CustomSelect } from "pages/dashboard/documents/custom-form"
import FormSectionWrapper from "pages/employee-dashboard/FormSectionWrapper"
import { ReviewTemplateQuestion } from "../../Templates/interface/template"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { TemplateCard } from "../../Templates/components/TemplateCard"
import getIdFromKey from "utils/getIdFromKey"
import { SurveyQuestionCard } from "./SurveyQuestionCard"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"

export const allResponseType = [
  {
    id: "1",
    title: "Rating scale",
  },
  {
    id: "2",
    title: "Text box",
  },
  {
    id: "3",
    title: "Text box and Rating scale",
  },
]
export const allCoreValues = [
  {
    id: "1",
    title: "Honesty",
  },
  {
    id: "2",
    title: "Integrity",
  },
  {
    id: "3",
    title: "Respect",
  },
  {
    id: "4",
    title: "Humility",
  },
]

export const AddSurveyQuestion: React.FC<{
  showNew: boolean
  setShowNew: Function
  questions: ReviewTemplateQuestion[] | any[]
  invalidateQueries: Function
  x?: string | null
  isLoading: boolean
}> = ({
  showNew,
  setShowNew,

  questions,
  invalidateQueries,
  isLoading,
  x,
}) => {
  const [form] = Form.useForm()
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeletId, setActiveDeleteId] = useState("")
  const [activeQuestion, setActiveQuestion] = useState({
    question_title: "",
    question: "",
    type_id: "",
    type: "",
    value_id: "",
    value: "",
  })

  const { isLoading: isLoadingAddQuestion, mutate: submitAddQuestion } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/surveys/add_question?id=${getIdFromKey(
            x || ""
          )}&description=${activeQuestion.question_title}&alias=${
            activeQuestion.question
          }&response_type_id=${activeQuestion.type_id}&response_option=${
            activeQuestion.type
          }`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data) {
          invalidateQueries()
          setShowNew(false)
          setActiveQuestion({
            question_title: "",
            question: "",
            type_id: "",
            type: "",
            value_id: "",
            value: "",
          })
        } else {
        }
      },
    })
  const handleAddQuestions = () => {
    submitAddQuestion()
  }
  const handleChange = (value: any) => {
    console.log(value)

    let owner = value.split("-")
    let title = owner[0]
    let id = owner[1]

    setActiveQuestion({
      ...activeQuestion,
      type_id: id,
      type: title,
    })
  }
  const handleChangeValues = (value: any) => {
    let owner = value.split("-")
    let title = owner[0]
    let id = owner[1]

    setActiveQuestion({
      ...activeQuestion,
      value_id: id,
      value: title,
    })
  }

  const { isLoading: isdeleting, mutate: deleteTempQuestion } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/surveys/delete_question?id=${activeDeletId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        setOpenDeleteConfirmation(false)
        setActiveDeleteId("")
        invalidateQueries()
        setShowNew(false)
        setActiveQuestion({
          question_title: "",
          question: "",
          type_id: "",
          type: "",
          value_id: "",
          value: "",
        })
      }
    },
  })

  const handleOpenDelete = (id: any) => {
    console.log(id)

    setOpenDeleteConfirmation(true)
    setActiveDeleteId(id)
  }

  return (
    <div className="flex  flex-col  gap-1 px-4 md:px-6 xl:px-[60px]">
      <h6 className=" text-neutral700  mb-2 text-md">Survey questions</h6>
      <div className="flex flex-col gap-4">
        {isLoading ? (
          <div className="w-full flex  justify-center">
            <LoadingIndicatorWhite />
          </div>
        ) : (
          questions?.map((quest, index) => (
            <SurveyQuestionCard
              id={quest.survey_question_id}
              key={index}
              question_title={quest.description}
              question={quest.alias}
              type_id={String(quest?.review_response_type_id)}
              type={quest?.response_options}
              handleOpenDelete={handleOpenDelete}
              invalidateQueries={invalidateQueries}
            />
          ))
        )}
      </div>
      {showNew && (
        <div className=" border border-neutral30 py-6 px-3 rounded-5px mt-4">
          <div className=" bg-[#f3f5fbcf] p-2 rounded-5px h-auto border border-neutral30 flex flex-col justify-between gap-2 ">
            <Input
              type={"text"}
              name={""}
              // id="mobile-number"
              // required={true}
              placeholder="Title"
              value={activeQuestion.question_title}
              onChange={(e) => {
                setActiveQuestion({
                  ...activeQuestion,
                  question_title: e.target.value,
                })
              }}
              className="px-3  bg-transparent"
            />
            <Input
              type={"text"}
              name={""}
              // id="mobile-number"
              // required={true}
              placeholder="Question"
              value={activeQuestion.question}
              onChange={(e) => {
                setActiveQuestion({
                  ...activeQuestion,
                  question: e.target.value,
                })
              }}
              className="px-3 bg-transparent"
            />

            <div className="flex items-center justify-between w-full  mt-1">
              <div className=" flex gap-2  items-center">
                {" "}
                <CustomSelect
                  label=""
                  name="type"
                  value={activeQuestion?.type == "" ? null : ""}
                  onChange={handleChange}
                  className="bg-[#f3f5fbcf] w-[157px] mb-0"
                  options={allResponseType?.map((state) => ({
                    label: `${state.title}`,
                    value: `${state.title}-${state.id}`,
                  }))}
                  placeholder="Response type"
                />
                <CustomSelect
                  label=""
                  name="values"
                  style={{ backgroundColor: "#f3f5fbcf" }}
                  onChange={handleChangeValues}
                  className="bg-[#f3f5fbcf] w-[157px] mb-0"
                  options={allCoreValues?.map((state) => ({
                    label: `${state.title}`,
                    value: `${state.title}-${state.id}`,
                  }))}
                  placeholder="Core values"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-2 mt-2">
            <Button
              title="Previous"
              color="secondary"
              onClick={() => setShowNew(false)}
            >
              Cancel
            </Button>

            <Button
              title="Next"
              color="primary"
              submitting={isLoadingAddQuestion}
              disabled={
                activeQuestion.question_title == "" ||
                activeQuestion.type_id == "" ||
                activeQuestion.value_id == ""
                  ? true
                  : false
              }
              onClick={() => handleAddQuestions()}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {!showNew && (
        <button
          className="mt-6   text-sm text-primary500 flex items-center gap-2 mb-3"
          onClick={() => {
            setShowNew(!showNew)
          }}
        >
          <PlusIcon className="w-4 stroke-2" />
          <p>Add a question</p>
        </button>
      )}
      <NewModalWrapper
        open={openDeleteConfirmation}
        title="
              Delete Survey Question
          "
        onClose={() => {
          setOpenDeleteConfirmation(false)
          setActiveDeleteId("")
        }}
      >
        <div className="px-5 pt-3 flex flex-col">
          <p className="text-[#15294B] text-sm mb-6 ">
            If you delete this survey question,it would be removed from your
            list. Are you sure you want to continue?
          </p>
        </div>
        <div className="border py-5 px-6 flex gap-4 justify-end ">
          <Button
            color="secondary"
            onClick={() => {
              setOpenDeleteConfirmation(false)
              setActiveDeleteId("")
            }}
          >
            Cancel
          </Button>

          <Button
            submitting={isdeleting}
            title="remove"
            color="primary"
            onClick={() => {
              deleteTempQuestion()
            }}
          >
            Confirm
          </Button>
        </div>
      </NewModalWrapper>
    </div>
  )
}
