import { useState } from "react"
import { Button } from "components"
import { CustomInput } from "components/new-stuff/form"
import { Form, Radio, Select, Spin, Tooltip } from "antd"
import { useGet, usePost } from "utils/useFetch"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { queryClient } from "index"

import Global from "assets/svg/countries/Global.svg"
import { ICurrencies } from "pages/dashboard/wallets/integration/interface/kyc"
import { ICountry } from "pages/employee-dashboard/typings"
import { AllBeneficiaryType } from "../crossBorder/viewcurrency/sendMoney/SingleSend"
import { IResolveBank } from "pages/dashboard/wallets/crossBorderPayments/viewcurrency/beneficiaries/interface"
import { useNavigate } from "react-router-dom"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

const CreateBeneficiary = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const [accountName, setAccountName] = useState("")
  const selectedBank = Form.useWatch("bank", form)
  const accountNumber = Form.useWatch("accountNumber", form)
  const currencyCode = Form.useWatch("currencyCode", form)
  const beneficiaryType = Form.useWatch("beneficiaryType", form)
  const transferMethod = Form.useWatch("transferMethod", form) ?? "local"

  const { isLoading: isCurrencyLoading, data: currencies } = usePost<
    ICurrencies[]
  >({
    url: "/commonutils/getcurrencies",
    body: {
      q: "",
      page: "",
    },
  })
  const { isLoading: isLoadingCountries, data: countries } = useGet<ICountry[]>(
    {
      url: `/commonutils/getcountriesbycurrencycode?code=${currencyCode}`,
    }
  )
  const { isLoading: isLoadingAllCountries, data: allCountries } = usePost<
    ICountry[]
  >({
    url: "/commonutils/getcountries",
  })
  const { isLoading: getBanksLoading, data: banksData } = useQuery<any>({
    queryKey: [`${walletBaseUrl}/v1/wallets/banks`],
    queryFn: async () => {
      const res = await axiosInstance.get(`${walletBaseUrl}/v1/wallets/banks`, {
        headers: {
          api_key: walletPublicKey,
        },
      })
      return res.data
    },

    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,

    onSuccess: (data: any) => {},
  })

  const { isLoading: getBankDetails, data: bankDetails } =
    useQuery<IResolveBank>({
      queryKey: [`${walletBaseUrl}/v1/wallets/resolve-account`],
      queryFn: async () => {
        const res = await axiosInstance.post(
          `${walletBaseUrl}/v1/wallets/resolve-account`,
          {
            accountNumber: accountNumber,
            bankCode: selectedBank,
            currency: "NGN",
          },

          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },
      enabled: selectedBank !== "" && accountNumber?.toString().length === 10,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,

      onSuccess: (data: any) => {
        if (data.success) {
          setAccountName(data?.data?.accountName)
        } else {
        }
      },
    })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res: any = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res
    },
    onSuccess: (data: any) => {
      if (data?.data?.success) {
        data?.data?.message && toast.success(data?.data?.message)
        navigate(-1)
        queryClient.invalidateQueries({
          queryKey: [
            `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transfer-beneficiaries`,
          ],
        })
      } else {
        data.message && toast.error(data.message)
      }
    },
    onError: (data: any) => {
      data.message && toast.error(data.response?.data?.message)
    },
  })
  const onFinish = (values: any) => {
    const {
      accountNumber,
      bankCode,
      beneficiaryCountryISO,
      beneficiaryType,
      beneficiaryFirstName,
      beneficiaryLastName,
      beneficiaryCompanyName,
    } = values
    let transferMethodSelected: string
    if (transferMethod === "local" && currencyCode === "USD") {
      transferMethodSelected = "RoutingNumber"
    } else if (
      transferMethod === "international" ||
      (transferMethod === "local" && !["USD", "GBP"].includes(currencyCode))
    ) {
      transferMethodSelected = "SwiftCode"
    } else if (transferMethod === "local" && currencyCode === "GBP") {
      transferMethodSelected = "SortCode"
    } else if (transferMethod === "local" && currencyCode === "INR") {
      transferMethodSelected = "IfcCode"
    } else {
      transferMethodSelected = "BankCode"
    }
    currencyCode === "NGN"
      ? submit({
          accountNumber: values?.account_number || "090404044593",
          bankCode: values?.bank,
          type: "bank",
          currency: currencyCode,
          // "userGroupId": "padeCompanyId"
        })
      : submit({
          accountNumber,
          bankCode,
          beneficiaryCountryISO,
          beneficiaryType,
          beneficiaryFirstName,
          beneficiaryLastName,
          beneficiaryCompanyName,
          type: "bank",
          currency: currencyCode,
          paymentMethod: transferMethodSelected,
        })
  }
  const isIntlCurrency = ["USD", "GBP", "CAD", "DKK"].includes(currencyCode)
  return (
    <DashboardWrapper>
      <div className=" flex flex-col items-start justify-start   gap-6 max-w-[800px] ">
        <SegmentWrapper
          // width={603}
          isActive={true}
          // ={() => {
          //       navigate(-1)

          // }}
          title="Add Beneficiary"
          headerClassName=" xl:!px-[40px]"
          bodyClassName="!pb-0"
          // closable
        >
          <div className="w-full   bg-white  rounded-md ">
            <div className="px-4 md:px-6 lg:px-8 xl:px-[40px] ">
              <p className="text-[#7A8699] text-sm mb-4">
                Save a beneficiary to quickly make transfers and eliminate
                unnecessary repeated actions.
              </p>
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{
                  transferMethod: "local",
                }}
              >
                <div className="w-full flex flex-col lg:grid grid-cols-2 gap-4">
                  <div className="col-span-2">
                    <Form.Item
                      label={
                        <span className="font-circular text-padeBlack text-sm">
                          Select currency{" "}
                        </span>
                      }
                      name="currencyCode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        loading={isCurrencyLoading}
                        options={currencies?.map((curr) => ({
                          label: (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <img
                                  src={curr?.flagUrl || Global}
                                  alt={curr?.code}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    marginRight: "8px",
                                    marginLeft: "8px",
                                  }}
                                />
                                <span>{curr.code}</span>
                              </div>
                            </span>
                          ),
                          value: curr?.code,
                        }))}
                      />
                    </Form.Item>
                  </div>
                  {currencyCode === "NGN" && (
                    <div>
                      <Form.Item
                        label={
                          <span className="text-padeBlack text-sm  font-circular ">
                            Bank name
                          </span>
                        }
                        name="bank"
                        rules={[
                          {
                            required: true,
                            message: "Please enter this field!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={getBanksLoading}
                          onChange={(value) => {
                            form.setFieldValue("account_number", "")
                            setAccountName("")
                          }}
                          options={banksData?.data?.map((item: any) => ({
                            label: item.name,
                            value: item.code,
                          }))}
                          filterOption={(input, option) => {
                            if (option) {
                              const value = (option?.label as string) || ""
                              return (
                                value
                                  ?.toLowerCase()
                                  ?.indexOf(input?.toLowerCase()) >= 0
                              )
                            } else {
                              return false
                            }
                          }}
                          placeholder=""
                        />
                      </Form.Item>
                    </div>
                  )}
                  {currencyCode === "NGN" && (
                    <CustomInput
                      label="Account number"
                      name="accountNumber"
                      type="number"
                      required
                      extra={
                        selectedBank !== "" &&
                        accountNumber?.toString().length === 10 &&
                        getBankDetails ? (
                          <div>
                            <Spin className="h-4 w-4" />
                          </div>
                        ) : (
                          <span className="text-xs">{accountName}</span>
                        )
                      }
                      onChange={() => setAccountName("")}
                    />
                  )}
                  {/* OTHERS */}
                  {currencyCode !== "NGN" && (
                    <div className="   col-span-2 flex flex-col lg:grid grid-cols-2 gap-4">
                      {isIntlCurrency && (
                        <div className="col-span-2">
                          <Form.Item
                            label={
                              <span className="font-circular text-padeBlack text-sm  ">
                                Payment method
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Please enter this field!",
                              },
                            ]}
                            name="transferMethod"
                          >
                            <Radio.Group defaultValue="local">
                              <Radio value="local">
                                <span className="flex items-center">
                                  Local Bank{" "}
                                  <Tooltip title="The beneficiary’s bank is located in domestic country of the currency. E.g US Dollar being sent to the USA.">
                                    {" "}
                                    <InformationCircleIcon className="h-4 w-4 ml-2" />{" "}
                                  </Tooltip>
                                </span>{" "}
                              </Radio>
                              <Radio value="international">
                                <span className="flex items-center">
                                  International Bank Transfer
                                  <Tooltip title="The beneficiary’s bank is located outside the domestic country of the currency. E.g US Dollar being sent to Nigeria.">
                                    {" "}
                                    <InformationCircleIcon className="h-4 w-4 ml-2" />{" "}
                                  </Tooltip>
                                </span>{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      )}
                      {/* BENEFICIARY TYPES */}

                      <Form.Item
                        label={
                          <span className="font-circular text-padeBlack text-sm  ">
                            Beneficiary type
                          </span>
                        }
                        name="beneficiaryType"
                        rules={[
                          {
                            required: true,
                            message: "Please enter this field!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          options={AllBeneficiaryType?.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          }))}
                          filterOption={(input, option) => {
                            if (option) {
                              const value = (option?.label as string) || ""
                              return (
                                value
                                  ?.toLowerCase()
                                  ?.indexOf(input?.toLowerCase()) >= 0
                              )
                            } else {
                              return false
                            }
                          }}
                          placeholder="Select"
                        />
                      </Form.Item>

                      {/* BENEFICIARY INDIVIDUAL INFO */}
                      {beneficiaryType === "individual" && (
                        <>
                          <CustomInput
                            label="First name"
                            name="beneficiaryFirstName"
                            required
                          />
                          <CustomInput
                            label="Last name"
                            name="beneficiaryLastName"
                            required
                          />
                        </>
                      )}
                      {/* BENEFICIARY COMPANY NAME */}
                      {beneficiaryType === "company" && (
                        <CustomInput
                          label="Company name"
                          name="beneficiaryCompanyName"
                          required
                        />
                      )}
                      {/*ACCOUNT NUMBER  */}

                      {!["EUR"].includes(currencyCode) &&
                      transferMethod === "local" ? (
                        <CustomInput
                          label={"Account number"}
                          name="accountNumber"
                          required
                        />
                      ) : null}

                      {transferMethod === "international" ||
                      (transferMethod === "local" &&
                        ["EUR"].includes(currencyCode)) ? (
                        <CustomInput
                          label="Account number/IBAN"
                          name="accountNumber"
                          required
                        />
                      ) : null}

                      {/* BANK CODES */}
                      {transferMethod === "local" && currencyCode === "USD" && (
                        <div>
                          <CustomInput
                            label="ABA Code/Routing number"
                            name="bankCode"
                            required
                          />
                        </div>
                      )}
                      {transferMethod === "international" ||
                      (transferMethod === "local" &&
                        !["USD", "GBP"].includes(currencyCode)) ? (
                        <CustomInput
                          label="Swift code"
                          name="bankCode"
                          required
                        />
                      ) : null}

                      {transferMethod === "local" && currencyCode === "GBP" && (
                        <CustomInput
                          label="Sort code"
                          name="bankCode"
                          required
                        />
                      )}
                      {transferMethod === "local" && currencyCode === "INR" && (
                        <CustomInput
                          label="IFCS"
                          name="bankCode"
                          required
                        />
                      )}

                      {/* // COUNTRYISO */}
                      <Form.Item
                        label={
                          <span className="text-padeBlack text-sm font-circular  ">
                            {" "}
                            Country
                          </span>
                        }
                        name="beneficiaryCountryISO"
                        rules={[
                          {
                            required: true,
                            message: "Please enter this field!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          loading={isLoadingCountries || isLoadingAllCountries}
                          options={
                            transferMethod === "international"
                              ? allCountries?.map((item) => ({
                                  label: item?.description,
                                  value: item?.alpha2Code,
                                }))
                              : countries?.map((item) => ({
                                  label: item?.description,
                                  value: item?.alpha2Code,
                                }))
                          }
                          filterOption={(input, option) => {
                            if (option) {
                              const value = (option?.label as string) || ""
                              return (
                                value
                                  ?.toLowerCase()
                                  ?.indexOf(input?.toLowerCase()) >= 0
                              )
                            } else {
                              return false
                            }
                          }}
                          placeholder="Select country"
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>

                <div className="flex justify-end w-full gap-6 mt-2  py-3 bg-white border-t shadow-footer  items-center">
                  <Button
                    color="secondary"
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isLoading}
                    // disabled={isLoading || accountName === ""}
                    submitting={isLoading}
                    // onClick={handleAddBeneficiary}
                  >
                    Save{" "}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </SegmentWrapper>
      </div>
    </DashboardWrapper>
  )
}

export default CreateBeneficiary
