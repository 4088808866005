import { Form, Radio, Space } from "antd"
import React, { useState } from "react"
import { Button, DashboardContainer } from "components"
import FormSectionWrapper from "../FormSectionWrapper"
import { usePost } from "utils/useFetch"
import { AntDesignInput } from "components/antd/AntDesignInput"
import TextArea from "antd/es/input/TextArea"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore } from "stores"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { CustomSelect } from "components/new-stuff/form"

const NewCustomField: React.FC<{}> = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const [form] = Form.useForm()
  const [errorMessage, setErrorMessage] = useState("")
  const ResponseType = Form.useWatch("response", form)
  const group = Form.useWatch("group", form)

  const reqBody = {
    q: "",
    page: "",
  }
  const { isLoading: isGroupLoading, data: groups } = usePost<ICustomGroup[]>({
    url: `/admin/custom_fields/getgroups`,
    body: reqBody,
  })

  const { isLoading: isLoadingCheck, mutate: check } = useMutation({
    mutationFn: async (body: string) => {
      const res = await axiosInstance.get(
        `/admin/custom_fields/check?description=${body}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
      } else {
        setErrorMessage("Custom field name is already taken")
        toast.error(`Custom field name is already taken`)
      }
    },
  })
  const { isLoading: isLoadingCheckGroup, mutate: checkGroup } = useMutation({
    mutationFn: async (body: string) => {
      const res = await axiosInstance.get(
        `/admin/custom_fields/checkgroup?description=${body}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
      } else {
        setErrorMessage("Custom field group name is already taken")
        toast.error(`Custom field group name is already taken`)
      }
    },
  })
  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`/admin/custom_fields/select`],
    })
  }

  const { isLoading: isLoading, mutate: submitCustom } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(`/admin/custom_fields/add`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        mixPanelEvent("settings-customization-custom-fields-add-success")
        {
          data.msg && toast.success(data.msg)
        }
        navigate("/dashboard/settings/customization/custom-fields")
        invalidateQueries()
      } else {
        toast.error(data.msg)
      }
    },
  })
  const onFinish = (values: any) => {
    console.log(values)

    const { group, name, helperText, group_name, who, response, options } =
      values

    const body = {
      description: name,
      alias: helperText,
      custom_field_group_id: group,
      custom_field_group: group_name || "",
      response_type_id: response,
      response_options: options || "",
      is_admin: who,
    }
    console.log(body)

    mixPanelEvent("settings-customization-custom-fields-add-init")
    submitCustom(body)
  }
  return (
    <DashboardContainer>
      <FormSectionWrapper
        name=" Create New Custom Field"
        title=" Create New Custom Field"
        subtitle="Add a custom field to store any info you like on your employees’ profiles."
        isActive={true}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        style={{ maxWidth: 1024 }}
        autoComplete="off"
        footerBtns={
          <div className="flex justify-end items-center gap-4">
            <Button
              color="secondary"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              title="save"
              submitting={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className=" lg:grid lg:grid-cols-2 gap-x-10  px-4 md:px-6 xl:px-[60px]">
          <div className={`${group == 0 ? " col-span-1" : " col-span-2"}`}>
            <CustomSelect
              label="Group"
              name="group"
              required
              isLoading={isGroupLoading}
              options={
                groups?.map((item) => ({
                  label: item.description,
                  value: String(item.customFieldGroupId),
                })) || []
              }
              placeholder="Please select"
              addBottomSpace
              // required
            />
          </div>
          {group == 0 && (
            <div>
              <AntDesignInput
                label="New group"
                name="group_name"
                required={true}
                placeholder="Ex. General Information"
                onBlur={(e) => {
                  checkGroup(e.target.value)
                }}
              />
            </div>
          )}
          <div className="col-span-2">
            <AntDesignInput
              label="Name"
              name="name"
              required={true}
              placeholder="Ex. Genotype"
              onBlur={(e) => {
                check(e.target.value)
              }}
            />
          </div>
          <Form.Item
            required
            className="col-span-2"
            name="helperText"
            label={
              <span className="font-circular text-neutral300 text-sm ">
                Helper text
              </span>
            }
          >
            <TextArea
              rows={3}
              placeholder=""
            />
          </Form.Item>

          <AntDesignRadio
            label="Who should fill out this field?"
            required
            name="who"
          >
            <Space direction="vertical">
              <Radio value="yes"> Admins </Radio>
              <Radio value="no"> Employees </Radio>
            </Space>
          </AntDesignRadio>
          <AntDesignRadio
            label="How should this field be filled?"
            required
            name="response"
          >
            <Space direction="vertical">
              <Radio value="1"> Dropdown </Radio>
              <Radio value="2"> Textbox </Radio>
              <Radio value="3"> File upload </Radio>
            </Space>
          </AntDesignRadio>

          {ResponseType == 1 && (
            <Form.Item
              required
              className="col-span-2"
              name="options"
              label={
                <span className="font-circular text-neutral300 text-sm ">
                  Options
                </span>
              }
            >
              <TextArea
                rows={3}
                placeholder="Separate options with a semicolon(;). Example: Yes;No"
              />
            </Form.Item>
          )}
        </div>
      </FormSectionWrapper>
    </DashboardContainer>
  )
}
export default NewCustomField

export interface ICustomGroup {
  customFieldGroupId: number
  description: string
  createdDate: null
  createdBy: null
  updatedDate: null
  updatedBy: null
  recordGuid: null
  recordVersion: null
  isDeleted: boolean | null
  isActive: boolean | null
  createdByName: null
  updatedByName: null
  companyId: null
  company: null
  customFields: any[]
}
