import { formatCurrencyNoSymbol } from "utils/currency"
import { INSITFSchedule } from "./NSITFReportToPrint"

const NSITFRow: React.FC<{
  item: INSITFSchedule
}> = ({ item }) => {
  return (
    <div className={`py-3 flex-grow   grid-cols-3 grid gap-[6px] w-full `}>
      <div className="">
        <span className="flex-wrap  font-avenir   text-sm text-[#42526D]">
          {item.full_name}
        </span>
      </div>
      <div className="">
        <p className=" flex-wrap break-words font-avenir   text-sm text-[#42526D]">
          {item.identification_number}
        </p>
      </div>
      <div className="">
        <span className="flex-wrap break-words font-avenir   text-sm text-[#42526D]">
          ₦{formatCurrencyNoSymbol(Number(item.amount))}
        </span>
      </div>
    </div>
  )
}
export default NSITFRow
