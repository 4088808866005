import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { APP_ID } from "utils/constants"
import { request } from "utils/request"
import { IFilterOptions } from "stores/interface"

interface IntegrationState {
  // integration: IIntegration[]
  integration: number
  isLoading: boolean
}

export interface IIntegration {
  status: string
  msg: string
  pagi: string
  record: string
  data: number
}

interface IntegrationMethods {
  // getIntegrationStatus: (params: IFilterOptions) => Promise<IIntegration[]>
  getIntegrationStatus: (params: IFilterOptions) => Promise<any>
}

export const useIntegrationStore = create<
  IntegrationState & IntegrationMethods
>()(
  devtools(
    persist(
      (set, get) => ({
        integration: 3,
        isLoading: false,
        isError: false,
        isSearching: false,
        getIntegrationStatus: async (params) => {
          set({
            isLoading: true,
          })

          try {
            const req = await request.get({
              url: "admin/company/getdirectdepositintegrationstatus",
            })
            set({
              integration: req.data,
            })

            set({
              isLoading: false,
            })

            return req.data
          } catch (error) {
            console.warn(error)
            set({
              integration: 3,
            })
            return []
          }
        },
      }),
      {
        name: `${APP_ID}.accounts`,
      }
    )
  )
)
