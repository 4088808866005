import React, { forwardRef } from "react"
import PensionRow from "./PensionRow"
import PadeLogo from "assets/logos/paidhr-logo.svg"
import { formatCurrencyNoSymbol } from "utils/currency"
import ImageComponent from "components/custom/image"
import { useAuthStore } from "stores"
interface PensionSchedule {
  pfa_name: string
  pfa_code: string
  rsa_pin: string
  full_name: string
  employer_contribution: number
  employee_contribution: number
  voluntary_contribution: number
  currency_symbol: string
}
const PensionReportToPrint = forwardRef(({ data }: any, ref: any) => {
  const { user } = useAuthStore()

  const groupPensionSchedulesByPfaName = (
    pensionSchedules: PensionSchedule[]
  ) => {
    const groupedPensionSchedules: { [key: string]: PensionSchedule[] } = {}

    pensionSchedules.forEach((schedule) => {
      if (!groupedPensionSchedules[schedule.pfa_name]) {
        groupedPensionSchedules[schedule.pfa_name] = []
      }
      groupedPensionSchedules[schedule.pfa_name].push(schedule)
    })

    return groupedPensionSchedules
  }
  const groupedPensionSchedules = groupPensionSchedulesByPfaName(
    data?.pensionSchedules || []
  )

  return (
    <div
      ref={ref}
      className="bg-white shadow-card  py-[25px]  px-4 lg:px-[35px]"
    >
      <div className=" flex justify-between w-full items-center mb-6">
        <div>
          {" "}
          <h4 className="text-neutral700 font-bold  text-2xl mb-4">
            {data?.scheduleDate}
          </h4>
          <p className=" text-neutral500 text-sm leading-snug">
            Report created on: {data?.report_date}
          </p>
        </div>
        <div className=" mt-1">
          <ImageComponent
            src={user?.organisationAvatarFilename ?? PadeLogo}
            alt="org"
            className={` w-[200px] h-[100px] max-h-[200px] `}
          />
        </div>
      </div>
      <div className="mb-9 h-[117px] border-b bg-primary50 px-4 lg:px-[50px] flex justify-between items-center py-5">
        <div className=" gap-4 flex items-center">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {/* {user?.userOrganisation} */}
            </h5>
            <p className=" text-neutral500 font-semibold text-lg leading-snug">
              Pension Schedule Report
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              Employer code: {data?.pensionReferenceNumber}
            </p>
          </div>
        </div>
        <div className="">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {data?.full_name}
            </h5>
            <p className=" text-neutral500 text-sm leading-snug">
              {data?.officeAddress?.address_line_1}
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              {data?.officeAddress?.address_city},{" "}
              {data?.officeAddress?.address_state}
            </p>
          </div>
        </div>
      </div>

      <div className=" flex flex-col gap-4">
        <div className="border-b border-black  py-4  grid grid-cols-11  items-center gap-4 ">
          <div className="col-span-2 flex-wrap   font-avenir   text-sm text-[#42526D] font-black ">
            PFA
          </div>
          <div className=" col-span-9  flex flex-col gap-1">
            <div className=" grid grid-cols-6 ">
              <span className="flex-wrap break-words   font-avenir   text-sm text-[#42526D] font-black">
                Employee Name
              </span>
              <span className="flex-wrap break-words   font-avenir   text-sm text-[#42526D] font-black">
                RSA PIN
              </span>
              <span className="flex-wrap   font-avenir break-words   text-sm text-[#42526D] font-black">
                Employer
              </span>
              <span className="flex-wrap   font-avenir break-words   text-sm text-[#42526D] font-black">
                Employee
              </span>
              <span className="flex-wrap   font-avenir break-words   text-sm text-[#42526D] font-black">
                Voluntary
              </span>
              <span className="flex-wrap   font-avenir break-words   text-sm text-[#42526D] font-black">
                Total
              </span>
            </div>
          </div>
        </div>
        {Object.keys(groupedPensionSchedules).map((pfaName) => (
          <PensionRow
            pfaName={pfaName}
            groupedPensionSchedules={groupedPensionSchedules}
          />
        ))}
        <OverallTotal groupedPensionSchedules={groupedPensionSchedules} />
      </div>
    </div>
  )
})

const OverallTotal: React.FC<{
  groupedPensionSchedules: any
}> = ({ groupedPensionSchedules }) => {
  const overallTotal: any = Object.values(groupedPensionSchedules)
    .flat()
    .reduce(
      (total: any, schedule: any) =>
        total +
        Number(schedule.employer_contribution) +
        Number(schedule.employee_contribution),
      0
    )

  return (
    <div className="border-b mt-2 border-black py-4 grid grid-cols-9 items-center gap-4">
      <div className="col-span-2 flex-wrap font-avenir text-sm text-[#42526D] font-black"></div>
      <div className="col-span-7 flex flex-col gap-2">
        <div className="grid grid-cols-6 gap-2">
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
            Overall total
          </span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
            ₦{formatCurrencyNoSymbol(overallTotal)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default PensionReportToPrint
interface Schedule {
  full_name: string
  employer_contribution: number
  employee_contribution: number
}
