import React, { useState } from "react"
import { TopActionBar } from "../../../../components"
import { imagePrefix } from "utils/imagePrefix"
import { Table } from "antd"
import { ResponsiveSegmentWrapper } from "components/custom/SegmentWrapper"
import { DashboardContainer } from "components"
import ImageComponent from "components/custom/image"
import { useQuery } from "@tanstack/react-query"
import { IGetMangers } from "../../extras/settings/permissions/typings"
import { axiosInstance } from "utils/request"
import { useAuthStore } from "stores"
import { AppSearchInput } from "components/inputs/AppSearchInput"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

async function fetchManagers() {
  const res = await axiosInstance.post("/admin/company/selectmanagers", {
    start: "1",
    length: "100000",
  })
  return res.data
}

const Managers: React.FC = () => {
  const { user } = useAuthStore()
  const [search, setSearch] = useState("")

  const {
    isLoading: isSetupDataLoading,
    data,
    isPreviousData,
    refetch,
  } = useQuery<IGetMangers>({
    queryKey: ["/admin/company/selectmanagers"],
    queryFn: () => fetchManagers(),
    refetchOnWindowFocus: false,
    onSuccess: () => {
      mixPanelEvent(`people-managers-listing`)
    },
  })

  const TableData =
    data?.data
      .filter((person) => {
        const fullName = `${person.first_name} ${person.last_name}`
        return fullName.toLowerCase().includes(search.toLowerCase())
      })
      .map((person) => ({
        key: person.person_key,
        name: {
          fname: person.first_name,
          lname: person.last_name,
          image: person.avatar_url,
        },
        title: person.employee_title,
      })) || []

  const TableColumns = [
    {
      title: "Employee",
      dataIndex: "name",
      render: (name: { fname: string; image: string; lname: string }) => (
        <div className="flex items-center gap-2 text-[#42526D] font-avenir text-sm">
          <span className="shrink-0">
            <ImageComponent
              className="w-8 h-8 rounded-full"
              src={name.image || imagePrefix}
              alt=""
            />
          </span>
          <span>{name.fname || "null"}</span>
          <span>{name.lname || "null"}</span>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (text: string) => (
        <span className="flex items-center gap-2 text-[#42526D] font-avenir text-sm">
          {text}
        </span>
      ),
    },
  ]

  return (
    <>
      <TopActionBar>
        <div className="w-full">
          <AppSearchInput
            search={search}
            handleSearch={(value) => setSearch(value)}
          />
        </div>
      </TopActionBar>

      <DashboardContainer>
        <ResponsiveSegmentWrapper
          title={`${user?.userOrganisation || "People"} Managers`}
          subtitle="People Managers oversee other employees at your Company. They manage
        their team’s requests, time tracking, and employee information."
          // subtitle="The table below shows all employees that have been designated as a manager."
        >
          <div className="w-full">
            <Table
              columns={TableColumns}
              dataSource={TableData}
              loading={isSetupDataLoading || isPreviousData}
              className="text-[#42526D] min-h-screen"
              pagination={{
                total: data?.total,
                defaultPageSize: 10,
              }}
              scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
            />
          </div>
        </ResponsiveSegmentWrapper>
      </DashboardContainer>
    </>
  )
}

export default Managers
