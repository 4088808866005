import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { ILoginResponse, useAuthStore } from "stores/auth"
import { useQuery } from "hooks/useQuery"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Dispatch, useState } from "react"
import { setUser } from "@sentry/react"
import { useAuthModalStore } from "stores/auth-modal"

export const useHandleLogin = (
  setShowVerifyOtpPrompt: Dispatch<boolean>,
  setTwoFALoginResponse: Dispatch<ILoginResponse>
) => {
  const { login } = useAuthStore()
  const [loading, setLoading] = useState(false)
  const {
    // open: openAuthModal,
    close: closeAuthModal,
    isOpen: isAuthModalOpen,
  } = useAuthModalStore()

  const query = useQuery(useLocation().search)

  const navigate = useNavigate()

  const handleLoginProcess = async (
    email?: string,
    password?: string,
    token?: string,
    code?: string,
    provider?: string
  ) => {
    setLoading(true)
    try {
      const response = await login(email, password, token, code, provider)

      if (response.status === "Success") {
        //successful login

        setUser({
          email: response.user?.userName,
          id: response.user?.userId,
          username: response.user?.userName,
        })

        if (isAuthModalOpen) {
          closeAuthModal()
        } else {
          if (
            query.get("redirect") &&
            !query.get("redirect")?.includes("login")
          ) {
            const redirectUrl = new URL(query.get("redirect") as string)
            const redirectPath = redirectUrl.pathname
            const redirectSearch = redirectUrl.search
            navigate(redirectPath + redirectSearch, {
              replace: true,
            })
          } else {
            //check if login user is admin or employee
            const userResponse = response.user

            if (userResponse?.isAdmin === "True") {
              if (
                userResponse.setupPercentage === "100" ||
                userResponse?.setupStatus === "Approved"
              ) {
                navigate("/dashboard")
              } else {
                navigate("/dashboard/company-onboarding")
              }
            } else {
              if (
                userResponse?.userStatusId === "2" ||
                userResponse?.userStatusId === "3"
              ) {
                navigate("/employee/susp-exit/payslips")
              } else if (userResponse.userStatusId === "1") {
                navigate("/employee/dashboard/")
              } else {
                navigate("/employee/dashboard/welcome")
              }
            }

            mixPanelEvent(
              "auth-sign-in",
              String(response?.user?.userName) || ""
            )
          }
        }
      } else if (response?.status === "TwoFactorRequired") {
        setShowVerifyOtpPrompt(true)
        setTwoFALoginResponse(response)
      } else {
        const errorMsg = response.message
          ? response.message
          : "Your email or password is not correct"

        if (response?.status !== "TwoFactorRequired") {
          toast.error(errorMsg)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return { handleLoginProcess, loading }
}
