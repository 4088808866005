import { useMutation } from "@tanstack/react-query"
import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React from "react"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"
import { axiosInstance } from "utils/request"

const EmailPayslipModal: React.FC<{
  closeModal: Function

  payrollId: string
  personId: string
}> = ({ closeModal, payrollId, personId }) => {
  const { toast } = useAppNotificationStore()

  const { isLoading: loadingChangeAdmin, mutate: submit } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/payrolls/email_single_payslip?id=${getIdFromKey(
          payrollId
        )}&x=${personId}`
      )

      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        toast.success(data.msg)
        closeModal()
      } else {
        toast.error(data.msg)
        closeModal()
      }
    },
  })

  return (
    <div>
      <CustomModal
        onCancel={() => {
          closeModal()
        }}
        open
        title="Email Employee"
        footer={
          <div className="flex justify-end">
            <Button
              color="neutral"
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                submit()
              }}
              disabled={loadingChangeAdmin}
              submitting={loadingChangeAdmin}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <div>
          <p className="text-[#15294B] text-sm mb-6 text-center">
            We will email employee with instructions on how to view their
            payslip. Click Confirm to continue.
          </p>
        </div>
      </CustomModal>
    </div>
  )
}
export default EmailPayslipModal
