import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { SetupMessageAction } from "./SetupMessageAction"
import { EventTagsBirthday } from "./EventTagsBirthday"
import { EventTagsAnniversaries } from "./EventTagsAnniversaries"
import { EventTagsLeaves } from "./EventTagsLeaves"
import { TaskTagsRoutine } from "./TaskTagsRoutine"
import { IDashboard } from "../typings"
import { useGet } from "utils/useFetch"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { newBaseUrl } from "utils/newbaseurl"
import { Carousel, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { isPadeDomain } from "utils/utils"

export const Actions = ({
  actionData,
  isActionDataLoading,
}: {
  actionData: IDashboard | undefined
  isActionDataLoading: boolean
}) => {
  const navigate = useNavigate()

  const [activeButtonTasks, setActiveButtonTasks] = useState("task-1")
  const [activeButtonEvents, setActiveButtonEvents] = useState("event-1")

  const { isLoading: isEventsLoading, data: eventsData } = useGet<IDashboard>({
    url: "/admin/view/dashboard_events",
    cacheTime: 24 * 60 * 60 * 1000,
  })
  const [setupMessages, SetsetupMessages] = useState<any>([])

  const { isLoading: isCampaignsLoading, data: campaignData } = useGet<any>({
    url: `${newBaseUrl}/v1/promotions?status=published`,
    external: true,
    enabled: isPadeDomain(),
  })

  useEffect(() => {
    let SetSubArray: any[] = []
    if (
      actionData?.person?.exitRequests &&
      actionData?.person?.exitRequests > 0
    ) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.exitRequests} exit requests to review.`,
          url: "/approval/requests/exits",
        },
      ]
    }
    if (
      actionData?.person?.leaveRequests &&
      actionData?.person?.leaveRequests > 0
    ) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.leaveRequests} leave request to review.`,
          url: "/approval/requests/leaves",
        },
      ]
    }
    if (
      actionData?.person?.loanRequests &&
      actionData?.person?.loanRequests > 0
    ) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.loanRequests} loan request to review.`,
          url: "/approval/requests/loans",
        },
      ]
    }
    if (actionData?.person?.payrolls && actionData?.person?.payrolls > 0) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.payrolls} payroll to review.`,
          url: "/approval/payrolls/records",
        },
      ]
    }
    if (
      actionData?.person?.payrollChanges &&
      actionData?.person?.payrollChanges > 0
    ) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.payrollChanges} payroll changes to review.`,
          url: "/approval/payrolls/changes",
        },
      ]
    }

    if (
      actionData?.person?.redeploymentRequests &&
      actionData?.person?.redeploymentRequests > 0
    ) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.redeploymentRequests} redeployment requests  to review.`,
          url: "/approval/requests/redeployment",
        },
      ]
    }
    if (
      actionData?.person?.salaryAdvanceRequests &&
      actionData?.person?.salaryAdvanceRequests > 0
    ) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.salaryAdvanceRequests} salary advance requests  to review.`,
          url: "/approval/requests/salary-advances",
        },
      ]
    }
    if (actionData?.person?.violations && actionData?.person?.violations > 0) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.violations} violations to review.`,
          url: "/approval/violations",
        },
      ]
    }
    if (
      actionData?.person?.suspensions &&
      actionData?.person?.suspensions > 0
    ) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.suspensions} suspensions to review.`,
          url: "/approval/disciplinary/suspensions",
        },
      ]
    }
    if (
      actionData?.person?.customPaymentSchedules &&
      actionData?.person?.customPaymentSchedules > 0
    ) {
      SetSubArray = [
        ...SetSubArray,
        {
          subtitle: `You have ${actionData?.person?.customPaymentSchedules} custom payment schedule(s) to review.`,
          url: "/approval/payment-schedules/custom",
        },
      ]
    }
    SetsetupMessages([...setupMessages, ...SetSubArray])
  }, [actionData])

  return (
    <div className="">
      {/* <h5 className="text-base font-semibold text-padeBlack font-circular   mb-4">
        Actions
      </h5> */}

      <div className="grid  sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5  xl:h-[390px]">
        <CustomCard
          title="Tasks"
          activeTabKey={activeButtonTasks}
          onTabChange={(key) => setActiveButtonTasks(key as string)}
          rootClassName="h-auto xl:h-[390px]"
          tabList={[
            {
              key: "task-1",
              tab: (
                <div className="max-h-[260px] overflow-y-auto  ">
                  <SetupMessageAction setupArray={setupMessages} />
                </div>
              ),
              label: "Actions",
            },
            {
              key: "task-2",
              tab: (
                <div className="max-h-[260px] overflow-y-auto  ">
                  <TaskTagsRoutine
                    taskArray={actionData?.person?.employeeRoutineTasks}
                  />
                </div>
              ),
              label: "Routine",
            },
            {
              key: "task-3",
              tab: (
                <div className="max-h-[260px] overflow-y-auto  ">
                  <TaskTagsRoutine
                    taskArray={actionData?.person?.employeeOnboardingTasks}
                  />
                </div>
              ),
              label: "Onboarding",
            },
          ]}
        />

        <CustomCard
          title="Events"
          activeTabKey={activeButtonEvents}
          rootClassName="h-auto xl:h-[390px]"
          onTabChange={(key) => setActiveButtonEvents(key as string)}
          tabList={[
            {
              key: "event-1",
              tab: (
                <div className="h-[270px]  overflow-y-auto ">
                  <EventTagsBirthday eventArray={eventsData?.birthdayPersons} />
                </div>
              ),
              label: "Birthdays",
            },
            {
              key: "event-2",
              tab: (
                <div className="h-[270px] overflow-y-auto ">
                  <EventTagsAnniversaries
                    eventArray={eventsData?.anniversaryPersons}
                  />
                </div>
              ),
              label: "Anniversaries",
            },
            {
              key: "event-3",
              tab: (
                <div className="h-[270px]  overflow-y-auto ">
                  <EventTagsLeaves eventArray={actionData?.leaveRequests} />
                </div>
              ),
              label: "Leaves",
            },
          ]}
          loading={isEventsLoading}
        />
        {isPadeDomain() === true && (
          <div className="w-full  h-auto xl:h-[390px] rounded-[5px] overflow-hidden">
            {isCampaignsLoading ? (
              <div className="flex-grow w-full h-full text-center flex  items-center justify-center text-base">
                <Spin
                  spinning={true}
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24 }}
                      spin
                    />
                  }
                />
              </div>
            ) : campaignData?.data?.docs?.length ? (
              <div className="h-full w-full">
                <Carousel
                  autoplay
                  effect="fade"
                  dots={{
                    className:
                      "!bottom-[25px] [&_button]:!rounded-full [&_button]:!w-[12px] [&_button]:!h-[12px] [&_button]:!bg-a11y",
                  }}
                  rootClassName=" h-full w-full "
                >
                  {campaignData?.data?.docs?.map((data: any, index: any) => {
                    if (
                      (data?.platform === "web" || data?.platform === "all") &&
                      data?.status === "published" &&
                      (data?.audience === "all" || data?.audience === "admin")
                    ) {
                      return (
                        <div
                          className="h-auto xl:h-[390px] w-full "
                          key={index}
                        >
                          <Link
                            className="h-full w-full"
                            to={data?.ctaLink ? data?.ctaLink : ""}
                            target="_blank"
                          >
                            <div
                              style={{
                                backgroundImage: `url(${data?.webImage})`,
                                backgroundSize: "110%",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",

                                height: "100%",
                              }}
                              className="h-full w-full cursor-pointer  "
                            >
                              <img
                                src={data?.webImage}
                                alt=""
                                style={{
                                  visibility: "hidden",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </div>
                          </Link>
                        </div>
                      )
                    } else {
                      return null
                    }
                  })}
                </Carousel>
              </div>
            ) : (
              <div>Your campaign will appear here</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
