import { useMutation } from "@tanstack/react-query"
import { Form, Input } from "antd"
import { Button } from "components"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import React, { useState } from "react"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
type Props = {
  onClose: () => void
  invalidateQueries: () => void
  open: boolean
  paymentId: string
}

export const RestorePayment = ({
  onClose,
  open,
  paymentId,
  invalidateQueries,
}: Props) => {
  const { toast } = useAppNotificationStore()
  const [comment, setComment] = useState("")
  const { isLoading: isSubmittingRestore, mutate: submitRestore } = useMutation(
    {
      mutationFn: async (body: string) => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/restore_payment_schedule_item?id=${paymentId}&comment=${body}`
        )
        return res.data
      },
      onSuccess: (data) => {
        console.log(data.status)
        if (data.status === "success") {
          onClose()

          {
            data.msg && toast.success(data.msg)
          }
          invalidateQueries()
        } else {
          onClose()
          {
            data.msg && toast.error(data.msg)
          }
        }
      },
      onError: (data: any) => {
        onClose()
        {
          data.msg && toast.error(data.msg)
        }
      },
    }
  )
  return (
    <NewModalWrapper
      open={open}
      title="Restore Payment"
      className="w-[750px]"
      onClose={onClose}
    >
      <div className="px-5 pt-3">
        <p className=" text-neutral300 mb-5 text-sm">
          Beneficiary will get paid if you restore this payment. Provide reason
          to continue.
        </p>
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: "Please input your reason!",
            },
          ]}
        >
          <Input
            className="h-10 mb-5"
            placeholder="Reason"
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="border py-5 px-6 flex gap-4 justify-end ">
        <Button
          title="cancel"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          title="remove"
          color="primary"
          submitting={isSubmittingRestore}
          // onClick={onFinish}
          disabled={comment == "" ? true : false}
          onClick={() => {
            submitRestore(comment)
          }}
        >
          Restore
        </Button>
      </div>
    </NewModalWrapper>
  )
}

export default RestorePayment
