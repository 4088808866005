/**
 * An optimized H2 component.
 *
 * @param props
 * @returns h2
 */
export const H2: React.FC<JSX.IntrinsicElements["h2"]> = (props) => {
  return (
    <h2
      {...props}
      className={`text-[29px] leading-[40px] font-circular ${props.className}`}
    >
      {props.children}
    </h2>
  )
}
