/**
 * An optimized text anchor link.
 * Pass the `title` props, else `⚠` is returned
 */
export const TextAnchor: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => {
  if (!props["title"]) return <span children={"⚠"} />
  return (
    <a
      aria-label={props.title}
      {...props}
      className={`text-center p-0 decoration-solid hover:underline active:underline focus:underline focus:outline-none focus:shadow-none disabled:opacity-50 ${props.className}`}
    >
      {props.children}
    </a>
  )
}
