import { Form, Select } from "antd"
import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React from "react"
import { availableFlags } from "../sampleData"
interface Props {
  onClose: () => void
  open: boolean
}
const RequestAccount = ({ onClose, open }: Props) => {
  const handleSubmit = () => {
    onClose()
  }
  return (
    <CustomModal
      title="Request For a Currency"
      onCancel={onClose}
      open={open}
      width={475}
      footer={
        <div className="  flex items-center gap-2   w-full">
          {/* <Button
            color="secondary"
            className="w-full"
            onClick={() => {
              onClose()
            }}
          >
            Cancel{" "}
          </Button> */}
          <Button
            color="primary"
            className="w-full"
            onClick={() => handleSubmit()}
            // submitting={loading}
            // disabled={loading }
          >
            Request Currency
          </Button>
        </div>
      }
    >
      <Form layout="vertical">
        <Form.Item
          label={
            <span className="font-circular text-neutral300 text-sm  ">
              Select a currency{" "}
            </span>
          }
          name="currency"
          rules={[
            {
              required: true,
              message: "Please enter this field!",
            },
          ]}
        >
          <Select
            showSearch
            // loading={isLoadingBeneficiaries}
            onChange={(value) => {}}
            options={availableFlags?.map((item) => ({
              label: (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={item.flag}
                    alt={`${item.flag} flag`}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <span>{item.code} Account</span>
                </span>
              ),
              value: item.code,
            }))}
            placeholder="Select a currency"
            filterOption={(input, option) =>
              option?.label?.props.children[1]?.props.children
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </CustomModal>
  )
}

export default RequestAccount
