import { InformationCircleIcon } from "@heroicons/react/24/outline"

import { Table, Tag, Tooltip } from "antd"
import { useAuthStore } from "stores"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { formatCurrencyNoSymbol } from "utils/currency"
import { TransRes } from "../interface"
import { useQuery } from "@tanstack/react-query"
import { ICurrenciesData } from "../../interface"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"
import { availableFlags } from "../../sampleData"
interface props {
  transactionsData: TransRes[] | undefined
  loading: boolean
}
const CrossBorderTransTable = ({ transactionsData, loading }: props) => {
  const { user } = useAuthStore()
  const { isLoading: isLoadingCurrencies, data: availableCurrencies } =
    useQuery<ICurrenciesData>({
      queryKey: [`${walletBaseUrl}/v1/misc/wallet-currencies`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/misc/wallet-currencies`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const TableData =
    transactionsData?.map((transaction: TransRes) => {
      const currencyInfo = availableFlags.find(
        (flag) => flag.code === transaction.currency
      )
      return {
        key: transaction?._id,
        date: {
          date: formatDateReadable(String(transaction?.createdAt)),
          time: formatTimeReadble(String(transaction?.createdAt)),
        },
        type: transaction.type,
        amount: {
          value: transaction.amount,
          code: transaction.type,
          symbol: currencyInfo?.symbol,
        },
        currency: {
          flag: currencyInfo?.flag,
          currency_code: transaction.currency,
        },
        before: `${currencyInfo?.symbol} ${formatCurrencyNoSymbol(
          Number(transaction?.previousBalance)
        )}`,
        after: `${currencyInfo?.symbol} ${formatCurrencyNoSymbol(
          Number(transaction?.newBalance)
        )}`,
        reference: transaction.reference,
        remark: transaction.description,

        status: transaction?.status,
      }
    }) || []
  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        filters:
          ["Debit", "Credit"]?.map((item) => ({
            text: item,
            value: item,
          })) || [],
        onFilter: (value: any, record: any) => {
          return record.type === value.toLowerCase()
        },
        render: (amount: { value: string; code: string; symbol: string }) => (
          <div
            className={`font-avenir text-sm ${
              amount.code === "debit" ? "text-danger500" : "text-green"
            }`}
          >
            <p>
              {amount?.symbol}
              {formatCurrencyNoSymbol(Number(amount.value))}
            </p>
          </div>
        ),
      },
      {
        title: "Currency",
        dataIndex: "currency",
        filters:
          availableCurrencies?.data?.map((item) => ({
            text: item.currency,
            value: item.currency,
          })) || [],
        onFilter: (value: any, record: any) => {
          return record?.currency?.currency_code === value
        },
        render: (currency: { flag: any; currency_code: any }) => (
          <div className="flex items-center gap-2">
            <div>
              <img
                src={currency?.flag}
                alt=""
                className="h-6 w-6"
              />
            </div>
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {currency?.currency_code}
            </span>
          </div>
        ),
      },
      {
        title: "Previous Balance",
        dataIndex: "before",
        render: (before: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] whitespace-nowrap`}
          >
            <p>{before}</p>
          </div>
        ),
      },
      {
        title: "New Balance",
        dataIndex: "after",
        render: (after: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] whitespace-nowrap`}
          >
            <p>{after}</p>
          </div>
        ),
      },
      {
        title: "Reference",
        dataIndex: "reference",
        render: (reference: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[150px] overflow-hidden`}
          >
            <p>{reference}</p>
          </div>
        ),
      },
      {
        title: "Description",
        dataIndex: "remark",
        render: (remark: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[170px] overflow-hidden`}
          >
            <p>{remark}</p>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (status: string) => (
          <div className="flex items-center ">
            <Tag
              color={
                status === "successful"
                  ? "success"
                  : status === "failed"
                  ? "error"
                  : status === "pending"
                  ? "warning"
                  : "default"
              }
            >
              {status}
            </Tag>
          </div>
        ),
      },
    ] || []

  return (
    <>
      <Table
        dataSource={TableData}
        columns={TableColumns}
        loading={loading}
        className="text-[#42526D]"
        pagination={{
          defaultPageSize: 20,
        }}
        scroll={{
          y: "calc(100vh - 300px)",
          x: "max-content",
        }}
      />
    </>
  )
}
export default CrossBorderTransTable
