import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

/**
 * An optimized button.
 * Pass the `title` props, else `⚠` is returned
 */
const NewButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    submitting?: boolean
    color?:
      | "primary"
      | "secondary"
      | "success"
      | "danger"
      | "danger-outline"
      | "neutral"
      | "neutral-outline"
    outline?: Boolean
  }
> = ({ disabled, title, submitting, color, outline, className, ...props }) => {
  return (
    <button
      disabled={disabled || submitting}
      className={` rounded-5px disabled:opacity-50 focus:outline-none focus:shadow-none disabled:cursor-not-allowed py-[7px]  px-2 sm:px-4 text-sm min-w-[106px] font-avenir
      ${
        color! === "primary"
          ? "bg-primary500 hover:bg-primary700 active:bg-primary800 focus:bg-primary800 text-white"
          : ""
      }
      ${
        color! === "secondary"
          ? "bg-neutral20 border border-neutral30 hover:bg-neutral30 active:bg-neutral30 focus:bg-neutral30 text-neutral300"
          : ""
      }
      ${
        color! === "success"
          ? "bg-success500 hover:bg-success700 active:bg-success800 focus:bg-success800 text-white"
          : ""
      }
      ${
        color! === "danger"
          ? "bg-danger500 hover:bg-danger700 active:bg-danger800 focus:bg-danger800 text-white"
          : ""
      }
        ${
          color! === "danger-outline"
            ? "box-border border-2 border-danger500 bg-white hover:bg-danger100 active:bg-danger100 focus:bg-danger100 active:border-danger800 focus:border-danger800 text-danger500"
            : ""
        }
        ${
          color! === "neutral"
            ? "bg-neutral20 border border-neutral30 hover:bg-neutral30 active:bg-neutral30 focus:bg-neutral30 text-neutral300"
            : ""
        }
        ${
          color! === "neutral-outline"
            ? "box-border border-2 border-neutral30 bg-transparent hover:bg-neutral30 hover:text-neutral300 text-neutral10"
            : ""
        }
      ${
        outline!
          ? "bg-transparent border border-neutral300 hover:border-[#9babc720] hover:bg-[#9babc720] active:bg-[#9babc720] focus:bg-[#9babc720] "
          : ""
      }

      ${className}
      `}
      onClick={(e) => {
        e.preventDefault()
        props.onClick?.(e)
      }}
      type={props.type || "button"}
      {...props}
    >
      <div className={`w-full h-full flex gap-2 justify-center items-center`}>
        {submitting && (
          <LoadingOutlined
            spin
            className="w-4 h-4 "
          />
        )}
        <>{props.children}</>
      </div>
    </button>
  )
}

export default NewButton
