import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import StarFilled from "assets/svg/star_filled.svg"
import StarUnFilled from "assets/svg/star_unfilled.svg"

import React, { useState, useEffect } from "react"

import {
  IReviewEmployeeData,
  IReviewEntityFeedbacks,
} from "../interface/listings"

import { useGet } from "utils/useFetch"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
export const Feedback: React.FC<{
  ratings: any[]
  updatedEntityKey: any
  questions: any[]
  employeeReviewId: string | number
  invalidateQueries: Function
  feedback: IReviewEntityFeedbacks[] | any[]
}> = ({
  questions,
  updatedEntityKey,
  ratings,
  employeeReviewId,
  invalidateQueries,
  feedback,
}) => {
  const { toast } = useAppNotificationStore()
  const [activeResponseId, setActiveResponseId] = useState("")

  const [activeResponses, setActiveResponses] = useState({
    text: "",
    rate: 3,
    dropdown: "",
  })
  const [updatedQuestions, setUpdatedQuestions] = useState<any[]>([])
  const [activeQuestion, setActiveQuestion] = useState("")
  const {
    isLoading: isFeedbackLoading,
    data: feedbackData,
    refetch,
  } = useGet<IReviewEmployeeData>({
    url: `/performance/view/review?id=feedback&x=${updatedEntityKey}`,
    enabled: !!updatedEntityKey,
  })
  const { isLoading: isReviewResponseLoading, mutate: saveFeedback } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.post(
          `/admin/performance/save_feedback_response`,
          body
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data) {
          refetch()
          mixPanelEvent("pf-review-feedback-add-success")
          {
            data.msg && toast.success(data.msg)
          }
        }
      },
      onError: (data: any) => {
        {
          data.msg && toast.error(data.msg)
        }
      },
    })

  useEffect(() => {
    if (feedbackData) {
      const questionsList =
        feedbackData?.reviewGroup?.reviewEntity?.reviewQuestions
      const answerList = feedbackData?.reviewGroup.reviewEntity?.reviewResponses
      const employeeReviews =
        feedbackData?.reviewGroup?.reviewEntity?.employeeReviews

      if (questionsList?.length) {
        if (answerList?.length) {
          const feedbackReview = employeeReviews.find(
            (emplo) => emplo.employee_review_type === "Feedback"
          )

          let foundFeedback: any
          if (feedbackReview) {
            foundFeedback = answerList.filter(
              (ans) =>
                ans.employee_review_id === feedbackReview.employee_review_id
            )
          }
          const updatedList = questionsList.map((ques, index) => {
            let found = answerList.filter(
              (ans) => ans.review_question_id === ques.review_question_id
            )

            found = found.filter((ans) => {
              return !foundFeedback.some(
                (feedbackItem: any) =>
                  feedbackItem.employee_review_id === ans.employee_review_id
              )
            })

            if (found) {
              const returningArray =
                feedbackData?.reviewGroup?.reviewEntity?.employeeReviews

              const updatedFound = found.map((item) => {
                const matchingEntry = returningArray.find(
                  (entry) =>
                    entry.employee_review_id === item.employee_review_id
                )

                if (matchingEntry) {
                  return {
                    ...item,
                    full_name: matchingEntry.full_name,
                    avatar_url: matchingEntry.avatar_url,
                  }
                }

                return item
              })

              return {
                response: updatedFound,
                ...ques,
              }
            } else {
              return {
                ...ques,
              }
            }
          })

          if (foundFeedback.length) {
            let updatedWithFeedback = updatedList.map((lis) => {
              let feedback = foundFeedback.find(
                (feedbackItem: any) =>
                  feedbackItem.review_question_id === lis.review_question_id
              )

              if (feedback) {
                return {
                  ...lis,
                  feedback: feedback,
                }
              } else {
                return lis
              }
            })

            setUpdatedQuestions(updatedWithFeedback)
          } else {
            setUpdatedQuestions(updatedList)
          }
        } else {
          setUpdatedQuestions(questionsList)
        }
      }
    }
  }, [feedbackData])

  const isNumericDescription = (description: any) => {
    return !isNaN(description)
  }
  const handleTextboxFunction = (questionId: number) => {
    mixPanelEvent("pf-review-feedback-add-init")
    const body = {
      employee_review_id: String(feedbackData?.employeeReviewId),
      review_question_id: String(questionId),
      response: activeResponses.text,
    }
    saveFeedback(body)
  }

  return (
    <div className="mt-8">
      {isFeedbackLoading ? (
        <div className="h-[200px] flex justify-center items-center py-5">
          <LoadingIndicatorWhite />
        </div>
      ) : (
        <div className=" flex flex-col gap-4">
          {feedbackData?.reviewGroup?.review_id ? (
            <div className="bg-white rounded-5px shadow-card">
              <h4 className="text-sm sm:text-base font-extrabold text-neutral700 my-4  px-4 sm:px-[22px]">
                Review questions
              </h4>
              <div className="border border-neutral50 w-full mb-[12px]" />
              {updatedQuestions?.length ? (
                <div className="pb-[43px] ">
                  {updatedQuestions.map((ques, index) => (
                    <div
                      key={index}
                      className=" px-4  sm:px-[22px] pb-5 flex gap-4 flex-col "
                    >
                      <div className="border-2 border-cardgrey rounded-lg bg-white py-4 px-3 sm:px-[30px]">
                        <div
                          className="flex items-center justify-between w-full  cursor-pointer "
                          onClick={() => {
                            if (activeQuestion == ques.review_question_id) {
                              setActiveQuestion("")
                            } else {
                              setActiveQuestion(ques.review_question_id)
                            }
                          }}
                        >
                          {" "}
                          <div>
                            <h6 className=" text-sm sm:text-base text-neutral600  font-bold">
                              {index + 1}. {ques?.description}
                            </h6>
                            <p className="text-xs sm:text-sm text-bluesubtext  mt-1">
                              {ques?.alias}
                            </p>
                          </div>
                          <div className="border  border-neutral50 rounded-full h-7 w-7 flex justify-center items-center text-center ">
                            {activeQuestion == ques.review_question_id ? (
                              <ChevronDownIcon className=" text-primary500  w-4 h-4" />
                            ) : (
                              <ChevronRightIcon className=" text-primary500  w-4 h-4" />
                            )}
                          </div>
                        </div>
                        {/* RESPONSE TYPES */}
                        {activeQuestion == ques.review_question_id && (
                          <>
                            {ques?.response?.length ? (
                              <>
                                {ques.review_question_id && (
                                  <div>
                                    {/* TEXT BOX AND RATING */}
                                    <>
                                      {(ques?.review_response_type_id == 4 ||
                                        ques?.review_response_type_id == 3) && (
                                        <div className="border-t">
                                          {/* RATING LEGEND */}
                                          <div className="border-2 rounded-5px  px-4 sm:px-[31px] py-3 bg-[#FAFAFA] mt-4">
                                            <h6 className="font-bold text-[#15294B] text-sm mb-1">
                                              Assessment legend
                                            </h6>
                                            <div className="  sm:grid grid-cols-5 w-full my-2 gap-5 ">
                                              {ratings?.length &&
                                                ratings?.map((rate, index) => (
                                                  <div
                                                    key={index}
                                                    className="w-full  text-center text-neutral400  sm:h-20   gap-1 flex flex-row sm:flex-col  justify-between sm:justify-evenly items-center py-2 sm:py-0"
                                                  >
                                                    <div className="flex items-center gap-1 sm:px-2 h-5">
                                                      {[1, 2, 3, 4, 5].map(
                                                        (
                                                          rate_inside,
                                                          index
                                                        ) => (
                                                          <div
                                                            key={index}
                                                            className=" w-full flex justify-center items-center"
                                                          >
                                                            <img
                                                              src={
                                                                rate_inside <=
                                                                rate?.rating_id
                                                                  ? StarFilled
                                                                  : StarUnFilled
                                                              }
                                                              alt="stars"
                                                              className="w-5 h-5"
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    <span className=" text-xs sm:text-sm font-medium flex justify-center items-center text-neutral500 px-1 text-right sm:text-start ">
                                                      {rate?.description}
                                                    </span>
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                          <div className="flex flex-col gap-2 mt-4">
                                            {ques.response.map((res: any) => (
                                              <div className="flex flex-col gap-2 ">
                                                <div className="border-2 rounded-5px py-2 px-[18px] gap-[10px] flex flex-col ">
                                                  <div className=" flex flex-col sm:flex-row   items-start sm:items-center gap-2 sm:gap-[36px]">
                                                    {/* <div className="flex items-center gap-1">
                                                    <ImageComponent
                                                      src={res?.avatar_url}
                                                      alt="user"
                                                      className="rounded-full w-[25px] h-[25px]"
                                                    />
                                                    <h6 className="text-[#15294B]  text-sm font-bold">
                                                      {res?.full_name}
                                                    </h6>
                                                  </div> */}
                                                    <div className=" flex  items-center gap-10">
                                                      {ratings.map(
                                                        (rate, index) => (
                                                          <div
                                                            key={index}
                                                            className=" w-full flex justify-center items-center"
                                                          >
                                                            <img
                                                              src={
                                                                index + 1 <=
                                                                Number(
                                                                  res.description
                                                                )
                                                                  ? StarFilled
                                                                  : StarUnFilled
                                                              }
                                                              alt="stars"
                                                              className="w-6 h-6"
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                  {/* {res?.alias && ( */}
                                                  <p className="text-[#243757] text-sm">
                                                    {isNumericDescription(
                                                      res.description
                                                    )
                                                      ? res.alias
                                                      : res.description}
                                                  </p>
                                                  {/* )} */}
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                    {/* TEXT BOX /DROPDOWN */}
                                    <>
                                      {(ques?.review_response_type_id == 2 ||
                                        ques?.review_response_type_id == 1) && (
                                        <div className="border-t pt-4">
                                          <div className="flex flex-col gap-2">
                                            {ques.response.map((res: any) => (
                                              <div className="border-2 rounded-5px py-2 px-4 gap-[10px] flex  items-center ">
                                                {/* <div className=" flex  items-center">
                                                <div className="flex items-center gap-1">
                                                  <ImageComponent
                                                    src={res?.avatar_url}
                                                    alt="user"
                                                    className="rounded-full w-[25px] h-[25px]"
                                                  />
                                                  <h6 className="text-[#15294B]  text-sm font-black">
                                                    {res?.full_name}
                                                  </h6>
                                                </div>
                                              </div> */}
                                                <p className="text-[#243757] text-sm">
                                                  {res?.description}
                                                </p>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="mt-1 border-t text-neutral500 font-medium flex justify-center items-center w-full py-10  text-center">
                                No available response from employees
                              </div>
                            )}
                            {/* FEEDBACK */}
                            <div className="">
                              <h6 className="mt-6 font-bold text-[#15294B] text-sm mb-1">
                                Feedback
                              </h6>
                              <div className=" rounded-lg border border-cardgrey h-[90px] flex flex-col justify-between  px-4 py-1">
                                <textarea
                                  className="h-full  flex-grow  outline-none text-neutral500"
                                  placeholder="Your feedback here...."
                                  // defaultValue={
                                  //   ques?.feedback?.description
                                  // }
                                  value={
                                    activeResponseId !== "" &&
                                    activeResponseId ==
                                      ques?.review_question_id &&
                                    activeResponses.text !== ""
                                      ? activeResponses.text
                                      : ques?.feedback?.description
                                  }
                                  // value={ques?.feedback?.description}
                                  onChange={(e) => {
                                    setActiveResponses({
                                      ...activeResponses,
                                      text: e.target.value,
                                    })
                                    setActiveResponseId(
                                      ques?.review_question_id
                                    )
                                  }}
                                  onBlur={() => {
                                    handleTextboxFunction(
                                      ques?.review_question_id
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="h-40  flex justify-center items-center">
                  <h6 className="text-neutral-500">
                    No available review questions
                  </h6>
                </div>
              )}
            </div>
          ) : (
            <div className="bg-white rounded-5px shadow-card w-full">
              <div className="text-neutral500 font-medium flex justify-center items-center w-full py-10  px-4 text-center">
                You are not permitted to view feedbacks from other employees{" "}
              </div>
            </div>
          )}
        </div>
      )}
      {/* <ProfessionalDvt
        invalidateQueries={invalidateQueries}
        employeeReviewId={employeeReviewId || ""}
        // feedback={feedback || []}
        feedback={
          feedback.filter((feed) => feed.review_entity_feedback_type_id == 1) ||
          []
        }
      />
      <NotableAchievement
        invalidateQueries={invalidateQueries}
        employeeReviewId={employeeReviewId || ""}
        feedback={
          feedback.filter((feed) => feed.review_entity_feedback_type_id == 2) ||
          []
        }
      />
      <AreaOfImprovement
        invalidateQueries={invalidateQueries}
        employeeReviewId={employeeReviewId}
        feedback={
          feedback.filter((feed) => feed.review_entity_feedback_type_id == 3) ||
          []
        }
      /> */}
    </div>
  )
}
export default Feedback
