import { useMutation } from "@tanstack/react-query"
import { Form, Radio, Space } from "antd"
import AntDesignSelect from "components/antd/AntDesignSelect"
import NewButton from "components/new-stuff/ui/button/Button"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import React, { useState } from "react"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
import { useGet } from "utils/useFetch"
import { IReviewRoundsRevieweesData } from "../interface/reviews"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import { IEmployeeRevieweeData } from "../interface/listings"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const AddReviewee: React.FC<{
  onClose: Function
  invalidateQueries: Function
  personId: string
  groupId: string
  existingReviewees: IEmployeeRevieweeData | undefined
}> = ({ onClose, invalidateQueries, personId, groupId, existingReviewees }) => {
  const { toast } = useAppNotificationStore()
  const [selectedMethod, setSelectedMethod] = useState("1")
  const [selectedAssignee, setSelectedAssignee] = useState("")

  const { isLoading: isRevieweesLoading, data: allReviewees } =
    useGet<IReviewRoundsRevieweesData>({
      url: `/admin/performance/selectreviewroundreviewees?review_group_id=${groupId}`,
    })

  const existingEmployeeKeys = existingReviewees?.data?.map(
    (reviewee) => reviewee.review_entity_key
  )

  // Filter allReviewees based on existingEmployeeKeys
  const nonExistingReviewees = allReviewees?.data.filter(
    (reviewee) => !existingEmployeeKeys?.includes(reviewee.review_entity_key)
  )

  const { isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/performance/add_reviewer`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        onClose()
        invalidateQueries()
        mixPanelEvent("pf-review-add-reviewee-success")
        {
          data.msg && toast.success(data.msg)
        }
      } else {
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const handleSubmit = () => {
    submit({
      review_entity_id: selectedAssignee,
      person_id: personId,
      employee_review_type_id: selectedMethod,
    })
  }
  const handleOnChange = (value: any) => {
    console.log(value?.split("-")[1])
    setSelectedAssignee(value?.split("-")[1])
  }
  return (
    <NewModalWrapper
      title={`Add new reviewees`}
      onClose={() => onClose()}
      open={true}
    >
      <>
        <div className=" ">
          <div className="px-4 lg:px-5 py-4">
            <p className=" text-neutral300 text-sm font-avenir">
              Search by name and pick who you want to add as a reviewee
            </p>
            <div className="mt-5">
              <Form layout="vertical">
                <AntDesignSelect
                  required={true}
                  label="Employee"
                  placeholder="Select employee"
                  name="employee"
                  isLoading={isRevieweesLoading}
                  options={
                    nonExistingReviewees?.map((person) => ({
                      label: person.full_name,
                      value: `${person.full_name}-${person.review_entity_id}`,
                    })) || []
                  }
                  onChange={handleOnChange}
                />
                <AntDesignRadio
                  label="Select review type"
                  name="access"
                  value={selectedMethod}
                  required
                >
                  <Space direction="horizontal">
                    <Radio
                      value="1"
                      checked={selectedMethod === "1" ? true : false}
                      onChange={() => {
                        setSelectedMethod("1")
                      }}
                    >
                      Review
                    </Radio>

                    <Radio
                      value="2"
                      checked={selectedMethod === "2" ? true : false}
                      onChange={() => {
                        setSelectedMethod("2")
                      }}
                    >
                      Feedback
                    </Radio>
                    <Radio
                      value="3"
                      checked={selectedMethod === "3" ? true : false}
                      onChange={() => {
                        setSelectedMethod("3")
                      }}
                    >
                      Rating
                    </Radio>
                  </Space>
                </AntDesignRadio>
              </Form>
            </div>
          </div>
          <div className="w-full flex gap-6 justify-end border mt-4 py-4 px-4 lg:px-5 shadow-footer  bg-white ">
            <NewButton
              color="secondary"
              // disabled={currentTab === 1}
              onClick={() => onClose()}
            >
              Cancel
            </NewButton>
            <NewButton
              color="primary"
              onClick={() => {
                handleSubmit()
                mixPanelEvent("pf-review-add-reviewee-init")
              }}
              disabled={selectedMethod == "" && selectedAssignee == ""}
              submitting={isLoading}
            >
              Confirm{" "}
            </NewButton>
          </div>
        </div>
      </>
    </NewModalWrapper>
  )
}

export default AddReviewee
