import { formatCurrencyNoSymbol } from "utils/currency"

const PensionRow: React.FC<{
  pfaName: any
  groupedPensionSchedules: any
}> = ({ pfaName, groupedPensionSchedules }) => {
  const totalEmployerContribution = groupedPensionSchedules[pfaName].reduce(
    (total: any, schedule: any) =>
      total + Number(schedule.employer_contribution),
    0
  )

  const totalEmployeeContribution = groupedPensionSchedules[pfaName].reduce(
    (total: any, schedule: any) =>
      total + Number(schedule.employee_contribution),
    0
  )
  return (
    <>
      <div className="border-b  border-black py-2  grid grid-cols-11 mb-1  items-center gap-4 ">
        <div className="col-span-2 flex-wrap  font-avenir break-words  text-sm text-[#42526D]">
          {pfaName !== "null" ? pfaName : "Unspecified PFA"}
        </div>
        <div className=" col-span-9  flex flex-col gap-1 ">
          {groupedPensionSchedules[pfaName].map((schedule: any, index: any) => (
            <div
              className={`py-1 flex-grow   grid-cols-6 grid gap-[6px] ${
                index == groupedPensionSchedules.length - 1
                  ? "border-none"
                  : "border-b border-cardgrey"
              } `}
            >
              <div className="">
                <span className="flex-wrap  font-avenir   text-sm text-[#42526D]">
                  {schedule.full_name}
                </span>
              </div>
              <div className="">
                <p className=" flex-wrap break-words font-avenir   text-sm text-[#42526D]">
                  {schedule.rsa_pin}
                </p>
              </div>
              <div className="">
                <span className="flex-wrap break-words font-avenir   text-sm text-[#42526D]">
                  ₦
                  {formatCurrencyNoSymbol(
                    Number(schedule.employer_contribution)
                  )}
                </span>
              </div>
              <div className="">
                <span className="flex-wrap break-words font-avenir   text-sm text-[#42526D]">
                  ₦
                  {formatCurrencyNoSymbol(
                    Number(schedule.employee_contribution)
                  )}
                </span>
              </div>
              <div className="">
                <span className="flex-wrap break-words font-avenir   text-sm text-[#42526D]">
                  ₦
                  {formatCurrencyNoSymbol(
                    Number(schedule.voluntary_contribution)
                  )}
                </span>
              </div>
              <div className="">
                <span className="flex-wrap break-words font-avenir   text-sm text-[#42526D]">
                  ₦
                  {formatCurrencyNoSymbol(
                    Number(
                      schedule.employer_contribution +
                        schedule.employee_contribution
                    )
                  )}
                </span>
              </div>
            </div>
          ))}
          <div className="grid grid-cols-6 py-2 gap-1">
            <div className=""></div>
            <div className=""></div>
            <div className=""></div>
            <div className=""></div>

            <div className="">
              <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
                Total contribution
              </span>
            </div>
            <div className="">
              <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
                ₦
                {formatCurrencyNoSymbol(
                  totalEmployerContribution + totalEmployeeContribution
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PensionRow
