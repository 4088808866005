import { Spin } from "antd"
import React, { useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"

type props = {
  title?: string
  tabList?: {
    key: string
    label: React.ReactNode
    tab: React.ReactNode
  }[]
  children?: React.ReactNode
  activeTabKey?: string
  onTabChange?: (key: string) => void
  rootClassName?: string
  headerClassName?: string
  childrenWrapperClassName?: string
  ctaBtn?: React.ReactNode
  loading?: boolean
  id?: string
}
const CustomCard: React.FC<props> = ({
  title,
  tabList = [],
  activeTabKey,
  children = null,
  ctaBtn,
  onTabChange = () => {},
  loading = false,
  rootClassName = "",
  headerClassName = "",
  childrenWrapperClassName = "",
  id,
}) => {
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  )
  return (
    <div
      id={id}
      className={`bg-white border rounded-xl p-4 lg:p-5 ${rootClassName}`}
    >
      <Spin
        spinning={loading}
        indicator={antIcon}
      >
        <div className={`flex justify-between items-center ${headerClassName}`}>
          {title ? (
            <h5 className="font-circular text-base text-padeBlack mb-3 font-semibold ">
              {title}
            </h5>
          ) : null}
          {ctaBtn ? ctaBtn : null}
        </div>
        {tabList?.length > 0 && (
          <div className="font-avenir">
            <div
              className={`border grid grid-flow-col border-cardgrey rounded-[5px]`}
            >
              {tabList?.map((tab) => (
                <button
                  key={tab.key}
                  className={` py-1 text-sm min-h-[27px] ${
                    activeTabKey == tab.key
                      ? "bg-primary500 rounded-[5px] text-a11y"
                      : "bg-grey10 text-neutral500"
                  }`}
                  onClick={() => {
                    onTabChange(tab.key)
                  }}
                >
                  {tab.label}
                </button>
              ))}
            </div>

            <div className="mt-5 max-h-[360px] max-w-full overflow-auto">
              {tabList?.find((tab) => tab.key === activeTabKey)?.tab}
            </div>
          </div>
        )}
        {children && (
          <div className={`${childrenWrapperClassName} font-avenir`}>
            {children}
          </div>
        )}
      </Spin>
    </div>
  )
}

export default CustomCard
