import {
  AppDownloadTemplateButton,
  AppFileUpload,
  Button,
  DashboardContainer,
  H5,
  P2,
} from "components"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore } from "stores"
import { Form, Input } from "antd"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"

import SegmentWrapper from "components/custom/SegmentWrapper"
import { isPadeDomain } from "utils/utils"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

const BulkSuspensionsUpload = () => {
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const [file, setFile] = useState<any>(null)
  const [email, setEmail] = useState("")
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const { user } = useAuthStore()

  const { isLoading: isAddingFile, mutate: submitFile } = useMutation({
    mutationFn: async (formData: FormData) => {
      email && formData.append("email", email)
      const res = await axiosInstance.post(
        `/admin/disciplinary/import_suspensions`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
        queryClient
          .invalidateQueries({
            queryKey: ["/admin/disciplinary/selectsuspensions"],
          })
          .then(() => navigate(-1))
        mixPanelEvent("req-suspensions-bulk-upload-success")
      } else if (data.status === "error") {
        data.msg && toast.error(data.msg)
      }
    },
  })

  const handleFinish = () => {
    if (file) {
      const formData = new FormData()
      formData.append("file_upload", file)
      mixPanelEvent("req-suspensions-bulk-upload-init")

      submitFile(formData)
    }
  }

  return (
    <DashboardContainer>
      <SegmentWrapper
        title="Bulk suspensions in 2 steps"
        subtitle={
          isPadeDomain() === true ? (
            <p className="font-normal text-neutral500 ">
              Need Help?{" "}
              <a
                href="https://support.padehcm.com"
                className=" text-primary500 font-light"
                target="_blank"
              >
                Get started: Bulk upload Suspensions
              </a>
            </p>
          ) : null
        }
        contentHeading={
          <div className=" mb-8">
            <h3 className="font-medium text-lg text-neutral500 mb-2  font-circular">
              What you must have ready
            </h3>
            <div className="flex flex-col w-full gap-3">
              {["Employees full name", "Start date", "End date", "Reason"].map(
                (item, index) => (
                  <P2
                    className="flex w-full gap-4"
                    key={index}
                  >
                    <img
                      src={
                        "https://padefiles.blob.core.windows.net/images/8a82057b-5be2-4992-9e08-0c0f66ca9bc5.svg"
                      }
                    />
                    <span className="font-medium text-sm text-[#5D6B82] ml-4">
                      {item}
                    </span>
                  </P2>
                )
              )}
            </div>
          </div>
        }
      >
        <div className="px-4 md:px-6 xl:px-[60px] mb-6 space-y-10">
          <div className="w-full flex flex-col gap-2.5">
            <H5 className="font-medium text-neutral600">
              1. Download spreadsheet
            </H5>
            <P2 className="text-neutral500">
              We recommend that you download a new spreadsheet before each bulk
              employee upload. This ensures that your lookup data is up-to-date.
            </P2>
            <AppDownloadTemplateButton
              fileName={`${user?.userOrganisation}_bulk_suspension_requests`}
              url="/admin/disciplinary/view/download_bulk_suspension_upload_form?include_exited=false"
              method="GET"
            />
          </div>
          <div className="w-full flex flex-col gap-2.5">
            <H5 className="font-medium text-neutral600">
              2. Upload spreadsheet
            </H5>
            <P2 className="text-neutral500">
              We recommend that you double-check to be sure you have filled the
              spreadsheet correctly.
              <br /> Note: Maximum allowed file size is 2MB.
            </P2>
            <AppFileUpload
              onUpdate={(file) => {
                setFile(file)
              }}
            />
            {file && (
              <div className="mt-2">
                <Input
                  placeholder="Email address where the processed file will be sent."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end items-center w-full pt-6  mt-6 -mb-4 border-t px-4 md:px-6 lg:px-8 xl:px-[60px]">
          <div className={`mt-2 flex gap-3 justify-end `}>
            <Button
              onClick={(e) => {
                e.preventDefault()
                navigate(-1)
              }}
              color="neutral"
              title="Previous"
              className="h-fit font-circular"
              disabled={isAddingFile}
            >
              Cancel
            </Button>
            <Button
              title="Upload employees"
              color="primary"
              className="w-max"
              onClick={(e) => {
                e.preventDefault()
                handleFinish()
              }}
              disabled={isAddingFile}
              submitting={isAddingFile}
            >
              Upload Suspensions
            </Button>
          </div>
        </div>
      </SegmentWrapper>
    </DashboardContainer>
  )
}

export default BulkSuspensionsUpload
