import React from "react"

type props = {
  children: JSX.Element | JSX.Element[]
}

const DashboardWrapper: React.FC<props> = ({ children }) => {
  return (
    <div className="w-full h-full px-4 md:px-6 lg:px-10  py-5  wrapper">
      {children}
    </div>
  )
}

export default DashboardWrapper
