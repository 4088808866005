import {
  ArrowDownCircleIcon,
  ArrowRightCircleIcon,
  ArrowRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"
import { Alert } from "antd"
import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React from "react"
interface props {
  onClose: () => void
  open: boolean
}
const EWAToWallet = ({ onClose, open }: props) => {
  return (
    <CustomModal
      title={<span className=" text-primary500">New Update</span>}
      onCancel={onClose}
      open={open}
      width={514}
    >
      <div className="">
        <h4 className="text-padeBlack font-bold text-xl mb-1 font-circular">
          {" "}
          Your EWA Withdrawals Are Moving
        </h4>
        <p className="text-[#102149BA] font-circular mb-[31px]">
          Going forward, all your Earned Wage withdrawals will be sent directly
          to your PaidHR Wallet for instant access.
        </p>
        <div className="font-avenir text-padeBlack text-sm w-full flex justify-between items-start !bg-[#E8F0FE] !rounded gap-2 py-2.5 px-3">
          <div className="h-6 w-6 ">
            <InformationCircleIcon className="h-6 w-6 text-primary500" />
          </div>{" "}
          <div className="">
            <h6 className="text-padeBlack font-circular font-bold mb-2.5 text-base ">
              Why this change?
            </h6>
            <p className="text-[#102149BA] font-circular">
              Your PaidHR wallet offers instant access to your money with zero
              transfer fees. You can still trasnfer to your bank account anytime
            </p>
          </div>
        </div>

        <div className=" mt-[25px] mb-[31px] flex flex-col gap-5">
          {[
            "Instant access to your earned wage",
            "No withdrawal fees",
            "Easy transfers to your bank account",
          ].map((item, index) => (
            <div
              key={index}
              className="flex items-center gap-4"
            >
              <ArrowRightIcon className="h-6 w-6 text-primary500 font-bold" />

              <p className="text-padeBlack font-circular text-sm">{item}</p>
            </div>
          ))}
        </div>
        <Button
          color="primary"
          className="w-full"
          onClick={onClose}
        >
          Got It
        </Button>
        <button className="mt-5 text-primary500 text-center w-full ">
          Learn more about PaidHR Wallet
        </button>
      </div>
    </CustomModal>
  )
}

export default EWAToWallet
