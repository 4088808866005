import { Form, FormItemProps, Input, Select } from "antd"

const { Option } = Select

export const CustomInput = ({
  label,
  name,
  placeholder,
  required = false,
  onChange,
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      label={
        <span className="font-circular text-neutral300 text-sm  ">{label}</span>
      }
      name={name}
      rules={[{ required, message: "Please enter this field" }]}
      // tooltip="This is a required field"
      {...restProps}
    >
      <Input
        onChange={onChange}
        placeholder={placeholder}
        className=" font-circular text-neutral400"
      />
    </Form.Item>
  )
}

export const CustomSelect = ({
  label,
  name,
  required = false,
  showSearch = true,
  placeholder,
  options,
  onChange,
  onSelectClick,
  isLoading = false,
  addBottomSpace = false,
  ...restProps
}: SelectItemProps) => {
  return (
    <Form.Item
      name={name}
      label={
        <span className="font-circular text-neutral300 text-sm  ">{label}</span>
      }
      rules={[{ required, message: "Please enter this field" }]}
      className="my-0"
      {...restProps}
    >
      <Select
        loading={isLoading}
        showSearch={showSearch}
        placeholder={placeholder}
        onChange={onChange}
        allowClear
        onClick={onSelectClick}
        filterOption={(input, option) => {
          if (option) {
            const value = (option.value as string) || ""
            return value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } else {
            return false
          }
        }}
      >
        {options?.map((option, index) => (
          <Option
            key={index}
            value={option.value}
          >
            <span className=" text-neutral400 font-circular">
              {option.label}
            </span>
          </Option>
        ))}
        {((options && options?.length > 50) || addBottomSpace) && (
          <>
            {" "}
            {["first", "second"].map((item) => (
              <Option
                key={"empty" + item}
                disabled
                className="pointer-events-none"
              >
                {" "}
              </Option>
            ))}
          </>
        )}
      </Select>
    </Form.Item>
  )
}

type InputItemProps = FormItemProps & {
  label: React.ReactNode
  required?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  placeholder?: string
}

type SelectItemProps = FormItemProps & {
  label: React.ReactNode
  required?: boolean
  showSearch?: boolean
  onChange?: (e: any) => void
  onSelectClick?: VoidFunction
  placeholder: string
  options: { label: string; value: string }[] | undefined
  isLoading?: boolean
  addBottomSpace?: boolean
}
