import { useMutation } from "@tanstack/react-query"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import NewModalWrapper from "components/new-stuff/ui/modals/NewModalWrapper"
import { useState } from "react"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
type Props = {
  onClose: () => void
  invalidateQueries: () => void
  open: boolean
  paymentId: string
  x: string
  currency?: string
}

export const UpdatePayment = ({
  onClose,
  open,
  paymentId,
  x,
  currency,
  invalidateQueries,
}: Props) => {
  const { toast } = useAppNotificationStore()
  const [comment, setComment] = useState("")
  const { isLoading: isSubmittingUpdate, mutate: submitUpdateSingle } =
    useMutation({
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/update_payment_schedule_item_detail?id=${paymentId}&currency_code=${currency}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status !== "error") {
          onClose()
          {
            data.msg && toast.success(data.msg)
          }
          mixPanelEvent("pay-schedule-payout-update-success")
          invalidateQueries()
        } else {
          onClose()
          {
            data.msg && toast.error(data.msg)
          }
        }
      },
      onError: (data: any) => {
        onClose()
        {
          data.msg && toast.error(data.msg)
        }
      },
    })

  return (
    <NewModalWrapper
      open={open}
      title="Update Account Details"
      className="w-[750px]"
      onClose={onClose}
    >
      <div className="px-5 py-3 flex flex-col items-center">
        <p className="text-[#15294B] text-sm mb-6">
          Beneficiary account details will be updated - if they have changed.
          Are you sure you want to continue?
        </p>
      </div>
      <div className="border py-5 px-6 flex gap-4 justify-end ">
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          submitting={isSubmittingUpdate}
          onClick={() => {
            mixPanelEvent("pay-schedule-payout-update-init")
            submitUpdateSingle()
          }}
        >
          Update
        </Button>
      </div>
    </NewModalWrapper>
  )
}

export default UpdatePayment
