import React from "react"
import { Route } from "react-router-dom"

const ExternalFeedback = React.lazy(
  () => import("pages/performance/ExternalFeedback")
)

const PerformanceRoutes = () => {
  return (
    <Route
      path=""
      // element={<NewResponsiveApprovalsLayout />}
    >
      <Route path="/performance/">
        <Route
          path="feedback"
          element={<ExternalFeedback />}
        />
      </Route>
    </Route>
  )
}

export default PerformanceRoutes
