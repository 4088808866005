import UnauthourizedPage from "components/layouts/UnauthourizedPage"
import usePermissions from "hooks/usePermissions"
import { useAuthStore } from "stores"
import { useAuthModalStore } from "stores/auth-modal"

const RequireRoutePermit = ({
  allowedPermissions,
  children,
}: {
  allowedPermissions: string[]
  children: React.ReactNode
}) => {
  const [canView] = usePermissions({ allowedPermissions })
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const { isOpen } = useAuthModalStore()

  if (isAuthenticated && !canView && !isOpen) {
    return <UnauthourizedPage type="admin" />
  }

  return <> {children} </>
}

export default RequireRoutePermit
