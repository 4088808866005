import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { ConfigProvider } from "antd"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import NetworkStatus from "NetworkStatus"
import { useCustomizationSettingsStore } from "stores/customization-settings"

import { MsalProvider } from "@azure/msal-react"
import { Configuration, PublicClientApplication } from "@azure/msal-browser"
import {
  googleClientId,
  microsoftClientId,
  microsoftClientRedirectUri,
} from "utils/constants"
import ScrollToTop from "ScrollToTop"

import "./index.css"
import reportWebVitals from "reportWebVitals"

import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
})

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: microsoftClientId as string,
    redirectUri: microsoftClientRedirectUri as string,
  },
}

const pca = new PublicClientApplication(configuration)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

// dotnet backend error handling
// errors come with 200 status code, hence we need to check for the status
const checkForBackendError = (response: any) => {
  if (response.status === "error") {
    throw new Error(response.msg || "An error occurred")
  }
  return response
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      onSettled: (data, error) => {
        if (error) {
          Sentry.captureException(error)
        } else {
          try {
            checkForBackendError(data)
          } catch (error) {
            Sentry.captureException(error)
          }
        }
      },
    },
    mutations: {
      onSettled: (data, error) => {
        if (error) {
          Sentry.captureException(error)
        } else {
          try {
            checkForBackendError(data)
          } catch (error) {
            Sentry.captureException(error)
          }
        }
      },
    },
  },
})

const RootComponent = () => {
  const { customizationSettings } = useCustomizationSettingsStore()

  return (
    <>
      <NetworkStatus />
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: customizationSettings?.brand?.colorScheme,
            },
          }}
        >
          <GoogleOAuthProvider clientId={`${googleClientId}`}>
            <MsalProvider instance={pca}>
              <BrowserRouter>
                <ScrollToTop />
                <App />
              </BrowserRouter>
            </MsalProvider>
          </GoogleOAuthProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </>
  )
}

root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
