import { Checkbox, Form, FormItemProps, Input, Radio } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { AbstractCheckboxProps } from "antd/es/checkbox/Checkbox"

export const AntDesignRadio = ({
  label,
  name,
  required = false,
  defaultChecked = false,
  onChange,
  children,
  value,
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      required={required}
      name={name}
      rules={[{ required, message: "Please enter this field!" }]}
      {...restProps}
      label={
        label ? (
          <span className=" text-padeBlack text-sm  font-circular">
            {label}
          </span>
        ) : (
          ""
        )
      }
    >
      <Radio.Group
        className="text-sm text-[#5D6B82]"
        onChange={onChange}
        defaultValue={value && value}
        // value={value && value}
      >
        {children}
      </Radio.Group>
    </Form.Item>
  )
}
type InputItemProps = FormItemProps & {
  label: string
  required?: boolean
  defaultChecked?: boolean
  onChange?: ((e: CheckboxChangeEvent) => void) | undefined
  children: any
  value?: any
}
// ;<Form.Item label="Radio">
//   <Radio.Group>
//     <Radio value="apple"> Apple </Radio>
//     <Radio value="pear"> Pear </Radio>
//   </Radio.Group>
// </Form.Item>
