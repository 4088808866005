import React, { forwardRef } from "react"
import PadeLogo from "assets/logos/paidhr-logo.svg"
import { formatCurrencyNoSymbol } from "utils/currency"
import ImageComponent from "components/custom/image"
import { useAuthStore } from "stores"
import NHFRow from "./NHFRow"

type INHFSchedule = {
  location_name: string
  location_code: string
  nhf_epn: string
  full_name: string
  employer_contribution: number
  employee_contribution: number
  total: number
  currency_symbol: string
}

const NHFReportToPrint = forwardRef(({ data }: any, ref: any) => {
  const { user } = useAuthStore()

  const groupNHFSchedulesByNHFName = (pensionSchedules: INHFSchedule[]) => {
    const groupedNHFSchedules: { [key: string]: INHFSchedule[] } = {}

    pensionSchedules.forEach((schedule) => {
      if (!groupedNHFSchedules[schedule.location_name]) {
        groupedNHFSchedules[schedule.location_name] = []
      }
      groupedNHFSchedules[schedule.location_name].push(schedule)
    })

    return groupedNHFSchedules
  }
  const groupedNHFSchedules = groupNHFSchedulesByNHFName(
    data?.nhfSchedules || []
  )

  return (
    <div
      ref={ref}
      className="bg-white shadow-card  py-[25px]  px-4 lg:px-[35px]"
    >
      <div className=" flex justify-between w-full items-center mb-6">
        <div>
          {" "}
          <h4 className="text-neutral700 font-bold  text-2xl mb-4">
            {data?.scheduleDate}
          </h4>
          <p className=" text-neutral500 text-sm leading-snug">
            Report created on: {data?.report_date}
          </p>
        </div>
        <div className=" mt-1">
          <ImageComponent
            src={user?.organisationAvatarFilename ?? PadeLogo}
            alt="org"
            className={` w-[200px] h-[100px] max-h-[200px] `}
          />
        </div>
      </div>
      <div className="mb-9 h-[117px] border-b bg-primary50 px-4 lg:px-[50px] flex justify-between items-center py-5">
        <div className=" gap-4 flex items-center">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {/* {user?.userOrganisation} */}
            </h5>
            <p className=" text-neutral500 font-semibold text-lg leading-snug">
              NHF Schedule Report
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              Employer code: {data?.housingReferenceNumber}
            </p>
          </div>
        </div>
        <div className="">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {data?.full_name}
            </h5>
            <p className=" text-neutral500 text-sm leading-snug">
              {data?.officeAddress?.address_line_1}
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              {data?.officeAddress?.address_city},{" "}
              {data?.officeAddress?.address_state}
            </p>
          </div>
        </div>
      </div>

      <div className=" flex flex-col gap-4">
        <div className="border-b border-black  py-4  grid grid-cols-10  items-center gap-4 ">
          <div className="col-span-2 flex-wrap   font-avenir   text-sm text-[#42526D] font-black ">
            NHF
          </div>
          <div className=" col-span-8  flex flex-col gap-1">
            <div className=" grid grid-cols-5 ">
              <span className="flex-wrap break-words   font-avenir   text-sm text-[#42526D] font-black">
                Employee Name
              </span>
              <span className="flex-wrap break-words   font-avenir   text-sm text-[#42526D] font-black">
                NHF_NO{" "}
              </span>
              <span className="flex-wrap   font-avenir break-words   text-sm text-[#42526D] font-black">
                Employer
              </span>
              <span className="flex-wrap   font-avenir break-words   text-sm text-[#42526D] font-black">
                Employee
              </span>

              <span className="flex-wrap   font-avenir break-words   text-sm text-[#42526D] font-black">
                Total
              </span>
            </div>
          </div>
        </div>
        {Object.keys(groupedNHFSchedules).map((pfaName) => (
          <NHFRow
            pfaName={pfaName}
            groupedPensionSchedules={groupedNHFSchedules}
          />
        ))}
        <OverallTotal groupedPensionSchedules={groupedNHFSchedules} />
      </div>
    </div>
  )
})

const OverallTotal: React.FC<{
  groupedPensionSchedules: any
}> = ({ groupedPensionSchedules }) => {
  const overallTotal: any = Object.values(groupedPensionSchedules)
    .flat()
    .reduce(
      (total: any, schedule: any) =>
        total +
        Number(schedule.employer_contribution) +
        Number(schedule.employee_contribution),
      0
    )

  return (
    <div className="border-b mt-2 border-black py-4 grid grid-cols-9 items-center gap-4">
      <div className="col-span-2 flex-wrap font-avenir text-sm text-[#42526D] font-black"></div>
      <div className="col-span-7 flex flex-col gap-2">
        <div className="grid grid-cols-6 gap-2">
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
            Overall total
          </span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
            ₦{formatCurrencyNoSymbol(overallTotal)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default NHFReportToPrint
interface Schedule {
  full_name: string
  employer_contribution: number
  employee_contribution: number
}
