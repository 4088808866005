import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
interface Props {
  title: string
  onClose: () => void
  open: boolean
  loading: boolean
  handleSubmit: (e: string) => void
  btnText: string
  otp: string[]
  setOtp: any
}
const EnterEwaPin = ({
  title,
  onClose,
  open,
  handleSubmit,
  loading,
  btnText,
  otp,
  setOtp,
}: Props) => {
  const navigate = useNavigate()
  const otpInputs = useRef<(HTMLInputElement | null)[]>([])
  const [isPinComplete, setIsPinComplete] = useState(false)

  useEffect(() => {
    setIsPinComplete(otp.every((digit) => digit !== ""))
  }, [otp])

  const handleChange = (index: number, value: string) => {
    if (isNaN(Number(value))) return

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (
      value !== "" &&
      index < otp.length - 1 &&
      otpInputs.current[index + 1]
    ) {
      otpInputs.current[index + 1]?.focus()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = event.clipboardData.getData("text")
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault()
      return
    }

    const newOtp = pastedData.split("").slice(0, otp.length)
    setOtp(newOtp)
  }
  return (
    <CustomModal
      title={title}
      onCancel={onClose}
      open={open}
      className="w-[800px]"
      footer={
        <div className="  flex items-center gap-2   w-full">
          <Button
            color="secondary"
            className="w-full"
            onClick={() => {
              onClose()
              setOtp(["", "", "", ""])
            }}
          >
            Cancel{" "}
          </Button>
          <Button
            color="primary"
            className="w-full"
            onClick={() => handleSubmit(otp.join(""))}
            submitting={loading}
            disabled={loading || !isPinComplete}
          >
            {btnText}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col justify-between w-full items-center bg-white pt-[37px] px-4 max-w-[572px]">
        <img
          src={
            "https://padefiles.blob.core.windows.net/images/66cc977a-f451-46d4-8e2e-24b6a82479de.svg"
          }
          alt="password"
          className="w-[59px] h-[59px]"
        />
        <p className="text-sm  text-bluesubtext mt-7 mb-5">
          Please enter your PIN
        </p>

        <div className="flex gap-[22px]">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="password"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onPaste={handlePaste}
              ref={(ref) => {
                otpInputs.current[index] = ref
              }}
              className="border w-[50px] md:w-[60px] h-[60px] rounded-5px text-center text-lg font-bold active:border-primary500"
              style={{
                fontFamily: "PasswordFont",
              }}
            />
          ))}
        </div>
      </div>
    </CustomModal>
  )
}

export default EnterEwaPin
