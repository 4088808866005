import { Loading } from "App"
import RequireRoutePermit from "RequireRoutePermit"
import React, { Suspense } from "react"
import { Route } from "react-router-dom"
import PaybandSummaryPreviewPage from "./report-previews/PaybandSummaryPreview"

const PayrollSummaryPreview = React.lazy(
  () => import("./report-previews/PayrollSummaryPreview")
)
const PayrollJournalPreview = React.lazy(
  () => import("./report-previews/PayrollJournalPreview")
)
const PayrollVariancePreview = React.lazy(
  () => import("./report-previews/PayrollVariancePreview")
)
const StatutoryBenefitsPreview = React.lazy(
  () => import("./report-previews/StatutoryBenefitsPreview")
)
const YearToDatePreview = React.lazy(
  () => import("./report-previews/YearToDatePreview")
)
const EditBuilder = React.lazy(
  () => import("./report-previews/EditEmployeeReportBuilder")
)
const EmployeeRequestPreview = React.lazy(
  () => import("./report-previews/EmployeeRequestPreview")
)
const CustomBenefitsPreview = React.lazy(
  () => import("./report-previews/CustomBenefitsPreview")
)
const PayrollChangePreview = React.lazy(
  () => import("./report-previews/PayrollChangesReport")
)

const PayrollExemptionPreview = React.lazy(
  () => import("./report-previews/PayrollExemptionsPreview")
)
const PaygradeSummaryPreviewPage = React.lazy(
  () => import("./report-previews/PaygradeSummaryPreview")
)
const LeaveBalancePreviewPage = React.lazy(
  () => import("./report-previews/LeaveBalancesPreview")
)
const CustomFieldsPreviewPage = React.lazy(
  () => import("./report-previews/CustomFieldsPreviewPage")
)
const EmployeeSummaryPreviewPage = React.lazy(
  () => import("./report-previews/EmployeeSummaryPreviewPage")
)

export const ReportRoutes = () => {
  return (
    <Route path="report-preview">
      <Route
        path="payroll-summary"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <PayrollSummaryPreview />
          </RequireRoutePermit>
        }
      />
      <Route
        path="payroll-variance"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <PayrollVariancePreview />
          </RequireRoutePermit>
        }
      />
      <Route
        path="statutory-benefits"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <StatutoryBenefitsPreview />
          </RequireRoutePermit>
        }
      />
      <Route
        path="ytd"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <YearToDatePreview />
          </RequireRoutePermit>
        }
      />

      <Route
        path="payroll-journal"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <PayrollJournalPreview />
          </RequireRoutePermit>
        }
      />

      <Route
        path="employee_request"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <EmployeeRequestPreview />
          </RequireRoutePermit>
        }
      />
      <Route
        path="custom-benefits"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <CustomBenefitsPreview />
          </RequireRoutePermit>
        }
      />
      <Route
        path="payroll-changes"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <PayrollChangePreview />
          </RequireRoutePermit>
        }
      />
      <Route
        path="payroll-exemption"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <PayrollExemptionPreview />
          </RequireRoutePermit>
        }
      />
      <Route
        path="paygrade-summary"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <PaygradeSummaryPreviewPage />
          </RequireRoutePermit>
        }
      />
      <Route
        path="payband-summary"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <PaybandSummaryPreviewPage />
          </RequireRoutePermit>
        }
      />
      <Route
        path="leave-balances"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <LeaveBalancePreviewPage />
          </RequireRoutePermit>
        }
      />
      <Route
        path="custom-fields"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <CustomFieldsPreviewPage />
          </RequireRoutePermit>
        }
      />
      <Route
        path="employee-summary"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <EmployeeSummaryPreviewPage />
          </RequireRoutePermit>
        }
      />
      <Route
        path="builder/:x/:id"
        element={
          <RequireRoutePermit allowedPermissions={["can_view_reports"]}>
            <EditBuilder />
          </RequireRoutePermit>
        }
      />
    </Route>
  )
}
