/**
 * An optimized P1 component.
 */
export const P2: React.FC<JSX.IntrinsicElements["p"]> = (props) => {
  return (
    <p
      {...props}
      className={`text-sm font-avenir ${props.className}`}
    >
      {props.children}
    </p>
  )
}
