import { Modal } from "antd"
import React from "react"
import { VerifyOtp } from "../components/VerifyOtp"
import { ILoginResponse } from "stores/auth"

type Props = {
  onSuccess?: Function
  loginResponse: ILoginResponse
}

export const VerifyOtpPrompt = ({ onSuccess, loginResponse }: Props) => {
  return (
    <Modal
      open
      footer={null}
      className="lg:max-w-lg max-w-sm"
    >
      <div className="w-full">
        <VerifyOtp
          onSuccess={onSuccess?.()}
          loginResponse={loginResponse}
        />
      </div>
    </Modal>
  )
}
