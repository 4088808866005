import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { usePost } from "utils/useFetch"
import { IPreviewRequest } from "../interface/preview-request"

export const useGetDateRanges = () => {
  const { data, isLoading } = usePost<{ id: string; text: string }[]>({
    url: `/admin/reports/getdateranges`,
    body: {
      q: "",
      page: "",
    },
  })

  return { dateRanges: data, isLoading }
}
export const useGetDatePeriods = (id: string) => {
  const { data, isLoading } = useQuery({
    enabled: !!id,
    queryKey: ["periods", id],
    queryFn: async () => {
      const res = await axiosInstance.post(
        `/admin/reports/getdateperiods?id=${id}`,
        {
          q: "",
          page: "",
        }
      )
      return res.data
    },
  })

  return { periods: data, loadingPeriods: isLoading }
}
export const useGetGroupingTypes = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["groups"],
    queryFn: async () => {
      const res = await axiosInstance.post(`/admin/reports/getgroupingtypes`, {
        q: "",
        page: "",
      })
      return res.data
    },
  })

  return { groups: data, loadingGroupTypes: isLoading }
}

export const useGetPreview = () => {
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: Partial<IPreviewRequest>) => {
      const res = await axiosInstance.post(`/admin/reports/view/preview`, data)
      return res.data
    },
  })
  return { mutate, getting: isLoading }
}
export function usePostPreview(record: Partial<IPreviewRequest>) {
  const { data, isLoading } = useQuery({
    queryFn: async () => {
      const res = await axiosInstance.post(
        `/admin/reports/view/preview`,
        record
      )
      return res.data
    },
    queryKey: ["data-preview", record],
    enabled: !!record,
  })
  return { data, getting: isLoading }
}

export const useGetEmployeeTypes = () => {
  const { data = [], isLoading } = usePost<any>({
    url: `/commonutils/getemployeescopes`,
    body: {
      q: "",
      page: "",
    },
  })

  return {
    employeeTypes: data,
    gettingTypes: isLoading,
  }
}
export const useGetRequestTypes = () => {
  const { data = [], isLoading } = usePost<any>({
    url: `/commonutils/getemployeerequesttypes`,
    body: {
      q: "",
      page: "",
    },
  })

  return {
    requestTypes: data,
    gettingRequestTypes: isLoading,
  }
}
