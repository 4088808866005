import { P2, P3 } from "components"
import { CheckIcon } from "@heroicons/react/24/outline"

// interface IAction {
//   handleClick: () => void
// }
interface indicator {
  index: number
  title: string
  completed: boolean
  active: boolean
  content?: JSX.Element
}

const OnboardingIndicator: React.FC<{
  indicator: indicator
  handleClick: (index: number) => void
}> = ({ indicator, handleClick }) => {
  const { active, completed, index, title } = indicator

  return (
    <div
      className="flex gap-1.5 min-h-[40px] "
      onClick={() => handleClick(index)}
    >
      <div
        className={`shrink-0 w-5 h-5 cursor-pointer flex font-medium justify-center items-center rounded-full ${
          completed === true
            ? "bg-[#C1EFDA] text-[#289061]"
            : active === true
            ? "bg-primary500 text-white"
            : "bg-white border border-neutral100 text-neutral100"
        }`}
      >
        {completed === true ? (
          <CheckIcon className="w-3 stroke-[3px]" />
        ) : (
          <div className="text-[10px]">{index + 1}</div>
        )}
      </div>
      <div className="flex flex-col gap-1 items-start">
        <P2
          className={`${
            active === true || completed === true ? "font-bold" : "font-normal"
          } text-neutral300 font-circular whitespace-nowrap`}
        >
          {title}
        </P2>
        <P3
          className={`text-[#33B97D] font-medium ${
            completed === true ? "inline-block" : "hidden"
          }`}
        >
          Completed
        </P3>
      </div>
    </div>
  )
}

export default OnboardingIndicator
