import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import hiring_landing from "../../../../../assets/images/hiring/hiring_landing.png"
import icon from "../../../../../assets/images/hiring/hiring_list.svg"
import NewButton from "components/new-stuff/ui/button/Button"
import { useState } from "react"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { Form, Input } from "antd"

type Props = {}

const HiringLandingPage = (props: Props) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const [form] = Form.useForm()
  return (
    <DashboardWrapper>
      <div className="flex flex-col gap-4 bg-white pl-12 py-12">
        <div className="flex flex-col gap-3">
          <div className="text-padeBlack text-[24px] font-[600]">
            Transform Your Hiring Process
          </div>
          <div className="text-padeSubtext text-[15px] w-[50%]">
            Streamline your recruitment workflow, find top talent faster, and
            make better hiring decisions with our comprehensive hiring solution
          </div>
        </div>
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col gap-2 mt-8">
            {items?.map((item, index) => (
              <div className="flex items-center gap-2">
                <img
                  src={icon}
                  alt=""
                />
                <div className="text-[15px]">{item}</div>
              </div>
            ))}
            <div className="mt-[10%]">
              <NewButton
                color="primary"
                className="w-fit"
                onClick={() => setIsButtonClicked(true)}
              >
                Get Started with Hiring
              </NewButton>
            </div>
          </div>

          <img
            src={hiring_landing}
            alt=""
          />
        </div>
      </div>
      <>
        {isButtonClicked && (
          <CustomModal
            title="Tell us about your hiring needs"
            open
            onCancel={() => setIsButtonClicked(false)}
            subtitle="Our customer experience team will reach out to discuss pricing and help you get started."
            footer={
              <div className="flex gap-2 justify-end">
                <NewButton
                  color="secondary"
                  onClick={() => setIsButtonClicked(false)}
                >
                  Cancel
                </NewButton>
                <NewButton
                  color="primary"
                  onClick={() => {
                    form.submit()
                  }}
                >
                  Submit Request
                </NewButton>
              </div>
            }
          >
            <Form
              form={form}
              layout="vertical"
            >
              <Form.Item
                label={
                  <div className="text-padeBlack font-[600]">Work Email</div>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your work email!",
                  },
                ]}
              >
                <Input
                  placeholder="you@company.com"
                  type="email"
                />
              </Form.Item>
            </Form>
          </CustomModal>
        )}
      </>
    </DashboardWrapper>
  )
}

export default HiringLandingPage

const items = [
  "Post and manage job openings efficiently",
  "Track applicants through custom hiring pipelines",
  "Collaborate with team members on candidate evaluations",
  "Schedule interviews and send automated communications",
  "Generate offer letters and onboarding documents",
]
