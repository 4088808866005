import { Checkbox, Form, FormItemProps, Input } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { AbstractCheckboxProps } from "antd/es/checkbox/Checkbox"
import React from "react"

export const AntDesignCheckbox = ({
  label,
  name,
  required = false,
  defaultChecked = false,
  onChange,
  ...restProps
}: InputItemProps) => {
  return (
    <Form.Item
      name={name}
      rules={[{ required, message: "Please enter this field!" }]}
      {...restProps}
    >
      <Checkbox
        className="text-sm text-[#5D6B82]"
        defaultChecked={defaultChecked}
        onChange={onChange}
      >
        {label}
      </Checkbox>
    </Form.Item>
  )
}
type InputItemProps = FormItemProps & {
  label: string | JSX.Element
  required?: boolean
  defaultChecked?: boolean
  onChange?: ((e: CheckboxChangeEvent) => void) | undefined
}
