import { P2 } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { LoginForm } from "pages/auth/Login"
import { useAuthModalStore } from "stores/auth-modal"

const AuthModal = () => {
  const { isOpen } = useAuthModalStore()
  return (
    <CustomModal
      closable={false}
      open={isOpen}
      title={
        <span className="text-xl font-circular">Authentication Required</span>
      }
      destroyOnClose={true}
      styles={{
        mask: {
          backgroundColor: "rgba(225, 225, 225, 0.9)",
        },
      }}
    >
      <div className="w-full mb-6">
        <P2 className="mb-4 ">
          Your session has expired. Please log in again to continue.
        </P2>
        <LoginForm />
      </div>
    </CustomModal>
  )
}

export default AuthModal
