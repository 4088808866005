import { useMutation } from "@tanstack/react-query"
import { Form, Spin } from "antd"
import { Button, ModalWrapper } from "components"
import { AntDesignInput } from "components/antd/AntDesignInput"
import AntDesignSelect from "components/antd/AntDesignSelect"
import React, { useState, useEffect } from "react"
import { useProfileStore } from "stores/profile"
import { axiosInstance } from "utils/request"
import { useGet, usePost } from "utils/useFetch"

const EditPersonalDetails: React.FC<{
  closeModal: Function
  PersonData: any
}> = ({ closeModal, PersonData }) => {
  const { getProfile } = useProfileStore()
  const [dataBody, setDataBody] = useState({
    nin: "",
    date_of_birth_month: "",
    date_of_birth_day: "",
    date_of_birth_year: "",
    mobile_phone: "",
    display_phone_number: "",
  })
  useEffect(() => {
    setDataBody({
      nin: "",
      date_of_birth_month: String(PersonData?.date_of_birth_month_id),
      date_of_birth_day: String(PersonData?.date_of_birth_day_id),
      date_of_birth_year: String(PersonData?.date_of_birth_year),
      mobile_phone: String(PersonData?.mobile_phone),
      display_phone_number: String(PersonData?.mobile_phone),
    })
  }, [PersonData])
  const reqBody = {
    q: "",
    page: "",
  }
  const { isLoading: IsLoadingBirthMonth, data: birthMonth } = usePost<any>({
    url: `/commonutils/getbirthdaymonth`,
    body: reqBody,
  })
  const { isLoading: IsLoadingDays, data: birthDays } = usePost<any>({
    url: `/commonutils/getbirthdayday`,
    body: reqBody,
  })
  const { isLoading: IsLoadingYears, data: birthYears } = usePost<any>({
    url: `/commonutils/getbirthdayyear`,
    body: reqBody,
  })

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/settings/update_details`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        getProfile()
        closeModal()
      }
    },
  })
  const onFinish = () => {
    submit({
      ...dataBody,
    })
  }
  return (
    <ModalWrapper
      title="Change your personal details"
      subTitle="We’ll use this information to quickly and securely verify your identity over the phone when you call our team. Rest assured, your information is in good hands."
      className="w-[700px]"
      onClose={() => {
        closeModal()
      }}
    >
      <>
        <Form
          layout="vertical"
          initialValues={{
            day: PersonData?.date_of_birth_day_alias,
            month: PersonData?.date_of_birth_month_alias,
            year: PersonData?.date_of_birth_year_alias,
            number: PersonData?.mobile_phone,
          }}
        >
          <div className="w-full  p-5 grid grid-cols-2 gap-x-6">
            <AntDesignInput
              name="id"
              label="National ID. Number"
              onChange={(e) => {
                setDataBody({
                  ...dataBody,
                  nin: e.target.value,
                })
              }}
            />
            <AntDesignSelect
              label="Month"
              name="month"
              required
              isLoading={IsLoadingBirthMonth}
              onChange={(selected) => {
                const selectedOption = birthMonth?.find(
                  (option: any) => option.description === selected
                )

                setDataBody({
                  ...dataBody,
                  date_of_birth_month: String(selectedOption.monthId),
                })
              }}
              options={birthMonth?.map((option: any) => ({
                label: option.description,
                value: option.description,
              }))}
              placeholder="Please select"
            />
            <AntDesignSelect
              label="Day"
              name="day"
              required
              isLoading={IsLoadingDays}
              onChange={(selected) => {
                const selectedOption = birthDays?.find(
                  (option: any) => option.description === selected
                )

                setDataBody({
                  ...dataBody,
                  date_of_birth_day: String(selectedOption.monthDayId),
                })
              }}
              options={birthDays?.map((option: any) => ({
                label: option.description,
                value: option.description,
              }))}
              placeholder="Please select"
            />
            <AntDesignSelect
              label="Year"
              name="year"
              required
              isLoading={IsLoadingYears}
              onChange={(selected) => {
                const selectedOption = birthYears?.find(
                  (option: any) => option.text === selected
                )

                setDataBody({
                  ...dataBody,
                  date_of_birth_year: String(selectedOption.id),
                })
              }}
              options={birthYears?.map((option: any) => ({
                label: option.text,
                value: option.text,
              }))}
              placeholder="Please select"
            />
            <AntDesignInput
              name="number"
              label="Mobile number"
              onChange={(e) => {
                setDataBody({
                  ...dataBody,
                  mobile_phone: e.target.value,
                  display_phone_number: e.target.value,
                })
              }}
            />
          </div>
        </Form>
        <div className="flex justify-end items-center gap-4 p-5">
          <Button
            onClick={() => {
              closeModal()
            }}
            color="neutral"
            title="Previous"
            className="h-fit text-[#5D6B82]"
          >
            Cancel{" "}
          </Button>
          <Button
            color={isLoading ? "secondary" : "primary"}
            title="update"
            className="rounded-5px disabled:opacity-50 focus:outline-none focus:shadow-none disabled:cursor-not-allowed py-[7px] px-4 text-sm min-w-[106px] bg-primary500 hover:bg-primary700 active:bg-primary800 focus:bg-primary800 text-white shadow-none h-auto"
            onClick={() => {
              onFinish()
            }}
          >
            {isLoading ? <Spin></Spin> : "Save"}
          </Button>
        </div>
      </>
    </ModalWrapper>
  )
}

export default EditPersonalDetails
