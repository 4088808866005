import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React from "react"
interface Props {
  onClose: () => void
  open: boolean
  paymentLink: string | undefined
}
const FundMoney = ({ onClose, open, paymentLink }: Props) => {
  const handleClick = () => {
    window.open(paymentLink)
  }
  return (
    <CustomModal
      // title={title}
      title={<span className="invisible">Fund Now</span>}
      onCancel={onClose}
      open={open}
      width={650}
    >
      <div className="w-full h-[100vh]">
        <Button></Button>
        {/* <iframe
          className="w-full h-full"
          src={paymentLink}
          // src={"https://pay.leatherback.co/0-oy68ejf"}
        /> */}
      </div>
    </CustomModal>
  )
}

export default FundMoney
