type props = {
  children: JSX.Element | JSX.Element[]
  className?: string
}

export const TopActionBar: React.FC<props> = ({ className, children }) => {
  return (
    <div className={`${className}`}>
      <div
        className={`w-full flex justify-between items-center py-3 px-4 md:px-6 lg:px-10 border-y border-y-neutral40 bg-white`}
      >
        {children}
      </div>
    </div>
  )
}

const MenuIcon = () => {
  return (
    <button className="border rounded-[4px] h-full px-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-[#42526D] "
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <line
          x1="4"
          y1="6"
          x2="20"
          y2="6"
        />
        <line
          x1="6"
          y1="12"
          x2="18"
          y2="12"
        />
        <line
          x1="10"
          y1="18"
          x2="14"
          y2="18"
        />
      </svg>
    </button>
  )
}
const SortIcon = () => {
  return (
    <button className="border rounded-[4px] h-full px-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M10.5 19C10.5 20.1 11.4 21 12.5 21C13.6 21 14.5 20.1 14.5 19C14.5 17.9 13.6 17 12.5 17C11.4 17 10.5 17.9 10.5 19Z" />
        <path d="M10.5 5C10.5 6.1 11.4 7 12.5 7C13.6 7 14.5 6.1 14.5 5C14.5 3.9 13.6 3 12.5 3C11.4 3 10.5 3.9 10.5 5Z" />
        <path
          d="M12.5 14C13.6 14 14.5 13.1 14.5 12C14.5 10.9 13.6 10 12.5 10C11.4 10 10.5 10.9 10.5 12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  )
}
