import { matchRoutes, useLocation, useNavigate } from "react-router-dom"
import { TopActionBar } from "../layouts/TopActionBar"
import { ActionBarTab } from "./ActionBarTab"

export const SettingsTopActionBar: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <div className="h-full flex items-center gap-6 px-4 md:px-6 lg:px-10 border-y scrollbar-hide border-y-neutral40 bg-white overflow-x-auto">
      {[
        // {
        //   title: "Permissions",
        //   route: "/dashboard/settings/permissions",
        // },
        {
          title: "Admins",
          route: "/dashboard/settings/permissions/admins",
        },
        // {
        //   title: "Integration",
        //   route: "/dashboard/settings/integration",
        // },
        {
          title: "HR & Payroll",
          route: "/dashboard/settings/hr-and-payroll",
        },
        {
          title: "Approval Workflows",
          route: "/dashboard/settings/approval-workflow",
        },
        {
          title: "Plan & Billing",
          route: "/dashboard/settings/plan-and-billing",
        },
        {
          title: "Customization",
          route: "/dashboard/settings/customization",
        },
      ].map((item, index) => (
        <div>
          {" "}
          <ActionBarTab
            key={index}
            action={() => navigate(item.route)}
            active={
              matchRoutes([{ path: `${item.route}/*` }]!, pathname) !== null
            }
            title={item.title}
          />
        </div>
      ))}
    </div>
  )
}
