import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useState } from "react"
import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { IWithdrawalData } from "../../interface/wallet"
import TransactionsTemplate from "../../components/TransactionsTemplate"
import { periods } from "pages/dashboard/wallets/crossBorderPayments/transactions/Transactions"
import { dateData } from "pages/dashboard/wallets/Statements"
import dayjs from "dayjs"
import { DatePicker } from "antd"

const Transactions = () => {
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(7, "day").format("YYYY/MM/DD") || ""
  )
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )
  const [isCustom, setIsCustom] = useState(false)
  const [activePeriod, setActivePeriod] = useState<dateData>({
    id: "1",
    period: "One Week",
    startDate: "",
    endDate: "",
  })
  const { isLoading: isWithdrawalsLoading, data: withdrawalsData } =
    useGet<IWithdrawalData>({
      url: `${newBaseUrl}/v1/employee/withdrawals?limit=0&startDate=${
        startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
      }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
      external: true,
      onSuccess: (data: any) => {
        mixPanelEvent("empl-wallet-transaction-listing")
      },
    })

  return (
    <DashboardWrapper>
      <div className="">
        <div className=" bg-white my-4 px-4 pt-5">
          <div className="flex gap-2 justify-end items-center mb-4">
            <div className="bg-[#F3F5FB] rounded-sm cursor-pointer flex ">
              {periods?.map((it: dateData) => (
                <div
                  onClick={() => {
                    if (it?.id === "1") {
                      setStartDate(
                        dayjs(new Date())
                          .subtract(7, "day")
                          .format("YYYY/MM/DD") || ""
                      )
                      setEndDate(dayjs(new Date())?.format("YYYY/MM/DD") || "")
                    } else if (it?.id === "2") {
                      setStartDate(
                        dayjs(new Date())
                          .subtract(30, "day")
                          .format("YYYY/MM/DD") || ""
                      )
                      setEndDate(dayjs(new Date())?.format("YYYY/MM/DD") || "")
                    } else {
                      setIsCustom(true)
                      setActivePeriod({
                        id: "3",
                        period: "Custom",
                        startDate: "",
                        endDate: "",
                      })
                      return
                    }
                    setActivePeriod(it)
                    setIsCustom(false)
                  }}
                  key={it?.id}
                  className={`${
                    it.id === activePeriod?.id && "bg-primary500 text-white"
                  } whitespace-nowrap text-[#42526D] h-full w-full p-2 rounded-md`}
                >
                  {it?.period}
                </div>
              ))}
            </div>
            {isCustom && (
              <div className="flex gap-2">
                <DatePicker
                  placeholder="Start date"
                  onChange={(value) => {
                    setStartDate(value?.format("YYYY/MM/DD") || "")
                    setEndDate("")
                  }}
                  disabledDate={(date) => {
                    return date.isAfter(dayjs())
                  }}
                />
                <DatePicker
                  placeholder="End date"
                  disabled={!startDate}
                  onChange={(value) => {
                    setEndDate(value?.format("YYYY/MM/DD") || "")
                  }}
                  disabledDate={(date) => {
                    const testDate = dayjs(startDate)
                    const before = date.isBefore(testDate)
                    const after = date.isAfter(testDate.add(1, "M"))
                    const afterToday = date.isAfter(dayjs())

                    return before || after || afterToday
                  }}
                />
              </div>
            )}
          </div>
          <TransactionsTemplate
            data={withdrawalsData?.data?.docs || []}
            isLoading={isWithdrawalsLoading}
          />
        </div>
      </div>
    </DashboardWrapper>
  )
}
export default Transactions
