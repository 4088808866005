import MicrosoftIcon from "../../assets/images/microsofticon.svg"
import { Space } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useMsal } from "@azure/msal-react"

type Props = {
  loading: boolean
  handleLoginProcess: (
    email?: string | undefined,
    password?: string | undefined,
    token?: string | undefined,
    code?: string | undefined,
    provider?: string | undefined
  ) => Promise<void>
}

export const MicrosoftLogin = ({ loading, handleLoginProcess }: Props) => {
  const { instance, accounts } = useMsal()

  return (
    <div
      className="flex flex-col items-center cursor-pointer select-none"
      onClick={async () => {
        try {
          if (loading) return
          const res = await instance.acquireTokenPopup({
            scopes: ["email", "user.read"],
            account: accounts[0],
          })
          handleLoginProcess("", "", "", res.accessToken, "microsoft")
        } catch (e) {}
      }}
    >
      {loading ? (
        <div className="flex gap-2 items-center">
          <div className="invisible ">
            <img
              src={MicrosoftIcon}
              alt="microsoft"
            />
          </div>
          <span>
            <Space>
              <LoadingOutlined size={20} />
            </Space>
          </span>
        </div>
      ) : (
        <>
          <div>
            <img
              src={MicrosoftIcon}
              alt="microsofticon"
            />
          </div>
          <div className="mt-[8px] font-ibm font-medium text-[16px] leading-[20.8px] text-[#243757]">
            Microsoft
          </div>
        </>
      )}
    </div>
  )
}
