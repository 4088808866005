import { Collapse, DatePicker, Select, Spin } from "antd"
import DropdownCard, { Items } from "components/new-stuff/ui/DropdownCard"
import React, { useState } from "react"
import type { CollapseProps } from "antd"

// import Measurements from "../../../employee-dashboard/performance/initiatives/Measurements"

import { usePost, useGet } from "utils/useFetch"
import { Label } from "components"
import Measurements from "pages/employee-dashboard/performance/initiatives/Measurements"
import { IReviewKREmplData } from "../interface/viewemployee"
const { Panel } = Collapse

type measurementprops = {
  ReviewEntityKey: string
}
const ReviewViewKeyResults: React.FC<measurementprops> = ({
  ReviewEntityKey,
}) => {
  const { RangePicker } = DatePicker

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [dateType, setDateType] = useState("1")
  const [showCustomDate, setShowCustomDate] = useState(false)
  const {
    isLoading,
    data: personInfo,
    refetch,
  } = useGet<IReviewKREmplData>({
    url: `/performance/getperformancedata?id=${ReviewEntityKey}&x=${dateType}&s=${startDate}&r=${endDate}`,
  })

  // const {
  //   isLoading: initiativeLoading,
  //   data: initiativesData,
  //   refetch,
  // } = useGet<any>({
  //   url: `admin/performance/selectanotheremployeeobjectives?person_id=${personForKR}&period_id=${periodID}&for_employee=${true}`,
  //   // enabled: !!selectedPersonId,
  // })

  // const totalCompletedMeasurement = initiativesData?.data.filter(
  //   (item: any) =>
  //     item.employee_objective_status_id == "4" ||
  //     item.employee_objective_status_id == "5"
  // )

  // const Panels: Items[] = [
  //   {
  //     key: "1",
  //     header: (
  //       <div className=" font-avenir">
  //         <h6 className=" text-neutral700 text-base font-extrabold">
  //           Measured Initiatives
  //         </h6>
  //         <p className=" text-neutral400 text-sm font-light">
  //           {totalCompletedMeasurement?.length || 0}/
  //           {initiativesData?.data.length} Completed
  //         </p>
  //       </div>
  //     ),
  //     children: (

  //     ),
  //   },
  // ]
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Key Results",
      children: (
        <div>
          {personInfo?.employeeObjectives.map((item) => (
            <div className="border-b pb-4 mb-4">
              <p className="text-sm">{item?.key_result}</p>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "2",
      label: "Feedback",
      children: (
        <div>
          {personInfo?.employeeFeedback.map((item) => (
            <div className="border-b pb-4 mb-4">
              <h6 className="text-padeBlack font-circular text-base  font-medium">
                {item?.nick_name}
              </h6>
              <p className="text-sm">{item?.description}</p>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "3",
      label: "Conversation",
      children: <p>{text}</p>,
    },
  ]
  const onChange = (key: string | string[]) => {
    console.log(key)
  }
  return (
    <div className=" font-avenir flex flex-col gap-4">
      {isLoading ? (
        <Spin />
      ) : personInfo ? (
        // <DropdownCard
        //   rootClassName="perfCollapse h-auto  bg-white"
        //   items={Panels}
        // />
        <div className="border-2">
          <div className="flex ">
            <Select
              defaultValue={dateType}
              onChange={(value) => {
                if (value === "-1") {
                  setShowCustomDate(true)
                  setDateType(value)
                } else {
                  setDateType(value)
                }
              }}
              style={{ width: 200 }}
              placeholder="Select Date Range"
              options={[
                {
                  value: "1",
                  label: "Last 3 Months",
                },
                {
                  value: "2",
                  label: "Last 6 Months",
                },
                {
                  value: "3",
                  label: "Last 1 Year",
                },
                {
                  value: "-1",
                  label: "Custom",
                },
              ]}
            />

            {showCustomDate && (
              <RangePicker
                format="DD/MM/YYYY"
                onChange={(dates) => {
                  if (dates && dates.length === 2) {
                    const start = dates[0]?.format("DD/MM/YYYY") ?? ""
                    const end = dates[1]?.format("DD/MM/YYYY") ?? ""
                    setStartDate(start)
                    setEndDate(end)
                    refetch()
                  }
                }}
                placeholder={["Start Date", "End Date"]}
                style={{ marginRight: 8 }}
              />
            )}
          </div>
          <Collapse
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
          {/* <Measurements
            completed={totalCompletedMeasurement?.length}
            total={initiativesData?.data.length}
            keyResultDetails={initiativesData?.data || []}
            refetchKeyData={() => refetch()}
            showCompleted={false}
            isProgressUpdateAllowed={false}
            isKRLocked={false}
            permissionGranted={true}
            myInitiativeSection={true}
            showUpdateProgressBtn={false}
          /> */}
        </div>
      ) : (
        <div>No available Initiative</div>
      )}
    </div>
  )
}

export default ReviewViewKeyResults
