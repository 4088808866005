import { useQuery } from "@tanstack/react-query"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React, { useRef } from "react"
import { useAppNotificationStore, useAuthStore } from "stores"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"
import { ITransferResponse } from "./interface"
import { formatCurrencyNoSymbol } from "utils/currency"
import { Spin, Tag, Tooltip } from "antd"
import {
  ClipboardDocumentIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { availableFlags } from "utils/flags"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import ReactToPrint from "react-to-print"
import { Button } from "components"

const ViewTransferDetails = ({
  reference,
  onClose,
  open,
}: {
  reference: string
  onClose: () => void
  open: boolean
}) => {
  const componentRef: any = useRef()
  const { toast } = useAppNotificationStore()
  const [_, copy] = useCopyToClipboard()
  const { isLoading: isTransfersLoading, data: transferData } =
    useQuery<ITransferResponse>({
      queryKey: [`payment-transactions`, reference],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/payment-transactions/${reference}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })
  const foundCurrency = availableFlags?.find(
    (curr) => curr.code === transferData?.data?.currency
  )

  const paymentDetails = [
    {
      title: "Recipient Detail",
      value: transferData?.data?.accountName
        ? transferData?.data?.accountName
        : transferData?.data?.beneficiaryFirstName
        ? `${transferData?.data?.beneficiaryFirstName} ${transferData?.data?.beneficiaryLastName}`
        : "-",
    },
    {
      title: "Account Number",
      value: transferData?.data?.accountNumber ?? "-",
    },
    {
      title: "Fee",
      value: transferData?.data?.fee
        ? `${foundCurrency?.symbol} ${formatCurrencyNoSymbol(
            Number(transferData?.data?.fee)
          )}`
        : "-",
    },
    { title: "Transaction Type", value: transferData?.data?.type ?? "-" },
    { title: " Status", value: transferData?.data?.status ?? "-" },
    { title: " Narration", value: transferData?.data?.description ?? "-" },
    {
      title: " Ref",
      value: transferData?.data?.reference ?? "-",
      copy: transferData?.data?.reference,
    },
  ]

  return (
    <CustomModal
      title={<span className="opacity-0 ">dererß</span>}
      open={open}
      onCancel={onClose}
    >
      {isTransfersLoading ? (
        <div className="flex justify-center items-center my-10">
          <Spin spinning={isTransfersLoading} />
        </div>
      ) : (
        <div
          ref={componentRef}
          className=" flex flex-col items-center"
        >
          <h2 className="font-circular text-[32px] font-bold mb-5">
            {foundCurrency?.symbol}
            {formatCurrencyNoSymbol(Number(transferData?.data?.amount))}
          </h2>
          <div className="flex items-center mb-5">
            <Tag
              color={
                transferData?.data?.status === "successful"
                  ? "success"
                  : transferData?.data?.status === "failed"
                  ? "error"
                  : transferData?.data?.status === "processing"
                  ? "warning"
                  : "default"
              }
            >
              {transferData?.data?.status}
            </Tag>
            {transferData?.data?.status === "failed" && (
              <Tooltip
                title={transferData?.data?.statusMessage}
                className=" cursor-pointer"
              >
                <InformationCircleIcon className="h-4 w-4" />
              </Tooltip>
            )}
          </div>
          <p className="flex text-subText3 text-sm">
            {transferData?.data?.createdAt &&
              formatDateReadable(String(transferData?.data?.createdAt))}{" "}
            {transferData?.data?.createdAt &&
              formatTimeReadble(String(transferData?.data?.createdAt))}
          </p>
          <div className=" mt-[32px] bg-[rgba(204,219,253,0.4)] px-[22px] py-[26px] w-full rounded">
            <h4 className="text-base font-avenir text-padeBlack font-bold mb-[27px]">
              Transaction Details
            </h4>
            <div className=" flex flex-col gap-5">
              {paymentDetails?.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between"
                >
                  <p className="text-sm font-thin">{item?.title}</p>
                  <p className="text-padeBlack font-circular text-sm font-medium flex items-center">
                    {item?.value}
                    {item?.copy && (
                      <button
                        onClick={() => {
                          copy(String(item?.copy))
                          toast.success("Copied")
                        }}
                        className="ml-0 sm:ml-2 shrink-0  text-primary500 p-1 rounded-full"
                      >
                        <ClipboardDocumentIcon className="w-4 h-4" />
                      </button>
                    )}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="mt-2 flex justify-end">
        {componentRef && (
          <ReactToPrint
            trigger={() => (
              <Button
                color="primary"
                title="download"
              >
                Download
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle={`${transferData?.data?.reference}.pdf`}
          />
        )}
      </div>
    </CustomModal>
  )
}

export default ViewTransferDetails
