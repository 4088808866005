import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React from "react"
import { useNavigate } from "react-router-dom"

interface Props {
  onClose: () => void
  open: boolean
}
const DowntimeModal = ({ onClose, open }: Props) => {
  const navigate = useNavigate()
  return (
    <CustomModal
      title={
        <span className="text-padeBlack font-bold font-circular text-base">
          Service Notice
        </span>
      }
      subtitle=""
      onCancel={onClose}
      open={open}
      width={520}
      footer={
        <div className="flex items-center  gap-4 justify-end">
          <Button
            color="secondary"
            onClick={() => {
              navigate(-1)
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={onClose}
          >
            Continue Payment
          </Button>
        </div>
      }
    >
      <div className=" flex flex-col gap-6">
        <p className="text-sm  font-circular text-[#42526D]">
          Due to payment provider downtime, some transactions may fail or delay.
          We’re monitoring the situation and will update as services normalise.
        </p>
      </div>
    </CustomModal>
  )
}

export default DowntimeModal
