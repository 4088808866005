import { useEffect, useMemo, useState } from "react"
import { Button } from "components"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import SingleSend from "./SingleSend"
import MultipleSend from "./MultipleSend"
import { useMutation, useQuery } from "@tanstack/react-query"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import { formatCurrencyNoSymbol } from "utils/currency"
import { Alert, Form, Radio, Spin } from "antd"
import { KycLevel } from "pages/employee-dashboard/wallet/wallet/kyc/interface"
import { IGetFeeRes } from "pages/employee-dashboard/wallet/wallet/crossBorder/viewLocal/sendMoney/interface"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { availableFlags } from "pages/dashboard/wallets/crossBorderPayments/sampleData"
import EnterPin from "../../../pin/EnterPin"
import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import BenBankDetails from "./BenBankDetails"
export const sampleAmounts = [50, 100, 500, 1000]
const SendMoneyEmpCrossBorder = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const location = useLocation()
  const { currencyCode } = location.state || {}
  const currencyInfo = availableFlags.find((flag) => flag.code === currencyCode)
  const [currencyUpdated, setCurrencyUpdated] = useState(currencyCode)
  const [updSymbol, setUpdSymbol] = useState(currencyInfo?.symbol)

  const transferType = Form.useWatch("transferType", form) ?? "Single"
  const [showPinModal, setShowPinModal] = useState(false)
  const [active, setActive] = useState(0)
  const [previewData, setPreviewData] = useState<any[]>([])
  const [fee, setFee] = useState("")
  const [subTotal, setSubTotal] = useState("")
  const [total, setTotal] = useState("")

  const [currentLevel, setCurrentLevel] = useState<null | KycLevel>(null)

  const { isLoading: isLoadingFee, mutate: submitFee } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/payout-charges`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )

      return res?.data
    },

    onSuccess: (data: IGetFeeRes) => {
      const totalFee = data.data.reduce(
        (acc, current) => acc + current.charge,
        0
      )

      const totalAmountSingle = previewData?.reduce(function (
        acc: any,
        obj: any
      ) {
        return Number(acc) + Number(obj.amountconverted)
      },
      0)
      const totalAmountMultiple = previewData?.reduce(function (
        acc: any,
        obj: any
      ) {
        return Number(acc) + Number(obj.amountconverted)
      },
      0)

      setFee(String(totalFee))
      transferType == "Single"
        ? setSubTotal(totalAmountSingle)
        : setSubTotal(totalAmountMultiple)
      transferType == "Single"
        ? setTotal(totalAmountSingle + totalFee)
        : setTotal(totalAmountMultiple + totalFee)
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/employee/wallet/initiate_bank_transfer`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )

      return res?.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data.msg && toast.success(data.msg)
        transferType === "Single"
          ? mixPanelEvent(
              `empl-send-${currencyUpdated}-single-transfer-success`
            )
          : mixPanelEvent(
              `empl-send-${currencyUpdated}-multiple-transfer-success`
            )
        navigate("/employee/dashboard/wallet/cross-border/overview")
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })

  const { isLoading: isLoadingOverview, data: walletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const availableBalance = walletData?.data?.balances?.find(
    (balance) => balance.currency === currencyCode
  )
  // const total=previewData?.
  const amountOverBalance = Number(total) > Number(availableBalance?.balance)

  const filteredAmounts = useMemo(() => {
    return previewData
      .map((item) => ({
        amount: Number(item.amount),
        currency: item?.currency ?? currencyUpdated,
        paymentMethod: item.paymentMethodType,
      }))
      .filter((item) => item.amount > 0)
  }, [previewData])

  useEffect(() => {
    if (filteredAmounts.length) {
      submitFee({
        walletId: EmployeeWalletID,
        payments: filteredAmounts,
      })
    }
  }, [filteredAmounts])
  const handleSubmit = (value: string) => {
    submit({
      pin: value,
      payments: previewData,
    })
    transferType === "Single"
      ? mixPanelEvent(`empl-send-${currencyUpdated}-single-transfer-init`)
      : mixPanelEvent(`empl-send-${currencyUpdated}-multiple-transfer-init`)
  }

  return (
    <DashboardWrapper>
      <div className=" flex flex-col items-start justify-start   gap-6 max-w-[800px] ">
        <div className=" h-full  w-full  ">
          <div className="w-full ">
            <SegmentWrapper
              title="Send Money"
              headerClassName=" xl:!px-[40px]"
              bodyClassName="!pb-0"
              isActive={active === 0 ? true : false}
              actionBtn={
                <div className="gap-[11px] flex items-center ">
                  <img
                    src={currencyInfo?.flag}
                    alt=""
                    className=" h-[17px] w-[17px]"
                  />
                  <h2 className=" text-[#75829A] font-bold font-avenir  text-sm  ">
                    {currencyCode} Account
                  </h2>
                </div>
              }
            >
              <div className="w-full   bg-white  rounded-md ">
                <div className="px-4 md:px-6 lg:px-8 xl:px-[40px] ">
                  <Form
                    layout="vertical"
                    form={form}
                  >
                    <Form.Item
                      label={
                        <span className="font-circular text-padeBlack text-sm  ">
                          Choose type of transfer
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                      name="transferType"
                    >
                      <Radio.Group defaultValue="Single">
                        <Radio value="Single">Single</Radio>
                        <Radio value="Multiple"> Multiple </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form>
                  {/* <Label required="true">Choose type of transfer</Label>
                  <div className="mt-2 flex items-center gap-10">
                    <label className="flex items-center justify-center">
                      <RadioInput
                        name="transfer-type"
                        onChange={() => {
                          setTransferType("Single")
                          setPreviewData([
                            {
                              beneficiary: "",
                              bank_name: "",
                              account_number: "",
                              amount: "",
                            },
                          ])
                        }}
                        checked={transferType == "Single" ? true : false}
                      />
                      <span className="ml-2 text-sm text-padeBlack">
                        Single
                      </span>
                    </label>
                    <label className="flex items-center justify-center">
                      <RadioInput
                        name="transfer-type"
                        onChange={() => {
                          setTransferType("Multiple")
                          setPreviewData([
                            {
                              beneficiary: "",
                              bank_name: "",
                              account_number: "",
                              amount: "",
                            },
                          ])
                        }}
                        checked={transferType == "Multiple" ? true : false}
                      />
                      <span className="ml-2 text-sm text-padeBlack">
                        Multiple
                      </span>
                    </label>
                  </div> */}
                </div>
                {transferType == "Single" ? (
                  <SingleSend
                    currentLevel={currentLevel}
                    x={x}
                    found={currencyInfo}
                    setPreviewData={setPreviewData}
                    handleProceed={() => {
                      setActive(1)
                    }}
                    currencyCode={currencyCode}
                    currencyUpdated={currencyUpdated}
                    setCurrencyUpdated={setCurrencyUpdated}
                    availableBalance={availableBalance?.balance}
                    setUpdSymbol={setUpdSymbol}
                    updSymbol={updSymbol}
                  />
                ) : (
                  <MultipleSend
                    setPreviewData={setPreviewData}
                    currentLevel={currentLevel}
                    amountOverBalance={amountOverBalance}
                    fee={fee}
                    setFee={setFee}
                    found={currencyInfo}
                    submitFee={submitFee}
                    showPinModal={showPinModal}
                    setShowPinModal={setShowPinModal}
                    handleProceed={() => {
                      setActive(2)
                    }}
                    currencyCode={currencyCode}
                    availableBalance={availableBalance?.balance}
                  />
                )}
              </div>
            </SegmentWrapper>
          </div>
        </div>
        {transferType == "Single" && (
          <BenBankDetails
            currencyCode={currencyUpdated}
            isActive={active === 1 ? true : false}
            setActive={setActive}
            setPreviewData={setPreviewData}
            handleProceed={() => {
              setActive(2)
            }}
          />
        )}
        <div className="h-fit w-full  ">
          {" "}
          <FormSectionWrapper
            title="Confirm Transaction"
            headerClassName=" xl:!px-[40px]"
            isActive={active === 2 ? true : false}
            onFinish={(e) => {
              setShowPinModal(true)
            }}
            footerBtns={
              <div className="flex justify-end w-full gap-6 bg-white  items-center">
                <Button
                  title="Previous"
                  color="secondary"
                  type="button"
                  onClick={() => {
                    transferType === "Single" ? setActive(1) : setActive(0)
                  }}
                >
                  Cancel
                </Button>

                <Button
                  title="Next"
                  color="primary"
                  submitting={false}
                  disabled={amountOverBalance || fee == ""}
                >
                  Proceed
                </Button>
              </div>
            }
          >
            {previewData.length > 0 ? (
              <div className="shadow-sm w-full  bg-white px-[10px] xl:!px-[40px]  rounded h-fit">
                {amountOverBalance && (
                  <div className="my-[11px]">
                    <Alert
                      type="error"
                      message="Insufficient funds"
                      description="You need to fund your wallet to complete this transaction. "
                      showIcon
                      className="invoice-alert font-avenir text-neutral500 text-sm w-full flex justify-between items-center  "
                    />
                  </div>
                )}

                {previewData.length > 0 && (
                  <div className="pb-[23px]">
                    <div className=" max-h-[300px] overflow-y-scroll scrollbar-hide ">
                      {previewData.map((item: any, index: any) => (
                        <div
                          key={index}
                          className={`space-y-4 ${
                            index !== previewData?.length - 1
                              ? "border-b border-[#C2C7D0] mb-4 pb-4"
                              : ""
                          }`}
                        >
                          <div className="flex w-full justify-between items-center gap-4 mb-2">
                            <span className="text-primary500 font-circular  text-sm font-medium">
                              Beneficiary {index + 1}
                            </span>
                          </div>
                          <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              Beneficiary
                            </span>
                            <span className=" text-[#243757] font-bold text-sm truncate">
                              {item.beneficiaryFirstName}
                              {item.beneficiaryLastName}
                              {item.beneficiaryCompanyName}
                            </span>
                          </div>
                          <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              {item.paymentMethodType}
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.bankCode}
                            </span>
                          </div>
                          <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              Account number
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.accountNumber}
                            </span>
                          </div>

                          {/* <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              Country
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item.beneficiaryCountryISO}
                            </span>
                          </div> */}

                          <div className="flex w-full justify-between items-center gap-4">
                            <span className="text-[#6C7A93] font-circular  text-sm">
                              Amount
                            </span>
                            <span className="text-[#243757] font-bold text-sm">
                              {item?.symbol ?? updSymbol}{" "}
                              {formatCurrencyNoSymbol(Number(item.amount || 0))}{" "}
                              {item?.currency !== "USD" && (
                                <span className="text-[#243757] font-bold text-sm">
                                  = {currencyInfo?.symbol}{" "}
                                  {formatCurrencyNoSymbol(
                                    Number(item.amountconverted || 0)
                                  )}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Spin spinning={isLoadingFee}>
                      <div
                        className={` mt-8 px-6 py-3 bg-[#E8F0FE] flex  flex-col gap-4`}
                      >
                        <div className="flex w-full justify-between items-center gap-4">
                          <span className="text-[#6C7A93] font-circular  text-sm">
                            Total Amount
                          </span>
                          <span className=" text-padeBlack font-bold text-sm">
                            {currencyInfo?.symbol}{" "}
                            {subTotal
                              ? formatCurrencyNoSymbol(Number(subTotal))
                              : "-"}
                          </span>
                        </div>
                        <div className="flex w-full justify-between items-center gap-4">
                          <span className="text-[#6C7A93] font-circular  text-sm">
                            Total Processing Fee
                          </span>
                          <span className="text-[#243757] font-bold text-sm">
                            {currencyInfo?.symbol}{" "}
                            {fee ? formatCurrencyNoSymbol(Number(fee)) : "-"}
                          </span>
                        </div>
                        <div className="  flex w-full justify-between items-center gap-4">
                          <span className="text-[#6C7A93] font-circular  text-sm">
                            Total Disbursed
                          </span>
                          <span className=" text-padeBlack font-bold text-xl">
                            {currencyInfo?.symbol}{" "}
                            {total
                              ? formatCurrencyNoSymbol(Number(total))
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </Spin>
                  </div>
                )}
              </div>
            ) : null}
          </FormSectionWrapper>
        </div>
        <EnterPin
          title="Enter transaction PIN"
          onClose={() => setShowPinModal(false)}
          open={showPinModal}
          loading={isLoading}
          handleSubmit={handleSubmit}
        />
      </div>
    </DashboardWrapper>
  )
}

export default SendMoneyEmpCrossBorder
