import { Dispatch } from "react"
import OTPInput from "react-otp-input"

export const AppOTPInput = ({
  otp,
  setOtp,
}: {
  otp: string
  setOtp: Dispatch<string>
}) => {
  return (
    <OTPInput
      containerStyle={{
        height: "2.5rem",
      }}
      value={otp}
      onChange={setOtp}
      renderInput={(props) => (
        <input
          {...props}
          className="!border !select-none !font-avenir !border-[#5D6B82] !w-[2.5rem] !h-full mx-2 !focus:border-inherit !hover:border-inherit !rounded-[4px]"
        />
      )}
      numInputs={6}
      shouldAutoFocus
      inputType="number"
    />
  )
}
