import PayslipTemplate from "./PayslipTemplate"
import React, { forwardRef } from "react"
const ComponentToPrintAll = forwardRef(({ allPayslips }: any, ref: any) => {
  return (
    <div
      ref={ref}
      className=" gap-10 flex flex-col"
    >
      {allPayslips?.map((payslip: any, index: any) => (
        <PayslipTemplate data={payslip} />
      ))}
    </div>
  )
})

export default ComponentToPrintAll
