import React, { forwardRef } from "react"
import PadeLogo from "assets/logos/paidhr-logo.svg"
import { formatCurrencyNoSymbol } from "utils/currency"
import TaxRow from "./TaxRow"
import ImageComponent from "components/custom/image"
import { useAuthStore } from "stores"

interface TaxInformation {
  tax_office_name: string
  tax_office_code: string
  tin: string | null
  full_name: string
  employee_title: string | null
  nationality: string | null
  amount: number
  tax_amount: number
  currency_symbol: string
}

const TaxReportToPrint = forwardRef(({ data }: any, ref: any) => {
  const { user } = useAuthStore()
  const groupPensionSchedulesByPfaName = (taxSchedules: TaxInformation[]) => {
    const groupedTaxSchedules: { [key: string]: TaxInformation[] } = {}

    taxSchedules.forEach((schedule) => {
      if (!groupedTaxSchedules[schedule.tax_office_name]) {
        groupedTaxSchedules[schedule.tax_office_name] = []
      }
      groupedTaxSchedules[schedule.tax_office_name].push(schedule)
    })

    return groupedTaxSchedules
  }
  const groupedTaxSchedules = groupPensionSchedulesByPfaName(
    data?.taxSchedules || []
  )

  return (
    <div
      ref={ref}
      className="bg-white shadow-card  p-[35px]"
    >
      <div className=" flex justify-between w-full items-center mb-6">
        <div>
          <h4 className="text-neutral700 font-bold  text-2xl mb-4">
            {data?.scheduleDate}
          </h4>
          <p className=" text-neutral500 text-sm leading-snug">
            Report created on: {data?.report_date}
          </p>
        </div>
        <div className=" ">
          <ImageComponent
            src={user?.organisationAvatarFilename ?? PadeLogo}
            alt="org"
            className={` w-[200px] h-[100px] max-h-[200px] `}
          />
        </div>
      </div>
      <div className="mb-9 h-[117px] border-b bg-primary50 px-[50px] flex justify-between items-center py-5">
        <div className=" gap-4 flex items-center">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {/* {user?.userOrganisation} */}
            </h5>
            <p className=" text-neutral500 font-semibold text-lg leading-snug">
              Tax Schedule Report
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              Payer ID: {data?.taxIdentificationNumber}
            </p>
          </div>
        </div>
        <div className="">
          <div className="">
            <h5 className="text-lg text-neutral700 font-bold mb-2">
              {data?.full_name}
            </h5>
            <p className=" text-neutral500 text-sm leading-snug">
              {data?.officeAddress?.address_line_1}
            </p>
            <p className=" text-neutral500 text-sm leading-snug">
              {data?.officeAddress?.address_city},{" "}
              {data?.officeAddress?.address_state}
            </p>
          </div>
        </div>
      </div>

      <div className=" flex flex-col gap-4">
        <div className="border-b border-black  py-4  grid grid-cols-9  items-center gap-4 ">
          <div className="col-span-1 flex-wrap   font-avenir   text-sm text-[#42526D] font-black ">
            Tax Office
          </div>
          <div className=" col-span-8  flex flex-col gap-2">
            <div className=" grid grid-cols-7  gap-2">
              <span className="flex-wrap   font-avenir   text-sm text-[#42526D] font-black col-span-2">
                Employee Name
              </span>
              <span className="flex-wrap   font-avenir   text-sm text-[#42526D] font-black">
                Designation
              </span>
              <span className="flex-wrap   font-avenir   text-sm text-[#42526D] font-black">
                Nationality
              </span>
              <span className="flex-wrap   font-avenir   text-sm text-[#42526D] font-black">
                TIN
              </span>
              <span className="flex-wrap   font-avenir   text-sm text-[#42526D] font-black">
                Remuneration
              </span>
              <span className="flex-wrap   font-avenir   text-sm text-[#42526D] font-black">
                P.A.Y.E
              </span>
            </div>
          </div>
        </div>
        {Object.keys(groupedTaxSchedules).map((pfaName) => (
          <TaxRow
            pfaName={pfaName}
            groupedPensionSchedules={groupedTaxSchedules}
          />
        ))}
        <OverallTotal groupedTaxSchedules={groupedTaxSchedules} />
      </div>
    </div>
  )
})

const OverallTotal: React.FC<{
  groupedTaxSchedules: any
}> = ({ groupedTaxSchedules }) => {
  const overallTotalRenumeration: any = Object.values(groupedTaxSchedules)
    .flat()
    .reduce((total: any, schedule: any) => total + Number(schedule.amount), 0)
  const overallTotalPAYE: any = Object.values(groupedTaxSchedules)
    .flat()
    .reduce(
      (total: any, schedule: any) => total + Number(schedule.tax_amount),
      0
    )

  return (
    <div className="border-b mt-2 border-black py-4 grid grid-cols-9 items-center gap-4">
      <div className=" flex-wrap font-avenir text-sm text-[#42526D] font-black"></div>
      <div className="col-span-8 flex flex-col gap-2">
        <div className="grid grid-cols-7 gap-2">
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black col-span-2"></span>

          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black"></span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
            Overall total
          </span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
            ₦{formatCurrencyNoSymbol(overallTotalRenumeration)}
          </span>
          <span className="flex-wrap font-avenir text-sm text-[#42526D] font-black">
            ₦{formatCurrencyNoSymbol(overallTotalPAYE)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default TaxReportToPrint
interface Schedule {
  full_name: string
  employer_contribution: number
  employee_contribution: number
}
