import Global from "assets/svg/countries/Global.svg"

export const availableFlags = [
  {
    code: "Global",
    flag: Global,
    symbol: "",
  },
  {
    code: "GBP",
    flag: "https://padefiles.blob.core.windows.net/images/0d45f6fa-31b7-473e-9f07-22a4719a1b8f.png",
    symbol: "£",
  },
  {
    code: "NGN",
    flag: "https://padefiles.blob.core.windows.net/images/c5426cfe-c3a9-44c3-bbff-d924ab022d14.png",
    symbol: "₦",
  },
  {
    code: "USD",
    flag: "https://padefiles.blob.core.windows.net/images/c95e0752-c489-4e80-87f4-ea45950f1ad3.png",
    symbol: "$",
  },
  {
    code: "KES",
    flag: "https://padefiles.blob.core.windows.net/images/55b53c00-ca07-4a35-9001-56e00339886d.png",
    symbol: "Sh",
  },
  {
    code: "CAD",
    flag: "https://padefiles.blob.core.windows.net/images/2d0f27f3-3ab4-4385-a270-417f6e1f0feb.png",
    symbol: "$",
  },
  {
    code: "GHS",
    flag: "https://padefiles.blob.core.windows.net/images/1bbc83d5-30db-4fe1-9430-7cd098a59c73.png",
    symbol: "₵",
  },
  {
    code: "ETB",
    flag: "https://padefiles.blob.core.windows.net/images/3ad1c073-9e34-46ea-8d1c-48c1387bcecf.png",
    symbol: "Br",
  },
  {
    code: "MWK",
    flag: "https://padefiles.blob.core.windows.net/images/c7862328-a19c-426d-b3d0-8e2e66454567.png",
    symbol: "MK",
  },
  {
    code: "RWF",
    flag: "https://padefiles.blob.core.windows.net/images/5657006e-a132-4559-bc6a-57febdc11ead.png",
    symbol: "Fr",
  },
  {
    code: "TZS",
    flag: "https://padefiles.blob.core.windows.net/images/f80f5c11-651c-4e90-94e0-2ac5aeae1d9c.png",
    symbol: "Sh",
  },
  {
    code: "SLL",
    flag: Global,
    symbol: "Le",
  },
  {
    code: "EUR",
    flag: Global,
    symbol: "€",
  },
  {
    code: "ZAR",
    flag: "https://padefiles.blob.core.windows.net/images/c900d71b-5203-48c7-8bb3-44322e39f5c6.png",
    symbol: "R",
  },
  {
    code: "UGX",
    flag: "https://padefiles.blob.core.windows.net/images/4bb40983-0899-40a6-ac4f-333772acac8d.png",
    symbol: "USh",
  },
  {
    code: "TND",
    flag: "https://padefiles.blob.core.windows.net/images/3f7db183-8825-4006-a7fe-1acc0293daa1.png",
    symbol: "",
  },
  {
    code: "XAF",
    flag: "https://padefiles.blob.core.windows.net/images/599acb0a-6de2-40f3-9567-8d6b1b9a3d31.png",
    symbol: "",
  },
  {
    code: "XOF",
    flag: "https://padefiles.blob.core.windows.net/images/a31ca013-371c-4648-9a75-78d99e6df272.png",
    symbol: "",
  },
  {
    code: "MAD",
    flag: "https://padefiles.blob.core.windows.net/images/70c71eda-44c8-41af-a0e3-6c39b09861ae.png",
    symbol: "",
  },
  {
    code: "EGP",
    flag: "https://padefiles.blob.core.windows.net/images/5fa5ccf6-08ee-4602-b0d1-f18f3a2785fa.png",
    symbol: "",
  },
  {
    code: "BDT",
    flag: "https://padefiles.blob.core.windows.net/images/eee451d9-46ca-4b37-ac1a-d36c09af2247.png",
    symbol: "",
  },
  {
    code: "LKR",
    flag: "https://padefiles.blob.core.windows.net/images/bee9ab57-df81-4836-839e-97ed13a0a263.png",
    symbol: "",
  },
  {
    code: "AED",
    flag: "https://padefiles.blob.core.windows.net/images/d82461d4-5a89-438b-b817-2b368acb48d5.png",
    symbol: "",
  },
  {
    code: "IDR",
    flag: "https://padefiles.blob.core.windows.net/images/465bb356-80cc-4f4f-81b8-256b39e1abef.png",
    symbol: "",
  },
  {
    code: "KWD",
    flag: "https://padefiles.blob.core.windows.net/images/723ef9ef-8e2b-4088-8d28-87078a82b778.png",
    symbol: "",
  },
  {
    code: "PHP",
    flag: "https://padefiles.blob.core.windows.net/images/9d97574c-59c3-45d4-b15b-77166f604fe2.png",
    symbol: "",
  },
  {
    code: "CNY",
    flag: "https://padefiles.blob.core.windows.net/images/a7fd1159-f0b5-4bbd-aae7-15f9e4cc6bd6.png",
    symbol: "",
  },
  {
    code: "ILS",
    flag: "https://padefiles.blob.core.windows.net/images/7c156faa-af0e-4e8a-8b01-6d910aa3cfd4.png",
    symbol: "",
  },
  {
    code: "THB",
    flag: "https://padefiles.blob.core.windows.net/images/55515acf-717e-4c20-9e43-ae6d8b2619be.png",
    symbol: "",
  },
  {
    code: "PKR",
    flag: "https://padefiles.blob.core.windows.net/images/dec03831-a621-4f41-b500-de342b26eadc.png",
    symbol: "",
  },
  {
    code: "HKD",
    flag: "https://padefiles.blob.core.windows.net/images/06eae7a5-2cb6-45d9-b9f3-c0d70a4582f4.png",
    symbol: "",
  },
  {
    code: "SGD",
    flag: "https://padefiles.blob.core.windows.net/images/2162c860-befd-4efa-a499-3233431a7e94.png",
    symbol: "",
  },
  {
    code: "BHD",
    flag: "https://padefiles.blob.core.windows.net/images/3b57d1fe-ba1c-4f39-a213-250c087b6c2e.png",
    symbol: "",
  },
  {
    code: "INR",
    flag: "https://padefiles.blob.core.windows.net/images/a90a5137-95e3-4627-aa30-e9695f8c5f2d.png",
    symbol: "",
  },
  {
    code: "MYR",
    flag: "https://padefiles.blob.core.windows.net/images/3cb2a1a1-0015-412e-85f1-f85c7b69f2fe.png",
    symbol: "",
  },
  {
    code: "OMR",
    flag: "https://padefiles.blob.core.windows.net/images/1f688a76-b21b-4915-99ba-96a0439b5bb4.png",
    symbol: "",
  },
  {
    code: "QAR",
    flag: "https://padefiles.blob.core.windows.net/images/5185c119-e01c-4462-8f57-886948f52c54.png",
    symbol: "",
  },
  {
    code: "SAR",
    flag: "https://padefiles.blob.core.windows.net/images/cfd0ed4b-e5d2-4aa3-8e16-f4512970ee0b.png",
    symbol: "",
  },
  {
    code: "JPY",
    flag: "https://padefiles.blob.core.windows.net/images/9e1b23ae-5336-4198-b416-61be0f0db5fd.png",
    symbol: "",
  },
  {
    code: "CHF",
    flag: "https://padefiles.blob.core.windows.net/images/d53f59af-8f7d-44df-a2fe-072109e94ee6.png",
    symbol: "",
  },
  {
    code: "DKK",
    flag: "https://padefiles.blob.core.windows.net/images/d05e796d-c61b-4b9e-9ed2-ab88f4466697.png",
    symbol: "",
  },
  {
    code: "NOK",
    flag: "https://padefiles.blob.core.windows.net/images/d465158e-eb7f-40de-9fe7-b7dbfe165d0d.png",
    symbol: "",
  },
  {
    code: "RON",
    flag: "https://padefiles.blob.core.windows.net/images/24fa3cf6-2d04-4cca-bfcd-fccf012f8282.png”",
    symbol: "",
  },
  {
    code: "SEK",
    flag: "https://padefiles.blob.core.windows.net/images/4f48d27e-450b-43c8-8f27-4443ab7aec26.png",
    symbol: "",
  },
  {
    code: "BGN",
    flag: "https://padefiles.blob.core.windows.net/images/d1e516c4-19a1-4cf3-a55f-8634122285ed.png",
    symbol: "",
  },
  {
    code: "PLN",
    flag: "https://padefiles.blob.core.windows.net/images/31a8bb56-d6cf-4aae-96ed-eb22414a0037.png",
    symbol: "",
  },
  {
    code: "CZK",
    flag: "https://padefiles.blob.core.windows.net/images/e6270eb4-996f-45cf-974a-d2654168d3d9.png",
    symbol: "",
  },
  {
    code: "HUF",
    flag: "https://padefiles.blob.core.windows.net/images/49911080-ed0b-48e4-8516-6f700fb2556d.png",
    symbol: "",
  },
  {
    code: "TRY",
    flag: "https://padefiles.blob.core.windows.net/images/cb21f2c2-180b-4073-bb4e-4d07b7340ebb.png",
    symbol: "",
  },
  {
    code: "MXN",
    flag: "https://padefiles.blob.core.windows.net/images/20bd7d5a-aed9-427e-9a42-e227f08f117f.png",
    symbol: "",
  },
  {
    code: "AUD",
    flag: "https://padefiles.blob.core.windows.net/images/3ace6cb4-1b4a-4be1-bdd8-e41dd80a5af9.png",
    symbol: "",
  },
  {
    code: "NZD",
    flag: "https://padefiles.blob.core.windows.net/images/33aadbed-0b73-4886-a6dd-76d49584daa2.png",
    symbol: "",
  },
  {
    code: "BRL",
    flag: "https://padefiles.blob.core.windows.net/images/75655e27-c02e-4425-ad32-7e4752c8f92f.png",
    symbol: "",
  },
]

export const getCurrencyDataBySymbol = (symbol: string) => {
  return availableFlags.find((i) => i.symbol === symbol)
}

export const getCurrencyDataByCode = (currCode: string) => {
  return availableFlags.find((i) => i.code === currCode)
}
