import { FC, lazy, PropsWithChildren } from "react"
import { Route } from "react-router-dom"
import { JobPostingContextContainer } from "./components/job-posting/context/JobPostingContext"
import RequireRoutePermit from "RequireRoutePermit"
import { useAuthStore } from "stores"
import HiringLandingPage from "./screens/HiringLandingPage"

const PostJob = lazy(() => import("./screens/PostJob"))

const JobDetails = lazy(() => import("./screens/JobDetails"))
const ReviewCandidate = lazy(() => import("./screens/ReviewCandidate"))
const Settings = lazy(() => import("./screens/Settings"))
const HiringDashboard = lazy(() => import("./screens/HiringDashboard"))
const CreateTemplatePage = lazy(
  () => import("./components/settings/CreateEditTemplatePage")
)
// const ViewTemplate = lazy(() => import("./components/settings/ViewTemplate"))

const CreateEditPipelinePage = lazy(
  () => import("./components/settings/CreateEditPipelinePage")
)
const ViewPipeline = lazy(() => import("./screens/ViewPipeline"))
const AllJobs = lazy(() => import("./screens/AllJobs"))
const AllApplicants = lazy(() => import("./screens/AllApplicants"))
const ApplicantDetail = lazy(() => import("./screens/ApplicantDetail"))
const ScheduleInterview = lazy(() => import("./screens/ScheduleInterview"))

type Prop = {
  permissions: string[]
}

const ComponentWithPermission: FC<PropsWithChildren<Prop>> = ({
  permissions,
  children,
}) => {
  const { user } = useAuthStore()
  const isEnabled = user?.isAtsEnabled === "True" ? true : false
  // const isEnabled = false
  return (
    <RequireRoutePermit allowedPermissions={permissions}>
      {isEnabled ? children : <HiringLandingPage />}
    </RequireRoutePermit>
  )
}

export function hiringRoutes() {
  return (
    <Route path="hiring">
      <Route
        path="dashboard"
        index
        element={
          <ComponentWithPermission permissions={["can_view_jobs"]}>
            <HiringDashboard />
          </ComponentWithPermission>
        }
      />

      <Route
        path="post-job"
        element={
          <JobPostingContextContainer>
            <ComponentWithPermission permissions={["can_post_jobs"]}>
              <PostJob />
            </ComponentWithPermission>
          </JobPostingContextContainer>
        }
      />
      <Route
        path="settings"
        element={
          <ComponentWithPermission permissions={["can_view_jobs"]}>
            <Settings />
          </ComponentWithPermission>
        }
      />
      <Route
        path="jobs"
        element={
          <ComponentWithPermission permissions={["can_view_jobs"]}>
            <AllJobs />
          </ComponentWithPermission>
        }
      />

      <Route
        path="applicants"
        element={
          <ComponentWithPermission permissions={["can_view_applicants"]}>
            <AllApplicants />
          </ComponentWithPermission>
        }
      />
      <Route
        path="applicants/:applicantId"
        element={
          <ComponentWithPermission permissions={["can_view_applicants"]}>
            <ApplicantDetail />
          </ComponentWithPermission>
        }
      />
      <Route
        path="settings/pipeline"
        element={<ViewPipeline />}
      />
      <Route
        path="settings/create-template"
        element={<CreateTemplatePage type="create" />}
      />
      <Route
        path="settings/edit-template"
        element={<CreateTemplatePage type="edit" />}
      />
      {/* <Route
        path="settings/view-template"
        element={<ViewTemplate />}
      /> */}
      <Route
        path="settings/pipeline/create"
        element={<CreateEditPipelinePage type={"create"} />}
      />
      <Route
        path="settings/pipeline/edit"
        element={<CreateEditPipelinePage type={"edit"} />}
      />

      <Route
        path="job/:jobId"
        element={
          <ComponentWithPermission permissions={["can_view_jobs"]}>
            <JobDetails />
          </ComponentWithPermission>
        }
      />
      <Route
        path="review/:jobId/applicant/:applicantId"
        element={
          <ComponentWithPermission
            permissions={["can_view_applicants", "can_view_jobs"]}
          >
            <ReviewCandidate />
          </ComponentWithPermission>
        }
      />
      <Route
        path="schedule-interview"
        element={<ScheduleInterview />}
      />
    </Route>
  )
}
