import { Form, Spin } from "antd"
import { Button } from "components"
import { AppSegmentWrapper } from "components/custom/AppSegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useGet, usePost } from "utils/useFetch"
import { PermissionsPicker } from "./PermissionsPicker"
import { RoleForm } from "./RoleForm"
import { ICustomRoleRequest, IRole } from "./interface/interface"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { LoadingButton } from "components/buttons/LoadingButton"
import usePermissions from "hooks/usePermissions"
import SegmentWrapper from "components/custom/SegmentWrapper"

const ViewRoleDetailPage = () => {
  const { roleId } = useParams()

  const {
    data: role,
    isLoading,
    refetch,
  } = useGet<IRole>({
    url: `/admin/roles/view/edit?id=${roleId}`,
  })

  const [form] = Form.useForm()

  const [canEditRole] = usePermissions({
    allowedPermissions: ["can_edit_custom_roles"],
  })

  const [employees, setEmployees] = useState<string[]>([])

  const [isEditMode, setIsEditMode] = useState<boolean>(false)

  const [roleDetail, setRoleDetail] = useState<any>()

  const [formData, setFormData] = useState<ICustomRoleRequest>()

  //@: Local state to manage the selected permissions
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])

  const { data: admins, isLoading: isGetting } = usePost<any>({
    url: `/admin/company/getadmins?includePrimary=${!role?.is_default}`,
    body: {
      q: "",
      page: "",
    },
  })
  const { toast } = useAppNotificationStore()

  const navigate = useNavigate()

  const { mutate, isLoading: isUpdating } = useMutation({
    mutationFn: async (data: ICustomRoleRequest) => {
      const res = await axiosInstance.put(`/admin/roles/update`, data)
      return res.data
    },
    onSuccess: (data) => {
      if (data.status == "success") {
        data?.msg && setTimeout(() => toast.success(data?.msg), 100)
        refetch()
        setIsEditMode(false)
      } else {
        data?.msg && setTimeout(() => toast.error(data?.msg), 100)
      }
    },
  })

  useEffect(() => {
    setRoleDetail(role)
    // setFormData(role)
    setEmployees(role?.roleMembers?.map((x) => x?.nick_name) as string[])
    setSelectedPermissions(
      role?.rolePermissions?.map((x: any) =>
        x?.permission_id?.toString()
      ) as string[]
    )
  }, [role])

  return (
    <DashboardWrapper>
      <Spin spinning={isLoading}>
        <SegmentWrapper
          title="Role Details"
          actionBtn={
            role &&
            !role?.is_default && (
              <Button
                color="primary"
                onClick={() => {
                  if (canEditRole) {
                    setIsEditMode(true)
                  } else {
                    toast.error("You don't have permission to edit this role")
                  }
                }}
                className={`${!isEditMode ? "flex" : "hidden"}`}
              >
                Edit
              </Button>
            )
          }
        >
          <div className="flex flex-col relative h-screen overflow-y-hidden overflow-x-hidden px-4 md:px-6 xl:px-[60px] -mb-10">
            <div className="grid lg:grid-cols-[40%,60%] gap-2 lg:gap-0 grid-cols-1 box-border h-[100%]">
              <RoleForm
                form={form}
                employees={employees}
                setEmployees={setEmployees}
                isEditMode={isEditMode}
                initialData={roleDetail}
                showPeople={!isEditMode}
                isCustomRole={!role?.is_default}
                admins={admins}
                roleKey={roleId}
                onUpdateData={(values: any) => {
                  setFormData(values)
                }}
                onSuccess={(status) => {
                  if (status) {
                    refetch()
                  }
                }}
                onFinish={() => {
                  mutate({
                    ...formData,
                    permissions: selectedPermissions.map((permission) =>
                      String(permission)
                    ),
                    assignments: employees?.map((x: string) =>
                      admins?.find((it: any) => it?.text === x)?.id?.toString()
                    ),
                  } as ICustomRoleRequest)
                }}
              />

              <PermissionsPicker
                selectedPermissions={selectedPermissions}
                setSelectedPermissions={setSelectedPermissions}
                isEditMode={isEditMode}
              />
            </div>
            {isEditMode && (
              <div className="flex justify-end gap-3 mt-6 border-t pr-3 pt-6 pb-4 absolute bottom-0 right-0 w-full bg-white">
                <Button
                  color="neutral"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault()

                    mutate({
                      id: role?.role_id?.toString(),
                      ...formData,
                      permissions: selectedPermissions.map((permission) =>
                        String(permission)
                      ),
                    } as ICustomRoleRequest)
                  }}
                  type="submit"
                  submitting={isUpdating}
                >
                  Save changes
                </Button>
              </div>
            )}
          </div>
        </SegmentWrapper>
      </Spin>
    </DashboardWrapper>
  )
}

export default ViewRoleDetailPage
