import React, { useEffect, useState } from "react"

const NetworkStatus: React.FC = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
    }

    const handleOffline = () => {
      setIsOnline(false)
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  return !isOnline ? (
    <div
      className="fixed bottom-0 right-0 p-4 bg-gray-900 text-white z-50"
      style={{ zIndex: 9999 }}
    >
      <p className="flex items-center">
        <svg
          className="w-4 h-4 mr-2 fill-current text-red-500"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M10 20a9.96 9.96 0 0 0 7.072-2.928A9.96 9.96 0 0 0 20 10c0-2.37-.83-4.557-2.336-6.27A9.96 9.96 0 0 0 10 0C7.63 0 5.443.83 3.73 2.336 1.828 4.237.83 6.57.83 9c0 2.37.83 4.557 2.336 6.27A9.96 9.96 0 0 0 10 20zm-.707-5.15l4.242-4.243L14.243 10l-4.242 4.243-1.414-1.414L11.828 10 9.293 7.464l1.414-1.414z"
          />
        </svg>
        Your network connection is lost
      </p>
    </div>
  ) : null
}

export default NetworkStatus
