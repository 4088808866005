import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline"
import { useMutation } from "@tanstack/react-query"
import { Button, Input, ModalWrapper } from "components"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import React, { useState, useEffect } from "react"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
import { IReviewEntityFeedbacks } from "../interface/listings"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"

export const ProfessionalDvt: React.FC<{
  employeeReviewId: string | number
  invalidateQueries: Function
  feedback: IReviewEntityFeedbacks[] | any[]
}> = ({ employeeReviewId, invalidateQueries, feedback }) => {
  const { toast } = useAppNotificationStore()

  const [updatedGoal, setUpdatedGoal] = useState(feedback)
  const [showAddNew, setShowAddNew] = useState(false)
  const [activeGoal, setActiveGoal] = useState("")
  const [activeDeleteFeedback, setActiveDeleteFeedback] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    setUpdatedGoal(feedback)
  }, [feedback])
  const { isLoading, mutate: saveResponse } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/performance/save_review_entity_feedback`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        invalidateQueries()
        {
          data.msg && toast.success(data.msg)
        }
        mixPanelEvent("pf-review-professional-devt-add-success")

        setUpdatedGoal([
          {
            description: activeGoal,
          },
          ...feedback,
        ])
        setShowAddNew(false)
        setActiveGoal("")
      }
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const { isLoading: isLoadingDelete, mutate: deleteFeedback } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/performance/delete_review_entity_feedback?id=${activeDeleteFeedback}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status == "success") {
        {
          data.msg && toast.success(data.msg)
        }
        mixPanelEvent("pf-review-professional-devt-delete-success")

        invalidateQueries()
        setDeleteModal(false)
        setActiveDeleteFeedback("")
      } else {
        {
          data.msg && toast.error(data.msg)
        }
        setDeleteModal(false)
        setActiveDeleteFeedback("")
      }
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })

  const handleAddFeedback = () => {
    const body = {
      review_entity_id: String(employeeReviewId),
      review_entity_feedback_type_id: "1",
      description: activeGoal,
    }
    saveResponse(body)
  }
  const handleDelete = () => {
    deleteFeedback()
  }

  return (
    <div className="border mt-8 font-avenir flex flex-col gap-4 relative">
      <div className="bg-white  rounded-5px    shadow-card">
        <div className=" ">
          <h4 className="text-sm sm:text-base font-extrabold text-neutral700 my-4 px-4 sm:px-[22px]">
            Professional development goals
          </h4>
          <div className="border border-neutral50 w-full mb-[14px]" />
          <div className=" flex flex-col gap-4 px-4 sm:px-5">
            {updatedGoal.length ? (
              updatedGoal.map((dev, index) => (
                <div
                  key={index}
                  className="min-h-[52px] max-h-[120px] overflow-y-scroll rounded-5px border border-cardgrey justify-between items-center px-4 sm:px-5 flex"
                >
                  <h6 className="text-neutral700  text-sm sm:text-base font-medium">
                    {dev?.description}
                  </h6>
                  <p
                    className=" text-danger400 text-sm font-medium cursor-pointer"
                    onClick={() => {
                      setDeleteModal(true)
                      setActiveDeleteFeedback(dev.review_entity_feedback_id)
                    }}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </p>
                </div>
              ))
            ) : (
              <div className="text-neutral500 font-medium flex justify-center items-center w-full pt-10 ">
                No available professional development goals
              </div>
            )}
            {showAddNew && (
              <div className=" border border-neutral30  sm:py-6 my-4 sm:px-3 rounded-5px">
                <div className=" bg-[#f3f5fbcf] sm:p-2 rounded-5px h-auto border border-neutral30 flex flex-col justify-between gap-2 ">
                  <Input
                    type={"text"}
                    name={""}
                    // id="mobile-number"
                    required={true}
                    placeholder="Goal title"
                    value={activeGoal}
                    onChange={(e) => {
                      setActiveGoal(e.target.value)
                    }}
                    className=" px-2 sm:px-3  text-sm border-none focus:border border-[#C2C7D0] !bg-transparent"
                  />

                  <div className="flex items-center justify-end gap-2 mt-3">
                    <Button
                      title="Previous"
                      color="secondary"
                      className="font-extrabold"
                      onClick={() => {
                        setActiveGoal("")
                        setShowAddNew(false)
                      }}
                    >
                      Cancel
                    </Button>

                    {isLoading ? (
                      <Button
                        title="Next"
                        color="primary"
                        className="font-extrabold"
                      >
                        <LoadingIndicatorWhite />
                      </Button>
                    ) : (
                      <Button
                        title="Next"
                        color="primary"
                        className="font-extrabold "
                        disabled={activeGoal == "" ? true : false}
                        // onClick={handleAddGoals}
                        onClick={handleAddFeedback}
                      >
                        Add goal
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <button
              className="mt-6    text-xs sm:text-sm text-primary500 flex items-center gap-2 mb-3 font-extrabold"
              onClick={() => {
                setShowAddNew(!showAddNew)
                mixPanelEvent("pf-review-professional-devt-add-init")
              }}
            >
              <PlusIcon className=" w-4 sm:w-5 stroke-2" />
              <p>Add professional development goals</p>
            </button>
          </div>
        </div>
      </div>
      {deleteModal && (
        <ModalWrapper
          title="Delete professional development goals"
          onClose={() => {
            setDeleteModal(false)
            setActiveDeleteFeedback("")
          }}
        >
          <div className="px-5 py-3 flex flex-col items-center">
            <p className="text-[#15294B] text-sm mb-6">
              If you delete this goal, it would be removed from your list. Are
              you sure you want to continue?
            </p>
          </div>
          <div className="border py-5 px-6 flex gap-4  justify-end">
            <Button
              title="cancel"
              color="secondary"
              onClick={() => {
                setDeleteModal(false)
                setActiveDeleteFeedback("")
              }}
            >
              Cancel
            </Button>

            <Button
              title="remove"
              color="primary"
              submitting={isLoadingDelete}
              onClick={() => {
                handleDelete()
                mixPanelEvent("pf-review-professional-devt-delete-init")
              }}
            >
              Confirm
            </Button>
          </div>
        </ModalWrapper>
      )}
    </div>
  )
}
